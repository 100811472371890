import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map } from 'rxjs/operators';

@Injectable()
export class MedicineConfigService {
  constructor(private http: HttpClient) {}

  create(value): Observable<any> {
    return this.http
      .post(
        `${environment.rorUrl}/v4/medicine_configurators`,
        {
          medical_unit_id: localStorage.getItem('current_medical_unit_id'),
          auth_token: localStorage.getItem('auth_token'),
          medicine_configurator: value,
        },
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
  }
  createDosesv2(value): Observable<any> {
    return this.http
      .post(
        `${environment.pythonUrl}/api/medicine_configurators/?&auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          medical_unit_id: localStorage.getItem('current_medical_unit_id'),
          auth_token: localStorage.getItem('auth_token'),
          medicine_configurator: value,
        },
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
  }
  createFrequancy(value): Observable<any> {
    const results = this.http
      .post(
        `${environment.pythonUrl}/api/patient_medication_frequency/?auth_token=` +
          localStorage.getItem('auth_token'),
        {
          medical_unit_id: localStorage.getItem('current_medical_unit_id'),
          medicine_configurator: value,
        },
        {
          headers: new HttpHeaders({
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  getFrequancyListData(): Observable<any> {
    const results = this.http
      .get(
        `${environment.pythonUrl}/api/patient_medication_frequency/?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  // API for update Frequancy configuration/mecidine-config
  updateFrequancy(value, id): Observable<any> {
    const results = this.http
      .put(
        `${environment.pythonUrl}/api/patient_medication_frequency/` +
          id +
          `/?&auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          medical_unit_id: localStorage.getItem('current_medical_unit_id'),
          medicine_configurator: value,
        },
        {
          headers: new HttpHeaders({
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  updateMedicine(
    item_name,
    stoc_type_id,
    dosee_id,
    dos_type_id,
    dose_other_quantity,
    routee_id,
    no_of_days,
    from_date,
    to_date,
    pharmacist_comments,
    id,
    calculated_dose,
  ): Observable<any> {
    const results = this.http
      .put(
        `${environment.pythonUrl}/opd/patient_medication_flow/update_medicine/?&auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&id=' +
          id,
        {
          medical_unit_id: localStorage.getItem('current_medical_unit_id'),
          item_name,
          stoc_type_id,
          dosee_id,
          dos_type_id,
          dose_other_quantity,
          routee_id,
          no_of_days,
          from_date,
          to_date,
          pharmacist_comments,
          calculated_dose,
        },
        {
          headers: new HttpHeaders({
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  updateOrApprovedMedicine(
    dose_other_quantity,
    item_name,
    item_id,
    stoc_type_id,
    dosee_id,
    dos_type_id,
    routee_id,
    no_of_days,
    from_date,
    to_date,
    pharmacist_comments,
    id,
    calculated_dose,
    uom_id,
    strength
  ): Observable<any> {
    const results = this.http
      .put(
        `${environment.pythonUrl}/opd/patient_medication_flow/approve_medicine/?&auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&id=' +
          id,
        {
          medical_unit_id: localStorage.getItem('current_medical_unit_id'),
          dose_other_quantity,
          item_name,
          item_id,
          stoc_type_id,
          dosee_id,
          dos_type_id,
          routee_id,
          no_of_days,
          from_date,
          uom_id,
          strength,
          to_date,
          pharmacist_comments,
          calculated_dose,
        },
        {
          headers: new HttpHeaders({
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  rejectMedicine(id, rejectionReason): Observable<any> {
    const results = this.http
      .put(
        `${environment.pythonUrl}/opd/patient_medication_flow/reject_medicine/?&auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&id=' +
          id,
        {
          medical_unit_id: localStorage.getItem('current_medical_unit_id'),
          reject_reason: rejectionReason,
        },
        {
          headers: new HttpHeaders({
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  issuedMedicineRequest(
    expiryDate,
    comment,
    issueQTy,
    id,
    issueWardId,
  ): Observable<any> {
    const results = this.http
      .put(
        `${environment.pythonUrl}/opd/patient_medication_flow/issue_medicine/?&auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&id=' +
          id,
        {
          medical_unit_id: localStorage.getItem('current_medical_unit_id'),
          issue_quantity: issueQTy,
          expiry: expiryDate,
          comments: comment,
          req_issue_to_ward: issueWardId,
        },
        {
          headers: new HttpHeaders({
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  cancelMedicine(id): Observable<any> {
    const results = this.http
      .put(
        `${environment.pythonUrl}/opd/patient_medication_flow/cancel_medicine/?&auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&id=' +
          id,
        {
          medical_unit_id: localStorage.getItem('current_medical_unit_id'),
        },
        {
          headers: new HttpHeaders({
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  getMedicineQtyForReturn(id): Observable<any> {
    const results = this.http
      .get(
        `${environment.pythonUrl}/opd/patient_medication_flow/get_patient_medication_quantity_wise/?&auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&patient_medication_id=' +
          id,
        {
          headers: new HttpHeaders({
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  receiveReturnMedicineListing(patientMrn): Observable<any> {
    const results = this.http
      .get(
        `${environment.pythonUrl}/api/patient_medication_return_log/?&auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&patient_mrn=' +
          patientMrn +
          '&status=pending',
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  returnMedicine(id, qty): Observable<any> {
    const results = this.http
      .post(
        `${environment.pythonUrl}/api/patient_medication_return_log/?&auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&id=' +
          id,
        {
          medical_unit_id: localStorage.getItem('current_medical_unit_id'),
          patient_medication_id: id,
          return_quantity: qty,
        },
        {
          headers: new HttpHeaders({
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  // API for confirm Medicine Transfusion ipd/medicine/Administration
  confirmTransfusionMedicine(
    intervalId,
    medicationId,
    patientId,
    visitId,
    EmpId,
    userPassword,
    calculatedDose,
    medicineDate,
  ): Observable<any> {
    const results = this.http
      .post(
        `${environment.pythonUrl}/medication/patient_medication/` +
          medicationId +
          `/apply_dose/?&auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          medical_unit_id: localStorage.getItem('current_medical_unit_id'),
          frequency_interval: intervalId,
          patient_medication: medicationId,
          patient_id: patientId,
          visit_id: visitId,
          user_id: EmpId,
          medicine_date: medicineDate,
          user_password: userPassword,
          calculated_dose: calculatedDose,
        },
        {
          headers: new HttpHeaders({
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  // API for confirm Medicine Discontinue ipd/medicine/Administration
  confirmDiscontinueMedicine(
    medicationId,
    patientId,
    visitId,
    EmpId,
    userPassword,
  ): Observable<any> {
    const results = this.http
      .post(
        `${environment.pythonUrl}/medication/patient_medication/` +
          medicationId +
          `/dose_discontinue/?&auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          medical_unit_id: localStorage.getItem('current_medical_unit_id'),
          // frequency_interval: intervalId,
          patient_medication: medicationId,
          patient_id: patientId,
          visit_id: visitId,
          user_id: EmpId,
          user_password: userPassword,
        },
        {
          headers: new HttpHeaders({
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  // API for medicine List Against patientId, visitId
  getmedicineAdministration(
    patientId,
    visitId,
    medicineDate,
    frequency,
    isAdministration,
  ): Observable<any> {
    const results = this.http
      .get(
        `${environment.pythonUrl}/medication/patient_medication/?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&patient_id=' +
          patientId +
          '&visit_id=' +
          visitId +
          '&medicine_date=' +
          medicineDate +
          '&frequency=' +
          frequency +
          '&is_administration=' +
          isAdministration,
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }

  // API for medicine detail against Medicine ID
  getmedicineDetail(medicineId, medicineDate): Observable<any> {
    const results = this.http
      .get(
        `${environment.pythonUrl}/medication/patient_medication/` +
          medicineId +
          `/medication_detail/?&auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&medicine_date=' +
          medicineDate,
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  index(type, status): Observable<any> {
    const results = this.http
      .get(
        `${environment.rorUrl}/v4/medicine_configurators?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&type=' +
          type +
          '&status=' +
          status,
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  indexconfigv2(type, status): Observable<any> {
    const results = this.http
      .get(
        `${environment.pythonUrl}/api/medicine_configurators?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&type=' +
          type +
          '&status=' +
          status,
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  update(value, id): Observable<any> {
    const results = this.http
      .put(
        `${environment.rorUrl}/v4/medicine_configurators/${id}`,
        {
          medical_unit_id: localStorage.getItem('current_medical_unit_id'),
          auth_token: localStorage.getItem('auth_token'),
          medicine_configurator: value,
        },
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  updateDosesv2(value, id): Observable<any> {
    const results = this.http
      .put(
        `${environment.pythonUrl}/api/medicine_configurators/${id}/?&auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          medical_unit_id: localStorage.getItem('current_medical_unit_id'),
          auth_token: localStorage.getItem('auth_token'),
          medicine_configurator: value,
        },
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
}
