<!--begin::Content-->
<div class="d-flex flex-column flex-column-fluid">
  <!--begin::Subheader-->
  <div class="subheader py-2 py-lg-6 subheader-solid" id="kt_subheader">
    <div
      class="container-fluid d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap"
    >
      <!--begin::Info-->
      <div class="d-flex justify-content-between flex-wrap mr-1">
        <!--begin::Page Heading-->
        <div class="d-flex align-items-baseline flex-wrap mr-5">
          <!--begin::Page Title-->
          <h5 class="text-dark font-weight-bold my-1 mr-5">Doctor Notes</h5>
        </div>
        <!--end::Page Heading-->
      </div>
      <!--end::Info-->
      <!--begin::Toolbar-->
      <div class="d-flex align-items-center">
        <div>
          <button type="button" class="btn btn-sm btn-primary" (click)="routePatientDialysis()">Patient Dialysis <i class="fa fa-arrow-right"></i></button>
        </div>
      </div>
      <!--end::Toolbar-->
    </div>
  </div>
  <!--end::Subheader-->
  <div class="position-fixed patient-details mb-3 w-100" style="z-index: 98">
    <div class="px-7">
      <app-patient-details
        [patient]="currentPatientData?.patient"
      ></app-patient-details>
    </div>
  </div>
  <!--begin::Entry-->
  <div class="d-flex flex-column-fluid main-content mt-21">
    <!--begin::Container-->
    <!--    container-xl container-xxl-->
    <div class="container-fluid">
      <div class="row">
        <div class="col-md-9">
          <form [formGroup]="DoctorNotesForm">
            <div class="card card-custom gutter-b">
              <div class="px-7 pt-7" style="min-height: 50px; border: 0">
                <div class="card-title m-0 d-flex justify-content-between">
                  <div>
                    <h5
                      class="card-label font-weight-bolder text-dark text-left w-100"
                    >
                      Questionnaire
                    </h5>
                  </div>
                </div>
              </div>
              <div
                class="card-body pt-1 patient-listing"
                style="max-height: 200px"
              >
                <div class="table-responsive position-relative">
                  <table
                    class="table table-head-custom table-vertical-center"
                  >
                    <thead>
                    <tr>
                      <td style="width: 85%">Question</td>
                      <td style="width: 15%" class="text-right">
                        Response
                      </td>
                    </tr>
                    </thead>
                    <tbody class="">
                    <ng-container
                      *ngFor="
                            let questionnaire of questionnaires;
                            let i = index
                          "
                    >
                      <tr>
                        <td>{{ questionnaire?.question }}</td>
                        <td class="text-right">
                          <div
                            class="d-flex align-items-center justify-content-end"
                          >
                            <label class="checkbox">
                              <input
                                type="checkbox"
                                class="singleCheck"
                                id="singleCheck{{ i }}"
                                [(ngModel)]="questionnaire.is_active"
                                [ngModelOptions]="{ standalone: true }"
                              />
                              <span></span>
                            </label>
                          </div>
                        </td>
                      </tr>
                      <!--                          </div>-->
                    </ng-container>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <div class="card card-custom gutter-b">
              <div class="px-7 pt-7" style="min-height: 50px; border: 0">
                <div class="card-title m-0 d-flex justify-content-between">
                  <!--                  <div class="card-title align-items-start flex-column justify-content-center w-100">-->
                  <div>
                    <h5
                      class="card-label font-weight-bolder text-dark text-left w-100"
                    >
                      Screening Test Result
                    </h5>
                  </div>
                </div>
              </div>

              <div class="card-body pt-1">
                <div class="row">
                  <div class="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12">
                    <div class="form-group">
                      <label>HCV</label>
                      <select
                        class="form-control form-control-sm"
                        data-live-search="true"
                        formControlName="hcv"
                      >
                        <option value="" [disabled]="true">Select</option>
                        <option value="Reactive">Reactive</option>
                        <option value="Non Reactive">Non Reactive</option>
                        <option value="Borderline">Borderline</option>
                        <option value="Positive">Positive</option>
                        <option value="Negative">Negative</option>
                      </select>
                    </div>
                  </div>
                  <div class="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12">
                    <div class="form-group">
                      <label>PCR</label>
                      <!--                    <input type="text" class="form-control form-control-sm" formControlName="pregnancies" placeholder="Pregnancies"/>-->
                      <select
                        class="form-control form-control-sm normal-height p-lr-0"
                        formControlName="pcr"
                        id="pcr"
                      >
                        <option value="" [disabled]="true">Select</option>
                        <option value="Yes">Positive</option>
                        <option value="No">Negative</option>
                      </select>
                    </div>
                  </div>
                  <div class="col-xl-4 col-lg-4 col-md-4 col-sm-12">
                    <div class="form-group">
                      <label>HIV</label>
                      <select
                        class="form-control form-control-sm normal-height p-lr-0"
                        formControlName="hiv"
                        id="hiv"
                        placeholder="Previous Transfusion"
                      >
                        <option value="" disabled>Select</option>
                        <option value="Reactive">Reactive</option>
                        <option value="Non Reactive">Non Reactive</option>
                        <option value="Borderline">Borderline</option>
                        <option value="Positive">Positive</option>
                        <option value="Negative">Negative</option>
                      </select>
                    </div>
                  </div>
                  <div class="col-xl-4 col-lg-4 col-md-4 col-sm-12">
                    <div class="form-group">
                      <label>HBsAg</label>
                      <select
                        class="form-control form-control-sm normal-height p-lr-0"
                        formControlName="hbs_ag"
                        placeholder="HBsAg"
                        id="HBsAg"
                      >
                        <option value="" [disabled]="true">Select</option>
                        <option value="Reactive">Reactive</option>
                        <option value="Non Reactive">Non Reactive</option>
                        <option value="Borderline">Borderline</option>
                        <option value="Positive">Positive</option>
                        <option value="Negative">Negative</option>
                      </select>
                    </div>
                  </div>
<!--                  <div class="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12">-->
<!--                    <div class="form-group">-->
<!--                      <label>Donor Value</label>-->
<!--                      <input-->
<!--                        type="number"-->
<!--                        placeholder="00"-->
<!--                        class="form-control form-control-sm"-->
<!--                        formControlName="hcv_donor_value"-->
<!--                        id="hcv_donor_value"-->
<!--                      />-->
<!--                    </div>-->
<!--                  </div>-->
<!--                  <div class="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12">-->
<!--                    <div class="form-group">-->
<!--                      <label>Cutoff Value</label>-->
<!--                      <input-->
<!--                        type="text"-->
<!--                        (keydown)="_onlyNumericWithSingleDot($event)"-->
<!--                        placeholder="1.0"-->
<!--                        class="form-control form-control-sm"-->
<!--                        formControlName="hcv_cutoff_value"-->
<!--                        id="hcv_cutoff_value"-->
<!--                      />-->
<!--                    </div>-->
<!--                  </div>-->
                </div>
<!--                <div class="row">-->
<!--                  <div class="col-xl-4 col-lg-4 col-md-4 col-sm-12">-->
<!--                    <div class="form-group">-->
<!--                      <label>HIV</label>-->
<!--                      <select-->
<!--                        class="form-control form-control-sm normal-height p-lr-0"-->
<!--                        formControlName="hiv"-->
<!--                        id="hiv"-->
<!--                        placeholder="Previous Transfusion"-->
<!--                      >-->
<!--                        <option value="" disabled>Select</option>-->
<!--                        <option value="Reactive">Reactive</option>-->
<!--                        <option value="Non Reactive">Non Reactive</option>-->
<!--                        <option value="Borderline">Borderline</option>-->
<!--                        <option value="Positive">Positive</option>-->
<!--                        <option value="Negative">Negative</option>-->
<!--                      </select>-->
<!--                    </div>-->
<!--                  </div>-->
<!--                  <div class="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12">-->
<!--                    <div class="form-group">-->
<!--                      <label>Donor Value</label>-->
<!--                      <input-->
<!--                        type="number"-->
<!--                        placeholder="00"-->
<!--                        class="form-control form-control-sm"-->
<!--                        formControlName="hiv_donor_value"-->
<!--                        id="hiv_donor_value"-->
<!--                      />-->
<!--                    </div>-->
<!--                  </div>-->
<!--                  <div class="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12">-->
<!--                    <div class="form-group">-->
<!--                      <label>Cutoff Value</label>-->
<!--                      <input-->
<!--                        type="text"-->
<!--                        (keydown)="_onlyNumericWithSingleDot($event)"-->
<!--                        placeholder="1.0"-->
<!--                        class="form-control form-control-sm"-->
<!--                        id="hiv_cutoff_value"-->
<!--                        formControlName="hiv_cutoff_value"-->
<!--                      />-->
<!--                    </div>-->
<!--                  </div>-->
<!--                </div>-->
<!--                <div class="row">-->
<!--                  <div class="col-xl-4 col-lg-4 col-md-4 col-sm-12">-->
<!--                    <div class="form-group">-->
<!--                      <label>HBsAg</label>-->
<!--                      <select-->
<!--                        class="form-control form-control-sm normal-height p-lr-0"-->
<!--                        formControlName="hbs_ag"-->
<!--                        placeholder="HBsAg"-->
<!--                        id="HBsAg"-->
<!--                      >-->
<!--                        <option value="" [disabled]="true">Select</option>-->
<!--                        <option value="Reactive">Reactive</option>-->
<!--                        <option value="Non Reactive">Non Reactive</option>-->
<!--                        <option value="Borderline">Borderline</option>-->
<!--                        <option value="Positive">Positive</option>-->
<!--                        <option value="Negative">Negative</option>-->
<!--                      </select>-->
<!--                    </div>-->
<!--                  </div>-->
<!--                  <div class="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12">-->
<!--                    <div class="form-group">-->
<!--                      <label>Donor Value</label>-->
<!--                      <input-->
<!--                        type="number"-->
<!--                        placeholder="00"-->
<!--                        class="form-control form-control-sm"-->
<!--                        formControlName="hbs_ag_donor_value"-->
<!--                        id="hbs_ag_donor_value"-->
<!--                      />-->
<!--                    </div>-->
<!--                  </div>-->
<!--                  <div class="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12">-->
<!--                    <div class="form-group">-->
<!--                      <label>Cutoff Value</label>-->
<!--                      <input-->
<!--                        type="text"-->
<!--                        placeholder="0.5"-->
<!--                        (keydown)="_onlyNumericWithSingleDot($event)"-->
<!--                        class="form-control form-control-sm"-->
<!--                        id="hbs_ag_cutoff_value"-->
<!--                        formControlName="hbs_ag_cutoff_value"-->
<!--                      />-->
<!--                    </div>-->
<!--                  </div>-->
<!--                </div>-->
<!--                <div class="row">-->
<!--                  <div class="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12">-->
<!--                    <div class="form-group">-->
<!--                      <label>VDRL</label>-->
<!--                      <select-->
<!--                        class="form-control form-control-sm normal-height p-lr-0"-->
<!--                        formControlName="vdrc"-->
<!--                        id="vdrc"-->
<!--                        placeholder="Any Reaction"-->
<!--                      >-->
<!--                        <option value="" [disabled]="true">Select</option>-->
<!--                        <option value="Reactive">Reactive</option>-->
<!--                        <option value="Non Reactive">Non Reactive</option>-->
<!--                        <option value="Borderline">Borderline</option>-->
<!--                        <option value="Positive">Positive</option>-->
<!--                        <option value="Negative">Negative</option>-->
<!--                      </select>-->
<!--                    </div>-->
<!--                  </div>-->
<!--                  <div class="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12">-->
<!--                    <div class="form-group">-->
<!--                      <label>Donor Value</label>-->
<!--                      <input-->
<!--                        type="number"-->
<!--                        placeholder="00"-->
<!--                        class="form-control form-control-sm"-->
<!--                        formControlName="vdrc_donor_value"-->
<!--                        id="vdrc_donor_value"-->
<!--                      />-->
<!--                    </div>-->
<!--                  </div>-->
<!--                  <div class="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12">-->
<!--                    <div class="form-group">-->
<!--                      <label>Cutoff Value</label>-->
<!--                      <input-->
<!--                        type="text"-->
<!--                        placeholder="1.0"-->
<!--                        (keydown)="_onlyNumericWithSingleDot($event)"-->
<!--                        class="form-control form-control-sm"-->
<!--                        id="vdrc_cutoff_value"-->
<!--                        formControlName="vdrc_cutoff_value"-->
<!--                      />-->
<!--                    </div>-->
<!--                  </div>-->
<!--                </div>-->
<!--                <div class="row">-->
<!--                  <div class="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12">-->
<!--                    <div class="form-group">-->
<!--                      <label>MP</label>-->
<!--                      &lt;!&ndash;                    <input type="text" class="form-control form-control-sm" formControlName="pregnancies" placeholder="Pregnancies"/>&ndash;&gt;-->
<!--                      <select-->
<!--                        class="form-control form-control-sm normal-height p-lr-0"-->
<!--                        formControlName="mp"-->
<!--                        id="mp"-->
<!--                        placeholder="MP"-->
<!--                      >-->
<!--                        <option value="" [disabled]="true">Select</option>-->
<!--                        <option value="Yes">Positive</option>-->
<!--                        <option value="No">Negative</option>-->
<!--                      </select>-->
<!--                    </div>-->
<!--                  </div>-->

<!--                  <div class="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12">-->
<!--                    <div class="form-group">-->
<!--                      <span class="required"><label>HB(g/dl)</label></span>-->
<!--                      <input-->
<!--                        type="text"-->
<!--                        class="form-control form-control-sm"-->
<!--                        [value]="DoctorNotesForm.get('hb').value | number"-->
<!--                        formControlName="hb"-->
<!--                        placeholder="HB"-->
<!--                        id="hb"-->
<!--                        (keypress)="_onlyNumeric($event)"-->
<!--                        maxlength="4"-->
<!--                      />-->
<!--                    </div>-->
<!--                  </div>-->

<!--                  <div class="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12">-->
<!--                    <div class="form-group">-->
<!--                        <span class="required"-->
<!--                        ><label>Platelets(10^9/L)</label></span-->
<!--                        >-->
<!--                      <input-->
<!--                        type="text"-->
<!--                        class="form-control form-control-sm"-->
<!--                        formControlName="platelets"-->
<!--                        [value]="-->
<!--                            DoctorNotesForm.get('platelets').value | number-->
<!--                          "-->
<!--                        placeholder="Platelets"-->
<!--                        id="platelets"-->
<!--                        (keypress)="_onlyNumeric($event)"-->
<!--                        maxlength="6"-->
<!--                      />-->
<!--                    </div>-->
<!--                  </div>-->
<!--                </div>-->
              </div>
            </div>
            <div class="card card-custom gutter-b">
              <div class="px-7 pt-7 py-3" style="min-height: 50px; border: 0">
              <div class="card-title m-0 d-flex justify-content-between">
                <div>
                  <h5
                    class="card-label font-weight-bolder text-dark text-left w-100"
                  >
                    Consultant Visit
                  </h5>
                </div>
              </div>
              </div>
              <div class="px-4">
                <div class="row m-0">
                  <div class="col-3">
                    <div class="form-group">
                      <label> Doctor </label>
                      <div>
                        <select
                          class="form-control form-control-sm doctorSelectpicker"
                          id="ipdConsultingDoctors"
                          data-live-search="true"
                          formControlName="doctor_id"
                          (change)="fetchDoctorDepartments($event.target.value)"
                        >
                          <option value="">select</option>
                          <option *ngFor="let doc of doctor" [value]="doc.id">
                            {{ doc.first_name }} {{ doc.last_name }}
                          </option>
                        </select>
                      </div>
                    </div>
                  </div>
                  <div
                    class="col-3"
                  >
                    <div class="form-group">
                      <label> Department </label>
                      <select
                        class="form-control form-control-sm departSelectpicker"
                        id="ipdConsultingDepartment"
                        data-live-search="true"
                        formControlName="department_id"
                        (change)="fetchDeptDoctors($event.target.value)"
                      >
                        <option value="">select</option>
                        <option *ngFor="let dep of department" [value]="dep.id">
                          {{ dep.title }}
                        </option>
                      </select>
                    </div>
                  </div>
                </div>
                <div class="row m-0 mb-3">
                  <div class="col-12">
                    <textarea
                      rows="4"
                      formControlName="consultant_visit"
                      id="consultingDetails"
                      name="consultingDetails"
                    ></textarea>
                  </div>
                </div>
              </div>
            </div>
            <div class="card card-custom gutter-b">
              <div class="px-7 pt-7 py-3" style="min-height: 50px; border: 0">
                <div class="card-title m-0 d-flex justify-content-between">
                  <div>
                    <h5
                      class="card-label font-weight-bolder text-dark text-left w-100"
                    >
                      Diagnosis
                    </h5>
                  </div>
                </div>
              </div>
              <div class="px-4">
                <div class="row m-0 mb-3">
                  <div class="col-12">
                    <textarea
                      rows="4"
                      formControlName="diagnosis"
                      id="DiagnosisDetails"
                      name="DiagnosisDetails"
                    ></textarea>
                  </div>
                </div>
              </div>
            </div>
            <div class="card card-custom gutter-b">
              <div class="px-7 pt-7 py-3" style="min-height: 50px; border: 0">
                <div class="card-title m-0 d-flex justify-content-between">
                  <div class="d-flex">
                    <div class="mr-2">
                      <label class="checkbox">
                        <input
                          (change)="updateCheck($event.target.checked)"
                          type="checkbox"
                          name="Checkbox1"
                          formControlName="is_fistula_complicated"
                        />
                        <span></span>
                      </label>
                    </div>
                    <div>
                      <h5
                        class="card-label font-weight-bolder text-dark text-left w-100"
                      >
                        Fistula Complications
                      </h5>
                    </div>
                  </div>
                </div>
              </div>
              <div class="px-4" *ngIf="isShowFistula">
                <div class="row m-0 mb-3">
                  <div class="col-12">
                    <textarea
                      rows="4"
                      formControlName="fistula_complications"
                      id="fistulaComplications"
                      name="FistulaComplications"
                    ></textarea>
                  </div>
                </div>
              </div>
            </div>
            <div class="card card-custom gutter-b">
              <div class="px-7 pt-7 py-3" style="min-height: 50px; border: 0">
                <div class="card-title m-0 d-flex justify-content-between">
                  <div class="d-flex">
                    <div class="mr-2">
                      <label class="checkbox">
                        <input
                          (change)="updateLineCheck($event.target.checked)"
                          type="checkbox"
                          name="Checkbox1"
                          formControlName="is_cv_line_complicated"
                        />
                        <span></span>
                      </label>
                    </div>
                    <div>
                      <h5
                        class="card-label font-weight-bolder text-dark text-left w-100"
                      >
                        CV Line Complications
                      </h5>
                    </div>
                  </div>
                </div>
              </div>
              <div class="px-4" *ngIf="isShowCvLine">
                <div class="row m-0 mb-3">
                  <div class="col-12">
                    <textarea
                      rows="4"
                      formControlName="cv_line_complications"
                      id="cvLineComplications"
                      name="cvLineComplications"
                    ></textarea>
                  </div>
                </div>
              </div>
            </div>
          </form>
          <div class="d-flex justify-content-end">
            <button class="btn btn-sm btn-primary ml-2" (click)="clearForm()">Clear</button>
            <button class="btn btn-sm btn-primary ml-2" (click)="submit()" [disabled]="isSubmitted || !currentPatientData">Save</button>
            <button class="btn btn-sm btn-primary ml-2" (click)="saveData()" [disabled]="!currentPatientData">Save & Discharge</button>
          </div>
        </div>

        <div class="col-md-3">
          <div class="card clearfix">
            <div class="card-body patient-card-listing">
              <div class="row">
                <div class="col-12 mb-4">
                  <select
                    class="form-control form-control-sm"
                    (change)="setQueryType($event)"
                  >
                    <option value="default">All</option>
                    <option value="mrn">MRN</option>
                    <option value="patient_nic">CNIC</option>
                    <option value="phone1">Phone</option>
                  </select>
                </div>

                <div class="col-9 button-wrapper">
                  <input
                    type="text"
                    maxlength="13"
                    minlength="11"
                    placeholder="Search patient here"
                    class="form-control form-control-sm input-transparent"
                    (change)="setQuery($event)"
                  />
                </div>
                <div class="col-3">
                  <button
                    class="btn btn-primary btn-sm float-right"
                    type="button"
                    (click)="fetchDialysisRequests()"
                  >
                    Search
                  </button>
                </div>
              </div>
              <div class="mt-3 patient-listing">
                <ng-container
                  *ngFor="
                    let dialysisRequest of dialysisRequests;
                    let i = index
                  "
                >
                  <div
                    class="d-flex py-3 patient-panel"
                    (click)="fetchPatientValues(dialysisRequest, i)"
                  >
                    <div
                      class="d-flex flex-column flex-grow-1 font-weight-bold"
                    >
                      <a class="text-dark mb-1 font-size-lg">{{
                          dialysisRequest?.patient?.mrn
                        }}</a>
                      <div class="d-inline">
                        <span class="text-muted mr-4"
                        >{{ dialysisRequest?.patient?.first_name }}
                          {{ dialysisRequest?.patient?.last_name }}</span
                        >
                        <span class="text-muted mr-4">{{
                            dialysisRequest?.patient?.gender
                          }}</span>
                        <span class="text-muted mr-4">{{
                            dialysisRequest?.patient?.age
                          }}</span>
                      </div>
                    </div>
                  </div>
                </ng-container>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!--end::Ot Calendar-->
    </div>
    <!--end::Container-->
  </div>
  <!--end::Entry-->
</div>

<div class="modal fade" tabindex="-1"
     role="dialog"
     aria-labelledby="exampleModalLabel"
     aria-hidden="true"
     id="treatmentCompleteAndDischargeAlert">
  <div class="modal-dialog modal-lg" role="document">
    <div class="modal-content">
      <div class="modal-body">
        <div class="modal-header p-3">
          <h4 class="modal-title">Confirmation</h4>
          <button
            type="button"
            class="close"
            aria-label="Close"
            (click)="closeModal()"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div class="row p-0">
            <div class="col-6">
              <label>Discharge Status</label>
              <select
                class="form-control form-control-sm"
                [(ngModel)]="dischargeStatus"
              >
                <option value="normal">Normal</option>
                <option value="lama">LAMA</option>
                <option value="referral">Referral</option>
                <option value="admitted">Admitted</option>
                <option value="DOR">Discharged on request</option>
              </select>
            </div>
            <div class="col-6 form-group">
              <label>Emergency</label>
              <div class="form-group">
                <label class="checkbox">
                  <input
                    type="checkbox"
                    name="is_emergency"
                    [(ngModel)]="isEmergency"
                  />
                  <span></span>
                </label>
              </div>
            </div>
            <div class="col-6">
              <label>Comments</label>
              <input type="text" class="form-control form-control-sm" [(ngModel)]="dischargeTypeComments">
            </div>
          </div>
<!--          <div *ngIf="dischargeStatus === 'admitted'" class="ml-n6 mr-n6">-->
<!--            <app-patient-admission-modal [type]="'e-prescription'" [isSubmit]="isSubmitAdmission" [patient]="patient" [doctors_by_dept]="doctorsAll" [department]="department" (formChange)="onFormChange($event)"></app-patient-admission-modal>-->
<!--          </div>-->

          <div class="mt-2">
            By clicking the <strong>"Discharge"</strong> button, the patient and their current visit will be discharged. Please ensure that all bills have been settled beforehand.<br />
          </div>
        </div>
        <div class="modal-footer p-3">
          <button
            type="button"
            class="btn btn-sm btn-primary"
            (click)="closeModal()"
          >
            Close
          </button>
          <button
            type="submit"
            class="btn btn-sm btn-primary"
            (click)="submit()"
          >
            Discharge
          </button>
        </div>
      </div>
  </div>
  </div>
</div>
