import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class RosterServiceService {
  constructor(private http: HttpClient) {}
  getEmployRoaster(employeeIds, location, month, department): Observable<any> {
    let results = this.http
      .get(
        `${environment.pythonUrl}/hr/roster_list?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&month=' +
          month +
          '&employees=' +
          employeeIds +
          '&location=' +
          location +
          '&department=' +
          department,
        {
          headers: new HttpHeaders({ Accept: 'application/json' }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  searchEarlyInLateOutReport(
    from_date,
    to_date,
    department,
    designation,
    employee,
    status,
  ): Observable<any> {
    let results = this.http
      .get(
        `${environment.pythonUrl}/hr/late_in_early_out_report?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&from_date=' +
          from_date +
          '&to_date=' +
          to_date +
          '&department=' +
          department +
          '&designation=' +
          designation +
          '&employee=' +
          employee +
          '&status=' +
          status,
        {
          headers: new HttpHeaders({ Accept: 'application/json' }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  searchEarlyInLateOutReportDetailed(
    from_date,
    to_date,
    department,
    designation,
    employee,
    status,
  ): Observable<any> {
    let results = this.http
      .get(
        `${environment.pythonUrl}/hr/detailed_late_in_report?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&from_date=' +
          from_date +
          '&to_date=' +
          to_date +
          '&department=' +
          department +
          '&designation=' +
          designation +
          '&employee=' +
          employee +
          '&status=' +
          status,
        {
          headers: new HttpHeaders({ Accept: 'application/json' }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  searchAutoLateInDeduction(fromDate, toDate, department, designation, employee, status): Observable<any> {
    let results = this.http
      .get(
        `${environment.pythonUrl}/hr/late_in_deduction?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') + '&from_date=' + fromDate + '&to_date=' + toDate + '&department=' + department + '&designation=' + designation + '&employee=' + employee +  '&status=' + status,
        {
          headers: new HttpHeaders({ Accept: 'application/json' }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  submitAutoLateInDeduction(lisitng): Observable<any> {
    let results = this.http
      .post(
        `${environment.pythonUrl}/hr/create_late_in_deduction?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'), {
          employees: lisitng,
          medical_unit_id: localStorage.getItem('current_medical_unit_id'),
        },
        {
          headers: new HttpHeaders({ Accept: 'application/json' }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  getSingleEmployeeRosterDetail(
    month,
    location,
    shift,
    employee,
  ): Observable<any> {
    let results = this.http
      .get(
        `${environment.pythonUrl}/hr/roster_detail_against_shift?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&month=' +
          month +
          '&employee=' +
          employee +
          '&location=' +
          location +
          '&shift=' +
          shift,
        {
          headers: new HttpHeaders({ Accept: 'application/json' }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  getEmployattendance(employeeIds, month, department): Observable<any> {
    let results = this.http
      .get(
        `${environment.pythonUrl}/hr/get_employee_attendance_report?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&month=' +
          month +
          '&id=' +
          employeeIds +
          '&department=' +
          department,
        {
          headers: new HttpHeaders({ Accept: 'application/json' }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  getEmployAttendanceDayWise(employeeIds, month, department): Observable<any> {
    let results = this.http
      .get(
        `${environment.pythonUrl}/hr/employee_attendance_adjustment?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&month=' +
          month +
          '&id=' +
          employeeIds +
          '&department=' +
          department,
        {
          headers: new HttpHeaders({ Accept: 'application/json' }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  submitEmployAttendanceDayWise(value: any): Observable<any> {
    const results = this.http
      .put(
        `${environment.pythonUrl}/hr/employee_attendance_adjustment?auth_token=` +
          localStorage.getItem('auth_token'),
        {
          medical_unit_id: localStorage.getItem('current_medical_unit_id'),
          attendance: value,
        },
        {
          headers: new HttpHeaders({
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  submitRequestForPresentModal(value: any, reason: any): Observable<any> {
    const results = this.http
      .put(
        `${environment.pythonUrl}/hr/change_attendance_status?auth_token=` +
          localStorage.getItem('auth_token'),
        {
          medical_unit_id: localStorage.getItem('current_medical_unit_id'),
          value,
          reason,
        },
        {
          headers: new HttpHeaders({
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  submitRequestForOverTimeModal(
    reason: any,
    employee_attendance: any,
    over_time_hours: any,
  ): Observable<any> {
    const results = this.http
      .post(
        `${environment.pythonUrl}/hr/submit_over_time?auth_token=` +
          localStorage.getItem('auth_token'),
        {
          medical_unit_id: localStorage.getItem('current_medical_unit_id'),
          reason,
          employee_attendance,
          over_time_hours,
        },
        {
          headers: new HttpHeaders({
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  refreshAttendance(
    start_date,
    end_date,
    department,
    employees,
  ): Observable<any> {
    const results = this.http
      .post(
        `${environment.pythonUrl}/hr/refresh_attendance?auth_token=` +
          localStorage.getItem('auth_token'),
        {
          medical_unit_id: localStorage.getItem('current_medical_unit_id'),
          start_date,
          end_date,
          department,
          employees,
        },
        {
          headers: new HttpHeaders({
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  getEmployeesattendanceByDate(
    employeeIds,
    date,
    department,
    location,
  ): Observable<any> {
    let results = this.http
      .get(
        `${environment.pythonUrl}/hr/get_date_wise_attendance_report?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&date=' +
          date +
          '&id=' +
          employeeIds +
          '&department=' +
          department +
          '&location=' +
          location,
        {
          headers: new HttpHeaders({ Accept: 'application/json' }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  getEmployeeAttendanceByDateRange(
    employeeIds,
    fromDate,
    toDate,
    department,
    location,
  ): Observable<any> {
    let results = this.http
      .get(
        `${environment.pythonUrl}/hr/get_department_wise_attendance_report?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&start_date=' +
          fromDate +
          '&end_date=' +
          toDate +
          '&id=' +
          employeeIds +
          '&department=' +
          department +
          '&location=' +
          location,
        {
          headers: new HttpHeaders({ Accept: 'application/json' }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  submitEmployRoaster(value, month, close_roster): Observable<any> {
    const results = this.http
      .post(
        `${environment.pythonUrl}/hr/roster_list?auth_token=` +
          localStorage.getItem('auth_token'),
        {
          medical_unit_id: localStorage.getItem('current_medical_unit_id'),
          month,
          value,
          is_closed: close_roster,
        },
        {
          headers: new HttpHeaders({
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  updateEmployRoaster(value, location, month, department, id): Observable<any> {
    const results = this.http
      .put(
        `${environment.pythonUrl}/hr/get_roster_batch_detail?auth_token=` +
          localStorage.getItem('auth_token') +
          '&id=' +
          id,
        {
          medical_unit_id: localStorage.getItem('current_medical_unit_id'),
          location,
          month,
          department,
          value,
        },
        {
          headers: new HttpHeaders({
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  getEmployeeShiftsListing(
    page,
    per_page,
    location,
    department,
    month,
    status,
  ): Observable<any> {
    let results = this.http
      .get(
        `${environment.pythonUrl}/hr/get_employee_roster_batch?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&page=' +
          page +
          '&per_page=' +
          per_page +
          '&location=' +
          location +
          '&department=' +
          department +
          '&month=' +
          month +
          '&status=' +
          status,
        {
          headers: new HttpHeaders({ Accept: 'application/json' }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  getDepartmentLocation(): Observable<any> {
    let results = this.http
      .get(
        `${environment.pythonUrl}/hr/get_department_location?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          headers: new HttpHeaders({
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  getOpdDepartmentsListV2(): Observable<any> {
    const results = this.http
      .get(
        `${environment.pythonUrl}/api/fetch_opd_departments?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  getRostarShiftReport(id): Observable<any> {
    let results = this.http
      .get(
        `${environment.pythonUrl}/hr/get_batch_report?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&id=' +
          id,
        {
          headers: new HttpHeaders({
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  changeRosterStatus(status, ids): Observable<any> {
    const results = this.http
      .put(
        `${environment.pythonUrl}/hr/change_roster_batch_status?auth_token=` +
          localStorage.getItem('auth_token'),
        {
          medical_unit_id: localStorage.getItem('current_medical_unit_id'),
          status,
          ids,
        },
        {
          headers: new HttpHeaders({
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  getRosterApprovalListing(
    page,
    per_page,
    search_status,
    location,
    department,
    month,
  ): Observable<any> {
    let results = this.http
      .get(
        `${environment.pythonUrl}/hr/get_roster_batch?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&page=' +
          page +
          '&per_page=' +
          per_page +
          '&status=' +
          search_status +
          '&location=' +
          location +
          '&department=' +
          department +
          '&month=' +
          month,
        {
          headers: new HttpHeaders({ Accept: 'application/json' }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  getupdateshift(currentId): Observable<any> {
    const results = this.http
      .get(
        `${environment.pythonUrl}/hr/get_roster_batch_detail?auth_token=` +
          localStorage.getItem('auth_token') +
          '&id=' +
          currentId,
        {
          headers: new HttpHeaders({ Accept: 'application/json' }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  submitAttendencePolicy(value): Observable<any> {
    const results = this.http
      .post(
        `${environment.pythonUrl}/hr/attendance_policy?auth_token=` +
          localStorage.getItem('auth_token'),
        {
          medical_unit_id: localStorage.getItem('current_medical_unit_id'),
          value,
        },
        {
          headers: new HttpHeaders({
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  updateAttendencePolicy(value, id): Observable<any> {
    const results = this.http
      .put(
        `${environment.pythonUrl}/hr/attendance_policy_detail?auth_token=` +
          localStorage.getItem('auth_token') +
          '&id=' +
          id,
        {
          medical_unit_id: localStorage.getItem('current_medical_unit_id'),
          value,
        },
        {
          headers: new HttpHeaders({
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  searchAttendanceFlowPolicyListing(
    current_page,
    per_page,
    searchStatus,
    status,
  ): Observable<any> {
    let results = this.http
      .get(
        `${environment.pythonUrl}/hr/attendance_policy?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&page=' +
          current_page +
          '&per_page=' +
          per_page +
          '&search_string=' +
          searchStatus +
          '&status=' +
          status,
        {
          headers: new HttpHeaders({
            // //'AuthenticationToken': localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  getAttendencePolicyById(id): Observable<any> {
    let results = this.http
      .get(
        `${environment.pythonUrl}/hr/attendance_policy_detail?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&id=' +
          id,
        {
          headers: new HttpHeaders({
            // //'AuthenticationToken': localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  submitAttendenceReport(file): Observable<any> {
    const results = this.http
      .post(
        `${environment.pythonUrl}/hr/create_employee_attendance?auth_token=` +
          localStorage.getItem('auth_token'),
        {
          medical_unit_id: localStorage.getItem('current_medical_unit_id'),
          file,
        },
        {
          headers: new HttpHeaders({
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }

  printRostarShiftReport(month, location, department): Observable<any> {
    const results = this.http
      .get(
        `${environment.pythonUrl}/hr/location_wise_roster?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&month=' +
          month +
          '&location=' +
          location +
          '&department=' +
          department,
        {
          headers: new HttpHeaders({ Accept: 'application/json' }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  getDepartmentWiseReport(month, department): Observable<any> {
    const results = this.http
      .get(
        `${environment.pythonUrl}/hr/abcd?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&month=' +
          month +
          '&department=' +
          department,
        {
          headers: new HttpHeaders({ Accept: 'application/json' }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }

  getAttendanceStatusPolicy(): Observable<any> {
    const results = this.http
      .get(
        `${environment.pythonUrl}/hr/attendance_status_policy?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          headers: new HttpHeaders({ Accept: 'application/json' }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  submitAttendanceStatusPolicy(value): Observable<any> {
    const results = this.http
      .post(
        `${environment.pythonUrl}/hr/attendance_status_policy?auth_token=` +
          localStorage.getItem('auth_token'),
        {
          medical_unit_id: localStorage.getItem('current_medical_unit_id'),
          value,
        },
        {
          headers: new HttpHeaders({
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }

  updateAttendanceStatusPolicy(value, id): Observable<any> {
    const results = this.http
      .put(
        `${environment.pythonUrl}/hr/attendance_status_policy_detail?auth_token=` +
          localStorage.getItem('auth_token') +
          '&id=' +
          id,
        {
          medical_unit_id: localStorage.getItem('current_medical_unit_id'),
          value,
        },
        {
          headers: new HttpHeaders({
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }

  getEmployRoasterForApproval(
    employeeIds,
    location,
    month,
    department,
  ): Observable<any> {
    let results = this.http
      .get(
        `${environment.pythonUrl}/hr/roster_approval?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&month=' +
          month +
          '&employees=' +
          employeeIds +
          '&location=' +
          location +
          '&department=' +
          department,
        {
          headers: new HttpHeaders({ Accept: 'application/json' }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  approvedEmployeeRoster(value, month): Observable<any> {
    const results = this.http
      .put(
        `${environment.pythonUrl}/hr/roster_approval?auth_token=` +
          localStorage.getItem('auth_token'),
        {
          medical_unit_id: localStorage.getItem('current_medical_unit_id'),
          month,
          value,
        },
        {
          headers: new HttpHeaders({
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  getEmployRoasterForUpdate(
    employeeIds,
    location,
    month,
    department,
  ): Observable<any> {
    let results = this.http
      .get(
        `${environment.pythonUrl}/hr/roster_update?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&month=' +
          month +
          '&employees=' +
          employeeIds +
          '&location=' +
          location +
          '&department=' +
          department,
        {
          headers: new HttpHeaders({ Accept: 'application/json' }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  employeeRosterUodate(value, month): Observable<any> {
    const results = this.http
      .put(
        `${environment.pythonUrl}/hr/roster_update?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          medical_unit_id: localStorage.getItem('current_medical_unit_id'),
          month,
          value,
        },
        {
          headers: new HttpHeaders({
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  submitSystemControls(value): Observable<any> {
    const results = this.http
      .post(
        `${environment.pythonUrl}/hr/roster_system_control?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          medical_unit_id: localStorage.getItem('current_medical_unit_id'),
          value,
        },
        {
          headers: new HttpHeaders({
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  getSystemControls(): Observable<any> {
    const results = this.http
      .get(
        `${environment.pythonUrl}/hr/roster_system_control?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          headers: new HttpHeaders({
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  updateGazettedHolidays(value, holiday_id): Observable<any> {
    const results = this.http
      .put(
        `${environment.pythonUrl}/hr/gazetted_holidays?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          date: value.date,
          name: value.name,
          status: value.status,
          id: holiday_id,
        },
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    // console.log(results);
    return results;
  }
  submitGazettedHolidays(value): Observable<any> {
    const results = this.http
      .post(
        `${environment.pythonUrl}/hr/gazetted_holidays?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          date: value.date,
          name: value.name,
          status: value.status,
        },
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    // console.log(results);
    return results;
  }
  getGazettedHolidays(page, per_page): Observable<any> {
    const results = this.http
      .get(
        `${environment.pythonUrl}/hr/gazetted_holidays?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&page=' +
          page +
          '&per_page=' +
          per_page,
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    // console.log(results);
    return results;
  }

  //  get Day Off
  updateDayOff(value, holiday_id): Observable<any> {
    const results = this.http
      .put(
        `${environment.pythonUrl}/hr/week_holidays?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          day: value.day,
          status: value.status,
          id: holiday_id,
        },
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    // console.log(results);
    return results;
  }
  submitDayOff(value): Observable<any> {
    const results = this.http
      .post(
        `${environment.pythonUrl}/hr/week_holidays?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          day: value.day,
          status: value.status,
        },
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    // console.log(results);
    return results;
  }
  getDayOff(page, per_page): Observable<any> {
    const results = this.http
      .get(
        `${environment.pythonUrl}/hr/week_holidays?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&page=' +
          page +
          '&per_page=' +
          per_page,
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    // console.log(results);
    return results;
  }
  copyRosterSubmit(from_month, to_month, employee): Observable<any> {
    const results = this.http
      .get(
        `${environment.pythonUrl}/hr/roster_copy?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&from_month=' +
          from_month +
          '&to_month=' +
          to_month +
          '&employee=' +
          employee,
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    // console.log(results);
    return results;
  }
  getEmployeeOverTimeData(id): Observable<any> {
    let results = this.http
      .get(
        `${environment.pythonUrl}/hr/get_over_time?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&id=' +
          id,
        {
          headers: new HttpHeaders({ Accept: 'application/json' }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
}
