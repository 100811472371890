<div class="row prescriptionReportPDF bodyContent">
  <div
    class="col-4 pt-4"
    style="
                        border-right: 2px solid #000000;
                        border-top: 2px solid #000000;
                      "
  >
    <ng-container *ngFor="let presControl of prescriptionControlsLeft">
      <div
        class="row mb-5"
        *ngIf="
                            presControl.name === 'Triage' &&
                            presControl.show_on_pdf === true &&
                            presControl.alignment_on_print === 'left'
                          "
      >
        <div class="col-12">
          <table class="table table-bordered text-center">
            <tbody>
            <tr>
              <td colspan="3">
                <strong>{{ presControl.heading }}</strong>
              </td>
            </tr>
            <tr>
              <td style="width: 33%">
                <strong>Immediate</strong>
              </td>
              <td style="width: 33%">
                <strong>Urgent</strong>
              </td>
              <td style="width: 33%">
                <strong>Non Urgent</strong>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>

      <!-- Vitals -->
      <div
        *ngIf="
                            presControl.name === 'Vitals' &&
                            presControl.show_on_pdf === true &&
                            presControl.alignment_on_print === 'left'
                          "
      >
        <div
          class="row mb-5"
          *ngIf="
                              prescriptionData?.vitals?.length > 0;
                              else vitalsElse
                            "
        >
          <div class="col-12">
            <h4 class="para-heading-font-size">
              {{ presControl.heading }}
            </h4>
          </div>

          <ng-container
            *ngIf="prescriptionData?.vitals[0]?.pulse"
          >
            <div class="col-5"><strong>Pulse:</strong></div>
            <div class="col-7">
              {{ prescriptionData?.vitals[0]?.pulse }} /min
            </div>
          </ng-container>

          <ng-container
            *ngIf="prescriptionData?.vitals[0]?.weight"
          >
            <div class="col-5"><strong>Weight:</strong></div>
            <div class="col-7">
              {{ prescriptionData?.vitals[0]?.weight }} kg
            </div>
          </ng-container>

          <ng-container
            *ngIf="
                                prescriptionData?.vitals[0]?.bp_systolic ||
                                prescriptionData?.vitals[0]?.bp_diastolic
                              "
          >
            <div class="col-5"><strong>BP:</strong></div>
            <div class="col-7">
              {{ prescriptionData?.vitals[0]?.bp_systolic }}
              <span
                *ngIf="
                                    prescriptionData?.vitals[0]?.bp_diastolic
                                  "
              >/</span
              >
              {{ prescriptionData?.vitals[0]?.bp_diastolic }}
              mm/hg
            </div>
          </ng-container>

          <ng-container
            *ngIf="prescriptionData?.vitals[0]?.temperature"
          >
            <div class="col-5">
              <strong>Temperature:</strong>
            </div>
            <div class="col-7">
              {{ prescriptionData?.vitals[0]?.temperature }} F
            </div>
          </ng-container>

          <ng-container
            *ngIf="prescriptionData?.vitals[0]?.o2_saturation"
          >
            <div class="col-5">
              <strong>Saturation:</strong>
            </div>
            <div class="col-7">
              {{ prescriptionData?.vitals[0]?.o2_saturation }}
              %
            </div>
          </ng-container>

          <ng-container
            *ngIf="prescriptionData?.vitals[0]?.resp_rate"
          >
            <div class="col-5">
              <strong>Respiration:</strong>
            </div>
            <div class="col-7">
              {{ prescriptionData?.vitals[0]?.resp_rate }}
              /min
            </div>
          </ng-container>
          <ng-container
            *ngIf="prescriptionData?.vitals[0]?.bsr"
          >
            <div class="col-5"><strong>BSR:</strong></div>
            <div class="col-7">
              {{ prescriptionData?.vitals[0]?.bsr }} mg/dl
            </div>
          </ng-container>
        </div>
        <ng-template #vitalsElse>
          <div
            class="row mb-5"
            *ngIf="presControl.module_type === 'static'"
          >
            <div class="col-12">
              <h4 class="para-heading-font-size">
                {{ presControl.heading }}
              </h4>
            </div>
            <div
              [style.height]="presControl.height + 'px'"
            ></div>
          </div>
        </ng-template>
      </div>

      <!-- Investigations -->
      <div
        *ngIf="
                            presControl.name === 'Investigations' &&
                            presControl.show_on_pdf === true &&
                            presControl.alignment_on_print === 'left'
                          "
      >
        <div
          class="row mb-5 prescriptionReportPDF"
          *ngIf="
                              showInvestigationsData?.length > 0;
                              else investigationsElse
                            "
        >
          <div class="col-12">
            <h4 class="para-heading-font-size">
              {{ presControl.heading }}
            </h4>
          </div>
          <ol
            style="
                                padding-left: 25px;
                                font-size: 16px !important;
                              "
          >
            <ng-container
              *ngFor="
                                  let data of prescriptionData?.investigations;
                                  let i = index
                                "
            >
              <li
                *ngIf="
                                    data?.service_type === 'Radiology' ||
                                    data?.service_type === 'LabInvestigation'
                                  "
              >
                <strong>{{ data?.service_name }}</strong>
                <span
                  *ngIf="data?.comments"
                  class="font-italic"
                ><br />({{ data?.comments }})</span
                >
              </li>
            </ng-container>
          </ol>
        </div>
        <ng-template #investigationsElse>
          <div
            class="row mb-5"
            *ngIf="presControl.module_type === 'static'"
          >
            <div class="col-12">
              <h4 class="para-heading-font-size">
                {{ presControl.heading }}
              </h4>
            </div>
            <div
              [style.height]="presControl.height + 'px'"
            ></div>
          </div>
        </ng-template>
      </div>
      <div
        *ngIf="
                            presControl.name === 'Other Services' &&
                            presControl.show_on_pdf === true &&
                            presControl.alignment_on_print === 'left'
                          "
      >
        <div
          class="row mb-5"
          *ngIf="
                              showProceduresData?.length > 0;
                              else otherServicesElse
                            "
        >
          <div class="col-12">
            <h4 class="para-heading-font-size">
              {{ presControl.heading }}
            </h4>
          </div>
          <ol
            style="
                                padding-left: 25px;
                                font-size: 16px !important;
                              "
          >
            <ng-container
              *ngFor="
                                  let data of prescriptionData?.investigations;
                                  let i = index
                                "
            >
              <li
                *ngIf="
                                    data?.service_type !== 'Radiology' &&
                                    data?.service_type !== 'LabInvestigation' &&
                                    data?.service_type !== 'DoctorFee'
                                  "
              >
                <strong>{{ data?.service_name }}</strong>
                <span
                  *ngIf="data?.comments"
                  class="font-italic"
                ><br />({{ data?.comments }})</span
                >
              </li>
            </ng-container>
          </ol>
        </div>
        <ng-template #otherServicesElse>
          <div
            class="row mb-5"
            *ngIf="presControl.module_type === 'static'"
          >
            <div class="col-12">
              <h4 class="para-heading-font-size">
                {{ presControl.heading }}
              </h4>
            </div>
            <div
              [style.height]="presControl.height + 'px'"
            ></div>
          </div>
        </ng-template>
      </div>

      <div
        *ngIf="
                            presControl.name === 'Allergies' &&
                            presControl.show_on_pdf === true &&
                            presControl.alignment_on_print === 'left'
                          "
      >
        <div
          class="row mb-5"
          *ngIf="
                              prescriptionData?.allergies?.length > 0;
                              else allergiesElse
                            "
        >
          <div class="col-12">
            <h4 class="para-heading-font-size">
              {{ presControl.heading }}
            </h4>
          </div>
          <div class="col-12 font-size-print-16">
            <ng-container
              *ngFor="
                                  let allergy of prescriptionData?.allergies
                                "
            >
                                <span class="mr-1">{{
                                    allergy | stringReplace: "," : ", "
                                  }}</span
                                >,
            </ng-container>
          </div>
        </div>
        <ng-template #allergiesElse>
          <div
            class="row mb-5"
            *ngIf="presControl.module_type === 'static'"
          >
            <div class="col-12">
              <h4 class="para-heading-font-size">
                {{ presControl.heading }}
              </h4>
            </div>
            <div
              [style.height]="presControl.height + 'px'"
            ></div>
          </div>
        </ng-template>
      </div>

      <div
        *ngIf="
                            presControl.name === 'Social History' &&
                            presControl.show_on_pdf === true &&
                            presControl.alignment_on_print === 'left'
                          "
      >
        <div
          class="general-history-section font-size-print-16 row mb-5"
          *ngIf="
                              this.patientSocialHistory?.smoker ||
                                this.patientSocialHistory?.alcohol ||
                                this.patientSocialHistory?.other ||
                                this.patientSocialHistory?.marital_status ||
                                this.patientSocialHistory?.children ||
                                this.patientSocialHistory?.patient_occupation ||
                                this.patientSocialHistory?.drug ||
                                this.patientSocialHistory?.spouse_occupation;
                              else socialHistoryElse
                            "
        >
          <div class="col-12">
            <h4 class="para-heading-font-size">
              {{ presControl.heading }}
            </h4>
          </div>

          <div
            class="col-12"
            *ngIf="this.patientSocialHistory?.smoker"
          >
            <strong>Smoker:</strong>
            {{
              this.patientSocialHistory?.smoker === true
                ? "Yes"
                : "No"
            }}
          </div>
          <div
            class="col-12"
            *ngIf="this.patientSocialHistory?.drug"
          >
            <strong>Drug:</strong>
            {{
              this.patientSocialHistory?.drug === true
                ? "Yes"
                : "No"
            }}
          </div>

          <div
            class="col-12"
            *ngIf="this.patientSocialHistory?.alcohol"
          >
            <strong>Alcohol:</strong>
            {{
              this.patientSocialHistory?.alcohol === true
                ? "Yes"
                : "No"
            }}
          </div>

          <div
            class="col-12"
            *ngIf="this.patientSocialHistory?.other"
          >
            <strong>Other:</strong>
            {{
              this.patientSocialHistory?.other === true
                ? "Yes"
                : "No"
            }}
          </div>
          <div
            class="col-12"
            *ngIf="this.patientSocialHistory?.other_details"
          >
            <strong>Other Details:</strong>
            {{ this.patientSocialHistory?.other_details }}
          </div>
          <div
            class="col-12"
            *ngIf="this.patientSocialHistory?.marital_status"
          >
            <strong>Marital Status:</strong>
            {{ this.patientSocialHistory?.marital_status_name }}
          </div>
          <div
            class="col-12"
            *ngIf="this.patientSocialHistory?.children"
          >
            <strong>Children:</strong>
            {{ this.patientSocialHistory?.children }}
          </div>
          <div
            class="col-12"
            *ngIf="this.patientSocialHistory?.patient_occupation"
          >
            <strong>Pat.Occupation:</strong>
            {{ this.patientSocialHistory?.patient_occupation }}
          </div>
          <div
            class="col-12"
            *ngIf="this.patientSocialHistory?.spouse_occupation"
          >
            <strong>Spouse's Occupation:</strong>
            {{ this.patientSocialHistory?.spouse_occupation }}
          </div>
        </div>
        <ng-template #socialHistoryElse>
          <div
            class="row mb-5"
            *ngIf="presControl.module_type === 'static'"
          >
            <div class="col-12">
              <h4 class="para-heading-font-size">
                {{ presControl.heading }}
              </h4>
            </div>
            <div
              [style.height]="presControl.height + 'px'"
            ></div>
          </div>
        </ng-template>
      </div>

      <div
        *ngIf="
                            presControl.name === 'Family History' &&
                            presControl.show_on_pdf === true &&
                            presControl.alignment_on_print === 'left'
                          "
      >
        <div
          class="general-history-section font-size-print-16 row mb-5"
          *ngIf="hasFamilyHistoryConditions(); else showFamilyHistoryEmpty"
        >
          <div class="col-12">
            <h4 class="para-heading-font-size">
              {{ presControl.heading }}
            </h4>
          </div>
          <ng-container *ngFor="let family of prescriptionData?.family_history">
            <div class="col-12" *ngIf="family?.dm || family?.hypertention || family?.ihd || family?.arthritis || family?.cancer || family?.stroke || family?.depression || family?.tb">
              <strong>{{family?.name}}: </strong>
              <span *ngIf="family?.dm">Diabetes, </span>
              <span *ngIf="family?.hypertention">Hypertension, </span>
              <span *ngIf="family?.ihd">IHD, </span>
              <span *ngIf="family?.arthritis">Asthma, </span>
              <span *ngIf="family?.cancer">Cancer, </span>
              <span *ngIf="family?.stroke">Stroke, </span>
              <span *ngIf="family?.depression">Depression, </span>
              <span *ngIf="family?.tb">TB</span>
            </div>
          </ng-container>
        </div>
        <ng-template #showFamilyHistoryEmpty>
          <div
            class="row mb-5"
            *ngIf="presControl.module_type === 'static'"
          >
            <div class="col-12">
              <h4 class="para-heading-font-size">
                {{ presControl.heading }}
              </h4>
            </div>
            <div
              [style.height]="presControl.height + 'px'"
            ></div>
          </div>
        </ng-template>
      </div>

      <div
        *ngIf="
                            presControl.name === 'Mindfulness' &&
                            presControl.show_on_pdf === true &&
                            presControl.alignment_on_print === 'left'
                          "
      >
        <div
          class="general-history-section font-size-print-16 row mb-5"
          *ngIf="
                              this.prescriptionData?.mindfulness?.namaz ||
                                this.prescriptionData?.mindfulness?.yoga ||
                                this.prescriptionData?.mindfulness?.meditation ||
                                this.prescriptionData?.mindfulness?.tai_chi ||
                                this.prescriptionData?.mindfulness?.other ||
                                this.prescriptionData?.mindfulness?.other_detail;
                              else mindfulnessElse
                            "
        >
          <div class="col-12">
            <h4 class="para-heading-font-size">
              {{ presControl.heading }}
            </h4>
          </div>

          <div
            class="col-12"
            *ngIf="this.prescriptionData?.mindfulness?.namaz"
          >
            <strong>Namaz:</strong>
            {{
              this.prescriptionData?.mindfulness?.namaz === true
                ? "Yes"
                : "No"
            }}
          </div>
          <div
            class="col-12"
            *ngIf="this.prescriptionData?.mindfulness?.yoga"
          >
            <strong>Yoga:</strong>
            {{
              this.prescriptionData?.mindfulness?.yoga === true
                ? "Yes"
                : "No"
            }}
          </div>

          <div
            class="col-12"
            *ngIf="this.prescriptionData?.mindfulness?.tai_chi"
          >
            <strong>Tai chi:</strong>
            {{
              this.prescriptionData?.mindfulness?.tai_chi === true
                ? "Yes"
                : "No"
            }}
          </div>
          <div
            class="col-12"
            *ngIf="this.prescriptionData?.mindfulness?.meditation"
          >
            <strong>Meditation:</strong>
            {{
              this.prescriptionData?.mindfulness?.meditation === true
                ? "Yes"
                : "No"
            }}
          </div>

          <div
            class="col-12"
            *ngIf="this.prescriptionData?.mindfulness?.other"
          >
            <strong>Other:</strong>
            {{
              this.prescriptionData?.mindfulness?.other === true
                ? "Yes"
                : "No"
            }}
          </div>
          <div
            class="col-12"
            *ngIf="this.prescriptionData?.mindfulness?.other_detail"
          >
            <strong>Other Details:</strong>
            {{ this.prescriptionData?.mindfulness?.other_detail }}
          </div>
        </div>
        <ng-template #mindfulnessElse>
          <div
            class="row mb-5"
            *ngIf="presControl.module_type === 'static'"
          >
            <div class="col-12">
              <h4 class="para-heading-font-size">
                {{ presControl.heading }}
              </h4>
            </div>
            <div
              [style.height]="presControl.height + 'px'"
            ></div>
          </div>
        </ng-template>
      </div>

      <div
        *ngIf="
                            presControl.name === 'Past History' &&
                            presControl.show_on_pdf === true &&
                            presControl.alignment_on_print === 'left'
                          "
      >
        <div
          class="general-history-section font-size-print-16 row mb-5"
          *ngIf="
                              this.patientPastHistory?.diabetes ||
                                this.patientPastHistory?.hypertention ||
                                this.patientPastHistory?.IHD ||
                                this.patientPastHistory?.blood_thinner ||
                                this.patientPastHistory?.operation ||
                                this.patientPastHistory?.major_illness ||
                                this.patientPastHistory?.other ||
                                this.patientPastHistory?.cardiac_disorder ||
                                this.patientPastHistory?.asthma ||
                                this.patientPastHistory?.bleeding_disorder ||
                                this.patientPastHistory?.pregnancy_trimester ||
                                this.patientPastHistory?.epilepsy;
                              else pastlHistoryElse
                            "
        >
          <div class="col-12">
            <h4 class="para-heading-font-size">
              {{ presControl.heading }}
            </h4>
          </div>
          <div
            class="col-12"
            *ngIf="this.patientPastHistory?.diabetes"
          >
            <strong>Diabetes:</strong>
            {{
              this.patientPastHistory?.diabetes === true
                ? "Yes"
                : "No"
            }}
            <span
              *ngIf="
                                  this.patientPastHistory?.diabetes_duration ||
                                  this.patientPastHistory
                                    ?.diabetes_duration_unit
                                "
            >(Duration:
              {{
                this.patientPastHistory?.diabetes_duration +
                " " +
                this.patientPastHistory
                  ?.diabetes_duration_unit
              }})
                              </span>
            <span
              *ngIf="
                                  this.patientPastHistory?.diabetes_details
                                "
            >({{
                this.patientPastHistory?.diabetes_details
              }})</span
            >
          </div>

          <div
            class="col-12"
            *ngIf="this.patientPastHistory?.hypertention"
          >
            <strong>Hypertension:</strong>
            {{
              this.patientPastHistory?.hypertention === true
                ? "Yes"
                : "No"
            }}
            <span
              *ngIf="
                                  this.patientPastHistory
                                    ?.hypertention_duration ||
                                  this.patientPastHistory
                                    ?.hypertention_duration_unit
                                "
            >(Duration:
              {{
                this.patientPastHistory
                  ?.hypertention_duration +
                " " +
                this.patientPastHistory
                  ?.hypertention_duration_unit
              }})
                              </span>
            <span
              *ngIf="
                                  this.patientPastHistory?.hypertention_details
                                "
            >({{
                this.patientPastHistory?.hypertention_details
              }})</span
            >
          </div>

          <div
            class="col-12"
            *ngIf="this.patientPastHistory?.IHD"
          >
            <strong>IHD:</strong>
            {{
              this.patientPastHistory?.IHD === true
                ? "Yes"
                : "No"
            }}
            <span
              *ngIf="
                                  this.patientPastHistory?.IHD_duration ||
                                  this.patientPastHistory?.IHD_duration_unit
                                "
            >(Duration:
              {{
                this.patientPastHistory?.IHD_duration +
                " " +
                this.patientPastHistory?.IHD_duration_unit
              }})
                              </span>
            <span *ngIf="this.patientPastHistory?.IHD_details"
            >({{
                this.patientPastHistory?.IHD_details
              }})</span
            >
          </div>

          <div
            class="col-12"
            *ngIf="this.patientPastHistory?.blood_thinner"
          >
            <strong>Blood Thinner:</strong>
            {{
              this.patientPastHistory?.blood_thinner === true
                ? "Yes"
                : "No"
            }}
            <span
              *ngIf="
                                  this.patientPastHistory
                                    ?.blood_thinner_duration ||
                                  this.patientPastHistory
                                    ?.blood_thinner_duration_unit
                                "
            >(Duration:
              {{
                this.patientPastHistory
                  ?.blood_thinner_duration +
                " " +
                this.patientPastHistory
                  ?.blood_thinner_duration_unit
              }})
                              </span>
            <span
              *ngIf="
                                  this.patientPastHistory?.blood_thinner_details
                                "
            >({{
                this.patientPastHistory
                  ?.blood_thinner_details
              }})</span
            >
          </div>

          <div
            class="col-12"
            *ngIf="this.patientPastHistory?.operation"
          >
            <strong>Operation:</strong>
            {{
              this.patientPastHistory?.operation === true
                ? "Yes"
                : "No"
            }}
            <span
              *ngIf="
                                  this.patientPastHistory?.operation_details
                                "
            >({{
                this.patientPastHistory?.operation_details
              }})</span
            >
          </div>

          <div
            class="col-12"
            *ngIf="this.patientPastHistory?.major_illness"
          >
            <strong>Major Illness:</strong>
            {{
              this.patientPastHistory?.major_illness === true
                ? "Yes"
                : "No"
            }}
            <span
              *ngIf="
                                  this.patientPastHistory?.major_illness_details
                                "
            >({{
                this.patientPastHistory
                  ?.major_illness_details
              }})</span
            >
          </div>
          <div
            class="col-12"
            *ngIf="this.patientPastHistory?.other"
          >
            <strong>Other:</strong>
            {{
              this.patientPastHistory?.other === true
                ? "Yes"
                : "No"
            }}
            <span
              *ngIf="this.patientPastHistory?.other_details"
            >({{
                this.patientPastHistory?.other_details
              }})</span
            >
          </div>
          <!--                            Cardiac Disorder-->
          <div
            class="col-12"
            *ngIf="this.patientPastHistory?.cardiac_disorder"
          >
            <strong>Cardiac Disorder:</strong>
            {{
              this.patientPastHistory?.cardiac_disorder ===
              true
                ? "Yes"
                : "No"
            }}
            <span
              *ngIf="
                                  this.patientPastHistory
                                    ?.cardiac_disorder_details
                                "
            >({{
                this.patientPastHistory
                  ?.cardiac_disorder_details
              }})</span
            >
          </div>
          <!--                            Asthma-->
          <div
            class="col-12"
            *ngIf="this.patientPastHistory?.asthma"
          >
            <strong>Asthma:</strong>
            {{
              this.patientPastHistory?.asthma === true
                ? "Yes"
                : "No"
            }}
            <span
              *ngIf="this.patientPastHistory?.asthma_details"
            >({{
                this.patientPastHistory?.asthma_details
              }})</span
            >
          </div>
          <!--                            Bleeding Disorder-->
          <div
            class="col-12"
            *ngIf="this.patientPastHistory?.bleeding_disorder"
          >
            <strong>Bleeding Disorder:</strong>
            {{
              this.patientPastHistory?.bleeding_disorder ===
              true
                ? "Yes"
                : "No"
            }}
            <span
              *ngIf="
                                  this.patientPastHistory
                                    ?.bleeding_disorder_details
                                "
            >({{
                this.patientPastHistory
                  ?.bleeding_disorder_details
              }})</span
            >
          </div>
          <!--                            Pregnancy (Trimester)-->
          <div
            class="col-12"
            *ngIf="
                                this.patientPastHistory?.pregnancy_trimester
                              "
          >
            <strong>Pregnancy (Trimester):</strong>
            {{
              this.patientPastHistory?.pregnancy_trimester ===
              true
                ? "Yes"
                : "No"
            }}
            <span
              *ngIf="
                                  this.patientPastHistory
                                    ?.pregnancy_trimester_details
                                "
            >({{
                this.patientPastHistory
                  ?.pregnancy_trimester_details
              }})</span
            >
          </div>
          <!--                            Epilepsy-->
          <div
            class="col-12"
            *ngIf="this.patientPastHistory?.epilepsy"
          >
            <strong>Epilepsy:</strong>
            {{
              this.patientPastHistory?.epilepsy === true
                ? "Yes"
                : "No"
            }}
            <span
              *ngIf="
                                  this.patientPastHistory?.epilepsy_details
                                "
            >({{
                this.patientPastHistory?.epilepsy_details
              }})</span
            >
          </div>
        </div>
        <ng-template #pastlHistoryElse>
          <div
            class="row mb-5"
            *ngIf="presControl.module_type === 'static'"
          >
            <div class="col-12">
              <h4 class="para-heading-font-size">
                {{ presControl.heading }}
              </h4>
            </div>
            <div
              [style.height]="presControl.height + 'px'"
            ></div>
          </div>
        </ng-template>
      </div>
      <!--                        Immunizations-->
      <div
        *ngIf="
                            presControl.name === 'Immunizations' &&
                            presControl.show_on_pdf === true &&
                            presControl.alignment_on_print === 'left'
                          "
      >
        <div class="general-history-section font-size-print-16 row mb-5" *ngIf="prescriptionData?.immunizations; else elseImmunization">
          <div class="col-12">
            <h4 class="para-heading-font-size">
              {{ presControl.heading }}
            </h4>
          </div>

          <div
            class="col-12"
            *ngIf="prescriptionData?.immunizations?.dtp"
          >
            <strong>DTP:</strong>
            <span
            > ({{
                prescriptionData?.immunizations?.dtp
              }}) </span
            >
          </div>
          <div
            class="col-12"
            *ngIf="prescriptionData?.immunizations?.dt_hib"
          >
            <strong>DT Hib:</strong>
            <span
            >({{
                prescriptionData?.immunizations?.dt_hib
              }}) </span
            >
          </div>
          <div
            class="col-12"
            *ngIf="prescriptionData?.immunizations?.td_adult"
          >
            <strong>Td (Adult):</strong>
            <span
            >({{
                prescriptionData?.immunizations?.td_adult
              }})</span
            >
          </div>
          <div
            class="col-12"
            *ngIf="prescriptionData?.immunizations?.ipv_opv"
          >
            <strong>IPV/OPV:</strong>
            <span
            >({{
                prescriptionData?.immunizations?.ipv_opv
              }})</span
            >
          </div>
          <div
            class="col-12"
            *ngIf="prescriptionData?.immunizations?.mmr"
          >
            <strong>MMR:</strong>
            <span
            >({{
                prescriptionData?.immunizations?.mmr
              }})</span
            >
          </div>
          <div
            class="col-12"
            *ngIf="prescriptionData?.immunizations?.hep_b"
          >
            <strong>Hep B:</strong>
            <span
            >({{
                prescriptionData?.immunizations?.hep_b
              }})</span
            >
          </div>
          <div
            class="col-12"
            *ngIf="prescriptionData?.immunizations?.influenza"
          >
            <strong>Influenza:</strong>
            <span
            >({{
                prescriptionData?.immunizations?.influenza
              }})</span
            >
          </div>
          <div
            class="col-12"
            *ngIf="prescriptionData?.immunizations?.pneumococcal"
          >
            <strong>Pneumococcal:</strong>
            <span
            >({{
                prescriptionData?.immunizations?.pneumococcal
              }})</span
            >
          </div>
          <div
            class="col-12"
            *ngIf="prescriptionData?.immunizations?.mantoux"
          >
            <strong>Mantoux:</strong>
            <span
            >({{
                prescriptionData?.immunizations?.mantoux
              }})</span
            >
          </div>
        </div>
        <ng-template #elseImmunization>
          <div
            class="row mb-5"
            *ngIf="presControl.module_type === 'static'"
          >
            <div class="col-12">
              <h4 class="para-heading-font-size">
                {{ presControl.heading }}
              </h4>
            </div>
            <div
              [style.height]="presControl.height + 'px'"
            ></div>
          </div>
        </ng-template>
      </div>

      <!--   REVIEW HISTORY-->
      <div
        *ngIf="
                            presControl.name === 'Review History' &&
                            presControl.show_on_pdf === true &&
                            presControl.alignment_on_print === 'left'
                          "
      >
        <app-opd-review-history-print [reviewSystemHistory]="reviewSystemHistory" [presControl]="presControl"></app-opd-review-history-print>
      </div>
      <!--                        Physical Examination-->
<!--      <div-->
<!--        *ngIf="-->
<!--                            presControl.name === 'Physical Examination' &&-->
<!--                            presControl.show_on_pdf === true &&-->
<!--                            presControl.alignment_on_print === 'left'-->
<!--                          "-->
<!--      >-->
<!--        <div-->
<!--          class="general-history-section font-size-print-16 row mb-5"-->
<!--          *ngIf="-->
<!--                              this.physicalExamination?.gen_is_normal ||-->
<!--                                this.physicalExamination?.heent_is_normal ||-->
<!--                                this.physicalExamination?.neck_is_normal ||-->
<!--                                this.physicalExamination?.chest_is_normal ||-->
<!--                                this.physicalExamination?.heart_is_normal ||-->
<!--                                this.physicalExamination?.gi_is_normal ||-->
<!--                                this.physicalExamination?.back_is_normal ||-->
<!--                                this.physicalExamination?.exterm_is_normal ||-->
<!--                                this.physicalExamination?.neuro_is_normal ||-->
<!--                                this.physicalExamination?.skin_is_normal;-->
<!--                              else physicalExaminationElse-->
<!--                            "-->
<!--        >-->
<!--          <div class="col-12">-->
<!--            <h4 class="para-heading-font-size">-->
<!--              {{ presControl.heading }}-->
<!--            </h4>-->
<!--          </div>-->
<!--          <div-->
<!--            class="col-12"-->
<!--            *ngIf="this.physicalExamination?.gen_is_normal"-->
<!--          >-->
<!--            <strong>GEN:</strong>-->
<!--            {{-->
<!--              physicalExamination?.gen_is_normal === true-->
<!--                ? "Abnormal"-->
<!--                : ""-->
<!--            }}-->
<!--            <span *ngIf="physicalExamination?.gen_comments"-->
<!--            >({{ physicalExamination?.gen_comments }})-->
<!--                              </span>-->
<!--          </div>-->

<!--          <div-->
<!--            class="col-12"-->
<!--            *ngIf="this.physicalExamination?.heent_is_normal"-->
<!--          >-->
<!--            <strong>HEENT:</strong>-->
<!--            {{-->
<!--              this.physicalExamination?.heent_is_normal ===-->
<!--              true-->
<!--                ? "Abnormal"-->
<!--                : ""-->
<!--            }}-->
<!--            <span *ngIf="physicalExamination?.heent_comments"-->
<!--            >({{ physicalExamination?.heent_comments }})-->
<!--                              </span>-->
<!--          </div>-->

<!--          <div-->
<!--            class="col-12"-->
<!--            *ngIf="this.physicalExamination?.neck_is_normal"-->
<!--          >-->
<!--            <strong>NECK:</strong>-->
<!--            {{-->
<!--              this.physicalExamination?.neck_is_normal ===-->
<!--              true-->
<!--                ? "Abnormal"-->
<!--                : ""-->
<!--            }}-->
<!--            <span *ngIf="physicalExamination?.neck_comments"-->
<!--            >({{ physicalExamination?.neck_comments }})-->
<!--                              </span>-->
<!--          </div>-->

<!--          <div-->
<!--            class="col-12"-->
<!--            *ngIf="physicalExamination?.chest_is_normal"-->
<!--          >-->
<!--            <strong>CHEST:</strong>-->
<!--            {{-->
<!--              this.physicalExamination?.chest_is_normal ===-->
<!--              true-->
<!--                ? "Abnormal"-->
<!--                : ""-->
<!--            }}-->
<!--            <span *ngIf="physicalExamination?.chest_comments"-->
<!--            >({{ physicalExamination?.chest_comments }})-->
<!--                              </span>-->
<!--          </div>-->

<!--          <div-->
<!--            class="col-12"-->
<!--            *ngIf="this.physicalExamination?.heart_is_normal"-->
<!--          >-->
<!--            <strong>HEART:</strong>-->
<!--            {{-->
<!--              this.physicalExamination?.heart_is_normal ===-->
<!--              true-->
<!--                ? "Abnormal"-->
<!--                : ""-->
<!--            }}-->
<!--            <span-->
<!--              *ngIf="this.physicalExamination?.heart_comments"-->
<!--            >({{-->
<!--                physicalExamination?.heart_comments-->
<!--              }})</span-->
<!--            >-->
<!--          </div>-->

<!--          <div-->
<!--            class="col-12"-->
<!--            *ngIf="physicalExamination?.gi_is_normal"-->
<!--          >-->
<!--            <strong>GI:</strong>-->
<!--            {{-->
<!--              this.physicalExamination?.gi_is_normal === true-->
<!--                ? "Abnormal"-->
<!--                : ""-->
<!--            }}-->
<!--            <span *ngIf="physicalExamination?.gi_comments"-->
<!--            >({{ physicalExamination?.gi_comments }})</span-->
<!--            >-->
<!--          </div>-->
<!--          <div-->
<!--            class="col-12"-->
<!--            *ngIf="physicalExamination?.back_is_normal"-->
<!--          >-->
<!--            <strong>BACK:</strong>-->
<!--            {{-->
<!--              physicalExamination?.back_is_normal-->
<!--                ? "Abnormal"-->
<!--                : ""-->
<!--            }}-->
<!--            <span *ngIf="physicalExamination?.back_comments"-->
<!--            >({{-->
<!--                physicalExamination?.back_comments-->
<!--              }})</span-->
<!--            >-->
<!--          </div>-->
<!--          &lt;!&ndash;                            Cardiac Disorder&ndash;&gt;-->
<!--          <div-->
<!--            class="col-12"-->
<!--            *ngIf="physicalExamination?.exterm_is_normal"-->
<!--          >-->
<!--            <strong>EXTERM:</strong>-->
<!--            {{-->
<!--              physicalExamination?.exterm_is_normal === true-->
<!--                ? "Abnormal"-->
<!--                : ""-->
<!--            }}-->
<!--            <span-->
<!--              *ngIf="-->
<!--                                  this.physicalExamination?.exterm_comments-->
<!--                                "-->
<!--            >({{-->
<!--                this.physicalExamination?.exterm_comments-->
<!--              }})</span-->
<!--            >-->
<!--          </div>-->
<!--          &lt;!&ndash;                            Asthma&ndash;&gt;-->
<!--          <div-->
<!--            class="col-12"-->
<!--            *ngIf="physicalExamination?.neuro_is_normal"-->
<!--          >-->
<!--            <strong>NEURO:</strong>-->
<!--            {{-->
<!--              physicalExamination?.neuro_is_normal === true-->
<!--                ? "Abnormal"-->
<!--                : ""-->
<!--            }}-->
<!--            <span *ngIf="physicalExamination?.neuro_comments"-->
<!--            >({{-->
<!--                physicalExamination?.neuro_comments-->
<!--              }})</span-->
<!--            >-->
<!--          </div>-->
<!--          &lt;!&ndash;                            Bleeding Disorder&ndash;&gt;-->
<!--          <div-->
<!--            class="col-12"-->
<!--            *ngIf="physicalExamination?.skin_is_normal"-->
<!--          >-->
<!--            <strong>SKIN:</strong>-->
<!--            {{-->
<!--              physicalExamination.skin_is_normal === true-->
<!--                ? "Abnormal"-->
<!--                : ""-->
<!--            }}-->
<!--            <span *ngIf="physicalExamination?.skin_comments"-->
<!--            >({{-->
<!--                physicalExamination?.skin_comments-->
<!--              }})</span-->
<!--            >-->
<!--          </div>-->
<!--        </div>-->
<!--        <ng-template #physicalExaminationElse>-->
<!--          <div-->
<!--            class="row mb-5"-->
<!--            *ngIf="presControl.module_type === 'static'"-->
<!--          >-->
<!--            <div class="col-12">-->
<!--              <h4 class="para-heading-font-size">-->
<!--                {{ presControl.heading }}-->
<!--              </h4>-->
<!--            </div>-->
<!--            <div-->
<!--              [style.height]="presControl.height + 'px'"-->
<!--            ></div>-->
<!--          </div>-->
<!--        </ng-template>-->
<!--      </div>-->
      <!-- Labs -->
      <div
        *ngIf="
                            presControl.name === 'Labs' &&
                            presControl.show_on_pdf === true &&
                            presControl.alignment_on_print === 'left'
                          "
      >
        <div
          class="row mb-5"
          *ngIf="prescriptionData?.labs; else labsElse"
        >
          <div class="col-12 font-size-print-16">
            <h4 class="para-heading-font-size">
              {{ presControl.heading }}
            </h4>
            <app-summernotes
              [id]="'labs'"
              [htmlData]="prescriptionData?.labs"
            >
            </app-summernotes>
          </div>
        </div>
        <ng-template #labsElse>
          <div
            class="row mb-5"
            *ngIf="presControl.module_type === 'static'"
          >
            <div class="col-12">
              <h4 class="para-heading-font-size">
                {{ presControl.heading }}
              </h4>
            </div>
            <div
              [style.height]="presControl.height + 'px'"
            ></div>
          </div>
        </ng-template>
      </div>

      <!-- Plan -->
      <div
        *ngIf="
                            presControl.name === 'Plan' &&
                            presControl.show_on_pdf === true &&
                            presControl.alignment_on_print === 'left'
                          "
      >
        <div
          class="row mb-5"
          *ngIf="prescriptionData?.plan; else planElse"
        >
          <div class="col-12 font-size-print-16">
            <h4 class="para-heading-font-size">
              {{ presControl.heading }}
            </h4>
            <app-summernotes
              [id]="'plan'"
              [htmlData]="prescriptionData?.plan"
            >
            </app-summernotes>
          </div>
        </div>
        <ng-template #planElse>
          <div
            class="row mb-5"
            *ngIf="presControl.module_type === 'static'"
          >
            <div class="col-12">
              <h4 class="para-heading-font-size">
                {{ presControl.heading }}
              </h4>
            </div>
            <div
              [style.height]="presControl.height + 'px'"
            ></div>
          </div>
        </ng-template>
      </div>

      <!-- Treatment -->
      <div
        *ngIf="
                            presControl.name === 'Treatment' &&
                            presControl.show_on_pdf === true &&
                            presControl.alignment_on_print === 'left'
                          "
      >
        <div
          class="row mb-5"
          *ngIf="
                              prescriptionData?.treatment;
                              else treatmentElse
                            "
        >
          <div class="col-12 font-size-print-16">
            <h4 class="para-heading-font-size">
              {{ presControl.heading }}
            </h4>
            <app-summernotes
              [id]="'treatment'"
              [htmlData]="prescriptionData?.treatment"
            >
            </app-summernotes>
          </div>
        </div>
        <ng-template #treatmentElse>
          <div
            class="row mb-5"
            *ngIf="presControl.module_type === 'static'"
          >
            <div class="col-12">
              <h4 class="para-heading-font-size">
                {{ presControl.heading }}
              </h4>
            </div>
            <div
              [style.height]="presControl.height + 'px'"
            ></div>
          </div>
        </ng-template>
      </div>
      <!-- History of Presenting Complaints-->
      <div
        *ngIf="
                            presControl.name ===
                              'History of Presenting Complaints' &&
                            presControl.show_on_pdf === true &&
                            presControl.alignment_on_print === 'left'
                          "
      >
        <div
          class="row mb-5"
          *ngIf="
                              prescriptionData?.history_of_presenting_complaints;
                              else PresentingComplaintsHistoryElse
                            "
        >
          <div class="col-12 font-size-print-16">
            <h4 class="para-heading-font-size">
              {{ presControl.heading }}
            </h4>
            <app-summernotes
              [id]="'history_of_presenting_complaints'"
              [htmlData]="
                                  prescriptionData?.history_of_presenting_complaints
                                "
            >
            </app-summernotes>
          </div>
        </div>
        <ng-template #PresentingComplaintsHistoryElse>
          <div
            class="row mb-5"
            *ngIf="presControl.module_type === 'static'"
          >
            <div class="col-12">
              <h4 class="para-heading-font-size">
                {{ presControl.heading }}
              </h4>
            </div>
            <div
              [style.height]="presControl.height + 'px'"
            ></div>
          </div>
        </ng-template>
      </div>
      <!--  Past Dental History-->
      <div
        *ngIf="
                            presControl.name === 'Past Dental History' &&
                            presControl.show_on_pdf === true &&
                            presControl.alignment_on_print === 'left'
                          "
      >
        <div
          class="row mb-5"
          *ngIf="
                              prescriptionData?.past_dental_history;
                              else PastDentalHistoryElse
                            "
        >
          <div class="col-12 font-size-print-16">
            <h4 class="para-heading-font-size">
              {{ presControl.heading }}
            </h4>
            <app-summernotes
              [id]="'past_dental_history'"
              [htmlData]="
                                  prescriptionData?.past_dental_history
                                "
            >
            </app-summernotes>
          </div>
        </div>
        <ng-template #PastDentalHistoryElse>
          <div
            class="row mb-5"
            *ngIf="presControl.module_type === 'static'"
          >
            <div class="col-12">
              <h4 class="para-heading-font-size">
                {{ presControl.heading }}
              </h4>
            </div>
            <div
              [style.height]="presControl.height + 'px'"
            ></div>
          </div>
        </ng-template>
      </div>
      <!--    CONSULTANT VISITS Start-->
      <div
        *ngIf="
                            presControl.name === 'Consultant Visit' &&
                            presControl.show_on_pdf === true &&
                            presControl.alignment_on_print === 'left'
                          "
      >
        <div
          class="general-history-section font-size-print-16 row mb-5"
          *ngIf="
                              allConsultations.length;
                              else Consultations"
        >
          <div class="col-12">
            <h4 class="para-heading-font-size">
              {{ presControl.heading }}
            </h4>
          </div>
          <div
            class="col-12"
          >
                              <span>
                               <ng-container *ngFor="let consult of allConsultations; let i = index">
                                <div>
                                  <app-summernotes
                                    [id]="'consult' + i"
                                    [htmlData]="consult?.detail">
                                  </app-summernotes>
                                </div>
                                <div class="mb-4">{{ consult?.doctor?.first_name }} {{ consult?.doctor?.last_name }} ({{ consult?.department?.title }})</div>
                            </ng-container>
                              </span>
          </div>
        </div>
        <ng-template #Consultations>
          <div
            class="row mb-5"
            *ngIf="presControl.module_type === 'static'"
          >
            <div class="col-12">
              <h4 class="para-heading-font-size">
                {{ presControl.heading }}
              </h4>
            </div>
            <div
              [style.height]="presControl.height + 'px'"
            ></div>
          </div>
        </ng-template>
      </div>
      <!--   CONSULTANT VISITS End-->
      <!--  History Of Hospitalization-->
      <div
        *ngIf="
                            presControl.name === 'History of Hospitalization' &&
                            presControl.show_on_pdf === true &&
                            presControl.alignment_on_print === 'left'
                          "
      >
        <div
          class="row mb-5"
          *ngIf="
                              prescriptionData?.history_of_hospitalization;
                              else HistoryOfHospitalizationElse
                            "
        >
          <div class="col-12 font-size-print-16">
            <h4 class="para-heading-font-size">
              {{ presControl.heading }}
            </h4>
            <app-summernotes
              [id]="'history_of_hospitalization'"
              [htmlData]="
                                  prescriptionData?.history_of_hospitalization
                                "
            >
            </app-summernotes>
          </div>
        </div>
        <ng-template #HistoryOfHospitalizationElse>
          <div
            class="row mb-5"
            *ngIf="presControl.module_type === 'static'"
          >
            <div class="col-12">
              <h4 class="para-heading-font-size">
                {{ presControl.heading }}
              </h4>
            </div>
            <div
              [style.height]="presControl.height + 'px'"
            ></div>
          </div>
        </ng-template>
      </div>
      <!--  Card 1-->
      <div
        *ngIf="
                            presControl.name === 'Card 1' &&
                            presControl.show_on_pdf === true &&
                            presControl.alignment_on_print === 'left'
                          "
      >
        <div
          class="row mb-5"
          *ngIf="
                              prescriptionData?.card_1;
                              else card1Else
                            "
        >
          <div class="col-12 font-size-print-16">
            <h4 class="para-heading-font-size">
              {{ presControl.heading }}
            </h4>
            <app-summernotes
              [id]="'card_1'"
              [htmlData]="
                                  prescriptionData?.card_1
                                "
            >
            </app-summernotes>
          </div>
        </div>
        <ng-template #card1Else>
          <div
            class="row mb-5"
            *ngIf="presControl.module_type === 'static'"
          >
            <div class="col-12">
              <h4 class="para-heading-font-size">
                {{ presControl.heading }}
              </h4>
            </div>
            <div
              [style.height]="presControl.height + 'px'"
            ></div>
          </div>
        </ng-template>
      </div>
      <!--  Card 2-->
      <div
        *ngIf="
                            presControl.name === 'Card 2' &&
                            presControl.show_on_pdf === true &&
                            presControl.alignment_on_print === 'left'
                          "
      >
        <div
          class="row mb-5"
          *ngIf="
                              prescriptionData?.card_2;
                              else card2Else
                            "
        >
          <div class="col-12 font-size-print-16">
            <h4 class="para-heading-font-size">
              {{ presControl.heading }}
            </h4>
            <app-summernotes
              [id]="'card_2'"
              [htmlData]="
                                  prescriptionData?.card_2
                                "
            >
            </app-summernotes>
          </div>
        </div>
        <ng-template #card2Else>
          <div
            class="row mb-5"
            *ngIf="presControl.module_type === 'static'"
          >
            <div class="col-12">
              <h4 class="para-heading-font-size">
                {{ presControl.heading }}
              </h4>
            </div>
            <div
              [style.height]="presControl.height + 'px'"
            ></div>
          </div>
        </ng-template>
      </div>
      <!--  Card 3-->
      <div
        *ngIf="
                            presControl.name === 'Card 3' &&
                            presControl.show_on_pdf === true &&
                            presControl.alignment_on_print === 'left'
                          "
      >
        <div
          class="row mb-5"
          *ngIf="
                              prescriptionData?.card_3;
                              else card3Else
                            "
        >
          <div class="col-12 font-size-print-16">
            <h4 class="para-heading-font-size">
              {{ presControl.heading }}
            </h4>
            <app-summernotes
              [id]="'card_3'"
              [htmlData]="
                                  prescriptionData?.card_3
                                "
            >
            </app-summernotes>
          </div>
        </div>
        <ng-template #card3Else>
          <div
            class="row mb-5"
            *ngIf="presControl.module_type === 'static'"
          >
            <div class="col-12">
              <h4 class="para-heading-font-size">
                {{ presControl.heading }}
              </h4>
            </div>
            <div
              [style.height]="presControl.height + 'px'"
            ></div>
          </div>
        </ng-template>
      </div>
      <!--  Card 4-->
      <div
        *ngIf="
                            presControl.name === 'Card 4' &&
                            presControl.show_on_pdf === true &&
                            presControl.alignment_on_print === 'left'
                          "
      >
        <div
          class="row mb-5"
          *ngIf="
                              prescriptionData?.card_4;
                              else card4Else
                            "
        >
          <div class="col-12 font-size-print-16">
            <h4 class="para-heading-font-size">
              {{ presControl.heading }}
            </h4>
            <app-summernotes
              [id]="'card_4'"
              [htmlData]="
                                  prescriptionData?.card_4
                                "
            >
            </app-summernotes>
          </div>
        </div>
        <ng-template #card4Else>
          <div
            class="row mb-5"
            *ngIf="presControl.module_type === 'static'"
          >
            <div class="col-12">
              <h4 class="para-heading-font-size">
                {{ presControl.heading }}
              </h4>
            </div>
            <div
              [style.height]="presControl.height + 'px'"
            ></div>
          </div>
        </ng-template>
      </div>
      <!--  Card 5-->
      <div
        *ngIf="
                            presControl.name === 'Card 5' &&
                            presControl.show_on_pdf === true &&
                            presControl.alignment_on_print === 'left'
                          "
      >
        <div
          class="row mb-5"
          *ngIf="
                              prescriptionData?.card_5;
                              else card5Else
                            "
        >
          <div class="col-12 font-size-print-16">
            <h4 class="para-heading-font-size">
              {{ presControl.heading }}
            </h4>
            <app-summernotes
              [id]="'card_5'"
              [htmlData]="
                                  prescriptionData?.card_5
                                "
            >
            </app-summernotes>
          </div>
        </div>
        <ng-template #card5Else>
          <div
            class="row mb-5"
            *ngIf="presControl.module_type === 'static'"
          >
            <div class="col-12">
              <h4 class="para-heading-font-size">
                {{ presControl.heading }}
              </h4>
            </div>
            <div
              [style.height]="presControl.height + 'px'"
            ></div>
          </div>
        </ng-template>
      </div>
      <!--  Card 6-->
      <div
        *ngIf="
                            presControl.name === 'Card 6' &&
                            presControl.show_on_pdf === true &&
                            presControl.alignment_on_print === 'left'
                          "
      >
        <div
          class="row mb-5"
          *ngIf="
                              prescriptionData?.card_6;
                              else card6Else
                            "
        >
          <div class="col-12 font-size-print-16">
            <h4 class="para-heading-font-size">
              {{ presControl.heading }}
            </h4>
            <app-summernotes
              [id]="'card_6'"
              [htmlData]="
                                  prescriptionData?.card_6
                                "
            >
            </app-summernotes>
          </div>
        </div>
        <ng-template #card6Else>
          <div
            class="row mb-5"
            *ngIf="presControl.module_type === 'static'"
          >
            <div class="col-12">
              <h4 class="para-heading-font-size">
                {{ presControl.heading }}
              </h4>
            </div>
            <div
              [style.height]="presControl.height + 'px'"
            ></div>
          </div>
        </ng-template>
      </div>
      <!--  Card 7-->
      <div
        *ngIf="
                            presControl.name === 'Card 7' &&
                            presControl.show_on_pdf === true &&
                            presControl.alignment_on_print === 'left'
                          "
      >
        <div
          class="row mb-5"
          *ngIf="
                              prescriptionData?.card_7;
                              else card7Else
                            "
        >
          <div class="col-12 font-size-print-16">
            <h4 class="para-heading-font-size">
              {{ presControl.heading }}
            </h4>
            <app-summernotes
              [id]="'card_7'"
              [htmlData]="
                                  prescriptionData?.card_7
                                "
            >
            </app-summernotes>
          </div>
        </div>
        <ng-template #card7Else>
          <div
            class="row mb-5"
            *ngIf="presControl.module_type === 'static'"
          >
            <div class="col-12">
              <h4 class="para-heading-font-size">
                {{ presControl.heading }}
              </h4>
            </div>
            <div
              [style.height]="presControl.height + 'px'"
            ></div>
          </div>
        </ng-template>
      </div>
      <!--  Card 8-->
      <div
        *ngIf="
                            presControl.name === 'Card 8' &&
                            presControl.show_on_pdf === true &&
                            presControl.alignment_on_print === 'left'
                          "
      >
        <div
          class="row mb-5"
          *ngIf="
                              prescriptionData?.card_8;
                              else card8Else
                            "
        >
          <div class="col-12 font-size-print-16">
            <h4 class="para-heading-font-size">
              {{ presControl.heading }}
            </h4>
            <app-summernotes
              [id]="'card_8'"
              [htmlData]="
                                  prescriptionData?.card_8
                                "
            >
            </app-summernotes>
          </div>
        </div>
        <ng-template #card8Else>
          <div
            class="row mb-5"
            *ngIf="presControl.module_type === 'static'"
          >
            <div class="col-12">
              <h4 class="para-heading-font-size">
                {{ presControl.heading }}
              </h4>
            </div>
            <div
              [style.height]="presControl.height + 'px'"
            ></div>
          </div>
        </ng-template>
      </div>
      <!--  Card 9-->
      <div
        *ngIf="
                            presControl.name === 'Card 9' &&
                            presControl.show_on_pdf === true &&
                            presControl.alignment_on_print === 'left'
                          "
      >
        <div
          class="row mb-5"
          *ngIf="
                              prescriptionData?.card_9;
                              else card9Else
                            "
        >
          <div class="col-12 font-size-print-16">
            <h4 class="para-heading-font-size">
              {{ presControl.heading }}
            </h4>
            <app-summernotes
              [id]="'card_9'"
              [htmlData]="
                                  prescriptionData?.card_9
                                "
            >
            </app-summernotes>
          </div>
        </div>
        <ng-template #card9Else>
          <div
            class="row mb-5"
            *ngIf="presControl.module_type === 'static'"
          >
            <div class="col-12">
              <h4 class="para-heading-font-size">
                {{ presControl.heading }}
              </h4>
            </div>
            <div
              [style.height]="presControl.height + 'px'"
            ></div>
          </div>
        </ng-template>
      </div>
      <!--  Card 10-->
      <div
        *ngIf="
                            presControl.name === 'Card 10' &&
                            presControl.show_on_pdf === true &&
                            presControl.alignment_on_print === 'left'
                          "
      >
        <div
          class="row mb-5"
          *ngIf="
                              prescriptionData?.card_10;
                              else card10Else
                            "
        >
          <div class="col-12 font-size-print-16">
            <h4 class="para-heading-font-size">
              {{ presControl.heading }}
            </h4>
            <app-summernotes
              [id]="'card_10'"
              [htmlData]="
                                  prescriptionData?.card_10
                                "
            >
            </app-summernotes>
          </div>
        </div>
        <ng-template #card10Else>
          <div
            class="row mb-5"
            *ngIf="presControl.module_type === 'static'"
          >
            <div class="col-12">
              <h4 class="para-heading-font-size">
                {{ presControl.heading }}
              </h4>
            </div>
            <div
              [style.height]="presControl.height + 'px'"
            ></div>
          </div>
        </ng-template>
      </div>
      <!--  Drug History-->
      <div
        *ngIf="
                            presControl.name === 'Drug History' &&
                            presControl.show_on_pdf === true &&
                            presControl.alignment_on_print === 'left'
                          "
      >
        <div
          class="row mb-5"
          *ngIf="
                              prescriptionData?.drug_history;
                              else drugHistoryHistoryElse
                            "
        >
          <div class="col-12 font-size-print-16">
            <h4 class="para-heading-font-size">
              {{ presControl.heading }}
            </h4>
            <app-summernotes
              [id]="'drug_history'"
              [htmlData]="prescriptionData?.drug_history"
            >
            </app-summernotes>
          </div>
        </div>
        <ng-template #drugHistoryHistoryElse>
          <div
            class="row mb-5"
            *ngIf="presControl.module_type === 'static'"
          >
            <div class="col-12">
              <h4 class="para-heading-font-size">
                {{ presControl.heading }}
              </h4>
            </div>
            <div
              [style.height]="presControl.height + 'px'"
            ></div>
          </div>
        </ng-template>
      </div>
      <!--                       chronic_problems-->
      <div
        *ngIf="
                            presControl.name === 'Chronic Problem List' &&
                            presControl.show_on_pdf === true &&
                            presControl.alignment_on_print === 'left'
                          "
      >
        <div
          class="row mb-5"
          *ngIf="
                              prescriptionData?.chronic_problems.length;
                              else chronicProblem
                            "
        >
          <div class="col-12 font-size-print-16">
            <h4 class="para-heading-font-size">
              {{ presControl.heading }}
            </h4>
            <ng-container *ngFor="let data of prescriptionData?.chronic_problems">
              <div *ngIf="data?.date || data?.problem">{{data?.date | dateFormat}} -  {{data?.problem}}</div>
            </ng-container>
          </div>
        </div>
        <ng-template #chronicProblem>
          <div
            class="row mb-5"
            *ngIf="presControl.module_type === 'static'"
          >
            <div class="col-12">
              <h4 class="para-heading-font-size">
                {{ presControl.heading }}
              </h4>
            </div>
            <div
              [style.height]="presControl.height + 'px'"
            ></div>
          </div>
        </ng-template>
      </div>
      <!--                       Hospital Problem/Surgical History-->
      <div
        *ngIf="
                            presControl.name === 'Hospital Problem/Surgical History' &&
                            presControl.show_on_pdf === true &&
                            presControl.alignment_on_print === 'left'
                          "
      >
        <div
          class="row mb-5"
          *ngIf="
                              prescriptionData?.surgical_history.length;
                              else surgicalHistoryProblem
                            "
        >
          <div class="col-12 font-size-print-16">
            <h4 class="para-heading-font-size">
              {{ presControl.heading }}
            </h4>
            <ng-container *ngFor="let data of prescriptionData?.surgical_history">
              <div  *ngIf="data?.date || data?.problem">{{data?.date | dateFormat}} -  {{data?.problem}}</div>
            </ng-container>
          </div>
        </div>
        <ng-template #surgicalHistoryProblem>
          <div
            class="row mb-5"
            *ngIf="presControl.module_type === 'static'"
          >
            <div class="col-12">
              <h4 class="para-heading-font-size">
                {{ presControl.heading }}
              </h4>
            </div>
            <div
              [style.height]="presControl.height + 'px'"
            ></div>
          </div>
        </ng-template>
      </div>

      <!-- Presenting Complaint -->
      <div
        *ngIf="
                            presControl.name === 'Presenting Complaint' &&
                            presControl.show_on_pdf === true &&
                            presControl.alignment_on_print === 'left'
                          "
      >
        <div
          class="row mb-5"
          *ngIf="
                              prescriptionData?.patient_complaints
                                ?.presenting_complaints;
                              else presentingComplaintElse
                            "
        >
          <div class="col-12 font-size-print-16">
            <h4 class="para-heading-font-size">
              {{ presControl.heading }}
            </h4>
            <app-summernotes
              [id]="'presenting_complaints'"
              [htmlData]="
                                  prescriptionData?.patient_complaints
                                    ?.presenting_complaints
                                "
            >
            </app-summernotes>
          </div>
        </div>
        <ng-template #presentingComplaintElse>
          <div
            class="row mb-5"
            *ngIf="presControl.module_type === 'static'"
          >
            <div class="col-12">
              <h4 class="para-heading-font-size">
                {{ presControl.heading }}
              </h4>
            </div>
            <div
              [style.height]="presControl.height + 'px'"
            ></div>
          </div>
        </ng-template>
      </div>

      <div
        *ngIf="
                            presControl.name === 'Procedures' &&
                            presControl.show_on_pdf === true &&
                            presControl.alignment_on_print === 'left'
                          "
      >
        <div
          class="row mb-5"
          *ngIf="
                              prescriptionData?.patient_complaints?.procedures;
                              else proceduresElse
                            "
        >
          <div class="col-12 font-size-print-16">
            <h4 class="para-heading-font-size">
              {{ presControl.heading }}
            </h4>
            <app-summernotes
              [id]="'procedures'"
              [htmlData]="
                                  prescriptionData?.patient_complaints
                                    ?.procedures
                                "
            >
            </app-summernotes>
          </div>
        </div>
        <ng-template #proceduresElse>
          <div
            class="row mb-5"
            *ngIf="presControl.module_type === 'static'"
          >
            <div class="col-12">
              <h4 class="para-heading-font-size">
                {{ presControl.heading }}
              </h4>
            </div>
            <div
              [style.height]="presControl.height + 'px'"
            ></div>
          </div>
        </ng-template>
      </div>

      <!-- Examination -->
      <div
        *ngIf="
                            presControl.name === 'Examination' &&
                            presControl.show_on_pdf === true &&
                            presControl.alignment_on_print === 'left'
                          "
      >
        <div
          class="row mb-5"
          *ngIf="
                              prescriptionData?.patient_complaints
                                ?.general_physical_examination;
                              else examinationElse
                            "
        >
          <div class="col-12 font-size-print-16">
            <h4 class="para-heading-font-size">
              {{ presControl.heading }}
            </h4>
            <app-summernotes
              [id]="'general_physical_examination'"
              [htmlData]="
                                  prescriptionData?.patient_complaints
                                    ?.general_physical_examination
                                "
            >
            </app-summernotes>
          </div>
        </div>
        <ng-template #examinationElse>
          <div
            class="row mb-5"
            *ngIf="presControl.module_type === 'static'"
          >
            <div class="col-12">
              <h4 class="para-heading-font-size">
                {{ presControl.heading }}
              </h4>
            </div>
            <div
              [style.height]="presControl.height + 'px'"
            ></div>
          </div>
        </ng-template>
      </div>

      <!-- Diagnosis -->
      <div>
        <div
          *ngIf="
                              presControl.name === 'Provisional Diagnosis' &&
                              presControl.show_on_pdf === true &&
                              presControl.alignment_on_print === 'left'
                            "
        >
          <div
            class="row mb-5"
            *ngIf="
                                showProvisionalDia?.length > 0;
                                else provisionalDiagnosisElse
                              "
          >
            <div class="col-12">
              <h4 class="para-heading-font-size">
                {{ presControl.heading }}
              </h4>
            </div>
            <div class="col-12 mb-2 font-size-print-16">
              <ng-container
                *ngFor="
                                    let data of prescriptionData?.diagnosis
                                  "
              >
                                  <span
                                    *ngIf="
                                      data?.type === 'ProvisionalDiagnosis'
                                    "
                                  >{{ data?.name }}</span
                                  >
              </ng-container>
            </div>
          </div>
          <ng-template #provisionalDiagnosisElse>
            <div
              class="row mb-5"
              *ngIf="presControl.module_type === 'static'"
            >
              <div class="col-12">
                <h4 class="para-heading-font-size">
                  {{ presControl.heading }}
                </h4>
              </div>
              <div
                [style.height]="presControl.height + 'px'"
              ></div>
            </div>
          </ng-template>
        </div>

        <div
          *ngIf="
                              presControl.name === 'Differential Diagnosis' &&
                              presControl.show_on_pdf === true &&
                              presControl.alignment_on_print === 'left'
                            "
        >
          <div
            class="row mb-5"
            *ngIf="
                                showDifferentialDia?.length > 0;
                                else differentialDiagnosisElse
                              "
          >
            <div class="col-12">
              <h4 class="para-heading-font-size">
                {{ presControl.heading }}
              </h4>
            </div>
            <div class="col-12 mb-2 font-size-print-16">
              <ng-container
                *ngFor="
                                    let data of prescriptionData?.diagnosis; let i = index
                                  "
              >
                                  <span
                                    *ngIf="
                                      data?.type === 'DifferentialDiagnosis'
                                    "
                                  >{{ data?.name }}<span *ngIf="i < prescriptionData?.diagnosis.length - 1">, </span></span
                                  >
              </ng-container>
            </div>
          </div>
          <ng-template #differentialDiagnosisElse>
            <div
              class="row mb-5"
              *ngIf="presControl.module_type === 'static'"
            >
              <div class="col-12">
                <h4 class="para-heading-font-size">
                  {{ presControl.heading }}
                </h4>
              </div>
              <div
                [style.height]="presControl.height + 'px'"
              ></div>
            </div>
          </ng-template>
        </div>

        <div
          *ngIf="
                              presControl.name === 'Final Diagnosis' &&
                              presControl.show_on_pdf === true &&
                              presControl.alignment_on_print === 'left'
                            "
        >
          <div
            class="row mb-5"
            *ngIf="
                                showFinalDia?.length > 0;
                                else finalDiagnosisElse
                              "
          >
            <div class="col-12">
              <h4 class="para-heading-font-size">
                {{ presControl.heading }}
              </h4>
            </div>
            <div class="col-12 font-size-print-16">
              <ng-container
                *ngFor="
                                    let data of prescriptionData?.diagnosis
                                  "
              >
                                  <span
                                    *ngIf="data?.type === 'FinalDiagnosis'"
                                  >{{ data?.name }}</span
                                  >
              </ng-container>
            </div>
          </div>
          <ng-template #finalDiagnosisElse>
            <div
              class="row mb-5"
              *ngIf="presControl.module_type === 'static'"
            >
              <div class="col-12">
                <h4 class="para-heading-font-size">
                  {{ presControl.heading }}
                </h4>
              </div>
              <div
                [style.height]="presControl.height + 'px'"
              ></div>
            </div>
          </ng-template>
        </div>
      </div>

      <!-- medicines -->
      <div
        *ngIf="
                            presControl.name === 'Treatment At Hospital' &&
                            presControl.show_on_pdf === true &&
                            presControl.alignment_on_print === 'left'
                          "
      >
        <div
          class="row mb-5"
          *ngIf="
                              prescriptionData?.medications?.length > 0;
                              else treatmentAtHospitalElse
                            "
        >
          <div class="col-12">
            <h4 class="para-heading-font-size">
              {{ presControl.heading }}
            </h4>
          </div>
          <div class="col-12 font-size-print-16">
            <table class="table" *ngIf="!medicineAsTabular">
              <tbody class="service-table">
              <tr>
                <th style="width: 14%">Type</th>
                <th style="width: 40%">Drug(s)</th>
                <th style="width: 12%" class="text-center">
                  Dose
                </th>
                <th style="width: 12%" class="text-center">
                  Frequency
                </th>
                <th style="width: 12%" class="text-center">
                  Duration
                </th>
                <th style="width: 10%" class="text-center">
                  Route
                </th>
              </tr>
              <tr
                *ngFor="
                                      let data of prescriptionData?.medications
                                    "
              >
                <td>
                  <strong>{{
                      data?.stoc_type_translation | titlecase
                    }}</strong>
                </td>
                <td>
                  <strong
                  >{{ data?.name
                    }}<span *ngIf="data?.generic_name">
                                          ({{ data?.generic_name }})</span
                    ></strong
                  >
                  <span
                    *ngIf="data?.comments"
                    class="font-italic"
                    style="font-size: 11px"
                  ><br />{{ data?.comments }}</span
                  >
                </td>
                <td class="text-center">
                  {{ data?.dos_type_translation }}
                </td>
                <td class="text-center">
                  {{ data?.dosee_translation }}
                </td>
                <td class="text-center">
                  {{ data?.no_of_days_value }}
                </td>
                <td class="text-center">
                  {{ data?.routee_translation }}
                </td>
              </tr>
              </tbody>
            </table>
            <ng-container *ngIf="medicineAsTabular">
              <div
                *ngFor="
                                    let data of prescriptionData?.medications
                                  "
                style="
                                    border-bottom: 1px dotted #000000;
                                    margin-bottom: 10px;
                                  "
              >
                <p class="mb-0">
                  {{ data?.name }}
                  <span *ngIf="data?.generic_name">
                                      ({{ data?.generic_name }})</span
                  >
                </p>
                <p class="mb-3">
                                    <span *ngIf="data?.dos_type_translation"
                                    >{{ data?.dos_type_translation }},</span
                                    >
                  <span *ngIf="data?.dosee_translation"
                  >{{ data?.dosee_translation }},</span
                  >
                  {{ data?.no_of_days_value }}
                </p>
              </div>
            </ng-container>
          </div>
        </div>
        <ng-template #treatmentAtHospitalElse>
          <div
            class="row mb-5"
            *ngIf="presControl.module_type === 'static'"
          >
            <div class="col-12">
              <h4 class="para-heading-font-size">
                {{ presControl.heading }}
              </h4>
            </div>
            <div
              [style.height]="presControl.height + 'px'"
            ></div>
          </div>
        </ng-template>
      </div>
      <!--                        Medicine Allergies-->
      <div
        *ngIf="
                            presControl.name === 'Medicine Allergies' &&
                            presControl.show_on_pdf === true &&
                            presControl.alignment_on_print === 'left'
                          "
      >
        <div
          class="row mb-5"
          *ngIf="
                              prescriptionData?.medicine_allergies?.length > 0;
                              else medicineAllergies
                            "
        >
          <div class="col-12">
            <h4 class="para-heading-font-size">
              {{ presControl.heading }}
            </h4>
          </div>
          <div class="col-12 font-size-print-16">
            <table class="table">
              <tbody class="service-table">
              <tr>
                <th style="width: 50%">Allergies</th>
                <th style="width: 50%">Reaction</th>
              </tr>
              <tr
                *ngFor="
                                      let data of prescriptionData?.medicine_allergies
                                    "
              >
                <td>
                  <strong>{{
                      data?.allergies
                    }}</strong>
                </td>
                <td>
                  <strong>{{data?.reaction}}</strong>
                </td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
        <ng-template #medicineAllergies>
          <div
            class="row mb-5"
            *ngIf="presControl.module_type === 'static'"
          >
            <div class="col-12">
              <h4 class="para-heading-font-size">
                {{ presControl.heading }}
              </h4>
            </div>
            <div
              [style.height]="presControl.height + 'px'"
            ></div>
          </div>
        </ng-template>
      </div>

      <div
        *ngIf="
                            presControl.name === 'Treatment At Discharge' &&
                            presControl.show_on_pdf === true &&
                            presControl.alignment_on_print === 'left'
                          "
      >
        <div
          class="row mb-5"
          *ngIf="
                              prescriptionData?.discharge_medications?.length >
                                0;
                              else treatmentAtDischargeElse
                            "
        >
          <div class="col-12">
            <h4 class="para-heading-font-size">
              {{ presControl.heading }}
            </h4>
          </div>
          <div class="col-12 font-size-print-16">
            <table class="table" *ngIf="!medicineAsTabular">
              <tbody class="service-table">
              <tr>
                <th style="width: 14%">Type</th>
                <th style="width: 40%">Drug(s)</th>
                <th style="width: 12%" class="text-center">
                  Dose
                </th>
                <th style="width: 12%" class="text-center">
                  Frequency
                </th>
                <th style="width: 12%" class="text-center">
                  Duration
                </th>
                <th style="width: 10%" class="text-center">
                  Route
                </th>
              </tr>
              <tr
                *ngFor="
                                      let data of prescriptionData?.discharge_medications
                                    "
              >
                <td>
                  <strong>{{
                      data?.stoc_type_translation | titlecase
                    }}</strong>
                </td>
                <td>
                  <strong
                  >{{ data?.name }}
                    <span *ngIf="data?.generic_name">
                                          ({{ data?.generic_name }})</span
                    ></strong
                  >
                  <span
                    *ngIf="data?.comments"
                    class="font-italic"
                    style="font-size: 11px"
                  ><br />{{ data?.comments }}</span
                  >
                </td>
                <td class="text-center">
                  {{ data?.dos_type_translation }}
                </td>
                <td class="text-center">
                  {{ data?.dosee_translation }}
                </td>
                <td class="text-center">
                  {{ data?.no_of_days_value }}
                </td>
                <td class="text-center">
                  {{ data?.routee_translation }}
                </td>
              </tr>
              </tbody>
            </table>
            <ng-container *ngIf="medicineAsTabular">
              <div
                *ngFor="
                                    let data of prescriptionData?.discharge_medications
                                  "
                style="
                                    border-bottom: 1px dotted #000000;
                                    margin-bottom: 10px;
                                  "
              >
                <p class="mb-0">
                  {{ data?.name }}
                  <span *ngIf="data?.generic_name">
                                      ({{ data?.generic_name }})</span
                  >
                </p>
                <p class="mb-3">
                                    <span *ngIf="data?.dos_type_translation"
                                    >{{ data?.dos_type_translation }},</span
                                    >
                  <span *ngIf="data?.dosee_translation"
                  >{{ data?.dosee_translation }},</span
                  >
                  {{ data?.no_of_days_value }}
                </p>
              </div>
            </ng-container>
          </div>
        </div>
        <ng-template #treatmentAtDischargeElse>
          <div
            class="row mb-5"
            *ngIf="presControl.module_type === 'static'"
          >
            <div class="col-12">
              <h4 class="para-heading-font-size">
                {{ presControl.heading }}
              </h4>
            </div>
            <div
              [style.height]="presControl.height + 'px'"
            ></div>
          </div>
        </ng-template>
      </div>

      <!-- Follow up Instructions -->
      <div
        *ngIf="
                            presControl.name === 'Follow Up Date' &&
                            presControl.show_on_pdf === true &&
                            presControl.alignment_on_print === 'left'
                          "
      >
        <div
          class="row mb-5"
          *ngIf="
                              prescriptionData?.appointment?.length;
                              else followUpDateElse
                            "
        >
          <div class="col-12">
            <h4 class="para-heading-font-size">
              {{ presControl.heading }}
              <!--                                <span-->
              <!--                                  class="mr-5 font-size-h6"-->
              <!--                                  style="font-weight: initial"-->
              <!--                                  >{{-->
              <!--                                    prescriptionData?.follow_up-->
              <!--                                      | date: "dd-MM-yyyy"-->
              <!--                                  }}</span-->
              <!--                                >-->
            </h4>
            <ng-container *ngFor="let data of prescriptionData?.appointment">
              <div>Please follow up with Dr. {{data?.doctor_first_name}} {{data?.doctor_last_name}} on {{data?.date | dateFormat}} at {{data?.start_time}} - {{data?.end_time}}</div>
            </ng-container>
          </div>
        </div>
        <ng-template #followUpDateElse>
          <div
            class="row mb-5"
            *ngIf="presControl.module_type === 'static'"
          >
            <div class="col-12">
              <h4 class="para-heading-font-size">
                {{ presControl.heading }}
              </h4>
            </div>
            <div
              [style.height]="presControl.height + 'px'"
            ></div>
          </div>
        </ng-template>
      </div>

      <div
        *ngIf="
                            presControl.name === 'Consultant Notes' &&
                            presControl.show_on_pdf === true &&
                            presControl.alignment_on_print === 'left'
                          "
      >
        <div
          class="row mb-5"
          *ngIf="visitData?.note; else consultantNotesElse"
        >
          <div class="col-12">
            <h4 class="para-heading-font-size">
              {{ presControl.heading }}
            </h4>
            <div>
              {{
                visitData?.note_created_at
                  | date: "dd-MM-yyyy"
              }}
              <span class="ml-10">{{
                  visitData?.doctor_name
                }}</span>
            </div>
          </div>
          <div class="col-12 font-size-print-16 mt-2">
            <app-summernotes
              [id]="'note'"
              [htmlData]="visitData?.note"
            >
            </app-summernotes>
          </div>
        </div>
        <ng-template #consultantNotesElse>
          <div
            class="row mb-5"
            *ngIf="presControl.module_type === 'static'"
          >
            <div class="col-12">
              <h4 class="para-heading-font-size">
                {{ presControl.heading }}
              </h4>
            </div>
            <div
              [style.height]="presControl.height + 'px'"
            ></div>
          </div>
        </ng-template>
      </div>

      <div
        *ngIf="
                            presControl.name === 'Follow Up Instructions' &&
                            presControl.show_on_pdf === true &&
                            presControl.alignment_on_print === 'left'
                          "
      >
        <div
          class="row mb-5"
          *ngIf="
                              prescriptionData?.follow_up_instruction;
                              else followUpInstructionsElse
                            "
        >
          <div class="col-12">
            <h4 class="para-heading-font-size">
              {{ presControl.heading }}
            </h4>
          </div>
          <div class="col-12 font-size-print-16">
            <app-summernotes
              [id]="'follow_up_instruction'"
              [htmlData]="
                                  prescriptionData?.follow_up_instruction
                                "
            >
            </app-summernotes>
          </div>
        </div>
        <ng-template #followUpInstructionsElse>
          <div
            class="row mb-5"
            *ngIf="presControl.module_type === 'static'"
          >
            <div class="col-12">
              <h4 class="para-heading-font-size">
                {{ presControl.heading }}
              </h4>
            </div>
            <div
              [style.height]="presControl.height + 'px'"
            ></div>
          </div>
        </ng-template>
      </div>

      <div
        *ngIf="
                            presControl.name === 'Admission Orders' &&
                            presControl.show_on_pdf === true &&
                            presControl.alignment_on_print === 'left'
                          "
      >
        <div
          class="row mb-5"
          *ngIf="
                              visitData?.ref_visit?.doctor_name;
                              else admissionOrdersElse
                            "
        >
          <div class="col-12">
            <h4 class="para-heading-font-size">
              {{ presControl.heading }}
            </h4>
          </div>
          <div class="col-12 font-size-print-16">
            <div><span><strong>Doctor:</strong> {{visitData?.ref_visit?.doctor_name}}</span></div>
            <div><span><strong>Department:</strong> {{visitData?.ref_visit?.department_name}}</span></div>
            <div><span><strong>Ward/Bed:</strong> {{visitData?.ref_visit?.ward_name}} <span *ngIf="visitData?.ref_visit?.bed_no">({{visitData?.ref_visit?.bed_no}})</span></span></div>
            <div><span><strong>Condition:</strong> {{visitData?.ref_visit?.condition | titlecase}}</span></div>
            <div><span><strong>Comments:</strong> {{visitData?.ref_visit?.comments}}</span></div>
            <div>
              <strong>Admission Order Detail: </strong> <app-summernotes
                [id]="'admission_orders'"
                [htmlData]="
                                  visitData?.ref_visit?.admission_orders
                                "
              >
              </app-summernotes>
            </div>
<!--            <div>{{ visitData?.admission_orders }}</div>-->
          </div>
        </div>
        <ng-template #admissionOrdersElse>
          <div
            class="row mb-5"
            *ngIf="presControl.module_type === 'static'"
          >
            <div class="col-12">
              <h4 class="para-heading-font-size">
                {{ presControl.heading }}
              </h4>
            </div>
            <div
              [style.height]="presControl.height + 'px'"
            ></div>
          </div>
        </ng-template>
      </div>

      <div
        *ngIf="
                            presControl.name === 'Diet' &&
                            presControl.show_on_pdf === true &&
                            presControl.alignment_on_print === 'left'
                          "
      >
        <div class="row mb-5"
             *ngIf="this.prescriptionData.diet.breakfast ||
         this.prescriptionData.diet.lunch ||
          this.prescriptionData.diet.dinner ||
          this.prescriptionData.diet.snacks ||
          this.prescriptionData.diet.others_detail;
                              else dietElse">
          <div class="col-12">
            <h4 class="para-heading-font-size">
              {{ presControl.heading }}
            </h4>
          </div>
          <div class="col-12" *ngIf="prescriptionData.diet.breakfast">
            <strong>BreakFast: </strong>
            <span>{{ convertTo12HourFormat(prescriptionData.diet.breakfast_time) }}, </span>
            <span>{{prescriptionData.diet.breakfast_detail}} </span>
          </div>
          <div class="col-12" *ngIf="prescriptionData.diet.lunch">
            <strong>Lunch: </strong>
            <span>{{ convertTo12HourFormat(prescriptionData.diet.lunch_time) }}, </span>
            <span>{{prescriptionData.diet.lunch_detail}}</span>
          </div>
          <div class="col-12" *ngIf="prescriptionData.diet.dinner">
            <strong>Dinner: </strong>
            <span>{{ convertTo12HourFormat(prescriptionData.diet.dinner_time) }}, </span>
            <span>{{prescriptionData.diet.dinner_detail}}</span>
          </div>
          <div class="col-12" *ngIf="prescriptionData.diet.snacks">
            <strong>Snacks: </strong>
            <span>{{prescriptionData.diet.dinner_detail}}</span>
          </div>
          <div class="col-12" *ngIf="prescriptionData.diet.others_detail">
            <strong>Others: </strong>
            <span>{{prescriptionData.diet.others_detail}}</span>
          </div>
        </div>
        <ng-template #dietElse>
          <div
            class="row mb-5"
            *ngIf="presControl.module_type === 'static'"
          >
            <div class="col-12">
              <h4 class="para-heading-font-size">
                {{ presControl.heading }}
              </h4>
            </div>
            <div
              [style.height]="presControl.height + 'px'"
            ></div>
          </div>
        </ng-template>
      </div>

      <!-- Operation Details -->
      <div
        *ngIf="
                            presControl.name === 'Operation Details' &&
                            presControl.show_on_pdf === true &&
                            presControl.alignment_on_print === 'left'
                          "
      >
        <div
          class="row mb-5"
          *ngIf="presControl.module_type === 'dynamic'"
        >
          <div
            class="col-12"
            *ngIf="
                                prescriptionData?.operation_date ||
                                prescriptionData?.operative_procedure ||
                                prescriptionData?.operative_finding
                              "
          >
            <h4 class="para-heading-font-size">
              {{ presControl.heading }}
            </h4>
            <span
              class="d-flex"
              *ngIf="prescriptionData?.operation_date"
            ><strong>Operation Date:</strong>
              {{ prescriptionData?.operation_date }}</span
            >
            <span
              class="d-flex"
              *ngIf="prescriptionData?.operative_procedure"
            ><strong>Operative Procedure:</strong>
              {{
                prescriptionData?.operative_procedure
              }}</span
            >
            <span
              class="d-flex"
              *ngIf="prescriptionData?.operative_finding"
            ><strong>Operative Finding:</strong>
              {{ prescriptionData?.operative_finding }}</span
            >
          </div>
        </div>
        <ng-container
          *ngIf="presControl.module_type === 'static'"
        >
          <div class="row mb-5">
            <div class="col-12">
              <h4 class="para-heading-font-size">
                {{ presControl.heading }}
              </h4>
              <span class="d-flex"
              ><strong>Operation Date:</strong>
                {{
                  prescriptionData?.operation_date || ""
                }}</span
              >
              <span class="d-flex"
              ><strong>Operative Procedure:</strong>
                {{
                  prescriptionData?.operative_procedure || ""
                }}</span
              >
              <span class="d-flex"
              ><strong>Operative Finding:</strong>
                {{
                  prescriptionData?.operative_finding || ""
                }}</span
              >
            </div>
          </div>
        </ng-container>
      </div>

      <!-- Gyane Presenting Complaint -->
      <div
        *ngIf="
                            presControl.name === 'Gynae Presenting Complaint' &&
                            presControl.show_on_pdf === true &&
                            presControl.alignment_on_print === 'left'
                          "
      >
        <div
          class="row mb-5"
          *ngIf="
                              gynaecologicalComplaint?.mf ||
                                gynaecologicalComplaint?.lmp ||
                                gynaecologicalComplaint?.edd ||
                                gynaecologicalComplaint?.gastralunal_age;
                              else gynaecologicalPresentingComplaintElse
                            "
        >
          <div class="col-12 font-size-print-16">
            <h4 class="para-heading-font-size">
              {{ presControl.heading }}
            </h4>
            <app-summernotes
              [id]="'gynae_instructions'"
              [htmlData]="
                                  gynaecologicalComplaint?.gynae_instructions
                                "
            >
            </app-summernotes>
          </div>
          <div
            class="col-12"
            *ngIf="this.gynaecologicalComplaint?.mf"
          >
            <strong>MF:</strong>
            {{ this.gynaecologicalComplaint?.mf }}
          </div>
          <div
            class="col-12"
            *ngIf="this.gynaecologicalComplaint?.lmp"
          >
            <strong>LMP:</strong>
            {{ this.gynaecologicalComplaint?.lmp }}
          </div>
          <div
            class="col-12"
            *ngIf="this.gynaecologicalComplaint?.edd"
          >
            <strong>EDD:</strong>
            {{ this.gynaecologicalComplaint?.edd }}
          </div>
          <div
            class="col-12"
            *ngIf="
                                this.gynaecologicalComplaint?.gastralunal_age
                              "
          >
            <strong>Gestational Age:</strong>
            {{
              this.gynaecologicalComplaint?.gastralunal_age
            }}
          </div>
        </div>
        <ng-template #gynaecologicalPresentingComplaintElse>
          <div
            class="row mb-5"
            *ngIf="presControl.module_type === 'static'"
          >
            <div class="col-12">
              <h4 class="para-heading-font-size">
                {{ presControl.heading }}
              </h4>
            </div>
            <div
              [style.height]="presControl.height + 'px'"
            ></div>
          </div>
        </ng-template>
      </div>
      <!-- Obstetrical History -->
      <div
        *ngIf="
                            presControl.name === 'Obstetrical History' &&
                            presControl.show_on_pdf === true &&
                            presControl.alignment_on_print === 'left'
                          "
      >
        <div
          class="row mb-5"
          *ngIf="
                              obstetricalHistory?.g ||
                                obstetricalHistory?.p ||
                                obstetricalHistory?.a ||
                                obstetricalHistory?.lcb ||
                                obstetricalHistory?.mode_of_delivery ||
                                obstetricalHistory?.pod ||
                                obstetricalHistory?.rf;
                              else obstetricalHistoryElse
                            "
        >
          <div class="col-12 font-size-print-16">
            <h4 class="para-heading-font-size">
              {{ presControl.heading }}
            </h4>
          </div>
          <div
            class="col-12"
            *ngIf="this.obstetricalHistory?.g"
          >
            <strong>G:</strong>
            {{ this.obstetricalHistory?.g }}
          </div>
          <div
            class="col-12"
            *ngIf="this.obstetricalHistory?.p"
          >
            <strong>P:</strong>
            {{ this.obstetricalHistory?.p }}
          </div>
          <div
            class="col-12"
            *ngIf="this.obstetricalHistory?.a"
          >
            <strong>A:</strong>
            {{ this.obstetricalHistory?.a }}
          </div>
          <div
            class="col-12"
            *ngIf="this.obstetricalHistory?.lcb"
          >
            <strong>LCB:</strong>
            {{ this.obstetricalHistory?.lcb }}
          </div>
          <div
            class="col-12"
            *ngIf="this.obstetricalHistory?.mode_of_delivery"
          >
            <strong>Mode Of Delivery:</strong>
            {{ this.obstetricalHistory?.mode_of_delivery }}
          </div>
          <div
            class="col-12"
            *ngIf="this.obstetricalHistory?.pod"
          >
            <strong>POD:</strong>
            {{ this.obstetricalHistory?.pod }}
          </div>
          <div
            class="col-12"
            *ngIf="this.obstetricalHistory?.rf"
          >
            <strong>RF:</strong>
            {{ this.obstetricalHistory?.rf }}
          </div>
        </div>
        <ng-template #obstetricalHistoryElse>
          <div
            class="row mb-5"
            *ngIf="presControl.module_type === 'static'"
          >
            <div class="col-12">
              <h4 class="para-heading-font-size">
                {{ presControl.heading }}
              </h4>
            </div>
            <div
              [style.height]="presControl.height + 'px'"
            ></div>
          </div>
        </ng-template>
      </div>
      <!-- Gynaecological History -->
      <div
        *ngIf="
                            presControl.name === 'Gynaecological History' &&
                            presControl.show_on_pdf === true &&
                            presControl.alignment_on_print === 'left'
                          "
      >
        <div
          class="row mb-5"
          *ngIf="
                              gynaecologicalHistory?.age_of_minarche ||
                                gynaecologicalHistory?.menstrual_cycle ||
                                gynaecologicalHistory?.lmp ||
                                gynaecologicalHistory?.dysmenorrhea ||
                                gynaecologicalHistory?.dyspareunia ||
                                gynaecologicalHistory?.imb ||
                                gynaecologicalHistory?.pcb ||
                                gynaecologicalHistory?.contraception;
                              else gynaecologicalHistoryElse
                            "
        >
          <div class="col-12 font-size-print-16">
            <h4 class="para-heading-font-size">
              {{ presControl.heading }}
            </h4>
          </div>
          <div
            class="col-12"
            *ngIf="
                                this.gynaecologicalHistory?.age_of_minarche
                              "
          >
            <strong>Age Of Menarche:</strong>
            {{ this.gynaecologicalHistory?.age_of_minarche }}
          </div>
          <div
            class="col-12"
            *ngIf="
                                this.gynaecologicalHistory?.menstrual_cycle
                              "
          >
            <strong>Menstrual Cycle:</strong>
            {{ this.gynaecologicalHistory?.menstrual_cycle }}
          </div>
          <div
            class="col-12"
            *ngIf="this.gynaecologicalHistory?.lmp"
          >
            <strong>LMP:</strong>
            {{ this.gynaecologicalHistory?.lmp }}
          </div>
          <div
            class="col-12"
            *ngIf="this.gynaecologicalHistory?.dysmenorrhea"
          >
            <strong>Dysmenorrhea:</strong>
            {{ this.gynaecologicalHistory?.dysmenorrhea }}
          </div>
          <div
            class="col-12"
            *ngIf="this.gynaecologicalHistory?.dyspareunia"
          >
            <strong>Dyspareunia:</strong>
            {{ this.gynaecologicalHistory?.dyspareunia }}
          </div>
          <div
            class="col-12"
            *ngIf="this.gynaecologicalHistory?.imb"
          >
            <strong>IMB:</strong>
            {{ this.gynaecologicalHistory?.imb }}
          </div>
          <div
            class="col-12"
            *ngIf="this.gynaecologicalHistory?.pcb"
          >
            <strong>PCB:</strong>
            {{ this.gynaecologicalHistory?.pcb }}
          </div>
          <div
            class="col-12"
            *ngIf="this.gynaecologicalHistory?.contraception"
          >
            <strong>Contraception:</strong>
            {{ this.gynaecologicalHistory?.contraception }}
          </div>
        </div>
        <ng-template #gynaecologicalHistoryElse>
          <div
            class="row mb-5"
            *ngIf="presControl.module_type === 'static'"
          >
            <div class="col-12">
              <h4 class="para-heading-font-size">
                {{ presControl.heading }}
              </h4>
            </div>
            <div
              [style.height]="presControl.height + 'px'"
            ></div>
          </div>
        </ng-template>
      </div>
      <!-- Pelvic Examination -->
      <div
        *ngIf="
                      presControl.name === 'Pelvic Examination' &&
                      presControl.show_on_pdf === true &&
                      presControl.alignment_on_print === 'left'
                    "
      >
        <div
          class="row mb-5"
          *ngIf="
                        pelvicExamination;
                        else pelvicExaminationElse
                      "
        >
          <div class="col-12 font-size-print-16">
            <h4 class="para-heading-font-size">
              {{ presControl.heading }}
            </h4>
          </div>
          <div
            class="col-12"
            *ngIf="this.pelvicExamination?.ps_history"
          >
            <strong>P/S:</strong>
            {{ this.pelvicExamination?.ps_history }}
          </div>
          <div
            class="col-12"
            *ngIf="this.pelvicExamination?.bm_history"
          >
            <strong>B/M:</strong>
            {{ this.pelvicExamination?.bm_history }}
          </div>
          <div
            class="col-12"
            *ngIf="this.pelvicExamination?.pap_history"
          >
            <strong>PaP Smear:</strong>
            {{ this.pelvicExamination?.pap_history }}
          </div>
        </div>
        <ng-template #pelvicExaminationElse>
          <div
            class="row mb-5"
            *ngIf="presControl.module_type === 'static'"
          >
            <div class="col-12">
              <h4 class="para-heading-font-size">
                {{ presControl.heading }}
              </h4>
            </div>
            <div
              [style.height]="presControl.height + 'px'"
            ></div>
          </div>
        </ng-template>
      </div>
      <!-- Obstetrical Examination -->
      <div
        *ngIf="
                      presControl.name === 'Obstetrical Examination' &&
                      presControl.show_on_pdf === true &&
                      presControl.alignment_on_print === 'left'
                    "
      >
        <div
          class="row mb-5"
          *ngIf="
                        obstetricalExamination;
                        else obstetricalExaminationElse
                      "
        >
          <div class="col-12 font-size-print-16">
            <h4 class="para-heading-font-size">
              {{ presControl.heading }}
            </h4>
          </div>
          <div
            class="col-12"
            *ngIf="this.obstetricalExamination?.fh_history"
          >
            <strong>FH:</strong>
            {{ this.obstetricalExamination?.fh_history }}
          </div>
          <div
            class="col-12"
            *ngIf="this.obstetricalExamination?.lie_history"
          >
            <strong>Lie:</strong>
            {{ this.obstetricalExamination?.lie_history }}
          </div>
          <div
            class="col-12"
            *ngIf="
                          this.obstetricalExamination?.liqour_history
                        "
          >
            <strong>Liqour:</strong>
            {{ this.obstetricalExamination?.liqour_history }}
          </div>
          <div
            class="col-12"
            *ngIf="this.obstetricalExamination?.fhr_history"
          >
            <strong>FHR:</strong>
            {{ this.obstetricalExamination?.fhr_history }}
          </div>
          <div
            class="col-12"
            *ngIf="this.obstetricalExamination?.tvs"
          >
            <strong>TVS:</strong>
            {{ this.obstetricalExamination?.tvs }}
          </div>
          <div
            class="col-12"
            *ngIf="this.obstetricalExamination?.usg"
          >
            <strong>USG:</strong>
            {{ this.obstetricalExamination?.usg }}
          </div>
        </div>
        <ng-template #obstetricalExaminationElse>
          <div
            class="row mb-5"
            *ngIf="presControl.module_type === 'static'"
          >
            <div class="col-12">
              <h4 class="para-heading-font-size">
                {{ presControl.heading }}
              </h4>
            </div>
            <div
              [style.height]="presControl.height + 'px'"
            ></div>
          </div>
        </ng-template>
      </div>

      <!-- Physical Examination -->
      <div
        *ngIf="
                            presControl.name === 'Physical Examination' &&
                            presControl.show_on_pdf === true &&
                            presControl.alignment_on_print === 'left'
                          "
      >
        <div *ngIf="pelvicExamination || obstetricalExamination || chestExamination || gpe || abdomen || heent || cardiovascular || skin || neurological || musculoskeletal">
        <div class="col-12 font-size-print-16 p-0">
          <h4 class="para-heading-font-size">
            {{ presControl.heading }}
          </h4>
        </div>
        <!--        Pelvic Examination-->
        <div
          class="row"
          *ngIf="
                              pelvicExamination;
                            "
        >
          <div *ngIf="pelvicExamination?.type === 'Normal'">
            <div class="font-size-print-16 ml-4">
              <strong>
                Pelvic Examination
              </strong>
              (
              <span>{{pelvicExamination?.type_detail ? 'Normal: ' + pelvicExamination.type_detail : ''}}</span>
              )
            </div>
          </div>
          <div *ngIf="pelvicExamination?.type === 'Abnormal'">
            <div class="font-size-print-16 ml-4">
              <strong>
                Pelvic Examination
              </strong>
              (
              <span>
                {{pelvicExamination?.ps_history ? 'P/S: ' + pelvicExamination.ps_history + ', ' : ''}}
                {{pelvicExamination?.bm_history ? 'B/M: ' + pelvicExamination.bm_history + ', ' : ''}}
                {{pelvicExamination?.pap_history ? 'PaP Smear: ' + pelvicExamination.pap_history + ', ' : ''}}
                {{pelvicExamination?.other_detail ? 'Other: ' + pelvicExamination.other_detail : ''}}
              </span>
              )
            </div>
          </div>
        </div>
        <!--        Obstetrical Examination-->
        <div
          class="row"
          *ngIf="
                              obstetricalExamination
                            "
        >
          <div *ngIf="obstetricalExamination?.type === 'Abnormal'">
            <div class="font-size-print-16 ml-4">
              <strong>
                Obstetrical Examination
              </strong>
              (
              <span>
                {{obstetricalExamination?.fh_history ? 'FH: ' + obstetricalExamination.fh_history + ', ' : ''}}
                {{obstetricalExamination?.lie_history ? 'Lie: ' + obstetricalExamination.lie_history + ', ' : ''}}
                {{obstetricalExamination?.liqour_history ? 'Liqour: ' + obstetricalExamination.liqour_history + ', ' : ''}}
                {{obstetricalExamination?.fhr_history ? 'FHR: ' + obstetricalExamination.fhr_history + ', ' : ''}}
                {{obstetricalExamination?.tvs ? 'TVS: ' + obstetricalExamination.tvs + ', ' : ''}}
                {{obstetricalExamination?.usg ? 'USG: ' + obstetricalExamination.usg + ', ' : ''}}
                {{obstetricalExamination?.other_detail ? 'Other: ' + obstetricalExamination.other_detail : ''}}
              </span>
              )
            </div>

          </div>
          <div *ngIf="obstetricalExamination?.type === 'Normal'">
            <div class="font-size-print-16 ml-4">
              <strong>
                Obstetrical Examination
              </strong>
              (
              <span>{{obstetricalExamination?.type_detail ? 'Normal: ' + obstetricalExamination.type_detail : ''}}</span>
              )
            </div>
          </div>
        </div>
        <!--        Chest Examination-->
        <div
          class="row"
          *ngIf="
                              chestExamination
                            "
        >
          <div *ngIf="chestExamination?.type === 'Abnormal'">
            <div class="font-size-print-16 ml-4">
              <strong>
                Chest/Lung
              </strong>
              (
              <span>
                {{chestExamination?.chest_defermity ? 'Chest Defermity' + ', ' : ''}}
                {{chestExamination?.vesicular ? 'Vesicular' +  ', ' : ''}}
                {{chestExamination?.bronchial ? 'Bronchial' + ', ' : ''}}
                {{chestExamination?.crackles ? 'Crackles' + ', ' : ''}}
                {{chestExamination?.diminished_breath_sounds ? 'Diminished breath sounds' + ', ' : ''}}
                {{chestExamination?.rhonchi ? 'Rhonchi ' + ', ' : ''}}
                {{chestExamination?.wheeeze ? 'Wheeeze' + ', ' : ''}}
                {{chestExamination?.usg ? 'USG: ' + obstetricalExamination.usg + ', ' : ''}}
                {{chestExamination?.other_detail ? 'Other: ' + chestExamination.other_detail : ''}}
              </span>
              )
            </div>
          </div>
          <div *ngIf="chestExamination?.type === 'Normal'">
            <div class="font-size-print-16 ml-4">
              <strong>
                Chest/Lung
              </strong>
              (
              <span>{{chestExamination?.type_detail ? 'Normal: ' + chestExamination.type_detail : ''}}</span>
              )
            </div>
          </div>
        </div>
        <!--        GPE-->
        <div
          class="row"
          *ngIf="
                              gpe
                            "
        >
          <div *ngIf="gpe?.type === 'Abnormal'">

            <div class="font-size-print-16 ml-4">
              <strong>
                GPE
              </strong>
              (
              <span>
                {{gpe?.pallor ? 'Pallor' + ', ' : ''}}
                {{gpe?.clubbing ? 'Clubbing' +  ', ' : ''}}
                {{gpe?.cyanosis ? 'Cyanosis' + ', ' : ''}}
                {{gpe?.lymphadenopathy ? 'Lymphadenopathy ' + ', ' : ''}}
                {{gpe?.pedal ? 'Pedal/Ankle Edema' + ', ' : ''}}
                {{gpe?.rash ? 'Rash' + ', ' : ''}}
                {{gpe?.distressed ? 'Distressed' + ', ' : ''}}
                {{gpe?.cachectic ? 'Cachectic' + ', ' : ''}}
                {{gpe?.jaundiced ? 'Jaundiced' + ', ' : ''}}
                {{gpe?.dehydrated ? 'Dehydrated' + ', ' : ''}}
                {{gpe?.peropheral_vessel_palpable ? 'Peropheral vessel palpable' + ', ' : ''}}
                {{gpe?.other_detail ? 'Other: ' + gpe.other_detail : ''}}
              </span>
              )
            </div>
          </div>
          <div *ngIf="gpe?.type === 'Normal'">
            <div class="font-size-print-16 ml-4">
              <strong>
                GPE
              </strong>
              (
              <span>{{gpe?.type_detail ? 'Normal: ' + gpe.type_detail : ''}}</span>
              )
            </div>
          </div>
        </div>
        <!--        Abdomen-->
        <div
          class="row"
          *ngIf="
                              abdomen
                            "
        >
          <div *ngIf="abdomen?.type === 'Abnormal'">
            <div class="font-size-print-16 ml-4">
              <strong>
                Abdomen
              </strong>
              (
              <span>
                {{abdomen?.soft ? 'Soft' + ', ' : ''}}
                {{abdomen?.distended ? 'Distended' +  ', ' : ''}}
                {{abdomen?.ascities ? 'Ascities' + ', ' : ''}}
                {{abdomen?.bowel_sounds ? 'Hyperactive Bowel Sounds ' + ', ' : ''}}
                {{abdomen?.hepatomegaly ? 'Hepatomegaly' + ', ' : ''}}
                {{abdomen?.spleenomegaly ? 'Spleenomegaly' + ', ' : ''}}
                {{abdomen?.inguinal_hernia ? 'Inguinal Hernia' + ', ' : ''}}
                {{abdomen?.tenderness ? 'tenderness' + ', ' : ''}}
                {{abdomen?.rigidity ? 'Rigidity' + ', ' : ''}}
                {{abdomen?.guarding ? 'Guarding' + ', ' : ''}}
                {{abdomen?.palpable_liver ? 'Palpable liver' + ', ' : ''}}
                {{abdomen?.palpable_mass ? 'Palpable mass' + ', ' : ''}}
                {{abdomen?.palpable_spleen ? 'Palpable spleen' + ', ' : ''}}
                {{abdomen?.umbilical ? 'Umbilical' + ', ' : ''}}
                {{abdomen?.grade ? 'Grade:' + abdomen.grade + ', ' : ''}}
                {{abdomen?.area ? 'Area:' + abdomen.area + ', ' : ''}}
                {{abdomen?.genital_examination ? 'Genital Examination:' + abdomen.genital_examination + ', ' : ''}}
                {{abdomen?.other ? 'Other:' + abdomen.other : ''}}
              </span>
              )
            </div>
          </div>
          <div *ngIf="abdomen?.type === 'Normal'">
            <div class="font-size-print-16 ml-4">
              <strong>
                Abdomen
              </strong>
              (
              <span>{{abdomen?.type_detail ? 'Normal: ' + abdomen.type_detail : ''}}</span>
              )
            </div>
          </div>
        </div>
        <!--        HEENT-->
        <div
          class="row"
          *ngIf="
                              heent
                            "
        >
          <div *ngIf="heent?.type === 'Abnormal'">
            <div class="font-size-print-16 ml-4">
              <strong>
                Heent
              </strong>
              (
              <span>
                {{heent?.head ? 'Head: ' + heent.head + ', ' : ''}}
                {{heent?.eye ? 'Eye: ' + heent.eye + ', ' : ''}}
                {{heent?.ent ? 'Ent: ' + heent.ent + ', ' : ''}}
                {{heent?.neck ? 'Neck: ' + heent.neck + ', ' : ''}}
                {{heent?.neck_viens ? 'N-Viens: ' + heent.neck_viens + ', ' : ''}}
                {{abdomen?.icterus ? 'Icterus' + ', ' : ''}}
                {{abdomen?.pallor ? 'Pallor' + ', ' : ''}}
                {{abdomen?.conjunctival_injection ? 'Conjunctival injection' + ', ' : ''}}
                {{heent?.abnormal_findings ? 'Abnormal findings: ' + heent.abnormal_findings + ', ' : ''}}
                {{heent?.other_detail ? 'Other: ' + heent.other_detail : ''}}
              </span>
              )
            </div>
          </div>
          <div *ngIf="heent?.type === 'Normal'">
            <div class="font-size-print-16 ml-4">
              <strong>
                Heent
              </strong>
              (
              <span>{{heent?.type_detail ? 'Normal: ' + heent.type_detail : ''}}</span>
              )
            </div>
          </div>
        </div>
        <!--        Neurological-->
        <div
          class="row"
          *ngIf="
                              neurological
                            "
        >
          <div *ngIf="neurological?.type === 'Abnormal'">
            <div class="font-size-print-16 ml-4">
              <strong>
                Neurological
              </strong>
              (
              <span>
               {{neurological?.cranial_nerve_deficits ? 'Cranial nerve deficits' + ', ' : ''}}
               {{neurological?.muscle_weakness ? 'Muscle weakness' + ', ' : ''}}
               {{neurological?.hyperreflexia ? 'Hyperreflexia' + ', ' : ''}}
               {{neurological?.hyporeflexia ? 'hyporeflexia' + ', ' : ''}}
               {{neurological?.sensory_deficits ? 'Sensory deficits' + ', ' : ''}}
               {{neurological?.abnormal_gait ? 'Abnormal gait' + ', ' : ''}}
               {{neurological?.altered_mental_status ? 'Altered mental status' + ', ' : ''}}
                {{neurological?.other ? 'Other: ' + neurological.other_detail : ''}}
              </span>
              )
            </div>
          </div>
          <div *ngIf="neurological?.type === 'Normal'">
            <div class="font-size-print-16 ml-4">
              <strong>
                Neurological
              </strong>
              (
              <span>{{neurological?.type_detail ? 'Normal: ' + neurological.type_detail : ''}}</span>
              )
            </div>
          </div>
        </div>
        <!--        Musculoskeletal-->
        <div
          class="row"
          *ngIf="
                              musculoskeletal
                            "
        >
          <div *ngIf="musculoskeletal?.type === 'Abnormal'">
            <div class="font-size-print-16 ml-4">
              <strong>
                Musculoskeletal
              </strong>
              (
              <span>
               {{musculoskeletal?.limited_range_of_motion ? 'Limited range of motion' + ', ' : ''}}
               {{musculoskeletal?.tenderness ? 'Tenderness' + ', ' : ''}}
               {{musculoskeletal?.joint_swelling ? 'Joint Swelling' + ', ' : ''}}
               {{musculoskeletal?.deformities ? 'Deformities' + ', ' : ''}}
                {{musculoskeletal?.other_detail ? 'Other: ' + musculoskeletal.other_detail : ''}}
              </span>
              )
            </div>
          </div>
          <div *ngIf="musculoskeletal?.type === 'Normal'">
            <div class="font-size-print-16 ml-4">
              <strong>
                Musculoskeletal
              </strong>
              (
              <span>{{musculoskeletal?.type_detail ? 'Normal: ' + musculoskeletal.type_detail : ''}}</span>
              )
            </div>
          </div>
        </div>
        <!--        SKIN-->
        <div
          class="row"
          *ngIf="
                              skin
                            "
        >
          <div *ngIf="skin?.type === 'Abnormal'">
            <div class="font-size-print-16 ml-4">
              <strong>
                Skin
              </strong>
              (
              <span>
               {{skin?.rash ? 'Rash' + ', ' : ''}}
               {{skin?.lesions ? 'Lesions' + ', ' : ''}}
               {{skin?.bruising ? 'Bruising' + ', ' : ''}}
                {{skin?.other_detail ? 'Other: ' + skin.other_detail : ''}}
              </span>
              )
            </div>
          </div>
          <div *ngIf="skin?.type === 'Normal'">
            <div class="font-size-print-16 ml-4">
              <strong>
                Skin
              </strong>
              (
              <span>{{skin?.type_detail ? 'Normal: ' + skin.type_detail : ''}}</span>
              )
            </div>
          </div>
        </div>
        <!--        Cardiovascular-->
        <div
          class="row mb-4"
          *ngIf="
                              cardiovascular
                            "
        >
          <div *ngIf="cardiovascular?.type === 'Abnormal'">
            <div class="font-size-print-16 ml-4">
              <strong>
                Cardiovascular
              </strong>
              (
              <span>
                {{cardiovascular?.apex_beat ? 'Apex Beat: ' + cardiovascular.apex_beat + ', ' : ''}}
                {{cardiovascular?.pulse ? 'Pulses Palpable: ' + cardiovascular.pulse + ', ' : ''}}
                {{cardiovascular?.gallop ? 'Gallop ' + ', ' : ''}}
                {{cardiovascular?.edema? 'Edema ' + ', ' : ''}}
                {{cardiovascular?.heat_sound ? 'Heat Sound: ' + cardiovascular.heat_sound + ', ' : ''}}
                {{cardiovascular?.mur_mur ? 'MurMur: ' + cardiovascular.mur_mur + ', ' : ''}}
                {{cardiovascular?.other ? 'Other: ' + cardiovascular.other : ''}}
              </span>
              )
            </div>
          </div>
          <div *ngIf="cardiovascular?.type === 'Normal'">
            <div class="font-size-print-16 ml-4">
              <strong>
                Cardiovascular
              </strong>
              (
              <span>{{cardiovascular?.type_detail ? 'Normal: ' + cardiovascular.type_detail : ''}}</span>
              )
            </div>
          </div>
        </div>
        </div>
        <!--        Static Case-->
        <ng-container *ngIf="!pelvicExamination && !obstetricalExamination && !chestExamination && !gpe && !abdomen && !heent && !cardiovascular &&  !skin && !neurological && !musculoskeletal">
          <div
            class="row mb-5"
            *ngIf="presControl.module_type === 'static'"
          >
            <div class="col-12">
              <h4 class="para-heading-font-size">
                {{ presControl.heading }}
              </h4>
            </div>
            <div
              [style.height]="presControl.height + 'px'"
            ></div>
          </div>
        </ng-container>
      </div>
<!--      &lt;!&ndash; Obstetrical Examination &ndash;&gt;-->
<!--      <div-->
<!--        *ngIf="-->
<!--                            presControl.name === 'Obstetrical Examination' &&-->
<!--                            presControl.show_on_pdf === true &&-->
<!--                            presControl.alignment === 'left'-->
<!--                          "-->
<!--      >-->
<!--      </div>-->
    </ng-container>
  </div>

  <div
    class="col-8 pt-4"
    style="border-top: 2px solid #000000"
    [ngStyle]="{ 'min-height': bodyHeight + 'px' }"
  >
    <div *ngFor="let presControl of prescriptionControlsRight">
      <div
        class="row mb-5"
        *ngIf="
                            presControl.name === 'Triage' &&
                            presControl.show_on_pdf === true &&
                            presControl.alignment_on_print === 'right'
                          "
      >
        <div class="col-12">
          <table class="table table-bordered text-center">
            <tbody>
            <tr>
              <td colspan="3">
                <strong>{{ presControl.heading }}</strong>
              </td>
            </tr>
            <tr>
              <td style="width: 33%">
                <strong>Immediate</strong>
              </td>
              <td style="width: 33%">
                <strong>Urgent</strong>
              </td>
              <td style="width: 33%">
                <strong>Non Urgent</strong>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
      <!-- Vitals -->
      <div
        *ngIf="
                            presControl.name === 'Vitals' &&
                            presControl.show_on_pdf === true &&
                            presControl.alignment_on_print === 'right'
                          "
      >
        <div
          class="row mb-5"
          *ngIf="
                              prescriptionData?.vitals?.length > 0;
                              else vitalsElse
                            "
        >
          <div class="col-12">
            <h4 class="para-heading-font-size">
              {{ presControl.heading }}
            </h4>
          </div>

          <ng-container
            *ngIf="prescriptionData?.vitals[0]?.pulse"
          >
            <div class="col-5"><strong>Pulse:</strong></div>
            <div class="col-7">
              {{ prescriptionData?.vitals[0]?.pulse }} /min
            </div>
          </ng-container>

          <ng-container
            *ngIf="prescriptionData?.vitals[0]?.weight"
          >
            <div class="col-5"><strong>Weight:</strong></div>
            <div class="col-7">
              {{ prescriptionData?.vitals[0]?.weight }} kg
            </div>
          </ng-container>

          <ng-container
            *ngIf="
                                prescriptionData?.vitals[0]?.bp_systolic ||
                                prescriptionData?.vitals[0]?.bp_diastolic
                              "
          >
            <div class="col-5"><strong>BP:</strong></div>
            <div class="col-7">
              {{ prescriptionData?.vitals[0]?.bp_systolic }}
              <span
                *ngIf="
                                    prescriptionData?.vitals[0]?.bp_diastolic
                                  "
              >/</span
              >
              {{ prescriptionData?.vitals[0]?.bp_diastolic }}
              mm/hg
            </div>
          </ng-container>

          <ng-container
            *ngIf="prescriptionData?.vitals[0]?.temperature"
          >
            <div class="col-5">
              <strong>Temperature:</strong>
            </div>
            <div class="col-7">
              {{ prescriptionData?.vitals[0]?.temperature }} F
            </div>
          </ng-container>

          <ng-container
            *ngIf="prescriptionData?.vitals[0]?.o2_saturation"
          >
            <div class="col-5">
              <strong>Saturation:</strong>
            </div>
            <div class="col-7">
              {{ prescriptionData?.vitals[0]?.o2_saturation }}
              %
            </div>
          </ng-container>

          <ng-container
            *ngIf="prescriptionData?.vitals[0]?.resp_rate"
          >
            <div class="col-5">
              <strong>Respiration:</strong>
            </div>
            <div class="col-7">
              {{ prescriptionData?.vitals[0]?.resp_rate }}
              /min
            </div>
          </ng-container>
          <ng-container
            *ngIf="prescriptionData?.vitals[0]?.bsr"
          >
            <div class="col-5"><strong>BSR:</strong></div>
            <div class="col-7">
              {{ prescriptionData?.vitals[0]?.bsr }} mg/dl
            </div>
          </ng-container>
        </div>
        <ng-template #vitalsElse>
          <div
            class="row mb-5"
            *ngIf="presControl.module_type === 'static'"
          >
            <div class="col-12">
              <h4 class="para-heading-font-size">
                {{ presControl.heading }}
              </h4>
            </div>
            <div
              [style.height]="presControl.height + 'px'"
            ></div>
          </div>
        </ng-template>
      </div>

      <!-- Investigations -->
      <div
        *ngIf="
                            presControl.name === 'Investigations' &&
                            presControl.show_on_pdf === true &&
                            presControl.alignment_on_print === 'right'
                          "
      >
        <div
          class="row mb-5"
          *ngIf="
                              showInvestigationsData?.length > 0;
                              else investigationsElse
                            "
        >
          <div class="col-12">
            <h4 class="para-heading-font-size">
              {{ presControl.heading }}
            </h4>
          </div>
          <ol style="padding-left: 25px">
            <ng-container
              *ngFor="
                                  let data of prescriptionData?.investigations;
                                  let i = index
                                "
            >
              <li
                *ngIf="
                                    data?.service_type === 'Radiology' ||
                                    data?.service_type === 'LabInvestigation'
                                  "
              >
                <strong>{{ data?.service_name }}</strong>
                <span
                  *ngIf="data?.comments"
                  class="font-italic"
                ><br />({{ data?.comments }})</span
                >
              </li>
            </ng-container>
          </ol>
        </div>
        <ng-template #investigationsElse>
          <div
            class="row mb-5"
            *ngIf="presControl.module_type === 'static'"
          >
            <div class="col-12">
              <h4 class="para-heading-font-size">
                {{ presControl.heading }}
              </h4>
            </div>
            <div
              [style.height]="presControl.height + 'px'"
            ></div>
          </div>
        </ng-template>
      </div>
      <div
        *ngIf="
                            presControl.name === 'Other Services' &&
                            presControl.show_on_pdf === true &&
                            presControl.alignment_on_print === 'right'
                          "
      >
        <div
          class="row mb-5"
          *ngIf="
                              showProceduresData?.length > 0;
                              else otherServicesElse
                            "
        >
          <div class="col-12">
            <h4 class="para-heading-font-size">
              {{ presControl.heading }}
            </h4>
          </div>
          <ol style="padding-left: 25px">
            <ng-container
              *ngFor="
                                  let data of prescriptionData?.investigations;
                                  let i = index
                                "
            >
              <li
                *ngIf="
                                    data?.service_type !== 'Radiology' &&
                                    data?.service_type !== 'LabInvestigation' &&
                                    data?.service_type !== 'DoctorFee'
                                  "
              >
                <strong>{{ data?.service_name }}</strong>
                <span
                  *ngIf="data?.comments"
                  class="font-italic"
                ><br />({{ data?.comments }})</span
                >
              </li>
            </ng-container>
          </ol>
        </div>
        <ng-template #otherServicesElse>
          <div
            class="row mb-5"
            *ngIf="presControl.module_type === 'static'"
          >
            <div class="col-12">
              <h4 class="para-heading-font-size">
                {{ presControl.heading }}
              </h4>
            </div>
            <div
              [style.height]="presControl.height + 'px'"
            ></div>
          </div>
        </ng-template>
      </div>

      <div
        *ngIf="
                            presControl.name === 'Allergies' &&
                            presControl.show_on_pdf === true &&
                            presControl.alignment_on_print === 'right'
                          "
      >
        <div
          class="row mb-5"
          *ngIf="
                              prescriptionData?.allergies?.length > 0;
                              else allergiesElse
                            "
        >
          <div class="col-12">
            <h4 class="para-heading-font-size">
              {{ presControl.heading }}
            </h4>
          </div>
          <div class="col-12 font-size-print-16">
            <ng-container
              *ngFor="
                                  let allergy of prescriptionData?.allergies
                                "
            >
                                <span class="mr-1">{{
                                    allergy | stringReplace: "," : ", "
                                  }}</span
                                >,
            </ng-container>
          </div>
        </div>
        <ng-template #allergiesElse>
          <div
            class="row mb-5"
            *ngIf="presControl.module_type === 'static'"
          >
            <div class="col-12">
              <h4 class="para-heading-font-size">
                {{ presControl.heading }}
              </h4>
            </div>
            <div
              [style.height]="presControl.height + 'px'"
            ></div>
          </div>
        </ng-template>
      </div>

      <div
        *ngIf="
                            presControl.name === 'Social History' &&
                            presControl.show_on_pdf === true &&
                            presControl.alignment_on_print === 'right'
                          "
      >
        <div
          class="general-history-section font-size-print-16 row mb-5"
          *ngIf="
                              this.patientSocialHistory?.smoker ||
                                this.patientSocialHistory?.alcohol ||
                                this.patientSocialHistory?.other ||
                                this.patientSocialHistory?.marital_status ||
                                this.patientSocialHistory?.children ||
                                this.patientSocialHistory?.patient_occupation ||
                                this.patientSocialHistory?.drug ||
                                this.patientSocialHistory?.spouse_occupation;
                              else socialHistoryElse
                            "
        >
          <div class="col-12">
            <h4 class="para-heading-font-size">
              {{ presControl.heading }}
            </h4>
          </div>

          <div
            class="col-12"
            *ngIf="this.patientSocialHistory?.smoker"
          >
            <strong>Smoker:</strong>
            {{
              this.patientSocialHistory?.smoker === true
                ? "Yes"
                : "No"
            }}
          </div>
          <div
            class="col-12"
            *ngIf="this.patientSocialHistory?.drug"
          >
            <strong>Drug:</strong>
            {{
              this.patientSocialHistory?.drug === true
                ? "Yes"
                : "No"
            }}
          </div>

          <div
            class="col-12"
            *ngIf="this.patientSocialHistory?.alcohol"
          >
            <strong>Alcohol:</strong>
            {{
              this.patientSocialHistory?.alcohol === true
                ? "Yes"
                : "No"
            }}
          </div>

          <div
            class="col-12"
            *ngIf="this.patientSocialHistory?.other"
          >
            <strong>Other:</strong>
            {{
              this.patientSocialHistory?.other === true
                ? "Yes"
                : "No"
            }}
          </div>
          <div
            class="col-12"
            *ngIf="this.patientSocialHistory?.other_details"
          >
            <strong>Other Details:</strong>
            {{ this.patientSocialHistory?.other_details }}
          </div>
          <div
            class="col-12"
            *ngIf="this.patientSocialHistory?.marital_status_name"
          >
            <strong>Marital Status:</strong>
            {{ this.patientSocialHistory?.marital_status_name }}
          </div>
          <div
            class="col-12"
            *ngIf="this.patientSocialHistory?.children"
          >
            <strong>Children:</strong>
            {{ this.patientSocialHistory?.children }}
          </div>
          <div
            class="col-12"
            *ngIf="this.patientSocialHistory?.patient_occupation"
          >
            <strong>Pat.Occupation:</strong>
            {{ this.patientSocialHistory?.patient_occupation }}
          </div>
          <div
            class="col-12"
            *ngIf="this.patientSocialHistory?.spouse_occupation"
          >
            <strong>Spouse's Occupation:</strong>
            {{ this.patientSocialHistory?.spouse_occupation }}
          </div>
        </div>
        <ng-template #socialHistoryElse>
          <div
            class="row mb-5"
            *ngIf="presControl.module_type === 'static'"
          >
            <div class="col-12">
              <h4 class="para-heading-font-size">
                {{ presControl.heading }}
              </h4>
            </div>
            <div
              [style.height]="presControl.height + 'px'"
            ></div>
          </div>
        </ng-template>
      </div>
      <!--    Immunizations-->
      <div
        *ngIf="
                            presControl.name === 'Immunizations' &&
                            presControl.show_on_pdf === true &&
                            presControl.alignment_on_print === 'right'
                          "
      >
        <div class="general-history-section font-size-print-16 row mb-5" *ngIf="prescriptionData?.immunizations; else elseImmunization">
          <div class="col-12">
            <h4 class="para-heading-font-size">
              {{ presControl.heading }}
            </h4>
          </div>

          <div
            class="col-12"
            *ngIf="prescriptionData?.immunizations?.dtp"
          >
            <strong>DTP:</strong>
            <span
            >({{
                prescriptionData?.immunizations?.dtp
              }})</span
            >
          </div>
          <div
            class="col-12"
            *ngIf="prescriptionData?.immunizations?.dt_hib"
          >
            <strong>DT Hib:</strong>
            <span
            >({{
                prescriptionData?.immunizations?.dt_hib
              }})</span
            >
          </div>
          <div
            class="col-12"
            *ngIf="prescriptionData?.immunizations?.td_adult"
          >
            <strong>Td (Adult):</strong>
            <span
            >({{
                prescriptionData?.immunizations?.td_adult
              }})</span
            >
          </div>
          <div
            class="col-12"
            *ngIf="prescriptionData?.immunizations?.ipv_opv"
          >
            <strong>IPV/OPV:</strong>
            <span
            >({{
                prescriptionData?.immunizations?.ipv_opv
              }})</span
            >
          </div>
          <div
            class="col-12"
            *ngIf="prescriptionData?.immunizations?.mmr"
          >
            <strong>MMR:</strong>
            <span
            >({{
                prescriptionData?.immunizations?.mmr
              }})</span
            >
          </div>
          <div
            class="col-12"
            *ngIf="prescriptionData?.immunizations?.hep_b"
          >
            <strong>Hep B:</strong>
            <span
            >({{
                prescriptionData?.immunizations?.hep_b
              }})</span
            >
          </div>
          <div
            class="col-12"
            *ngIf="prescriptionData?.immunizations?.influenza"
          >
            <strong>Influenza:</strong>
            <span
            >({{
                prescriptionData?.immunizations?.influenza
              }})</span
            >
          </div>
          <div
            class="col-12"
            *ngIf="prescriptionData?.immunizations?.pneumococcal"
          >
            <strong>Pneumococcal:</strong>
            <span
            >({{
                prescriptionData?.immunizations?.pneumococcal
              }})</span
            >
          </div>
          <div
            class="col-12"
            *ngIf="prescriptionData?.immunizations?.mantoux"
          >
            <strong>Mantoux:</strong>
            <span
            >({{
                prescriptionData?.immunizations?.mantoux
              }})</span
            >
          </div>
        </div>
        <ng-template #elseImmunization>
          <div
            class="row mb-5"
            *ngIf="presControl.module_type === 'static'"
          >
            <div class="col-12">
              <h4 class="para-heading-font-size">
                {{ presControl.heading }}
              </h4>
            </div>
            <div
              [style.height]="presControl.height + 'px'"
            ></div>
          </div>
        </ng-template>
      </div>
      <!--  REVIEW HISTORY-->
      <div
        *ngIf="
                            presControl.name === 'Review History' &&
                            presControl.show_on_pdf === true &&
                            presControl.alignment_on_print === 'right'
                          "
      >
        <app-opd-review-history-print [reviewSystemHistory]="reviewSystemHistory" [presControl]="presControl"></app-opd-review-history-print>
      </div>
      <div
        *ngIf="
                            presControl.name === 'Family History' &&
                            presControl.show_on_pdf === true &&
                            presControl.alignment_on_print === 'right'
                          "
      >
        <div
          class="general-history-section font-size-print-16 row mb-5"
          *ngIf="hasFamilyHistoryConditions(); else showFamilyHistoryEmpty"
        >
          <div class="col-12">
            <h4 class="para-heading-font-size">
              {{ presControl.heading }}
            </h4>
          </div>
          <ng-container *ngFor="let family of prescriptionData?.family_history">
            <div class="col-12" *ngIf="family?.dm || family?.hypertention || family?.ihd || family?.arthritis || family?.cancer || family?.stroke || family?.depression || family?.tb">
              <strong>{{family?.name}}: </strong>
              <span *ngIf="family?.dm">Diabetes, </span>
              <span *ngIf="family?.hypertention">Hypertension, </span>
              <span *ngIf="family?.ihd">IHD, </span>
              <span *ngIf="family?.arthritis">Asthma, </span>
              <span *ngIf="family?.cancer">Cancer, </span>
              <span *ngIf="family?.stroke">Stroke, </span>
              <span *ngIf="family?.depression">Depression, </span>
              <span *ngIf="family?.tb">TB</span>
            </div>
          </ng-container>
        </div>
        <ng-template #showFamilyHistoryEmpty>
          <div
            class="row mb-5"
            *ngIf="presControl.module_type === 'static'"
          >
            <div class="col-12">
              <h4 class="para-heading-font-size">
                {{ presControl.heading }}
              </h4>
            </div>
            <div
              [style.height]="presControl.height + 'px'"
            ></div>
          </div>
        </ng-template>
      </div>
      <div
        *ngIf="
                            presControl.name === 'Mindfulness' &&
                            presControl.show_on_pdf === true &&
                            presControl.alignment_on_print === 'right'
                          "
      >
        <div
          class="general-history-section font-size-print-16 row mb-5"
          *ngIf="
                              this.prescriptionData?.mindfulness?.namaz ||
                                this.prescriptionData?.mindfulness?.yoga ||
                                this.prescriptionData?.mindfulness?.meditation ||
                                this.prescriptionData?.mindfulness?.tai_chi ||
                                this.prescriptionData?.mindfulness?.other ||
                                this.prescriptionData?.mindfulness?.other_detail;
                              else mindfulnessElse
                            "
        >
          <div class="col-12">
            <h4 class="para-heading-font-size">
              {{ presControl.heading }}
            </h4>
          </div>

          <div
            class="col-12"
            *ngIf="this.prescriptionData?.mindfulness?.namaz"
          >
            <strong>Namaz:</strong>
            {{
              this.prescriptionData?.mindfulness?.namaz === true
                ? "Yes"
                : "No"
            }}
          </div>
          <div
            class="col-12"
            *ngIf="this.prescriptionData?.mindfulness?.yoga"
          >
            <strong>Yoga:</strong>
            {{
              this.prescriptionData?.mindfulness?.yoga === true
                ? "Yes"
                : "No"
            }}
          </div>

          <div
            class="col-12"
            *ngIf="this.prescriptionData?.mindfulness?.tai_chi"
          >
            <strong>Tai chi:</strong>
            {{
              this.prescriptionData?.mindfulness?.tai_chi === true
                ? "Yes"
                : "No"
            }}
          </div>
          <div
            class="col-12"
            *ngIf="this.prescriptionData?.mindfulness?.meditation"
          >
            <strong>Meditation:</strong>
            {{
              this.prescriptionData?.mindfulness?.meditation === true
                ? "Yes"
                : "No"
            }}
          </div>

          <div
            class="col-12"
            *ngIf="this.prescriptionData?.mindfulness?.other"
          >
            <strong>Other:</strong>
            {{
              this.prescriptionData?.mindfulness?.other === true
                ? "Yes"
                : "No"
            }}
          </div>
          <div
            class="col-12"
            *ngIf="this.prescriptionData?.mindfulness?.other_detail"
          >
            <strong>Other Details:</strong>
            {{ this.prescriptionData?.mindfulness?.other_detail }}
          </div>
        </div>
        <ng-template #mindfulnessElse>
          <div
            class="row mb-5"
            *ngIf="presControl.module_type === 'static'"
          >
            <div class="col-12">
              <h4 class="para-heading-font-size">
                {{ presControl.heading }}
              </h4>
            </div>
            <div
              [style.height]="presControl.height + 'px'"
            ></div>
          </div>
        </ng-template>
      </div>
      <div
        *ngIf="
                            presControl.name === 'Past History' &&
                            presControl.show_on_pdf === true &&
                            presControl.alignment_on_print === 'right'
                          "
      >
        <div
          class="general-history-section font-size-print-16 row mb-5"
          *ngIf="
                              this.patientPastHistory?.diabetes ||
                                this.patientPastHistory?.hypertention ||
                                this.patientPastHistory?.IHD ||
                                this.patientPastHistory?.blood_thinner ||
                                this.patientPastHistory?.operation ||
                                this.patientPastHistory?.major_illness ||
                                this.patientPastHistory?.other ||
                                this.patientPastHistory?.cardiac_disorder ||
                                this.patientPastHistory?.asthma ||
                                this.patientPastHistory?.bleeding_disorder ||
                                this.patientPastHistory?.pregnancy_trimester ||
                                this.patientPastHistory?.epilepsy;
                              else pastlHistoryElse
                            "
        >
          <div class="col-12">
            <h4 class="para-heading-font-size">
              {{ presControl.heading }}
            </h4>
          </div>
          <div
            class="col-12"
            *ngIf="this.patientPastHistory?.diabetes"
          >
            <strong>Diabetes:</strong>
            {{
              this.patientPastHistory?.diabetes === true
                ? "Yes"
                : "No"
            }}
            <span
              *ngIf="
                                  this.patientPastHistory?.diabetes_duration ||
                                  this.patientPastHistory
                                    ?.diabetes_duration_unit
                                "
            >(Duration:
              {{
                this.patientPastHistory?.diabetes_duration +
                " " +
                this.patientPastHistory
                  ?.diabetes_duration_unit
              }})
                              </span>
            <span
              *ngIf="
                                  this.patientPastHistory?.diabetes_details
                                "
            >({{
                this.patientPastHistory?.diabetes_details
              }})</span
            >
          </div>

          <div
            class="col-12"
            *ngIf="this.patientPastHistory?.hypertention"
          >
            <strong>Hypertension:</strong>
            {{
              this.patientPastHistory?.hypertention === true
                ? "Yes"
                : "No"
            }}
            <span
              *ngIf="
                                  this.patientPastHistory
                                    ?.hypertention_duration ||
                                  this.patientPastHistory
                                    ?.hypertention_duration_unit
                                "
            >(Duration:
              {{
                this.patientPastHistory
                  ?.hypertention_duration +
                " " +
                this.patientPastHistory
                  ?.hypertention_duration_unit
              }})
                              </span>
            <span
              *ngIf="
                                  this.patientPastHistory?.hypertention_details
                                "
            >({{
                this.patientPastHistory?.hypertention_details
              }})</span
            >
          </div>

          <div
            class="col-12"
            *ngIf="this.patientPastHistory?.IHD"
          >
            <strong>IHD:</strong>
            {{
              this.patientPastHistory?.IHD === true
                ? "Yes"
                : "No"
            }}
            <span
              *ngIf="
                                  this.patientPastHistory?.IHD_duration ||
                                  this.patientPastHistory?.IHD_duration_unit
                                "
            >(Duration:
              {{
                this.patientPastHistory?.IHD_duration +
                " " +
                this.patientPastHistory?.IHD_duration_unit
              }})
                              </span>
            <span *ngIf="this.patientPastHistory?.IHD_details"
            >({{
                this.patientPastHistory?.IHD_details
              }})</span
            >
          </div>

          <div
            class="col-12"
            *ngIf="this.patientPastHistory?.blood_thinner"
          >
            <strong>Blood Thinner:</strong>
            {{
              this.patientPastHistory?.blood_thinner === true
                ? "Yes"
                : "No"
            }}
            <span
              *ngIf="
                                  this.patientPastHistory
                                    ?.blood_thinner_duration ||
                                  this.patientPastHistory
                                    ?.blood_thinner_duration_unit
                                "
            >(Duration:
              {{
                this.patientPastHistory
                  ?.blood_thinner_duration +
                " " +
                this.patientPastHistory
                  ?.blood_thinner_duration_unit
              }})
                              </span>
            <span
              *ngIf="
                                  this.patientPastHistory?.blood_thinner_details
                                "
            >({{
                this.patientPastHistory
                  ?.blood_thinner_details
              }})</span
            >
          </div>

          <div
            class="col-12"
            *ngIf="this.patientPastHistory?.operation"
          >
            <strong>Operation:</strong>
            {{
              this.patientPastHistory?.operation === true
                ? "Yes"
                : "No"
            }}
            <span
              *ngIf="
                                  this.patientPastHistory?.operation_details
                                "
            >({{
                this.patientPastHistory?.operation_details
              }})</span
            >
          </div>

          <div
            class="col-12"
            *ngIf="this.patientPastHistory?.major_illness"
          >
            <strong>Major Illness:</strong>
            {{
              this.patientPastHistory?.major_illness === true
                ? "Yes"
                : "No"
            }}
            <span
              *ngIf="
                                  this.patientPastHistory?.major_illness_details
                                "
            >({{
                this.patientPastHistory
                  ?.major_illness_details
              }})</span
            >
          </div>
          <div
            class="col-12"
            *ngIf="this.patientPastHistory?.other"
          >
            <strong>Other:</strong>
            {{
              this.patientPastHistory?.other === true
                ? "Yes"
                : "No"
            }}
            <span
              *ngIf="this.patientPastHistory?.other_details"
            >({{
                this.patientPastHistory?.other_details
              }})</span
            >
          </div>
          <!-- Cardiac Disorder-->
          <div
            class="col-12"
            *ngIf="this.patientPastHistory?.cardiac_disorder"
          >
            <strong>Cardiac Disorder:</strong>
            {{
              this.patientPastHistory?.cardiac_disorder ===
              true
                ? "Yes"
                : "No"
            }}
            <span
              *ngIf="
                                  this.patientPastHistory
                                    ?.cardiac_disorder_details
                                "
            >({{
                this.patientPastHistory
                  ?.cardiac_disorder_details
              }})</span
            >
          </div>
          <!-- Asthma-->
          <div
            class="col-12"
            *ngIf="this.patientPastHistory?.asthma"
          >
            <strong>Asthma:</strong>
            {{
              this.patientPastHistory?.asthma === true
                ? "Yes"
                : "No"
            }}
            <span
              *ngIf="this.patientPastHistory?.asthma_details"
            >({{
                this.patientPastHistory?.asthma_details
              }})</span
            >
          </div>
          <!-- Bleeding Disorder-->
          <div
            class="col-12"
            *ngIf="this.patientPastHistory?.bleeding_disorder"
          >
            <strong>Bleeding Disorder:</strong>
            {{
              this.patientPastHistory?.bleeding_disorder ===
              true
                ? "Yes"
                : "No"
            }}
            <span
              *ngIf="
                                  this.patientPastHistory
                                    ?.bleeding_disorder_details
                                "
            >({{
                this.patientPastHistory
                  ?.bleeding_disorder_details
              }})</span
            >
          </div>
          <!-- Pregnancy (Trimester)-->
          <div
            class="col-12"
            *ngIf="
                                this.patientPastHistory?.pregnancy_trimester
                              "
          >
            <strong>Pregnancy (Trimester):</strong>
            {{
              this.patientPastHistory?.pregnancy_trimester ===
              true
                ? "Yes"
                : "No"
            }}
            <span
              *ngIf="
                                  this.patientPastHistory
                                    ?.pregnancy_trimester_details
                                "
            >({{
                this.patientPastHistory
                  ?.pregnancy_trimester_details
              }})</span
            >
          </div>
          <!-- Epilepsy-->
          <div
            class="col-12"
            *ngIf="this.patientPastHistory?.epilepsy"
          >
            <strong>Epilepsy:</strong>
            {{
              this.patientPastHistory?.epilepsy === true
                ? "Yes"
                : "No"
            }}
            <span
              *ngIf="
                                  this.patientPastHistory?.epilepsy_details
                                "
            >({{
                this.patientPastHistory?.epilepsy_details
              }})</span
            >
          </div>
        </div>
        <ng-template #pastlHistoryElse>
          <div
            class="row mb-5"
            *ngIf="presControl.module_type === 'static'"
          >
            <div class="col-12">
              <h4 class="para-heading-font-size">
                {{ presControl.heading }}
              </h4>
            </div>
            <div
              [style.height]="presControl.height + 'px'"
            ></div>
          </div>
        </ng-template>
      </div>
      <!-- Physical Examination-->
<!--      <div-->
<!--        *ngIf="-->
<!--                            presControl.name === 'Physical Examination' &&-->
<!--                            presControl.show_on_pdf === true &&-->
<!--                            presControl.alignment_on_print === 'right'-->
<!--                          "-->
<!--      >-->
<!--        <div-->
<!--          class="general-history-section font-size-print-16 row mb-5"-->
<!--          *ngIf="-->
<!--                              this.physicalExamination?.gen_is_normal ||-->
<!--                                this.physicalExamination?.heent_is_normal ||-->
<!--                                this.physicalExamination?.neck_is_normal ||-->
<!--                                this.physicalExamination?.chest_is_normal ||-->
<!--                                this.physicalExamination?.heart_is_normal ||-->
<!--                                this.physicalExamination?.gi_is_normal ||-->
<!--                                this.physicalExamination?.back_is_normal ||-->
<!--                                this.physicalExamination?.exterm_is_normal ||-->
<!--                                this.physicalExamination?.neuro_is_normal ||-->
<!--                                this.physicalExamination?.skin_is_normal;-->
<!--                              else physicalExaminationElse-->
<!--                            "-->
<!--        >-->
<!--          <div class="col-12">-->
<!--            <h4 class="para-heading-font-size">-->
<!--              {{ presControl.heading }}-->
<!--            </h4>-->
<!--          </div>-->
<!--          <div-->
<!--            class="col-12"-->
<!--            *ngIf="this.physicalExamination?.gen_is_normal"-->
<!--          >-->
<!--            <strong>GEN:</strong>-->
<!--            {{-->
<!--              physicalExamination?.gen_is_normal === true-->
<!--                ? "Abnormal"-->
<!--                : ""-->
<!--            }}-->
<!--            <span *ngIf="physicalExamination?.gen_comments"-->
<!--            >({{ physicalExamination?.gen_comments }})-->
<!--                              </span>-->
<!--          </div>-->

<!--          <div-->
<!--            class="col-12"-->
<!--            *ngIf="this.physicalExamination?.heent_is_normal"-->
<!--          >-->
<!--            <strong>HEENT:</strong>-->
<!--            {{-->
<!--              this.physicalExamination?.heent_is_normal ===-->
<!--              true-->
<!--                ? "Abnormal"-->
<!--                : ""-->
<!--            }}-->
<!--            <span *ngIf="physicalExamination?.heent_comments"-->
<!--            >({{ physicalExamination?.heent_comments }})-->
<!--                              </span>-->
<!--          </div>-->

<!--          <div-->
<!--            class="col-12"-->
<!--            *ngIf="this.physicalExamination?.neck_is_normal"-->
<!--          >-->
<!--            <strong>NECK:</strong>-->
<!--            {{-->
<!--              this.physicalExamination?.neck_is_normal ===-->
<!--              true-->
<!--                ? "Abnormal"-->
<!--                : ""-->
<!--            }}-->
<!--            <span *ngIf="physicalExamination?.neck_comments"-->
<!--            >({{ physicalExamination?.neck_comments }})-->
<!--                              </span>-->
<!--          </div>-->

<!--          <div-->
<!--            class="col-12"-->
<!--            *ngIf="physicalExamination?.chest_is_normal"-->
<!--          >-->
<!--            <strong>CHEST:</strong>-->
<!--            {{-->
<!--              this.physicalExamination?.chest_is_normal ===-->
<!--              true-->
<!--                ? "Abnormal"-->
<!--                : ""-->
<!--            }}-->
<!--            <span *ngIf="physicalExamination?.chest_comments"-->
<!--            >({{ physicalExamination?.chest_comments }})-->
<!--                              </span>-->
<!--          </div>-->

<!--          <div-->
<!--            class="col-12"-->
<!--            *ngIf="this.physicalExamination?.heart_is_normal"-->
<!--          >-->
<!--            <strong>HEART:</strong>-->
<!--            {{-->
<!--              this.physicalExamination?.heart_is_normal ===-->
<!--              true-->
<!--                ? "Abnormal"-->
<!--                : ""-->
<!--            }}-->
<!--            <span-->
<!--              *ngIf="this.physicalExamination?.heart_comments"-->
<!--            >({{-->
<!--                physicalExamination?.heart_comments-->
<!--              }})</span-->
<!--            >-->
<!--          </div>-->

<!--          <div-->
<!--            class="col-12"-->
<!--            *ngIf="physicalExamination?.gi_is_normal"-->
<!--          >-->
<!--            <strong>GI:</strong>-->
<!--            {{-->
<!--              this.physicalExamination?.gi_is_normal === true-->
<!--                ? "Abnormal"-->
<!--                : ""-->
<!--            }}-->
<!--            <span *ngIf="physicalExamination?.gi_comments"-->
<!--            >({{ physicalExamination?.gi_comments }})</span-->
<!--            >-->
<!--          </div>-->
<!--          <div-->
<!--            class="col-12"-->
<!--            *ngIf="physicalExamination?.back_is_normal"-->
<!--          >-->
<!--            <strong>BACK:</strong>-->
<!--            {{-->
<!--              physicalExamination?.back_is_normal-->
<!--                ? "Abnormal"-->
<!--                : ""-->
<!--            }}-->
<!--            <span *ngIf="physicalExamination?.back_comments"-->
<!--            >({{-->
<!--                physicalExamination?.back_comments-->
<!--              }})</span-->
<!--            >-->
<!--          </div>-->
<!--          &lt;!&ndash;                            Cardiac Disorder&ndash;&gt;-->
<!--          <div-->
<!--            class="col-12"-->
<!--            *ngIf="physicalExamination?.exterm_is_normal"-->
<!--          >-->
<!--            <strong>EXTERM:</strong>-->
<!--            {{-->
<!--              physicalExamination?.exterm_is_normal === true-->
<!--                ? "Abnormal"-->
<!--                : ""-->
<!--            }}-->
<!--            <span-->
<!--              *ngIf="-->
<!--                                  this.physicalExamination?.exterm_comments-->
<!--                                "-->
<!--            >({{-->
<!--                this.physicalExamination?.exterm_comments-->
<!--              }})</span-->
<!--            >-->
<!--          </div>-->
<!--          &lt;!&ndash;                            Asthma&ndash;&gt;-->
<!--          <div-->
<!--            class="col-12"-->
<!--            *ngIf="physicalExamination?.neuro_is_normal"-->
<!--          >-->
<!--            <strong>NEURO:</strong>-->
<!--            {{-->
<!--              physicalExamination?.neuro_is_normal === true-->
<!--                ? "Abnormal"-->
<!--                : ""-->
<!--            }}-->
<!--            <span *ngIf="physicalExamination?.neuro_comments"-->
<!--            >({{-->
<!--                physicalExamination?.neuro_comments-->
<!--              }})</span-->
<!--            >-->
<!--          </div>-->
<!--          &lt;!&ndash;                            Bleeding Disorder&ndash;&gt;-->
<!--          <div-->
<!--            class="col-12"-->
<!--            *ngIf="physicalExamination?.skin_is_normal"-->
<!--          >-->
<!--            <strong>SKIN:</strong>-->
<!--            {{-->
<!--              physicalExamination.skin_is_normal === true-->
<!--                ? "Abnormal"-->
<!--                : ""-->
<!--            }}-->
<!--            <span *ngIf="physicalExamination?.skin_comments"-->
<!--            >({{-->
<!--                physicalExamination?.skin_comments-->
<!--              }})</span-->
<!--            >-->
<!--          </div>-->
<!--        </div>-->
<!--        <ng-template #physicalExaminationElse>-->
<!--          <div-->
<!--            class="row mb-5"-->
<!--            *ngIf="presControl.module_type === 'static'"-->
<!--          >-->
<!--            <div class="col-12">-->
<!--              <h4 class="para-heading-font-size">-->
<!--                {{ presControl.heading }}-->
<!--              </h4>-->
<!--            </div>-->
<!--            <div-->
<!--              [style.height]="presControl.height + 'px'"-->
<!--            ></div>-->
<!--          </div>-->
<!--        </ng-template>-->
<!--      </div>-->

      <!-- Labs -->
      <div
        *ngIf="
                            presControl.name === 'Labs' &&
                            presControl.show_on_pdf === true &&
                            presControl.alignment_on_print === 'right'
                          "
      >
        <div
          class="row mb-5"
          *ngIf="prescriptionData?.labs; else labsElse"
        >
          <div class="col-12 font-size-print-16">
            <h4 class="para-heading-font-size">
              {{ presControl.heading }}
            </h4>
            <app-summernotes
              [id]="'labs'"
              [htmlData]="prescriptionData?.labs"
            >
            </app-summernotes>
          </div>
        </div>
        <ng-template #labsElse>
          <div
            class="row mb-5"
            *ngIf="presControl.module_type === 'static'"
          >
            <div class="col-12">
              <h4 class="para-heading-font-size">
                {{ presControl.heading }}
              </h4>
            </div>
            <div
              [style.height]="presControl.height + 'px'"
            ></div>
          </div>
        </ng-template>
      </div>

      <!-- Plan -->
      <div
        *ngIf="
                            presControl.name === 'Plan' &&
                            presControl.show_on_pdf === true &&
                            presControl.alignment_on_print === 'right'
                          "
      >
        <div
          class="row mb-5"
          *ngIf="prescriptionData?.plan; else planElse"
        >
          <div class="col-12 font-size-print-16">
            <h4 class="para-heading-font-size">
              {{ presControl.heading }}
            </h4>
            <app-summernotes
              [id]="'plan'"
              [htmlData]="prescriptionData?.plan"
            >
            </app-summernotes>
          </div>
        </div>
        <ng-template #planElse>
          <div
            class="row mb-5"
            *ngIf="presControl.module_type === 'static'"
          >
            <div class="col-12">
              <h4 class="para-heading-font-size">
                {{ presControl.heading }}
              </h4>
            </div>
            <div
              [style.height]="presControl.height + 'px'"
            ></div>
          </div>
        </ng-template>
      </div>

      <!-- Treatment -->
      <div
        *ngIf="
                            presControl.name === 'Treatment' &&
                            presControl.show_on_pdf === true &&
                            presControl.alignment_on_print === 'right'
                          "
      >
        <div
          class="row mb-5"
          *ngIf="
                              prescriptionData?.treatment;
                              else treatmentElse
                            "
        >
          <div class="col-12 font-size-print-16">
            <h4 class="para-heading-font-size">
              {{ presControl.heading }}
            </h4>
            <app-summernotes
              [id]="'treatment'"
              [htmlData]="prescriptionData?.treatment"
            >
            </app-summernotes>
          </div>
        </div>
        <ng-template #treatmentElse>
          <div
            class="row mb-5"
            *ngIf="presControl.module_type === 'static'"
          >
            <div class="col-12">
              <h4 class="para-heading-font-size">
                {{ presControl.heading }}
              </h4>
            </div>
            <div
              [style.height]="presControl.height + 'px'"
            ></div>
          </div>
        </ng-template>
      </div>
      <!-- History of Presenting Complaints-->
      <div
        *ngIf="
                            presControl.name ===
                              'History of Presenting Complaints' &&
                            presControl.show_on_pdf === true &&
                            presControl.alignment_on_print === 'right'
                          "
      >
        <div
          class="row mb-5"
          *ngIf="
                              prescriptionData?.history_of_presenting_complaints;
                              else PresentingComplaintsHistoryElse
                            "
        >
          <div class="col-12 font-size-print-16">
            <h4 class="para-heading-font-size">
              {{ presControl.heading }}
            </h4>
            <span>
                                <app-summernotes
                                  [id]="'history_of_presenting_complaints'"
                                  [htmlData]="
                                    prescriptionData?.history_of_presenting_complaints
                                  "
                                >
                                </app-summernotes>
                              </span>
          </div>
        </div>
        <ng-template #PresentingComplaintsHistoryElse>
          <div
            class="row mb-5"
            *ngIf="presControl.module_type === 'static'"
          >
            <div class="col-12">
              <h4 class="para-heading-font-size">
                {{ presControl.heading }}
              </h4>
            </div>
            <div
              [style.height]="presControl.height + 'px'"
            ></div>
          </div>
        </ng-template>
      </div>
      <!--                        Past Dental History-->
      <div
        *ngIf="
                            presControl.name === 'Past Dental History' &&
                            presControl.show_on_pdf === true &&
                            presControl.alignment_on_print === 'right'
                          "
      >
        <div
          class="row mb-5"
          *ngIf="
                              prescriptionData?.past_dental_history;
                              else PastDentalHistoryElse
                            "
        >
          <div class="col-12 font-size-print-16">
            <h4 class="para-heading-font-size">
              {{ presControl.heading }}
            </h4>
            <app-summernotes
              [id]="'past_dental_history'"
              [htmlData]="
                                  prescriptionData?.past_dental_history
                                "
            >
            </app-summernotes>
          </div>
        </div>
        <ng-template #PastDentalHistoryElse>
          <div
            class="row mb-5"
            *ngIf="presControl.module_type === 'static'"
          >
            <div class="col-12">
              <h4 class="para-heading-font-size">
                {{ presControl.heading }}
              </h4>
            </div>
            <div
              [style.height]="presControl.height + 'px'"
            ></div>
          </div>
        </ng-template>
      </div>
      <!--                        History Of Hospitalization-->
      <div
        *ngIf="
                            presControl.name === 'History of Hospitalization' &&
                            presControl.show_on_pdf === true &&
                            presControl.alignment_on_print === 'right'
                          "
      >
        <div
          class="row mb-5"
          *ngIf="
                              prescriptionData?.history_of_hospitalization;
                              else HistoryOfHospitalizationElse
                            "
        >
          <div class="col-12 font-size-print-16">
            <h4 class="para-heading-font-size">
              {{ presControl.heading }}
            </h4>
            <app-summernotes
              [id]="'history_of_hospitalization'"
              [htmlData]="
                                  prescriptionData?.history_of_hospitalization
                                "
            >
            </app-summernotes>
          </div>
        </div>
        <ng-template #HistoryOfHospitalizationElse>
          <div
            class="row mb-5"
            *ngIf="presControl.module_type === 'static'"
          >
            <div class="col-12">
              <h4 class="para-heading-font-size">
                {{ presControl.heading }}
              </h4>
            </div>
            <div
              [style.height]="presControl.height + 'px'"
            ></div>
          </div>
        </ng-template>
      </div>

      <!--  Card 1-->
      <div
        *ngIf="
                            presControl.name === 'Card 1' &&
                            presControl.show_on_pdf === true &&
                            presControl.alignment_on_print === 'right'
                          "
      >
        <div
          class="row mb-5"
          *ngIf="
                              prescriptionData?.card_1;
                              else card1Else
                            "
        >
          <div class="col-12 font-size-print-16">
            <h4 class="para-heading-font-size">
              {{ presControl.heading }}
            </h4>
            <app-summernotes
              [id]="'card_1'"
              [htmlData]="
                                  prescriptionData?.card_1
                                "
            >
            </app-summernotes>
          </div>
        </div>
        <ng-template #card1Else>
          <div
            class="row mb-5"
            *ngIf="presControl.module_type === 'static'"
          >
            <div class="col-12">
              <h4 class="para-heading-font-size">
                {{ presControl.heading }}
              </h4>
            </div>
            <div
              [style.height]="presControl.height + 'px'"
            ></div>
          </div>
        </ng-template>
      </div>
      <!--  Card 2-->
      <div
        *ngIf="
                            presControl.name === 'Card 2' &&
                            presControl.show_on_pdf === true &&
                            presControl.alignment_on_print === 'right'
                          "
      >
        <div
          class="row mb-5"
          *ngIf="
                              prescriptionData?.card_2;
                              else card2Else
                            "
        >
          <div class="col-12 font-size-print-16">
            <h4 class="para-heading-font-size">
              {{ presControl.heading }}
            </h4>
            <app-summernotes
              [id]="'card_2'"
              [htmlData]="
                                  prescriptionData?.card_2
                                "
            >
            </app-summernotes>
          </div>
        </div>
        <ng-template #card2Else>
          <div
            class="row mb-5"
            *ngIf="presControl.module_type === 'static'"
          >
            <div class="col-12">
              <h4 class="para-heading-font-size">
                {{ presControl.heading }}
              </h4>
            </div>
            <div
              [style.height]="presControl.height + 'px'"
            ></div>
          </div>
        </ng-template>
      </div>
      <!--  Card 3-->
      <div
        *ngIf="
                            presControl.name === 'Card 3' &&
                            presControl.show_on_pdf === true &&
                            presControl.alignment_on_print === 'right'
                          "
      >
        <div
          class="row mb-5"
          *ngIf="
                              prescriptionData?.card_3;
                              else card3Else
                            "
        >
          <div class="col-12 font-size-print-16">
            <h4 class="para-heading-font-size">
              {{ presControl.heading }}
            </h4>
            <app-summernotes
              [id]="'card_3'"
              [htmlData]="
                                  prescriptionData?.card_3
                                "
            >
            </app-summernotes>
          </div>
        </div>
        <ng-template #card3Else>
          <div
            class="row mb-5"
            *ngIf="presControl.module_type === 'static'"
          >
            <div class="col-12">
              <h4 class="para-heading-font-size">
                {{ presControl.heading }}
              </h4>
            </div>
            <div
              [style.height]="presControl.height + 'px'"
            ></div>
          </div>
        </ng-template>
      </div>
      <!--  Card 4-->
      <div
        *ngIf="
                            presControl.name === 'Card 4' &&
                            presControl.show_on_pdf === true &&
                            presControl.alignment_on_print === 'right'
                          "
      >
        <div
          class="row mb-5"
          *ngIf="
                              prescriptionData?.card_4;
                              else card4Else
                            "
        >
          <div class="col-12 font-size-print-16">
            <h4 class="para-heading-font-size">
              {{ presControl.heading }}
            </h4>
            <app-summernotes
              [id]="'card_4'"
              [htmlData]="
                                  prescriptionData?.card_4
                                "
            >
            </app-summernotes>
          </div>
        </div>
        <ng-template #card4Else>
          <div
            class="row mb-5"
            *ngIf="presControl.module_type === 'static'"
          >
            <div class="col-12">
              <h4 class="para-heading-font-size">
                {{ presControl.heading }}
              </h4>
            </div>
            <div
              [style.height]="presControl.height + 'px'"
            ></div>
          </div>
        </ng-template>
      </div>
      <!--  Card 5-->
      <div
        *ngIf="
                            presControl.name === 'Card 5' &&
                            presControl.show_on_pdf === true &&
                            presControl.alignment_on_print === 'right'
                          "
      >
        <div
          class="row mb-5"
          *ngIf="
                              prescriptionData?.card_5;
                              else card5Else
                            "
        >
          <div class="col-12 font-size-print-16">
            <h4 class="para-heading-font-size">
              {{ presControl.heading }}
            </h4>
            <app-summernotes
              [id]="'card_5'"
              [htmlData]="
                                  prescriptionData?.card_5
                                "
            >
            </app-summernotes>
          </div>
        </div>
        <ng-template #card5Else>
          <div
            class="row mb-5"
            *ngIf="presControl.module_type === 'static'"
          >
            <div class="col-12">
              <h4 class="para-heading-font-size">
                {{ presControl.heading }}
              </h4>
            </div>
            <div
              [style.height]="presControl.height + 'px'"
            ></div>
          </div>
        </ng-template>
      </div>
      <!--  Card 6-->
      <div
        *ngIf="
                            presControl.name === 'Card 6' &&
                            presControl.show_on_pdf === true &&
                            presControl.alignment_on_print === 'right'
                          "
      >
        <div
          class="row mb-5"
          *ngIf="
                              prescriptionData?.card_6;
                              else card6Else
                            "
        >
          <div class="col-12 font-size-print-16">
            <h4 class="para-heading-font-size">
              {{ presControl.heading }}
            </h4>
            <app-summernotes
              [id]="'card_6'"
              [htmlData]="
                                  prescriptionData?.card_6
                                "
            >
            </app-summernotes>
          </div>
        </div>
        <ng-template #card6Else>
          <div
            class="row mb-5"
            *ngIf="presControl.module_type === 'static'"
          >
            <div class="col-12">
              <h4 class="para-heading-font-size">
                {{ presControl.heading }}
              </h4>
            </div>
            <div
              [style.height]="presControl.height + 'px'"
            ></div>
          </div>
        </ng-template>
      </div>
      <!--  Card 7-->
      <div
        *ngIf="
                            presControl.name === 'Card 7' &&
                            presControl.show_on_pdf === true &&
                            presControl.alignment_on_print === 'right'
                          "
      >
        <div
          class="row mb-5"
          *ngIf="
                              prescriptionData?.card_7;
                              else card7Else
                            "
        >
          <div class="col-12 font-size-print-16">
            <h4 class="para-heading-font-size">
              {{ presControl.heading }}
            </h4>
            <app-summernotes
              [id]="'card_7'"
              [htmlData]="
                                  prescriptionData?.card_7
                                "
            >
            </app-summernotes>
          </div>
        </div>
        <ng-template #card7Else>
          <div
            class="row mb-5"
            *ngIf="presControl.module_type === 'static'"
          >
            <div class="col-12">
              <h4 class="para-heading-font-size">
                {{ presControl.heading }}
              </h4>
            </div>
            <div
              [style.height]="presControl.height + 'px'"
            ></div>
          </div>
        </ng-template>
      </div>
      <!--  Card 8-->
      <div
        *ngIf="
                            presControl.name === 'Card 8' &&
                            presControl.show_on_pdf === true &&
                            presControl.alignment_on_print === 'right'
                          "
      >
        <div
          class="row mb-5"
          *ngIf="
                              prescriptionData?.card_8;
                              else card8Else
                            "
        >
          <div class="col-12 font-size-print-16">
            <h4 class="para-heading-font-size">
              {{ presControl.heading }}
            </h4>
            <app-summernotes
              [id]="'card_8'"
              [htmlData]="
                                  prescriptionData?.card_8
                                "
            >
            </app-summernotes>
          </div>
        </div>
        <ng-template #card8Else>
          <div
            class="row mb-5"
            *ngIf="presControl.module_type === 'static'"
          >
            <div class="col-12">
              <h4 class="para-heading-font-size">
                {{ presControl.heading }}
              </h4>
            </div>
            <div
              [style.height]="presControl.height + 'px'"
            ></div>
          </div>
        </ng-template>
      </div>
      <!--  Card 9-->
      <div
        *ngIf="
                            presControl.name === 'Card 9' &&
                            presControl.show_on_pdf === true &&
                            presControl.alignment_on_print === 'right'
                          "
      >
        <div
          class="row mb-5"
          *ngIf="
                              prescriptionData?.card_9;
                              else card9Else
                            "
        >
          <div class="col-12 font-size-print-16">
            <h4 class="para-heading-font-size">
              {{ presControl.heading }}
            </h4>
            <app-summernotes
              [id]="'card_9'"
              [htmlData]="
                                  prescriptionData?.card_9
                                "
            >
            </app-summernotes>
          </div>
        </div>
        <ng-template #card9Else>
          <div
            class="row mb-5"
            *ngIf="presControl.module_type === 'static'"
          >
            <div class="col-12">
              <h4 class="para-heading-font-size">
                {{ presControl.heading }}
              </h4>
            </div>
            <div
              [style.height]="presControl.height + 'px'"
            ></div>
          </div>
        </ng-template>
      </div>
      <!--  Card 10-->
      <div
        *ngIf="
                            presControl.name === 'Card 10' &&
                            presControl.show_on_pdf === true &&
                            presControl.alignment_on_print === 'right'
                          "
      >
        <div
          class="row mb-5"
          *ngIf="
                              prescriptionData?.card_10;
                              else card10Else
                            "
        >
          <div class="col-12 font-size-print-16">
            <h4 class="para-heading-font-size">
              {{ presControl.heading }}
            </h4>
            <app-summernotes
              [id]="'card_10'"
              [htmlData]="
                                  prescriptionData?.card_10
                                "
            >
            </app-summernotes>
          </div>
        </div>
        <ng-template #card10Else>
          <div
            class="row mb-5"
            *ngIf="presControl.module_type === 'static'"
          >
            <div class="col-12">
              <h4 class="para-heading-font-size">
                {{ presControl.heading }}
              </h4>
            </div>
            <div
              [style.height]="presControl.height + 'px'"
            ></div>
          </div>
        </ng-template>
      </div>

      <!--                        Drug History-->
      <div
        *ngIf="
                            presControl.name === 'Drug History' &&
                            presControl.show_on_pdf === true &&
                            presControl.alignment_on_print === 'right'
                          "
      >
        <div
          class="row mb-5"
          *ngIf="
                              prescriptionData?.drug_history;
                              else drugHistoryHistoryElse
                            "
        >
          <div class="col-12 font-size-print-16">
            <h4 class="para-heading-font-size">
              {{ presControl.heading }}
            </h4>
            <app-summernotes
              [id]="'drug_history'"
              [htmlData]="prescriptionData?.drug_history"
            >
            </app-summernotes>
          </div>
        </div>
        <ng-template #drugHistoryHistoryElse>
          <div
            class="row mb-5"
            *ngIf="presControl.module_type === 'static'"
          >
            <div class="col-12">
              <h4 class="para-heading-font-size">
                {{ presControl.heading }}
              </h4>
            </div>
            <div
              [style.height]="presControl.height + 'px'"
            ></div>
          </div>
        </ng-template>
      </div>

      <!--                       chronic_problems-->
      <div
        *ngIf="
                            presControl.name === 'Chronic Problem List' &&
                            presControl.show_on_pdf === true &&
                            presControl.alignment_on_print === 'right'
                          "
      >
        <div
          class="row mb-5"
          *ngIf="
                              prescriptionData?.chronic_problems.length;
                              else chronicProblem
                            "
        >
          <div class="col-12 font-size-print-16">
            <h4 class="para-heading-font-size">
              {{ presControl.heading }}
            </h4>
            <ng-container *ngFor="let data of prescriptionData?.chronic_problems">
              <div *ngIf="data?.date || data?.problem">{{data?.date | dateFormat}} -  {{data?.problem}}</div>
            </ng-container>
          </div>
        </div>
        <ng-template #chronicProblem>
          <div
            class="row mb-5"
            *ngIf="presControl.module_type === 'static'"
          >
            <div class="col-12">
              <h4 class="para-heading-font-size">
                {{ presControl.heading }}
              </h4>
            </div>
            <div
              [style.height]="presControl.height + 'px'"
            ></div>
          </div>
        </ng-template>
      </div>
      <!--    CONSULTANT VISITS Start-->
      <div
        *ngIf="
                            presControl.name === 'Consultant Visit' &&
                            presControl.show_on_pdf === true &&
                            presControl.alignment_on_print === 'right'
                          "
      >
        <div
          class="general-history-section font-size-print-16 row mb-5"
          *ngIf="
                              allConsultations.length;
                              else Consultations"
        >
          <div class="col-12">
            <h4 class="para-heading-font-size">
              {{ presControl.heading }}
            </h4>
          </div>
          <div
            class="col-12"
          >
                              <span>
                               <ng-container *ngFor="let consult of allConsultations; let i = index">
                                <div>
                                  <app-summernotes
                                    [id]="'consult' + i"
                                    [htmlData]="consult?.detail">
                                  </app-summernotes>
                                </div>
                                <div class="mb-4">{{ consult?.doctor?.first_name }} {{ consult?.doctor?.last_name }} ({{ consult?.department?.title }})</div>
                            </ng-container>
                              </span>
          </div>
        </div>
        <ng-template #Consultations>
          <div
            class="row mb-5"
            *ngIf="presControl.module_type === 'static'"
          >
            <div class="col-12">
              <h4 class="para-heading-font-size">
                {{ presControl.heading }}
              </h4>
            </div>
            <div
              [style.height]="presControl.height + 'px'"
            ></div>
          </div>
        </ng-template>
      </div>
      <!--   CONSULTANT VISITS End-->
      <!--                       Hospital Problem/Surgical History-->
      <div
        *ngIf="
                            presControl.name === 'Hospital Problem/Surgical History' &&
                            presControl.show_on_pdf === true &&
                            presControl.alignment_on_print === 'right'
                          "
      >
        <div
          class="row mb-5"
          *ngIf="
                              prescriptionData?.surgical_history.length;
                              else surgicalHistoryProblem
                            "
        >
          <div class="col-12 font-size-print-16">
            <h4 class="para-heading-font-size">
              {{ presControl.heading }}
            </h4>
            <ng-container *ngFor="let data of prescriptionData?.surgical_history">
              <div  *ngIf="data?.date || data?.problem">{{data?.date | dateFormat}} -  {{data?.problem}}</div>
            </ng-container>
          </div>
        </div>
        <ng-template #surgicalHistoryProblem>
          <div
            class="row mb-5"
            *ngIf="presControl.module_type === 'static'"
          >
            <div class="col-12">
              <h4 class="para-heading-font-size">
                {{ presControl.heading }}
              </h4>
            </div>
            <div
              [style.height]="presControl.height + 'px'"
            ></div>
          </div>
        </ng-template>
      </div>
      <!-- Presenting Complaint -->
      <div
        *ngIf="
                            presControl.name === 'Presenting Complaint' &&
                            presControl.show_on_pdf === true &&
                            presControl.alignment_on_print === 'right'
                          "
      >
        <div
          class="row mb-5"
          *ngIf="
                              prescriptionData?.patient_complaints
                                ?.presenting_complaints;
                              else presentingComplaintElse
                            "
        >
          <div class="col-12 font-size-print-16">
            <h4 class="para-heading-font-size">
              {{ presControl.heading }}
            </h4>
            <app-summernotes
              [id]="'presenting_complaints'"
              [htmlData]="
                                  prescriptionData?.patient_complaints
                                    ?.presenting_complaints
                                "
            >
            </app-summernotes>
          </div>
        </div>
        <ng-template #presentingComplaintElse>
          <div
            class="row mb-5"
            *ngIf="presControl.module_type === 'static'"
          >
            <div class="col-12">
              <h4 class="para-heading-font-size">
                {{ presControl.heading }}
              </h4>
            </div>
            <div
              [style.height]="presControl.height + 'px'"
            ></div>
          </div>
        </ng-template>
      </div>

      <div
        *ngIf="
                            presControl.name === 'Procedures' &&
                            presControl.show_on_pdf === true &&
                            presControl.alignment_on_print === 'right'
                          "
      >
        <div
          class="row mb-5"
          *ngIf="
                              prescriptionData?.patient_complaints?.procedures;
                              else proceduresElse
                            "
        >
          <div class="col-12 font-size-print-16">
            <h4 class="para-heading-font-size">
              {{ presControl.heading }}
            </h4>
            <app-summernotes
              [id]="'procedures'"
              [htmlData]="
                                  prescriptionData?.patient_complaints
                                    ?.procedures
                                "
            >
            </app-summernotes>
          </div>
        </div>
        <ng-template #proceduresElse>
          <div
            class="row mb-5"
            *ngIf="presControl.module_type === 'static'"
          >
            <div class="col-12">
              <h4 class="para-heading-font-size">
                {{ presControl.heading }}
              </h4>
            </div>
            <div
              [style.height]="presControl.height + 'px'"
            ></div>
          </div>
        </ng-template>
      </div>

      <!-- Examination -->
      <div
        *ngIf="
                            presControl.name === 'Examination' &&
                            presControl.show_on_pdf === true &&
                            presControl.alignment_on_print === 'right'
                          "
      >
        <div
          class="row mb-5"
          *ngIf="
                              prescriptionData?.patient_complaints
                                ?.general_physical_examination;
                              else examinationElse
                            "
        >
          <div class="col-12 font-size-print-16">
            <h4 class="para-heading-font-size">
              {{ presControl.heading }}
            </h4>
            <app-summernotes
              [id]="'general_physical_examination'"
              [htmlData]="
                                  prescriptionData?.patient_complaints
                                    ?.general_physical_examination
                                "
            >
            </app-summernotes>
          </div>
        </div>
        <ng-template #examinationElse>
          <div
            class="row mb-5"
            *ngIf="presControl.module_type === 'static'"
          >
            <div class="col-12">
              <h4 class="para-heading-font-size">
                {{ presControl.heading }}
              </h4>
            </div>
            <div
              [style.height]="presControl.height + 'px'"
            ></div>
          </div>
        </ng-template>
      </div>

      <!-- Diagnosis -->
      <div>
        <div
          *ngIf="
                              presControl.name === 'Provisional Diagnosis' &&
                              presControl.show_on_pdf === true &&
                              presControl.alignment_on_print === 'right'
                            "
        >
          <div
            class="row mb-5"
            *ngIf="
                                showProvisionalDia?.length > 0;
                                else provisionalDiagnosisElse
                              "
          >
            <div class="col-12">
              <h4 class="para-heading-font-size">
                {{ presControl.heading }}
              </h4>
            </div>
            <div class="col-12 mb-2 font-size-print-16">
              <ng-container
                *ngFor="
                                    let data of prescriptionData?.diagnosis
                                  "
              >
                                  <span
                                    *ngIf="
                                      data?.type === 'ProvisionalDiagnosis'
                                    "
                                  >{{ data?.name }}</span
                                  >
              </ng-container>
            </div>
          </div>
          <ng-template #provisionalDiagnosisElse>
            <div
              class="row mb-5"
              *ngIf="presControl.module_type === 'static'"
            >
              <div class="col-12">
                <h4 class="para-heading-font-size">
                  {{ presControl.heading }}
                </h4>
              </div>
              <div
                [style.height]="presControl.height + 'px'"
              ></div>
            </div>
          </ng-template>
        </div>

        <div
          *ngIf="
                              presControl.name === 'Differential Diagnosis' &&
                              presControl.show_on_pdf === true &&
                              presControl.alignment_on_print === 'right'
                            "
        >
          <div
            class="row mb-5"
            *ngIf="
                                showDifferentialDia?.length > 0;
                                else differentialDiagnosisElse
                              "
          >
            <div class="col-12">
              <h4 class="para-heading-font-size">
                {{ presControl.heading }}
              </h4>
            </div>
            <div class="col-12 mb-2 font-size-print-16">
              <ng-container
                *ngFor="
                                    let data of prescriptionData?.diagnosis
                                  "
              >
                                  <span
                                    *ngIf="
                                      data?.type === 'DifferentialDiagnosis'
                                    "
                                  >{{ data?.name }}</span
                                  >
              </ng-container>
            </div>
          </div>
          <ng-template #differentialDiagnosisElse>
            <div
              class="row mb-5"
              *ngIf="presControl.module_type === 'static'"
            >
              <div class="col-12">
                <h4 class="para-heading-font-size">
                  {{ presControl.heading }}
                </h4>
              </div>
              <div
                [style.height]="presControl.height + 'px'"
              ></div>
            </div>
          </ng-template>
        </div>

        <div
          *ngIf="
                              presControl.name === 'Final Diagnosis' &&
                              presControl.show_on_pdf === true &&
                              presControl.alignment_on_print === 'right'
                            "
        >
          <div
            class="row mb-5"
            *ngIf="
                                showFinalDia?.length > 0;
                                else finalDiagnosisElse
                              "
          >
            <div class="col-12">
              <h4 class="para-heading-font-size">
                {{ presControl.heading }}
              </h4>
            </div>
            <div class="col-12 font-size-print-16">
              <ng-container
                *ngFor="
                                    let data of prescriptionData?.diagnosis
                                  "
              >
                                  <span
                                    *ngIf="data?.type === 'FinalDiagnosis'"
                                  >{{ data?.name }}</span
                                  >
              </ng-container>
            </div>
          </div>
          <ng-template #finalDiagnosisElse>
            <div
              class="row mb-5"
              *ngIf="presControl.module_type === 'static'"
            >
              <div class="col-12">
                <h4 class="para-heading-font-size">
                  {{ presControl.heading }}
                </h4>
              </div>
              <div
                [style.height]="presControl.height + 'px'"
              ></div>
            </div>
          </ng-template>
        </div>
      </div>

      <!-- medicines -->
      <div
        *ngIf="
                            presControl.name === 'Treatment At Hospital' &&
                            presControl.show_on_pdf === true &&
                            presControl.alignment_on_print === 'right'
                          "
      >
        <div
          class="row mb-5"
          *ngIf="
                              prescriptionData?.medications?.length > 0;
                              else treatmentAtHospitalElse
                            "
        >
          <div class="col-12">
            <h4 class="para-heading-font-size">
              {{ presControl.heading }}
            </h4>
          </div>
          <div class="col-12 font-size-print-16">
            <table class="table" *ngIf="!medicineAsTabular">
              <tbody class="service-table">
              <tr>
                <th style="width: 14%">Type</th>
                <th style="width: 40%">Drug(s)</th>
                <th style="width: 12%" class="text-center">
                  Dose
                </th>
                <th style="width: 12%" class="text-center">
                  Frequency
                </th>
                <th style="width: 12%" class="text-center">
                  Duration
                </th>
                <th style="width: 10%" class="text-center">
                  Route
                </th>
              </tr>
              <tr
                *ngFor="
                                      let data of prescriptionData?.medications
                                    "
              >
                <td>
                  <strong>{{
                      data?.stoc_type_translation | titlecase
                    }}</strong>
                </td>
                <td>
                  <strong
                  >{{ data?.name }}
                    <span *ngIf="data?.generic_name">
                                          ({{ data?.generic_name }})</span
                    ></strong
                  >
                  <span
                    *ngIf="data?.comments"
                    class="font-italic"
                    style="font-size: 11px"
                  ><br />{{ data?.comments }}</span
                  >
                </td>
                <td class="text-center">
                  {{ data?.dos_type_translation }}
                </td>
                <td class="text-center">
                  {{ data?.dosee_translation }}
                </td>
                <td class="text-center">
                  {{ data?.no_of_days_value }}
                </td>
                <td class="text-center">
                  {{ data?.routee_translation }}
                </td>
              </tr>
              </tbody>
            </table>
            <ng-container *ngIf="medicineAsTabular">
              <div
                *ngFor="
                                    let data of prescriptionData?.medications
                                  "
                style="
                                    border-bottom: 1px dotted #000000;
                                    margin-bottom: 10px;
                                  "
              >
                <p class="mb-0">
                  {{ data?.name }}
                  <span *ngIf="data?.generic_name">
                                      ({{ data?.generic_name }})</span
                  >
                </p>
                <p class="mb-3">
                                    <span *ngIf="data?.dos_type_translation"
                                    >{{ data?.dos_type_translation }},</span
                                    >
                  <span *ngIf="data?.dosee_translation"
                  >{{ data?.dosee_translation }},</span
                  >
                  {{ data?.no_of_days_value }}
                </p>
              </div>
            </ng-container>
          </div>
        </div>
        <ng-template #treatmentAtHospitalElse>
          <div
            class="row mb-5"
            *ngIf="presControl.module_type === 'static'"
          >
            <div class="col-12">
              <h4 class="para-heading-font-size">
                {{ presControl.heading }}
              </h4>
            </div>
            <div
              [style.height]="presControl.height + 'px'"
            ></div>
          </div>
        </ng-template>
      </div>
      <!--                        Medicine Allergies-->
      <div
        *ngIf="
                            presControl.name === 'Medicine Allergies' &&
                            presControl.show_on_pdf === true &&
                            presControl.alignment_on_print === 'right'
                          "
      >
        <div
          class="row mb-5"
          *ngIf="
                              prescriptionData?.medicine_allergies?.length > 0;
                              else medicineAllergies
                            "
        >
          <div class="col-12">
            <h4 class="para-heading-font-size">
              {{ presControl.heading }}
            </h4>
          </div>
          <div class="col-12 font-size-print-16">
            <table class="table">
              <tbody class="service-table">
              <tr>
                <th style="width: 50%">Allergies</th>
                <th style="width: 50%">Reaction</th>
              </tr>
              <tr
                *ngFor="
                                      let data of prescriptionData?.medicine_allergies
                                    "
              >
                <td>
                  <strong>{{
                      data?.allergies
                    }}</strong>
                </td>
                <td>
                  <strong>{{data?.reaction}}</strong>
                </td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
        <ng-template #medicineAllergies>
          <div
            class="row mb-5"
            *ngIf="presControl.module_type === 'static'"
          >
            <div class="col-12">
              <h4 class="para-heading-font-size">
                {{ presControl.heading }}
              </h4>
            </div>
            <div
              [style.height]="presControl.height + 'px'"
            ></div>
          </div>
        </ng-template>
      </div>


      <div
        *ngIf="
                            presControl.name === 'Treatment At Discharge' &&
                            presControl.show_on_pdf === true &&
                            presControl.alignment_on_print === 'right'
                          "
      >
        <div
          class="row mb-5"
          *ngIf="
                              prescriptionData?.discharge_medications?.length >
                                0;
                              else treatmentAtDischargeElse
                            "
        >
          <div class="col-12">
            <h4 class="para-heading-font-size">
              {{ presControl.heading }}
            </h4>
          </div>
          <div class="col-12 font-size-print-16">
            <table class="table" *ngIf="!medicineAsTabular">
              <tbody class="service-table">
              <tr>
                <th style="width: 14%">Type</th>
                <th style="width: 40%">Drug(s)</th>
                <th style="width: 12%" class="text-center">
                  Dose
                </th>
                <th style="width: 12%" class="text-center">
                  Frequency
                </th>
                <th style="width: 12%" class="text-center">
                  Duration
                </th>
                <th style="width: 10%" class="text-center">
                  Route
                </th>
              </tr>
              <tr
                *ngFor="
                                      let data of prescriptionData?.discharge_medications
                                    "
              >
                <td>
                  <strong>{{
                      data?.stoc_type_translation | titlecase
                    }}</strong>
                </td>
                <td>
                  <strong
                  >{{ data?.name }}
                    <span *ngIf="data?.generic_name">
                                          ({{ data?.generic_name }})</span
                    ></strong
                  >
                  <span
                    *ngIf="data?.comments"
                    class="font-italic"
                    style="font-size: 11px"
                  ><br />{{ data?.comments }}</span
                  >
                </td>
                <td class="text-center">
                  {{ data?.dos_type_translation }}
                </td>
                <td class="text-center">
                  {{ data?.dosee_translation }}
                </td>
                <td class="text-center">
                  {{ data?.no_of_days_value }}
                </td>
                <td class="text-center">
                  {{ data?.routee_translation }}
                </td>
              </tr>
              </tbody>
            </table>
            <ng-container *ngIf="medicineAsTabular">
              <div
                *ngFor="
                                    let data of prescriptionData?.discharge_medications
                                  "
                style="
                                    border-bottom: 1px dotted #000000;
                                    margin-bottom: 10px;
                                  "
              >
                <p class="mb-0">
                  {{ data?.name }}
                  <span *ngIf="data?.generic_name">
                                      ({{ data?.generic_name }})</span
                  >
                </p>
                <p class="mb-3">
                                    <span *ngIf="data?.dos_type_translation"
                                    >{{ data?.dos_type_translation }},</span
                                    >
                  <span *ngIf="data?.dosee_translation"
                  >{{ data?.dosee_translation }},</span
                  >
                  {{ data?.no_of_days_value }}
                </p>
              </div>
            </ng-container>
          </div>
        </div>
        <ng-template #treatmentAtDischargeElse>
          <div
            class="row mb-5"
            *ngIf="presControl.module_type === 'static'"
          >
            <div class="col-12">
              <h4 class="para-heading-font-size">
                {{ presControl.heading }}
              </h4>
            </div>
            <div
              [style.height]="presControl.height + 'px'"
            ></div>
          </div>
        </ng-template>
      </div>

      <!-- Follow up Instructions -->
      <div
        *ngIf="
                            presControl.name === 'Follow Up Date' &&
                            presControl.show_on_pdf === true &&
                            presControl.alignment_on_print === 'right'
                          "
      >
        <div
          class="row mb-5"
          *ngIf="
                              prescriptionData?.appointment?.length;
                              else followUpDateElse
                            "
        >
          <div class="col-12">
            <h4 class="para-heading-font-size">
              {{ presControl.heading }}
              <!--                                <span-->
              <!--                                  class="mr-5 font-size-h6"-->
              <!--                                  style="font-weight: initial"-->
              <!--                                  >{{-->
              <!--                                    prescriptionData?.follow_up-->
              <!--                                      | date: "dd-MM-yyyy"-->
              <!--                                  }}</span-->
              <!--                                >-->
            </h4>
            <ng-container *ngFor="let data of prescriptionData?.appointment">
              <div>Please follow up with Dr. {{data?.doctor_first_name}} {{data?.doctor_last_name}} on {{data?.date | dateFormat}} at {{data?.start_time}} - {{data?.end_time}}</div>
            </ng-container>
          </div>
        </div>
        <ng-template #followUpDateElse>
          <div
            class="row mb-5"
            *ngIf="presControl.module_type === 'static'"
          >
            <div class="col-12">
              <h4 class="para-heading-font-size">
                {{ presControl.heading }}
              </h4>
            </div>
            <div
              [style.height]="presControl.height + 'px'"
            ></div>
          </div>
        </ng-template>
      </div>

      <div
        *ngIf="
                            presControl.name === 'Consultant Notes' &&
                            presControl.show_on_pdf === true &&
                            presControl.alignment_on_print === 'right'
                          "
      >
        <div
          class="row mb-5"
          *ngIf="visitData?.note; else consultantNotesElse"
        >
          <div class="col-12">
            <h4 class="para-heading-font-size">
              {{ presControl.heading }}
            </h4>
            <div>
              {{
                visitData?.note_created_at
                  | date: "dd-MM-yyyy"
              }}
              <span class="ml-10">{{
                  visitData?.doctor_name
                }}</span>
            </div>
          </div>
          <div class="col-12 font-size-print-16 mt-2">
            <app-summernotes
              [id]="'note'"
              [htmlData]="visitData?.note"
            >
            </app-summernotes>
          </div>
        </div>
        <ng-template #consultantNotesElse>
          <div
            class="row mb-5"
            *ngIf="presControl.module_type === 'static'"
          >
            <div class="col-12">
              <h4 class="para-heading-font-size">
                {{ presControl.heading }}
              </h4>
            </div>
            <div
              [style.height]="presControl.height + 'px'"
            ></div>
          </div>
        </ng-template>
      </div>

      <div
        *ngIf="
                            presControl.name === 'Follow Up Instructions' &&
                            presControl.show_on_pdf === true &&
                            presControl.alignment_on_print === 'right'
                          "
      >
        <div
          class="row mb-5"
          *ngIf="
                              prescriptionData?.follow_up_instruction;
                              else followUpInstructionsElse
                            "
        >
          <div class="col-12">
            <h4 class="para-heading-font-size">
              {{ presControl.heading }}
            </h4>
          </div>
          <div class="col-12 font-size-print-16">
            <app-summernotes
              [id]="'follow_up_instruction'"
              [htmlData]="
                                  prescriptionData?.follow_up_instruction
                                "
            >
            </app-summernotes>
          </div>
        </div>
        <ng-template #followUpInstructionsElse>
          <div
            class="row mb-5"
            *ngIf="presControl.module_type === 'static'"
          >
            <div class="col-12">
              <h4 class="para-heading-font-size">
                {{ presControl.heading }}
              </h4>
            </div>
            <div
              [style.height]="presControl.height + 'px'"
            ></div>
          </div>
        </ng-template>
      </div>

      <div
        *ngIf="
                            presControl.name === 'Admission Orders' &&
                            presControl.show_on_pdf === true &&
                            presControl.alignment_on_print === 'right' "
      >
        <div
          class="row mb-5"
          *ngIf="
                              visitData?.ref_visit?.doctor_name;
                              else admissionOrdersElse
                            "
        >
          <div class="col-12">
            <h4 class="para-heading-font-size">
              {{ presControl.heading }}
            </h4>
          </div>
          <div class="col-12 font-size-print-16">
            <div><span><strong>Doctor:</strong> {{visitData?.ref_visit?.doctor_name}}</span></div>
            <div><span><strong>Department:</strong> {{visitData?.ref_visit?.department_name}}</span></div>
            <div><span><strong>Ward/Bed:</strong> {{visitData?.ref_visit?.ward_name}} <span *ngIf="visitData?.ref_visit?.bed_no">({{visitData?.ref_visit?.bed_no}})</span></span></div>
            <div><span><strong>Condition:</strong> {{visitData?.ref_visit?.condition | titlecase}}</span></div>
            <div><span><strong>Comments:</strong> {{visitData?.ref_visit?.comments}}</span></div>
            <div>
              <strong>Admission Order Detail: </strong> <app-summernotes
              [id]="'admission_orders'"
              [htmlData]="
                                  visitData?.ref_visit?.admission_orders
                                "
            >
            </app-summernotes>
            </div>
<!--            <div>{{ visitData?.admission_orders }}</div>-->
          </div>
        </div>
        <ng-template #admissionOrdersElse>
          <div
            class="row mb-5"
            *ngIf="presControl.module_type === 'static'"
          >
            <div class="col-12">
              <h4 class="para-heading-font-size">
                {{ presControl.heading }}
              </h4>
            </div>
            <div
              [style.height]="presControl.height + 'px'"
            ></div>
          </div>
        </ng-template>
      </div>

      <div
        *ngIf="
                            presControl.name === 'Diet' &&
                            presControl.show_on_pdf === true &&
                            presControl.alignment_on_print === 'right'
                          "
      >
        <div class="row mb-5"
             *ngIf="this.prescriptionData.diet.breakfast ||
         this.prescriptionData.diet.lunch ||
          this.prescriptionData.diet.dinner ||
          this.prescriptionData.diet.snacks ||
          this.prescriptionData.diet.others_detail;
                              else dietElse">
          <div class="col-12">
            <h4 class="para-heading-font-size">
              {{ presControl.heading }}
            </h4>
          </div>
          <div class="col-12" *ngIf="prescriptionData.diet.breakfast">
            <strong>BreakFast: </strong>
            <span>{{ convertTo12HourFormat(prescriptionData.diet.breakfast_time) }}, </span>
            <span>{{prescriptionData.diet.breakfast_detail}} </span>
          </div>
          <div class="col-12" *ngIf="prescriptionData.diet.lunch">
            <strong>Lunch: </strong>
            <span>{{ convertTo12HourFormat(prescriptionData.diet.lunch_time) }}, </span>
            <span>{{prescriptionData.diet.lunch_detail}}</span>
          </div>
          <div class="col-12" *ngIf="prescriptionData.diet.dinner">
            <strong>Dinner: </strong>
            <span>{{ convertTo12HourFormat(prescriptionData.diet.dinner_time) }}, </span>
            <span>{{prescriptionData.diet.dinner_detail}}</span>
          </div>
          <div class="col-12" *ngIf="prescriptionData.diet.snacks">
            <strong>Snacks: </strong>
            <span>{{prescriptionData.diet.dinner_detail}}</span>
          </div>
          <div class="col-12" *ngIf="prescriptionData.diet.others_detail">
            <strong>Others: </strong>
            <span>{{prescriptionData.diet.others_detail}}</span>
          </div>
        </div>
        <ng-template #dietElse>
          <div
            class="row mb-5"
            *ngIf="presControl.module_type === 'static'"
          >
            <div class="col-12">
              <h4 class="para-heading-font-size">
                {{ presControl.heading }}
              </h4>
            </div>
            <div
              [style.height]="presControl.height + 'px'"
            ></div>
          </div>
        </ng-template>
      </div>

      <div
        *ngIf="
                            presControl.name === 'Operation Details' &&
                            presControl.show_on_pdf === true &&
                            presControl.alignment_on_print === 'right'
                          "
      >
        <div
          class="row mb-5"
          *ngIf="presControl.module_type === 'dynamic'"
        >
          <div
            class="col-12"
            *ngIf="
                                prescriptionData?.operation_date ||
                                prescriptionData?.operative_procedure ||
                                prescriptionData?.operative_finding
                              "
          >
            <h4 class="para-heading-font-size">
              {{ presControl.heading }}
            </h4>
            <span
              class="d-flex"
              *ngIf="prescriptionData?.operation_date"
            ><strong>Operation Date:</strong>
              {{ prescriptionData?.operation_date }}</span
            >
            <span
              class="d-flex"
              *ngIf="prescriptionData?.operative_procedure"
            ><strong>Operative Procedure:</strong>
              {{
                prescriptionData?.operative_procedure
              }}</span
            >
            <span
              class="d-flex"
              *ngIf="prescriptionData?.operative_finding"
            ><strong>Operative Finding:</strong>
              {{ prescriptionData?.operative_finding }}</span
            >
          </div>
        </div>
        <ng-container
          *ngIf="presControl.module_type === 'static'"
        >
          <div class="row mb-5">
            <div class="col-12">
              <h4 class="para-heading-font-size">
                {{ presControl.heading }}
              </h4>
              <span class="d-flex"
              ><strong>Operation Date:</strong>
                {{
                  prescriptionData?.operation_date || ""
                }}</span
              >
              <span class="d-flex"
              ><strong>Operative Procedure:</strong>
                {{
                  prescriptionData?.operative_procedure || ""
                }}</span
              >
              <span class="d-flex"
              ><strong>Operative Finding:</strong>
                {{
                  prescriptionData?.operative_finding || ""
                }}</span
              >
            </div>
          </div>
        </ng-container>
      </div>

      <!-- Operation Details -->
      <div
        *ngIf="
                            presControl.name === 'Operation Details' &&
                            presControl.show_on_pdf === true &&
                            presControl.alignment_on_print === 'right'
                          "
      >
        <div
          class="row mb-5"
          *ngIf="presControl.module_type === 'dynamic'"
        >
          <div class="col-12">
            <h4 class="para-heading-font-size">
              {{ presControl.heading }}
            </h4>
            <span
              class="d-flex"
              *ngIf="prescriptionData?.operation_date"
            ><strong>Operation Date:</strong>
              {{ prescriptionData?.operation_date }}</span
            >
            <span
              class="d-flex"
              *ngIf="prescriptionData?.operative_procedure"
            ><strong>Operative Procedure:</strong>
              {{
                prescriptionData?.operative_procedure
              }}</span
            >
            <span
              class="d-flex"
              *ngIf="prescriptionData?.operative_finding"
            ><strong>Operative Finding:</strong>
              {{ prescriptionData?.operative_finding }}</span
            >
          </div>
        </div>
        <ng-container
          *ngIf="presControl.module_type === 'static'"
        >
          <div class="row mb-5">
            <div class="col-12">
              <h4 class="para-heading-font-size">
                {{ presControl.heading }}
              </h4>
              <span class="d-flex"
              ><strong>Operation Date:</strong>
                {{
                  prescriptionData?.operation_date || ""
                }}</span
              >
              <span class="d-flex"
              ><strong>Operative Procedure:</strong>
                {{
                  prescriptionData?.operative_procedure || ""
                }}</span
              >
              <span class="d-flex"
              ><strong>Operative Finding:</strong>
                {{
                  prescriptionData?.operative_finding || ""
                }}</span
              >
            </div>
          </div>
        </ng-container>
      </div>

      <!-- Gyane Presenting Complaint -->
      <div
        *ngIf="
                            presControl.name === 'Gynae Presenting Complaint' &&
                            presControl.show_on_pdf === true &&
                            presControl.alignment_on_print === 'right'
                          "
      >
        <div
          class="row mb-5"
          *ngIf="
                              gynaecologicalComplaint?.mf ||
                                gynaecologicalComplaint?.lmp ||
                                gynaecologicalComplaint?.edd ||
                                gynaecologicalComplaint?.gastralunal_age;
                              else gynaecologicalPresentingComplaintElse
                            "
        >
          <div class="col-12 font-size-print-16">
            <h4 class="para-heading-font-size">
              {{ presControl.heading }}
            </h4>
            <app-summernotes
              [id]="'gynae_instructions'"
              [htmlData]="
                                  gynaecologicalComplaint?.gynae_instructions
                                "
            >
            </app-summernotes>
          </div>
          <div
            class="col-12"
            *ngIf="this.gynaecologicalComplaint?.mf"
          >
            <strong>MF:</strong>
            {{ this.gynaecologicalComplaint?.mf }}
          </div>
          <div
            class="col-12"
            *ngIf="this.gynaecologicalComplaint?.lmp"
          >
            <strong>LMP:</strong>
            {{ this.gynaecologicalComplaint?.lmp }}
          </div>
          <div
            class="col-12"
            *ngIf="this.gynaecologicalComplaint?.edd"
          >
            <strong>EDD:</strong>
            {{ this.gynaecologicalComplaint?.edd }}
          </div>
          <div
            class="col-12"
            *ngIf="
                                this.gynaecologicalComplaint?.gastralunal_age
                              "
          >
            <strong>Gestational Age:</strong>
            {{
              this.gynaecologicalComplaint?.gastralunal_age
            }}
          </div>
        </div>
        <ng-template #gynaecologicalPresentingComplaintElse>
          <div
            class="row mb-5"
            *ngIf="presControl.module_type === 'static'"
          >
            <div class="col-12">
              <h4 class="para-heading-font-size">
                {{ presControl.heading }}
              </h4>
            </div>
            <div
              [style.height]="presControl.height + 'px'"
            ></div>
          </div>
        </ng-template>
      </div>
      <!-- Obstetrical History -->
      <div
        *ngIf="
                            presControl.name === 'Obstetrical History' &&
                            presControl.show_on_pdf === true &&
                            presControl.alignment_on_print === 'right'
                          "
      >
        <div
          class="row mb-5"
          *ngIf="
                              obstetricalHistory?.g ||
                                obstetricalHistory?.p ||
                                obstetricalHistory?.a ||
                                obstetricalHistory?.lcb ||
                                obstetricalHistory?.mode_of_delivery ||
                                obstetricalHistory?.pod ||
                                obstetricalHistory?.rf;
                              else obstetricalHistoryElse
                            "
        >
          <div class="col-12 font-size-print-16">
            <h4 class="para-heading-font-size">
              {{ presControl.heading }}
            </h4>
          </div>
          <div
            class="col-12"
            *ngIf="this.obstetricalHistory?.g"
          >
            <strong>G:</strong>
            {{ this.obstetricalHistory?.g }}
          </div>
          <div
            class="col-12"
            *ngIf="this.obstetricalHistory?.p"
          >
            <strong>P:</strong>
            {{ this.obstetricalHistory?.p }}
          </div>
          <div
            class="col-12"
            *ngIf="this.obstetricalHistory?.a"
          >
            <strong>A:</strong>
            {{ this.obstetricalHistory?.a }}
          </div>
          <div
            class="col-12"
            *ngIf="this.obstetricalHistory?.lcb"
          >
            <strong>LCB:</strong>
            {{ this.obstetricalHistory?.lcb }}
          </div>
          <div
            class="col-12"
            *ngIf="this.obstetricalHistory?.mode_of_delivery"
          >
            <strong>Mode Of Delivery:</strong>
            {{ this.obstetricalHistory?.mode_of_delivery }}
          </div>
          <div
            class="col-12"
            *ngIf="this.obstetricalHistory?.pod"
          >
            <strong>POD:</strong>
            {{ this.obstetricalHistory?.pod }}
          </div>
          <div
            class="col-12"
            *ngIf="this.obstetricalHistory?.rf"
          >
            <strong>RF:</strong>
            {{ this.obstetricalHistory?.rf }}
          </div>
        </div>
        <ng-template #obstetricalHistoryElse>
          <div
            class="row mb-5"
            *ngIf="presControl.module_type === 'static'"
          >
            <div class="col-12">
              <h4 class="para-heading-font-size">
                {{ presControl.heading }}
              </h4>
            </div>
            <div
              [style.height]="presControl.height + 'px'"
            ></div>
          </div>
        </ng-template>
      </div>
      <!-- Gynaecological History -->
      <div
        *ngIf="
                            presControl.name === 'Gynaecological History' &&
                            presControl.show_on_pdf === true &&
                            presControl.alignment_on_print === 'right'
                          "
      >
        <div
          class="row mb-5"
          *ngIf="
                              gynaecologicalHistory?.age_of_minarche ||
                                gynaecologicalHistory?.menstrual_cycle ||
                                gynaecologicalHistory?.lmp ||
                                gynaecologicalHistory?.dysmenorrhea ||
                                gynaecologicalHistory?.dyspareunia ||
                                gynaecologicalHistory?.imb ||
                                gynaecologicalHistory?.pcb ||
                                gynaecologicalHistory?.contraception;
                              else gynaecologicalHistoryElse
                            "
        >
          <div class="col-12 font-size-print-16">
            <h4 class="para-heading-font-size">
              {{ presControl.heading }}
            </h4>
          </div>
          <div
            class="col-12"
            *ngIf="
                                this.gynaecologicalHistory?.age_of_minarche
                              "
          >
            <strong>Age Of Menarche:</strong>
            {{ this.gynaecologicalHistory?.age_of_minarche }}
          </div>
          <div
            class="col-12"
            *ngIf="
                                this.gynaecologicalHistory?.menstrual_cycle
                              "
          >
            <strong>Menstrual Cycle:</strong>
            {{ this.gynaecologicalHistory?.menstrual_cycle }}
          </div>
          <div
            class="col-12"
            *ngIf="this.gynaecologicalHistory?.lmp"
          >
            <strong>LMP:</strong>
            {{ this.gynaecologicalHistory?.lmp }}
          </div>
          <div
            class="col-12"
            *ngIf="this.gynaecologicalHistory?.dysmenorrhea"
          >
            <strong>Dysmenorrhea:</strong>
            {{ this.gynaecologicalHistory?.dysmenorrhea }}
          </div>
          <div
            class="col-12"
            *ngIf="this.gynaecologicalHistory?.dyspareunia"
          >
            <strong>Dyspareunia:</strong>
            {{ this.gynaecologicalHistory?.dyspareunia }}
          </div>
          <div
            class="col-12"
            *ngIf="this.gynaecologicalHistory?.imb"
          >
            <strong>IMB:</strong>
            {{ this.gynaecologicalHistory?.imb }}
          </div>
          <div
            class="col-12"
            *ngIf="this.gynaecologicalHistory?.pcb"
          >
            <strong>PCB:</strong>
            {{ this.gynaecologicalHistory?.pcb }}
          </div>
          <div
            class="col-12"
            *ngIf="this.gynaecologicalHistory?.contraception"
          >
            <strong>Contraception:</strong>
            {{ this.gynaecologicalHistory?.contraception }}
          </div>
        </div>
        <ng-template #gynaecologicalHistoryElse>
          <div
            class="row mb-5"
            *ngIf="presControl.module_type === 'static'"
          >
            <div class="col-12">
              <h4 class="para-heading-font-size">
                {{ presControl.heading }}
              </h4>
            </div>
            <div
              [style.height]="presControl.height + 'px'"
            ></div>
          </div>
        </ng-template>
      </div>
      <!-- Pelvic Examination -->
      <div
        *ngIf="
                      presControl.name === 'Pelvic Examination' &&
                      presControl.show_on_pdf === true &&
                      presControl.alignment_on_print === 'right'
                    "
      >
        <div
          class="row mb-5"
          *ngIf="
                        pelvicExamination;
                        else pelvicExaminationElse
                      "
        >
          <div class="col-12 font-size-print-16">
            <h4 class="para-heading-font-size">
              {{ presControl.heading }}
            </h4>
          </div>
          <div
            class="col-12"
            *ngIf="this.pelvicExamination?.ps_history"
          >
            <strong>P/S:</strong>
            {{ this.pelvicExamination?.ps_history }}
          </div>
          <div
            class="col-12"
            *ngIf="this.pelvicExamination?.bm_history"
          >
            <strong>B/M:</strong>
            {{ this.pelvicExamination?.bm_history }}
          </div>
          <div
            class="col-12"
            *ngIf="this.pelvicExamination?.pap_history"
          >
            <strong>PaP Smear:</strong>
            {{ this.pelvicExamination?.pap_history }}
          </div>
        </div>
        <ng-template #pelvicExaminationElse>
          <div
            class="row mb-5"
            *ngIf="presControl.module_type === 'static'"
          >
            <div class="col-12">
              <h4 class="para-heading-font-size">
                {{ presControl.heading }}
              </h4>
            </div>
            <div
              [style.height]="presControl.height + 'px'"
            ></div>
          </div>
        </ng-template>
      </div>
      <!-- Obstetrical Examination -->
      <div
        *ngIf="
                      presControl.name === 'Obstetrical Examination' &&
                      presControl.show_on_pdf === true &&
                      presControl.alignment_on_print === 'right'
                    "
      >
        <div
          class="row mb-5"
          *ngIf="
                        obstetricalExamination;
                        else obstetricalExaminationElse
                      "
        >
          <div class="col-12 font-size-print-16">
            <h4 class="para-heading-font-size">
              {{ presControl.heading }}
            </h4>
          </div>
          <div
            class="col-12"
            *ngIf="this.obstetricalExamination?.fh_history"
          >
            <strong>FH:</strong>
            {{ this.obstetricalExamination?.fh_history }}
          </div>
          <div
            class="col-12"
            *ngIf="this.obstetricalExamination?.lie_history"
          >
            <strong>Lie:</strong>
            {{ this.obstetricalExamination?.lie_history }}
          </div>
          <div
            class="col-12"
            *ngIf="
                          this.obstetricalExamination?.liqour_history
                        "
          >
            <strong>Liqour:</strong>
            {{ this.obstetricalExamination?.liqour_history }}
          </div>
          <div
            class="col-12"
            *ngIf="this.obstetricalExamination?.fhr_history"
          >
            <strong>FHR:</strong>
            {{ this.obstetricalExamination?.fhr_history }}
          </div>
          <div
            class="col-12"
            *ngIf="this.obstetricalExamination?.tvs"
          >
            <strong>TVS:</strong>
            {{ this.obstetricalExamination?.tvs }}
          </div>
          <div
            class="col-12"
            *ngIf="this.obstetricalExamination?.usg"
          >
            <strong>USG:</strong>
            {{ this.obstetricalExamination?.usg }}
          </div>
        </div>
        <ng-template #obstetricalExaminationElse>
          <div
            class="row mb-5"
            *ngIf="presControl.module_type === 'static'"
          >
            <div class="col-12">
              <h4 class="para-heading-font-size">
                {{ presControl.heading }}
              </h4>
            </div>
            <div
              [style.height]="presControl.height + 'px'"
            ></div>
          </div>
        </ng-template>
      </div>

      <!-- Physical Examination -->
      <div
        *ngIf="
                            presControl.name === 'Physical Examination' &&
                            presControl.show_on_pdf === true &&
                            presControl.alignment_on_print === 'right'
                          "
      >
        <div *ngIf="pelvicExamination || obstetricalExamination || chestExamination || gpe || abdomen || heent || cardiovascular || skin || neurological || musculoskeletal">
          <div class="col-12 font-size-print-16 p-0">
            <h4 class="para-heading-font-size">
              {{ presControl.heading }}
            </h4>
          </div>
          <!--        Pelvic Examination-->
          <div
            class="row"
            *ngIf="
                              pelvicExamination;
                            "
          >
            <div *ngIf="pelvicExamination?.type === 'Normal'">
              <div class="font-size-print-16 ml-4">
                <strong>
                  Pelvic Examination
                </strong>
                (
                <span>{{pelvicExamination?.type_detail ? 'Normal: ' + pelvicExamination.type_detail : ''}}</span>
                )
              </div>
            </div>
            <div *ngIf="pelvicExamination?.type === 'Abnormal'">
              <div class="font-size-print-16 ml-4">
                <strong>
                  Pelvic Examination
                </strong>
                (
                <span>
                {{pelvicExamination?.ps_history ? 'P/S: ' + pelvicExamination.ps_history + ', ' : ''}}
                  {{pelvicExamination?.bm_history ? 'B/M: ' + pelvicExamination.bm_history + ', ' : ''}}
                  {{pelvicExamination?.pap_history ? 'PaP Smear: ' + pelvicExamination.pap_history + ', ' : ''}}
                  {{pelvicExamination?.other_detail ? 'Other: ' + pelvicExamination.other_detail : ''}}
              </span>
                )
              </div>
            </div>
          </div>
          <!--        Obstetrical Examination-->
          <div
            class="row"
            *ngIf="
                              obstetricalExamination
                            "
          >
            <div *ngIf="obstetricalExamination?.type === 'Abnormal'">
              <div class="font-size-print-16 ml-4">
                <strong>
                  Obstetrical Examination
                </strong>
                (
                <span>
                {{obstetricalExamination?.fh_history ? 'FH: ' + obstetricalExamination.fh_history + ', ' : ''}}
                  {{obstetricalExamination?.lie_history ? 'Lie: ' + obstetricalExamination.lie_history + ', ' : ''}}
                  {{obstetricalExamination?.liqour_history ? 'Liqour: ' + obstetricalExamination.liqour_history + ', ' : ''}}
                  {{obstetricalExamination?.fhr_history ? 'FHR: ' + obstetricalExamination.fhr_history + ', ' : ''}}
                  {{obstetricalExamination?.tvs ? 'TVS: ' + obstetricalExamination.tvs + ', ' : ''}}
                  {{obstetricalExamination?.usg ? 'USG: ' + obstetricalExamination.usg + ', ' : ''}}
                  {{obstetricalExamination?.other_detail ? 'Other: ' + obstetricalExamination.other_detail : ''}}
              </span>
                )
              </div>

            </div>
            <div *ngIf="obstetricalExamination?.type === 'Normal'">
              <div class="font-size-print-16 ml-4">
                <strong>
                  Obstetrical Examination
                </strong>
                (
                <span>{{obstetricalExamination?.type_detail ? 'Normal: ' + obstetricalExamination.type_detail : ''}}</span>
                )
              </div>
            </div>
          </div>
          <!--        Chest Examination-->
          <div
            class="row"
            *ngIf="
                              chestExamination
                            "
          >
            <div *ngIf="chestExamination?.type === 'Abnormal'">
              <div class="font-size-print-16 ml-4">
                <strong>
                  Chest/Lung
                </strong>
                (
                <span>
                {{chestExamination?.chest_defermity ? 'Chest Defermity' + ', ' : ''}}
                  {{chestExamination?.vesicular ? 'Vesicular' +  ', ' : ''}}
                  {{chestExamination?.bronchial ? 'Bronchial' + ', ' : ''}}
                  {{chestExamination?.crackles ? 'Crackles' + ', ' : ''}}
                  {{chestExamination?.diminished_breath_sounds ? 'Diminished breath sounds' + ', ' : ''}}
                  {{chestExamination?.rhonchi ? 'Rhonchi ' + ', ' : ''}}
                  {{chestExamination?.wheeeze ? 'Wheeeze' + ', ' : ''}}
                  {{chestExamination?.usg ? 'USG: ' + obstetricalExamination.usg + ', ' : ''}}
                  {{chestExamination?.other_detail ? 'Other: ' + chestExamination.other_detail : ''}}
              </span>
                )
              </div>
            </div>
            <div *ngIf="chestExamination?.type === 'Normal'">
              <div class="font-size-print-16 ml-4">
                <strong>
                  Chest/Lung
                </strong>
                (
                <span>{{chestExamination?.type_detail ? 'Normal: ' + chestExamination.type_detail : ''}}</span>
                )
              </div>
            </div>
          </div>
          <!--        GPE-->
          <div
            class="row"
            *ngIf="
                              gpe
                            "
          >
            <div *ngIf="gpe?.type === 'Abnormal'">

              <div class="font-size-print-16 ml-4">
                <strong>
                  GPE
                </strong>
                (
                <span>
                {{gpe?.pallor ? 'Pallor' + ', ' : ''}}
                  {{gpe?.clubbing ? 'Clubbing' +  ', ' : ''}}
                  {{gpe?.cyanosis ? 'Cyanosis' + ', ' : ''}}
                  {{gpe?.lymphadenopathy ? 'Lymphadenopathy ' + ', ' : ''}}
                  {{gpe?.pedal ? 'Pedal/Ankle Edema' + ', ' : ''}}
                  {{gpe?.rash ? 'Rash' + ', ' : ''}}
                  {{gpe?.distressed ? 'Distressed' + ', ' : ''}}
                  {{gpe?.cachectic ? 'Cachectic' + ', ' : ''}}
                  {{gpe?.jaundiced ? 'Jaundiced' + ', ' : ''}}
                  {{gpe?.dehydrated ? 'Dehydrated' + ', ' : ''}}
                  {{gpe?.peropheral_vessel_palpable ? 'Peropheral vessel palpable' + ', ' : ''}}
                  {{gpe?.other_detail ? 'Other: ' + gpe.other_detail : ''}}
              </span>
                )
              </div>
            </div>
            <div *ngIf="gpe?.type === 'Normal'">
              <div class="font-size-print-16 ml-4">
                <strong>
                  GPE
                </strong>
                (
                <span>{{gpe?.type_detail ? 'Normal: ' + gpe.type_detail : ''}}</span>
                )
              </div>
            </div>
          </div>
          <!--        Abdomen-->
          <div
            class="row"
            *ngIf="
                              abdomen
                            "
          >
            <div *ngIf="abdomen?.type === 'Abnormal'">
              <div class="font-size-print-16 ml-4">
                <strong>
                  Abdomen
                </strong>
                (
                <span>
                {{abdomen?.soft ? 'Soft' + ', ' : ''}}
                  {{abdomen?.distended ? 'Distended' +  ', ' : ''}}
                  {{abdomen?.ascities ? 'Ascities' + ', ' : ''}}
                  {{abdomen?.bowel_sounds ? 'Hyperactive Bowel Sounds ' + ', ' : ''}}
                  {{abdomen?.hepatomegaly ? 'Hepatomegaly' + ', ' : ''}}
                  {{abdomen?.spleenomegaly ? 'Spleenomegaly' + ', ' : ''}}
                  {{abdomen?.inguinal_hernia ? 'Inguinal Hernia' + ', ' : ''}}
                  {{abdomen?.tenderness ? 'tenderness' + ', ' : ''}}
                  {{abdomen?.rigidity ? 'Rigidity' + ', ' : ''}}
                  {{abdomen?.guarding ? 'Guarding' + ', ' : ''}}
                  {{abdomen?.palpable_liver ? 'Palpable liver' + ', ' : ''}}
                  {{abdomen?.palpable_mass ? 'Palpable mass' + ', ' : ''}}
                  {{abdomen?.palpable_spleen ? 'Palpable spleen' + ', ' : ''}}
                  {{abdomen?.umbilical ? 'Umbilical' + ', ' : ''}}
                  {{abdomen?.grade ? 'Grade:' + abdomen.grade + ', ' : ''}}
                  {{abdomen?.area ? 'Area:' + abdomen.area + ', ' : ''}}
                  {{abdomen?.genital_examination ? 'Genital Examination:' + abdomen.genital_examination + ', ' : ''}}
                  {{abdomen?.other ? 'Other:' + abdomen.other : ''}}
              </span>
                )
              </div>
            </div>
            <div *ngIf="abdomen?.type === 'Normal'">
              <div class="font-size-print-16 ml-4">
                <strong>
                  Abdomen
                </strong>
                (
                <span>{{abdomen?.type_detail ? 'Normal: ' + abdomen.type_detail : ''}}</span>
                )
              </div>
            </div>
          </div>
          <!--        HEENT-->
          <div
            class="row"
            *ngIf="
                              heent
                            "
          >
            <div *ngIf="heent?.type === 'Abnormal'">
              <div class="font-size-print-16 ml-4">
                <strong>
                  Heent
                </strong>
                (
                <span>
                {{heent?.head ? 'Head: ' + heent.head + ', ' : ''}}
                  {{heent?.eye ? 'Eye: ' + heent.eye + ', ' : ''}}
                  {{heent?.ent ? 'Ent: ' + heent.ent + ', ' : ''}}
                  {{heent?.neck ? 'Neck: ' + heent.neck + ', ' : ''}}
                  {{heent?.neck_viens ? 'N-Viens: ' + heent.neck_viens + ', ' : ''}}
                  {{abdomen?.icterus ? 'Icterus' + ', ' : ''}}
                  {{abdomen?.pallor ? 'Pallor' + ', ' : ''}}
                  {{abdomen?.conjunctival_injection ? 'Conjunctival injection' + ', ' : ''}}
                  {{heent?.abnormal_findings ? 'Abnormal findings: ' + heent.abnormal_findings + ', ' : ''}}
                  {{heent?.other_detail ? 'Other: ' + heent.other_detail : ''}}
              </span>
                )
              </div>
            </div>
            <div *ngIf="heent?.type === 'Normal'">
              <div class="font-size-print-16 ml-4">
                <strong>
                  Heent
                </strong>
                (
                <span>{{heent?.type_detail ? 'Normal: ' + heent.type_detail : ''}}</span>
                )
              </div>
            </div>
          </div>
          <!--        Neurological-->
          <div
            class="row"
            *ngIf="
                              neurological
                            "
          >
            <div *ngIf="neurological?.type === 'Abnormal'">
              <div class="font-size-print-16 ml-4">
                <strong>
                  Neurological
                </strong>
                (
                <span>
               {{neurological?.cranial_nerve_deficits ? 'Cranial nerve deficits' + ', ' : ''}}
                  {{neurological?.muscle_weakness ? 'Muscle weakness' + ', ' : ''}}
                  {{neurological?.hyperreflexia ? 'Hyperreflexia' + ', ' : ''}}
                  {{neurological?.hyporeflexia ? 'hyporeflexia' + ', ' : ''}}
                  {{neurological?.sensory_deficits ? 'Sensory deficits' + ', ' : ''}}
                  {{neurological?.abnormal_gait ? 'Abnormal gait' + ', ' : ''}}
                  {{neurological?.altered_mental_status ? 'Altered mental status' + ', ' : ''}}
                  {{neurological?.other ? 'Other: ' + neurological.other_detail : ''}}
              </span>
                )
              </div>
            </div>
            <div *ngIf="neurological?.type === 'Normal'">
              <div class="font-size-print-16 ml-4">
                <strong>
                  Neurological
                </strong>
                (
                <span>{{neurological?.type_detail ? 'Normal: ' + neurological.type_detail : ''}}</span>
                )
              </div>
            </div>
          </div>
          <!--        Musculoskeletal-->
          <div
            class="row"
            *ngIf="
                              musculoskeletal
                            "
          >
            <div *ngIf="musculoskeletal?.type === 'Abnormal'">
              <div class="font-size-print-16 ml-4">
                <strong>
                  Musculoskeletal
                </strong>
                (
                <span>
               {{musculoskeletal?.limited_range_of_motion ? 'Limited range of motion' + ', ' : ''}}
                  {{musculoskeletal?.tenderness ? 'Tenderness' + ', ' : ''}}
                  {{musculoskeletal?.joint_swelling ? 'Joint Swelling' + ', ' : ''}}
                  {{musculoskeletal?.deformities ? 'Deformities' + ', ' : ''}}
                  {{musculoskeletal?.other_detail ? 'Other: ' + musculoskeletal.other_detail : ''}}
              </span>
                )
              </div>
            </div>
            <div *ngIf="musculoskeletal?.type === 'Normal'">
              <div class="font-size-print-16 ml-4">
                <strong>
                  Musculoskeletal
                </strong>
                (
                <span>{{musculoskeletal?.type_detail ? 'Normal: ' + musculoskeletal.type_detail : ''}}</span>
                )
              </div>
            </div>
          </div>
          <!--        SKIN-->
          <div
            class="row"
            *ngIf="
                              skin
                            "
          >
            <div *ngIf="skin?.type === 'Abnormal'">
              <div class="font-size-print-16 ml-4">
                <strong>
                  Skin
                </strong>
                (
                <span>
               {{skin?.rash ? 'Rash' + ', ' : ''}}
                  {{skin?.lesions ? 'Lesions' + ', ' : ''}}
                  {{skin?.bruising ? 'Bruising' + ', ' : ''}}
                  {{skin?.other_detail ? 'Other: ' + skin.other_detail : ''}}
              </span>
                )
              </div>
            </div>
            <div *ngIf="skin?.type === 'Normal'">
              <div class="font-size-print-16 ml-4">
                <strong>
                  Skin
                </strong>
                (
                <span>{{skin?.type_detail ? 'Normal: ' + skin.type_detail : ''}}</span>
                )
              </div>
            </div>
          </div>
          <!--        Cardiovascular-->
          <div
            class="row mb-4"
            *ngIf="
                              cardiovascular
                            "
          >
            <div *ngIf="cardiovascular?.type === 'Abnormal'">
              <div class="font-size-print-16 ml-4">
                <strong>
                  Cardiovascular
                </strong>
                (
                <span>
                {{cardiovascular?.apex_beat ? 'Apex Beat: ' + cardiovascular.apex_beat + ', ' : ''}}
                  {{cardiovascular?.pulse ? 'Pulses Palpable: ' + cardiovascular.pulse + ', ' : ''}}
                  {{cardiovascular?.gallop ? 'Gallop ' + ', ' : ''}}
                  {{cardiovascular?.edema? 'Edema ' + ', ' : ''}}
                  {{cardiovascular?.heat_sound ? 'Heat Sound: ' + cardiovascular.heat_sound + ', ' : ''}}
                  {{cardiovascular?.mur_mur ? 'MurMur: ' + cardiovascular.mur_mur + ', ' : ''}}
                  {{cardiovascular?.other ? 'Other: ' + cardiovascular.other : ''}}
              </span>
                )
              </div>
            </div>
            <div *ngIf="cardiovascular?.type === 'Normal'">
              <div class="font-size-print-16 ml-4">
                <strong>
                  Cardiovascular
                </strong>
                (
                <span>{{cardiovascular?.type_detail ? 'Normal: ' + cardiovascular.type_detail : ''}}</span>
                )
              </div>
            </div>
          </div>
        </div>
        <!--        Static Case-->
        <ng-container *ngIf="!pelvicExamination && !obstetricalExamination && !chestExamination && !gpe && !abdomen && !heent && !cardiovascular &&  !skin && !neurological && !musculoskeletal">
          <div
            class="row mb-5"
            *ngIf="presControl.module_type === 'static'"
          >
            <div class="col-12">
              <h4 class="para-heading-font-size">
                {{ presControl.heading }}
              </h4>
            </div>
            <div
              [style.height]="presControl.height + 'px'"
            ></div>
          </div>
        </ng-container>
      </div>
    </div>
  </div>
</div>
