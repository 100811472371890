import {Component, Input, OnInit, SimpleChanges} from '@angular/core';
import { OpdService } from '../../../../services/opd.service';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { ConfigurationService } from '../../../../services/configuration.service';
import { StockService } from '../../../../services/stock.service';
import { MedicineConfigService } from '../../../../services/medicine-config.service';

declare var $: any;
declare var toastr: any;
@Component({
  selector: 'app-is-consumable',
  templateUrl: './is-consumable.component.html',
  styleUrls: ['./is-consumable.component.css'],
})
export class IsConsumableComponent implements OnInit {
  @Input() allActiveEmployees: any;
  @Input() patient: any;
  @Input() pageType: any;
  @Input() visitType: any;
  @Input() doseTypeList: any;
  @Input() isIpdMedicineConfigurator: any;
  @Input() isipd: boolean;
  advisedBy = '';
  employeeType = '';
  addMedicine = [];
  paitentMedicineHistory = [];
  forFrequency = false;
  recentMedicineAll = false;
  patientAllHistory = false;
  clearTimeout: any;
  productListData = [];
  productSearchResult = false;
  servicesPrintRecent = true;
  singleMedicineId = '';
  medicine = '';
  doseType = '';
  days = '';
  isConfirmedCancel = false;
  cancelId = '';
  rejectionReason: any;
  doctorComments = '';
  pharmacistComment = '';
  returnMedicineId: any;
  returnMedicineName = '';
  returnQty = 0;
  qtyToReturn = 0;
  constructor(
    private opdService: OpdService,
    private ngxLoader: NgxUiLoaderService,
    private medicineConfigService: MedicineConfigService,
    private stockService: StockService,
  ) {}
  seperateAdvisedBy(data): any {
    let dataValues = data.split('/');
    this.advisedBy = dataValues[0];
    this.employeeType = dataValues[1];
  }
  removeMedicine(ind): any {
    this.addMedicine.splice(ind, 1);
  }
  addMoreMedicine(): any {
    const obj = {
      name: '',
      item_id: '',
      stoc_type_id: '',
      no_of_days: '',
      comments: '',
    };
    this.addMedicine.push(obj);
  }
  setDatesOfMedicines(val): any {
    this.addMedicine = [];
    for (let i = 0; i < 3; i++) {
      this.addMoreMedicine();
      // this.doses[i] = this.doseList;
    }
  }
  addMedicineAgainstPatient(value): any {
    $('#Medicines input, #Medicines select').css('border', '1px solid #E4E6EF');
    if (!this.advisedBy) {
      toastr.error('Select Advised By');
      return;
    }
    for (let i = 0; i < this.addMedicine.length; i++) {
      if (this.addMedicine[i].name === '') {
        $('#medicineNameID' + i).css('border', '1px solid #dc3545');
        // $('#medicineNames' + i).css('border', '1px solid #dc3545');
        return;
      }
      if (this.addMedicine[i].stoc_type_id === '') {
        $('#stockTypeID' + i).css('border', '1px solid #dc3545');
        return;
      }
      if (!this.addMedicine[i].no_of_days) {
        $('#medicineNoOfDay' + i).css('border', '1px solid #dc3545');
        return;
      }
      if (this.isipd) {
        if (
          !this.addMedicine[i].item_id &&
          this.isIpdMedicineConfigurator?.is_medicine_manual_input === false
        ) {
          toastr.error('Please select medicine from the list');
          return;
        }
      }
    }
    this.ngxLoader.start();
    this.opdService
      .assignMedicineToPatientv2(
        this.addMedicine,
        this.patient,
        this.advisedBy,
        this.employeeType,
        true,
      )
      .subscribe(
        (data) => {
          this.getMedicineHistory('');
          if (value === 'submit') {
            this.setDatesOfMedicines('');
          } else {
            this.addMedicine = [];
            this.addMoreMedicine();
          }
          // this.isMedicinesUpdate = Math.random();
          // // @ts-ignore
          // this.isMedicinesRecieved.emit(this.isServicesUpdate);
          // if (value === 'next') {
          //   $('.navi .navi-item .navi-link').removeClass('active');
          //   const element: HTMLElement = document.getElementById(
          //     'ServicesTabs',
          //   ) as HTMLElement;
          //   element.click();
          // }
          this.ngxLoader.stop();
          this.advisedBy = '';
          setTimeout(() => {
            $('.referringDoctors').selectpicker('refresh');
            $('.referringDoctors').selectpicker();
          });
        },
        (err) => {
          this.ngxLoader.stop();
          toastr.error(err.error.message);
        },
      );
  }

  getMedicineHistory(val): any {
    $('#Medicines input').css('border', '1px solid #E4E6EF');
    // this.servicesPrintRecent = true;
    this.ngxLoader.start();
    this.paitentMedicineHistory = [];
    if (this.visitType === 'both') {
      this.visitType = '';
    }
    this.opdService
      .getMedicineHistoryFunv2(
        this.patient,
        this.patientAllHistory,
        this.forFrequency,
        this.visitType,
        true,
      )
      .subscribe(
        (data) => {
          this.paitentMedicineHistory = data.patient_medications;
          this.ngxLoader.stop();
        },
        (err) => {
          this.ngxLoader.stop();
          toastr.error(err.error.message);
        },
      );
  }
  focusOut(index): any {
    setTimeout(() => {
      $('.search-result-main').hide();
      if (
        !this.addMedicine[index].item_id &&
        this.isIpdMedicineConfigurator?.is_medicine_manual_input === false
      ) {
        toastr.error('Please select medicine from the list');
        return;
      }
    }, 300);
  }
  removeSearchString(ind): any {
    this.addMedicine[ind].name = '';
    this.addMedicine[ind].item_id = '';
    $('.quick-search .quick-search-form .quick-search-close').css(
      'display',
      'none',
    );
  }
  getMedicinesOrProductList(ind): any {
    this.getItemsListMain(ind);
  }
  getItemsListMain(ind): any {
    clearTimeout(this.clearTimeout);
    this.clearTimeout = setTimeout(() => {
      this.productListData = [];
      this.productSearchResult = false;
      if (this.addMedicine[ind].name.length < 2) {
        return;
      }
      $('#Medicines #medicineSearchMain' + ind + ' .search-result-main').show();
      this.stockService
        .searchItemsListv2(
          this.addMedicine[ind].stoc_type_id,
          this.addMedicine[ind].name,
          true,
        )
        .subscribe(
          (data) => {
            this.productListData = data.items;
            this.productSearchResult = true;
          },
          (err) => {
            this.productListData = [];
            this.productSearchResult = true;
          },
        );
    }, 1000);
  }
  setProductMain(value, ind): any {
    this.addMedicine[ind].stoc_type_id = value.stoc_type || '';
    this.addMedicine[ind].name = value.product_name;
    this.addMedicine[ind].item_id = value.id;
    this.addMedicine[ind].no_of_days = value.no_of_days || '';
    $('#Medicines #medicineSearchMain' + ind + ' .search-result-main').hide();
  }
  updateServicesPrintRecent(value): any {
    $('#recentMedicineAll').prop('checked', false);
    this.recentMedicineAll = false;
    $('#recentMedicineAlls').prop('checked', false);
    $('#recentServiceAll').prop('checked', false);
    if (value === 'true') {
      this.servicesPrintRecent = true;
    } else {
      this.servicesPrintRecent = false;
    }
  }
  returnMedicines(): any {
    window.open('/patient/issue-item-v2?id=' + this.patient.mrn);
  }
  printRecentMedicineSlip(): any {
    const medicineArray = [];
    if (this.servicesPrintRecent === true) {
      for (let i = 0; i < this.paitentMedicineHistory.length; i++) {
        if (
          this.paitentMedicineHistory[i].recent === true &&
          $('#recentMedicine' + i).prop('checked') === true
        ) {
          medicineArray.push(this.paitentMedicineHistory[i].id);
        }
      }
    }
    if (this.servicesPrintRecent === false) {
      for (let i = 0; i < this.paitentMedicineHistory.length; i++) {
        if (
          this.paitentMedicineHistory[i].recent === false &&
          $('#recentMedicine' + i).prop('checked') === true
        ) {
          medicineArray.push(this.paitentMedicineHistory[i].id);
        }
      }
    }

    if (medicineArray.length === 0) {
      toastr.error('Select Medicine First');
      return;
    }

    if (medicineArray.length > 0) {
      $('.recentMedicine').prop('checked', false);
      $('#recentMedicineAll').prop('checked', false);
      this.recentMedicineAll = false;
      $('#recentMedicineAlls').prop('checked', false);
      const URL =
        '/pdf-reports/patient-medicines-v2?medication_id=' +
        medicineArray +
        '&mrn=' +
        this.patient.mrn +
        '&id=' +
        this.patient.id +
        '&visit_id=' +
        this.patient.visit_id +
        '&invoice_id=' +
        '&is_consumable_item=' +
        true;
      window.open(URL, '_blank');
      // window.open(environment.rorUrl + '/v4/patients/' + this.patient.mrn + '/patient_medications/report.pdf?auth_token=' + localStorage.getItem('auth_token') + '&medical_unit_id=' + localStorage.getItem('current_medical_unit_id') + '&medication_id=' + medicineArray);
    }
  }
  updateMedicines(data): any {
    this.singleMedicineId = data?.id;
    this.medicine = data.item.product_name;
    this.doseType = data.stoc_type_id;
    this.days = data.no_of_days;
    this.pharmacistComment = data.pharmacist_comments;
    this.doctorComments = data.comments;
    $('#updateMedicine').modal('show');
  }
  updateMedicine(): any {
    if (!this.days) {
      toastr.error('Add Days');
      return;
    }
    this.ngxLoader.start();
    this.medicineConfigService
      .updateMedicine(
        this.medicine,
        this.doseType,
        '',
        '',
        '',
        '',
        this.days,
        '',
        '',
        this.pharmacistComment,
        this.singleMedicineId,
      ''
      )
      .subscribe(
        (data) => {
          toastr.success('Update Successfully');
          $('#updateMedicine').modal('hide');
          this.getMedicineHistory('');
          this.ngxLoader.stop();
        },
        (err) => {
          this.ngxLoader.stop();
        },
      );
  }
  cancelMedicine(id): any {
    this.cancelId = id;
    $('#cancelModal').modal('show');
    this.isConfirmedCancel = false;
  }
  cancelConfirm(): any {
    if (!this.isConfirmedCancel) {
      toastr.error('Select Confirmation');
      return;
    }
    this.ngxLoader.start();
    this.medicineConfigService.cancelMedicine(this.cancelId).subscribe(
      (data) => {
        toastr.success('Cancel Successfully');
        $('#cancelModal').modal('hide');
        this.getMedicineHistory('');
        this.ngxLoader.stop();
      },
      (err) => {
        this.ngxLoader.stop();
        toastr.error(err.error.message);
      },
    );
  }
  rejectionReasonModal(value): any {
    this.rejectionReason = value;
    $('#rejectReason').modal('show');
  }
  closeModal(): any {
    $('#rejectReason').modal('hide');
  }
  selectAllRecentMedicine(check): any {
    if (check === true) {
      $('.recentMedicine').prop('checked', true);
    }
    if (check === false) {
      $('.recentMedicine').prop('checked', false);
    }
  }
  returnMedicine(): any {
    const returnQty = Number(this.returnQty);
    if (!returnQty) {
      toastr.error('Return qty cannot be empty or zero');
      return;
    }
    this.ngxLoader.start();
    this.medicineConfigService
      .returnMedicine(this.returnMedicineId, returnQty)
      .subscribe(
        (data) => {
          toastr.success('Return Successfully');
          $('#returnMedicineModal').modal('hide');
          this.getMedicineHistory('');
          this.ngxLoader.stop();
        },
        (err) => {
          this.ngxLoader.stop();
          toastr.error(err.error.message);
        },
      );
  }
  _onlyNumeric(e): any {
    var charCode = e.which ? e.which : e.keyCode;
    if (charCode !== 46 && charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }
  noDecimalPoint(event: any): any {
    return event.charCode >= 48 && event.charCode <= 57;
  }
  returnQtyValue(): any {
    if (this.returnQty > this.qtyToReturn) {
      this.returnQty = this.qtyToReturn;
    }
  }
  returnMedicineModal(value): any {
    this.returnMedicineId = value.id;
    this.returnMedicineName = value.item.product_name;
    this.qtyToReturn = 0;
    this.ngxLoader.start();
    this.medicineConfigService
      .getMedicineQtyForReturn(this.returnMedicineId)
      .subscribe(
        (data) => {
          this.qtyToReturn =
            data.data.total_issued_quantity -
            data.data.total_approved_returned_quantity -
            data.data.total_pending_returned_quantity;
          this.returnQty = this.qtyToReturn;
          this.returnQty = this.returnQty;
          this.ngxLoader.stop();
          $('#returnMedicineModal').modal('show');
          this.ngxLoader.stop();
        },
        (err) => {
          this.ngxLoader.stop();
          toastr.error(err.error.messages);
        },
      );
  }
  ngOnInit(): void {
    this.setDatesOfMedicines('');
    this.getMedicineHistory('');
    this.updateServicesPrintRecent('true');
    setTimeout(() => {
      $('.referringDoctors').selectpicker('refresh');
      $('.referringDoctors').selectpicker('');
    }, 500);
  }
  ngOnChanges(changes: SimpleChanges): any {
    this.getMedicineHistory('');
  }
}
