<div class="table-responsive">
  <div class="p-3 d-flex">
  <div style="width: 28%">
    <div class="d-flex justify-content-between">
      <input
        type="text"
        readonly
        style="text-align: center"
        class="form-control form-control-sm mt-1 medicine_date"
        [(ngModel)]="medicineDate"
        id="medicine_date"
      />
      <button class="btn btn-sm btn-light-primary ml-2">
        <span (click)="getMedicineAdministration('')">Search</span>
      </button>
    </div>
  </div>
  <!--                            <th style="width: 19%;">TDS</th>-->
  <div style="width: 35%"></div>
  <div style="width: 30%" class="pt-0 pb-0">
    <div class="d-flex justify-content-end">
      <div class="mt-2 mr-2">
        <p>Show Discontinued Medicines </p>
      </div>
      <div class="mt-1 mr-2">
            <span class="switch switch-sm switch-icon justify-content-end">
              <label>
                <input type="checkbox"
                       [(ngModel)]="isShowDiscontinued"
                />
                <span></span>
              </label>
            </span>
      </div>
    </div>
  </div>
  <div style="width: 6%" class="pt-0 pb-0">
    <button class="btn btn-sm btn-light-primary text-black">
          <span
            style="font-size: 14px; padding: 1px 3px"
            (click)="printMedicineAdministration()"
          >Print</span
          >
    </button>
  </div>
  </div>
  <table  [ngClass]="{'pointer-events-no': pageType}"
    class="table table-head-custom table-vertical-center mb-0"
    id="kt_advance_table_widget_4"
  >
    <tbody>
      <ng-container *ngFor="let data of medicineAdministration">
        <tr *ngIf="data.status !== 'Discontinued' || isShowDiscontinued">
          <td>
            <span
              class="text-dark-75 font-weight-bolder text-hover-primary mb-1"
              >{{ data?.items }}</span
            >
            <!--                                  <span class="font-weight-bolder text-dark-75 d-block"-->
            <!--                                        style="font-size: 11px">{{data?.patient_medication_frequency?.interval.length}}-->
            <!--                                    Times a day</span>-->
            <span
              class="font-weight-bolder text-dark-65 d-block"
              style="font-size: 11px"
              >{{ data?.no_of_days }} Days({{
                data?.from_date | date: "dd-MM-yyyy"
              }}
              To {{ data?.to_date | date: "dd-MM-yyyy" }})</span
            >
            <span
              class="font-weight-bolder text-dark-65 d-block"
              style="font-size: 11px"
              >Dose: {{ data?.dos_type || data?.dose_other_quantity }}</span
            >
            <span
              class="font-weight-bolder text-dark-65 d-block"
              style="font-size: 11px"
              >Dose Type: {{ data?.stoc_type }}</span
            >
            <span
              class="font-weight-bolder text-dark-65 d-block"
              style="font-size: 11px"
              >Frequency: {{ data?.dosee }}</span
            >
            <span *ngIf="data?.calculated_dose"
              class="font-weight-bolder text-dark-65 d-block"
              style="font-size: 11px"
              >Calculated Dose: {{ data?.calculated_dose }}</span
            >
            <div
              class="font-weight-bolder text-dark-65"
              style="font-size: 11px"
              *ngIf="data?.comments"
            >
              <b>Doctor Comments: {{ data?.comments }}</b>
            </div>
            <div
              class="font-weight-bolder text-dark-65"
              style="font-size: 11px"
              *ngIf="data?.pharmacist_comments"
            >
              <b>Pharmacist Comments: {{ data?.pharmacist_comments }}</b>
            </div>
            <div
              class="label label-lg label-light-danger label-inline d-flex flex-column discStyle"
              *ngIf="data.is_dose_discontinue"
            >
              <div style="margin-bottom: 5px">Discontinued</div>
              <div>
                {{ data?.dose_discontinue_time | date: "dd-MM-yyyy h:mm a" }}
              </div>
            </div>
          </td>
          <td class="row" style="justify-content: center">
            <ng-container
              *ngFor="
                let interval of data?.patient_medication_frequency?.interval
              "
            >
              <div class="m-1">
                <div
                  [ngClass]="{
                    disabled: interval?.is_apply === true
                  }"
                >
                  <span
                    class="btn btn-sm pr-1"
                    [style.background-color]="interval?.time_color"
                    style="padding: 2px 5px"
                    (click)="showTransfusionConfirmationModal(interval, data)"
                  >
                    <span class="" style="font-size: 12px; color: white">{{
                      interval?.time_name
                    }}</span>
                    <span class="d-block" style="font-size: 10px; color: white"
                      >({{ interval?.start_time }} -
                      {{ interval?.end_time }})</span
                    >
                  </span>
                </div>
              </div>
            </ng-container>
          </td>
          <td class="pl-8">
            <ng-container *ngFor="let userData of data?.dose_diffuse">
              <span>{{ data?.time_name }}</span>
              <span
                class="text-dark-75 font-weight-bolder text-hover-primary d-block"
                >{{ userData.interval_name }}</span
              >
              <span class="font-weight-bolder" style="font-size: 10px">{{
                userData?.time | date: "shortTime"
              }}</span
              ><br />
              <span class="pr-1 pl-1 font-weight-bolder" style="font-size: 10px"
                >by</span
              >
              <span class="font-weight-bolder" style="font-size: 10px">{{
                userData.created_by
              }}</span>
              <!--                                  <span class="font-weight-bold text-muted d-block">User ABC</span>-->
              <button
                type="button"
                class="btn btn-light-primary btn-sm d-block"
                style="padding: 2px 5px"
                (click)="showDetailModal(data)"
              >
                Details
              </button>
            </ng-container>
          </td>
          <td class="text-center">
            <!--                                  <ng-container *ngFor="let interval of data?.patient_medication_frequency">-->
            <div class="dropdown dropleft">
              <button
                type="button"
                class="btn btn-light-primary btn-icon btn-sm"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
                [disabled]="data.is_dose_discontinue"
              >
                <i class="ki ki-bold-more-ver"></i>
              </button>
              <div class="dropdown-menu">
                <!--                                      <button class="dropdown-item cursor-pointer">Edit</button>-->
                <button
                  class="dropdown-item cursor-pointer"
                  (click)="showDiscontinueConfirmationModal(data)"
                >
                  Discontinue
                </button>
                <!--                                      <button class="dropdown-item cursor-pointer">Status</button>-->
              </div>
            </div>
            <!--                                  </ng-container>-->
          </td>
        </tr>
      </ng-container>
    </tbody>
  </table>
</div>

<!--Transfusion Medicine Modal-->
<div
  id="transfusionMedicineModal"
  class="modal fade"
  tabindex="-1"
  role="dialog"
>
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title">Medicine Administration Confirmation</h4>
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <i aria-hidden="true" class="ki ki-close"></i>
        </button>
      </div>
      <div class="modal-body">
        <div class="row">
          <div class="col-md-12">
            <div class="d-flex">
              <div class="form-group">
                <label>Emp ID:<span class="text-danger">*</span></label>
                <input
                  type="text"
                  class="form-control form-control-sm"
                  placeholder="Employee ID"
                  name="empId"
                  [(ngModel)]="empId"
                />
              </div>
              <div class="form-group pl-8">
                <label>User Password:<span class="text-danger">*</span></label>
                <input
                  type="password"
                  class="form-control form-control-sm"
                  placeholder="Password"
                  name="pasword"
                  [(ngModel)]="userPassword"
                />
              </div>
            </div>
            <div class="checkbox-inline">
              <label class="checkbox">
                <input
                  type="checkbox"
                  name="Checkbox1"
                  [(ngModel)]="isConfimed"
                />
                <span></span>
              </label>
              Are you sure selected medicine is transfused?
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer p-3">
        <button type="button" class="btn btn-default" data-dismiss="modal">
          Close
        </button>
        <button
          type="submit"
          class="btn btn-primary"
          (click)="confirmTransfusion()"
        >
          Confirm
        </button>
        <!--        <button type="submit" [disabled]="isButtonClicked" class="btn btn-primary" (click)="deleteGeneral()">Confirm</button>-->
      </div>
    </div>
  </div>
</div>

<!-- Modal-->
<div
  id="historyDetail"
  class="modal fade"
  tabindex="-1"
  role="dialog"
  aria-labelledby="exampleModalLabel"
  aria-hidden="true"
>
  <div class="modal-dialog modal-xl modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabelnew">
          Medicine Administration History
        </h5>
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <!--      <ng-container *ngFor="let medDetail of medicineDeail">-->
      <div class="modal-body">
        <div class="row">
          <div class="col-6">
            <div class="form-group">
              <div class="d-flex">
                <span class="mr-4"
                  ><label><strong>Medicine Name:</strong></label></span
                >
                <span>{{ medicineDetail?.items }}</span>
              </div>
              <div class="d-flex">
                <span class="mr-4"
                  ><label><strong>Start Date:</strong></label></span
                >
                <span>{{ medicineDetail?.from_date }}</span>
              </div>
              <div class="d-flex">
                <span class="mr-4"
                  ><label><strong>End Date:</strong></label></span
                >
                <span>{{ medicineDetail?.to_date }}</span>
              </div>
              <div class="d-flex">
                <span class="mr-4"
                  ><label><strong>No. of Days:</strong></label></span
                >
                <span>{{ medicineDetail?.no_of_days }}</span>
              </div>
            </div>
          </div>
          <div class="col-6">
            <div class="d-flex">
              <span class="mr-4"
                ><label
                  ><strong>Medication Frequency Name:</strong></label
                ></span
              >
              <span>{{
                medicineDetail?.patient_medication_frequency?.name
              }}</span>
            </div>
            <div class="d-flex">
              <span class="mr-4"
                ><label
                  ><strong>Medication Frequency Code:</strong></label
                ></span
              >
              <span>{{
                medicineDetail?.patient_medication_frequency?.code
              }}</span>
            </div>
            <div class="d-flex">
              <span class="mr-4"
                ><label><strong>Status:</strong></label></span
              >
              <span *ngIf="medicineDetail?.is_dose_discontinue === true"
                >Discontinue</span
              >
              <span *ngIf="medicineDetail?.is_dose_discontinue === false"
                >Continue</span
              >
            </div>
          </div>
        </div>
      </div>
      <!--      </ng-container>-->

      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-dismiss="modal">
          Close
        </button>
      </div>
    </div>
  </div>
</div>

<!--Discontinue Medicine Modal-->
<div
  id="discontinueMedicineModal"
  class="modal fade"
  tabindex="-1"
  role="dialog"
>
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title">Medicine discontinue Confirmation</h4>
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <i aria-hidden="true" class="ki ki-close"></i>
        </button>
      </div>
      <div class="modal-body">
        <div class="row">
          <div class="col-md-12">
            <div class="d-flex">
              <div class="form-group">
                <label>Emp ID:<span class="text-danger">*</span></label>
                <input
                  type="text"
                  class="form-control form-control-sm"
                  placeholder="Employe ID"
                  name="empId"
                  [(ngModel)]="empId"
                />
              </div>
              <div class="form-group pl-8">
                <label>User Password:<span class="text-danger">*</span></label>
                <input
                  type="password"
                  class="form-control form-control-sm"
                  placeholder="Password"
                  name=""
                  [(ngModel)]="userPassword"
                />
              </div>
            </div>
            <div class="checkbox-inline">
              <label class="checkbox">
                <input
                  type="checkbox"
                  name="Checkbox1"
                  [(ngModel)]="isConfimedDiscontinue"
                />
                <span></span>
              </label>
              Are you sure you want to Discontinue selected Medicine?
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer p-3">
        <button type="button" class="btn btn-default" data-dismiss="modal">
          Close
        </button>
        <button
          type="submit"
          class="btn btn-primary"
          (click)="confirmDiscontinue()"
        >
          Confirm
        </button>
        <!--        <button type="submit" [disabled]="isButtonClicked" class="btn btn-primary" (click)="deleteGeneral()">Confirm</button>-->
      </div>
    </div>
  </div>
</div>
