import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { environment } from '../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class HrService {
  constructor(private http: HttpClient) {}

  private imageSource = new BehaviorSubject('');
  currentImage = this.imageSource.asObservable();

  private jobApplicationImageSource = new BehaviorSubject('');
  currentJobApplicationImage = this.jobApplicationImageSource.asObservable();

  emitImage(data): any {
    this.imageSource.next(data);
  }

  emitIJobApplicationImage(data): any {
    this.jobApplicationImageSource.next(data);
  }

  searchfilterMethod(
    current_page,
    per_page,
    department,
    designation,
    employeeName,
  ): Observable<any> {
    const results = this.http
      .get(
        `${environment.pythonUrl}/hr/employee_list?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&page=' +
          current_page +
          '&per_page=' +
          per_page +
          '&department=' +
          department +
          '&designation=' +
          designation +
          '&name=' +
          employeeName,
        {
          headers: new HttpHeaders({
            // AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  searchLatestEmployeesList(
    current_page,
    per_page,
    department,
    designation,
    employeeName,
    employeeFileId,
  ): Observable<any> {
    const results = this.http
      .get(
        `${environment.pythonUrl}/hr/get_main_employee_list?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&page=' +
          current_page +
          '&per_page=' +
          per_page +
          '&department=' +
          department +
          '&designation=' +
          designation +
          '&name=' +
          employeeName +
          '&employee_file_no=' +
          employeeFileId,
        {
          headers: new HttpHeaders({
            // AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  searchLatestAllEmployeesList(
    current_page,
    per_page,
    department,
    designation,
    employee_file_no,
    employeeName,
    state,
    employeeCategory,
  ): Observable<any> {
    const results = this.http
      .get(
        `${environment.pythonUrl}/hr/get_all_main_employee_list?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&page=' +
          current_page +
          '&per_page=' +
          per_page +
          '&department=' +
          department +
          '&designation=' +
          designation +
          '&employee_file_no=' +
          employee_file_no +
          '&name=' +
          employeeName +
          '&status=' +
          state +
          '&employee_category=' +
          employeeCategory,
        {
          headers: new HttpHeaders({
            // AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  updateStatus(list): Observable<any> {
    const results = this.http
      .post(
        `${environment.pythonUrl}/pharmacy/status_update?auth_token=` +
          localStorage.getItem('auth_token'),
        {
          medical_unit_id: localStorage.getItem('current_medical_unit_id'),
          list,
        },
        {
          headers: new HttpHeaders({
            // 'AuthenticationToken': localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  policy_control_setting(value): Observable<any> {
    const results = this.http
      .post(
        `${environment.pythonUrl}/hr/hr_policy_control_setting?auth_token=` +
          localStorage.getItem('auth_token'),
        {
          medical_unit_id: localStorage.getItem('current_medical_unit_id'),
          value,
        },
        {
          headers: new HttpHeaders({
            // 'AuthenticationToken': localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  getcountries(): Observable<any> {
    const results = this.http
      .get(
        `${environment.rorUrl}/v4/countries?auth_token=` +
          localStorage.getItem('auth_token'),
        {
          headers: new HttpHeaders({
            // //'AuthenticationToken': localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  submitEmployee(values): Observable<any> {
    const results = this.http
      .post(
        `${environment.pythonUrl}/hr/employee_list?auth_token=` +
          localStorage.getItem('auth_token'),
        {
          medical_unit_id: localStorage.getItem('current_medical_unit_id'),
          values,
        },
        {
          headers: new HttpHeaders({
            // //'AuthenticationToken': localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  getStaffList(employeeId): Observable<any> {
    const results = this.http
      .get(
        `${environment.pythonUrl}/hr/employee_detail?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&id=' +
          employeeId,
        {
          headers: new HttpHeaders({
            // //'AuthenticationToken': localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }

  getEmployeeSignature(employeeId): Observable<any> {
    const results = this.http
      .get(
        `${environment.pythonUrl}/hr/employee_signature_settings?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&id=' +
          employeeId,
        {
          headers: new HttpHeaders({
            // //'AuthenticationToken': localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  updateEmployee(value, id): Observable<any> {
    const results = this.http
      .put(
        `${environment.pythonUrl}/hr/employee_detail?auth_token=` +
          localStorage.getItem('auth_token') +
          '&id=' +
          id,
        {
          medical_unit_id: localStorage.getItem('current_medical_unit_id'),
          value,
        },
        {
          headers: new HttpHeaders({
            // //'AuthenticationToken': localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  updateEmployeeSignatureSettings(value, id): Observable<any> {
    const results = this.http
      .put(
        `${environment.pythonUrl}/hr/employee_signature_settings?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&id=' +
          id,
        {
          short_name: value.short_name,
          qualification: value.qualification,
          certification: value.certification,
          is_signature: value.is_signature,
        },
        {
          headers: new HttpHeaders({
            // //'AuthenticationToken': localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  addPersonalInformation(values, employeeId): Observable<any> {
    const results = this.http
      .post(
        `${environment.pythonUrl}/hr/employee_personal_information?auth_token=` +
          localStorage.getItem('auth_token') +
          '&id=' +
          employeeId,
        {
          medical_unit_id: localStorage.getItem('current_medical_unit_id'),
          values,
        },
        {
          headers: new HttpHeaders({
            // //'AuthenticationToken': localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  getPersonalInformation(employeeId): Observable<any> {
    const results = this.http
      .get(
        `${environment.pythonUrl}/hr/employee_personal_information_detail?auth_token=` +
          localStorage.getItem('auth_token') +
          '&id=' +
          employeeId,
        {
          headers: new HttpHeaders({
            // //'AuthenticationToken': localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  employeeList(): Observable<any> {
    const results = this.http
      .get(
        `${environment.pythonUrl}/hr/get_employee_list?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          headers: new HttpHeaders({
            // //'AuthenticationToken': localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  employeeListNew(): Observable<any> {
    const results = this.http
      .get(
        `${environment.pythonUrl}/hr/get_all_employee_list?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          headers: new HttpHeaders({
            // //'AuthenticationToken': localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  employeeListForSlots(): Observable<any> {
    const results = this.http
      .get(
        `${environment.pythonUrl}/reservation/employee_list?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          headers: new HttpHeaders({
            // //'AuthenticationToken': localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }

  addRepoartingHierarchy(value, employeeId): Observable<any> {
    const results = this.http
      .post(
        `${environment.pythonUrl}/hr/reporting_hierarchy?auth_token=` +
          localStorage.getItem('auth_token') +
          '&id=' +
          employeeId,
        {
          medical_unit_id: localStorage.getItem('current_medical_unit_id'),
          value,
        },
        {
          headers: new HttpHeaders({
            // //'AuthenticationToken': localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  getReportingDetails(employeeId): Observable<any> {
    const results = this.http
      .get(
        `${environment.pythonUrl}/hr/reporting_hierarchy?auth_token=` +
          localStorage.getItem('auth_token') +
          '&id=' +
          employeeId,
        {
          headers: new HttpHeaders({
            // 'AuthenticationToken': localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  inactiveReportingHierarchy(id, key): Observable<any> {
    const results = this.http
      .put(
        `${environment.pythonUrl}/hr/reporting_hierarchy_detail?auth_token=` +
          localStorage.getItem('auth_token'),
        {
          medical_unit_id: localStorage.getItem('current_medical_unit_id'),
          id,
          key,
        },
        {
          headers: new HttpHeaders({
            // 'AuthenticationToken': localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  adddependants(value, employeeId): Observable<any> {
    const results = this.http
      .post(
        `${environment.pythonUrl}/hr/employee_dependant_detail?auth_token=` +
          localStorage.getItem('auth_token') +
          '&id=' +
          employeeId,
        {
          medical_unit_id: localStorage.getItem('current_medical_unit_id'),
          value,
        },
        {
          headers: new HttpHeaders({
            // 'AuthenticationToken': localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }

  addContracts(value, empId): Observable<any> {
    const results = this.http
      .post(
        `${environment.pythonUrl}/hr/employee_contract_detail?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&id=' +
          empId,
        {
          medical_unit_id: localStorage.getItem('current_medical_unit_id'),
          value,
        },
        {
          headers: new HttpHeaders({
            // 'AuthenticationToken': localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  getdependantDetails(employeeId): Observable<any> {
    const results = this.http
      .get(
        `${environment.pythonUrl}/hr/employee_dependant_detail?auth_token=` +
          localStorage.getItem('auth_token') +
          '&id=' +
          employeeId,
        {
          headers: new HttpHeaders({
            // 'AuthenticationToken': localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }

  getContractList(empId): Observable<any> {
    const results = this.http
      .get(
        `${environment.pythonUrl}/hr/employee_contract_detail?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&id=' +
          empId,
        {
          headers: new HttpHeaders({
            // 'AuthenticationToken': localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  getSingledependantDetail(id): Observable<any> {
    const results = this.http
      .get(
        `${environment.pythonUrl}/hr/get_employee_dependant_detail?auth_token=` +
          localStorage.getItem('auth_token') +
          '&id=' +
          id,
        {
          headers: new HttpHeaders({
            // 'AuthenticationToken': localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  updatedependants(value, id): Observable<any> {
    const results = this.http
      .put(
        `${environment.pythonUrl}/hr/get_employee_dependant_detail?auth_token=` +
          localStorage.getItem('auth_token') +
          '&id=' +
          id,
        {
          medical_unit_id: localStorage.getItem('current_medical_unit_id'),
          value,
        },
        {
          headers: new HttpHeaders({
            // 'AuthenticationToken': localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  addEducation(value, employeeId): Observable<any> {
    const results = this.http
      .post(
        `${environment.pythonUrl}/hr/employee_educational_detail?auth_token=` +
          localStorage.getItem('auth_token') +
          '&id=' +
          employeeId,
        {
          medical_unit_id: localStorage.getItem('current_medical_unit_id'),
          value,
        },
        {
          headers: new HttpHeaders({
            // 'AuthenticationToken': localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  educationList(employeeId): Observable<any> {
    const results = this.http
      .get(
        `${environment.pythonUrl}/hr/employee_educational_detail?auth_token=` +
          localStorage.getItem('auth_token') +
          '&id=' +
          employeeId,
        {
          headers: new HttpHeaders({
            // 'AuthenticationToken': localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  getSingleeducationDetail(id): Observable<any> {
    const results = this.http
      .get(
        `${environment.pythonUrl}/hr/get_employee_educational_detail?auth_token=` +
          localStorage.getItem('auth_token') +
          '&id=' +
          id,
        {
          headers: new HttpHeaders({
            // 'AuthenticationToken': localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  updateEducation(value, id): Observable<any> {
    const results = this.http
      .put(
        `${environment.pythonUrl}/hr/get_employee_educational_detail?auth_token=` +
          localStorage.getItem('auth_token') +
          '&id=' +
          id,
        {
          medical_unit_id: localStorage.getItem('current_medical_unit_id'),
          value,
        },
        {
          headers: new HttpHeaders({
            // 'AuthenticationToken': localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  addProfessionalExperience(value, employeeId): Observable<any> {
    const results = this.http
      .post(
        `${environment.pythonUrl}/hr/employee_professional_experience?auth_token=` +
          localStorage.getItem('auth_token') +
          '&id=' +
          employeeId,
        {
          medical_unit_id: localStorage.getItem('current_medical_unit_id'),
          value,
        },
        {
          headers: new HttpHeaders({
            // 'AuthenticationToken': localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  ProfessionalExperienceList(employeeId): Observable<any> {
    const results = this.http
      .get(
        `${environment.pythonUrl}/hr/employee_professional_experience?auth_token=` +
          localStorage.getItem('auth_token') +
          '&id=' +
          employeeId,
        {
          headers: new HttpHeaders({
            // 'AuthenticationToken': localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  getSingleProfessionalExperienceDetail(id): Observable<any> {
    const results = this.http
      .get(
        `${environment.pythonUrl}/hr/employee_professional_experience_detail?auth_token=` +
          localStorage.getItem('auth_token') +
          '&id=' +
          id,
        {
          headers: new HttpHeaders({
            // 'AuthenticationToken': localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  updateProfessionalExperience(value, id): Observable<any> {
    const results = this.http
      .put(
        `${environment.pythonUrl}/hr/employee_professional_experience_detail?auth_token=` +
          localStorage.getItem('auth_token') +
          '&id=' +
          id,
        {
          medical_unit_id: localStorage.getItem('current_medical_unit_id'),
          value,
        },
        {
          headers: new HttpHeaders({
            // 'AuthenticationToken': localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }

  submitLeaves(value, employeeId): Observable<any> {
    const results = this.http
      .post(
        `${environment.pythonUrl}/hr/employee_leave_setting?auth_token=` +
          localStorage.getItem('auth_token') +
          '&id=' +
          employeeId,
        {
          medical_unit_id: localStorage.getItem('current_medical_unit_id'),
          value,
        },
        {
          headers: new HttpHeaders({
            // 'AuthenticationToken': localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  getLeaves(employeeId): Observable<any> {
    const results = this.http
      .get(
        `${environment.pythonUrl}/hr/employee_leave_setting?auth_token=` +
          localStorage.getItem('auth_token') +
          '&id=' +
          employeeId,
        {
          headers: new HttpHeaders({
            // 'AuthenticationToken': localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  getDoctorApprovalByHrListing(status): Observable<any> {
    const results = this.http
      .get(
        `${environment.pythonUrl}/api/get_doctors_for_approval/?auth_token=` +
          localStorage.getItem('auth_token') + '&medical_unit_id=' + localStorage.getItem('current_medical_unit_id') +
          '&status=' +
        status,
        {
          headers: new HttpHeaders({
            // 'AuthenticationToken': localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  changeDoctorApprovalStatus(status, id): Observable<any> {
    const results = this.http
      .post(
        `${environment.pythonUrl}/api/get_doctors_for_approval/?auth_token=` +
        localStorage.getItem('auth_token') + '&medical_unit_id=' + localStorage.getItem('current_medical_unit_id'),
        {
          medical_unit_id: localStorage.getItem('current_medical_unit_id'),
          id,
          approval_status: status,
        },
        {
          headers: new HttpHeaders({
            // 'AuthenticationToken': localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  changeAssociatedWithDr(doctor, id, approvalStatus): Observable<any> {
    const results = this.http
      .post(
        `${environment.pythonUrl}/api/get_doctors_for_approval/?auth_token=` +
        localStorage.getItem('auth_token') + '&medical_unit_id=' + localStorage.getItem('current_medical_unit_id'),
        {
          medical_unit_id: localStorage.getItem('current_medical_unit_id'),
          id,
          associated_with: doctor,
          approval_status: approvalStatus,
        },
        {
          headers: new HttpHeaders({
            // 'AuthenticationToken': localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  getLoginRoles(): Observable<any> {
    const results = this.http
      .get(
        `${environment.rorUrl}/v4/roles?auth_token=` +
          localStorage.getItem('auth_token'),
        {
          headers: new HttpHeaders({
            // 'AuthenticationToken': localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  addLoginInformation(value, employeeId): Observable<any> {
    const results = this.http
      .post(
        `${environment.pythonUrl}/hr/create_user_login?auth_token=` +
          localStorage.getItem('auth_token') +
          '&id=' +
          employeeId,
        {
          medical_unit_id: localStorage.getItem('current_medical_unit_id'),
          value,
        },
        {
          headers: new HttpHeaders({
            // 'AuthenticationToken': localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  getLoginDetails(employeeId): Observable<any> {
    const results = this.http
      .get(
        `${environment.pythonUrl}/hr/get_login_detail?auth_token=` +
          localStorage.getItem('auth_token') +
          '&id=' +
          employeeId,
        {
          headers: new HttpHeaders({
            // 'AuthenticationToken': localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  submitReferenceForm(value, employeeId): Observable<any> {
    const results = this.http
      .post(
        `${environment.pythonUrl}/hr/employee_reference?auth_token=` +
          localStorage.getItem('auth_token') +
          '&id=' +
          employeeId,
        {
          medical_unit_id: localStorage.getItem('current_medical_unit_id'),
          value,
        },
        {
          headers: new HttpHeaders({
            // 'AuthenticationToken': localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  getReferenceListing(employeeId): Observable<any> {
    const results = this.http
      .get(
        `${environment.pythonUrl}/hr/employee_reference?auth_token=` +
          localStorage.getItem('auth_token') +
          '&id=' +
          employeeId,
        {
          headers: new HttpHeaders({
            // 'AuthenticationToken': localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  getLeavePolicyList(per_page): Observable<any> {
    const results = this.http
      .get(
        `${environment.pythonUrl}/hr/leave_policy?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&per_page=' +
          per_page,
        {
          headers: new HttpHeaders({
            // //'AuthenticationToken': localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  getLeavePolicyDetail(id): Observable<any> {
    const results = this.http
      .get(
        `${environment.pythonUrl}/hr/get_leave_policy_detail?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&id=' +
          id,
        {
          headers: new HttpHeaders({
            // //'AuthenticationToken': localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  getTaxationMainListing(per_page): Observable<any> {
    const results = this.http
      .get(
        `${environment.pythonUrl}/hr/taxation_list?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&per_page=' +
          per_page,
        {
          headers: new HttpHeaders({
            // 'AuthenticationToken': localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  getEmployeeRolesList(): Observable<any> {
    let results = this.http
      .get(
        `${environment.pythonUrl}/hr/get_employee_roles?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          headers: new HttpHeaders({
            //'AuthenticationToken': localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  getLoanFlowPolicy(): Observable<any> {
    let results = this.http
      .get(
        `${environment.pythonUrl}/hr/get_loan_flow_policy?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          headers: new HttpHeaders({
            //'AuthenticationToken': localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  getLeaveFlowPolicy(): Observable<any> {
    let results = this.http
      .get(
        `${environment.pythonUrl}/hr/get_leave_flow_policy?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          headers: new HttpHeaders({
            //'AuthenticationToken': localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  updateEmployeePersonalForInventoryPolicy(
    values,
    employeeId,
  ): Observable<any> {
    const results = this.http
      .put(
        `${environment.pythonUrl}/hr/update_employee_personal_for_inventory_policy?auth_token=` +
          localStorage.getItem('auth_token') +
          '&id=' +
          employeeId,
        {
          medical_unit_id: localStorage.getItem('current_medical_unit_id'),
          values,
        },
        {
          headers: new HttpHeaders({
            // //'AuthenticationToken': localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
}
