import {Component, Input, OnInit, SimpleChanges} from '@angular/core';
import {environment} from '../../../../environments/environment';

declare var $: any;

@Component({
  selector: 'app-dynamic-search',
  templateUrl: './dynamic-search.component.html',
  styleUrls: ['./dynamic-search.component.css']
})
export class DynamicSearchComponent implements OnInit {
  @Input() formControl: any;
  @Input() index: number;
  @Input() apiUrl: any;
  @Input() multiple: boolean;
  @Input() editable: boolean;

  constructor() {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.index || changes.control) {
      this.initializeSelect2();
    }
  }

  initializeSelect2(): void {
    setTimeout(() => {
      const select2Id = `#select2-${this.index}`;
      const select2Element = $(select2Id);

      console.log(`Initializing Select2 for ${select2Id}`);

      // Destroy existing Select2 instance
      if (select2Element.hasClass('select2-hidden-accessible')) {
        console.log(`Destroying existing Select2 instance for ${select2Id}`);
        select2Element.select2('destroy');
      }

      // Initialize Select2
      select2Element.select2({
        width: '300px', // Fixed width
        tags: this.editable,
        ajax: {
          url: `${environment.pythonUrl}${this.apiUrl}`,
          type: 'GET',
          dataType: 'json',
          delay: 250,
          data: (params: any) => ({
            auth_token: localStorage.getItem('auth_token'),
            search_string: params.term || '',
            medical_unit_id: localStorage.getItem('current_medical_unit_id'),
          }),
          processResults: (data: any) => ({
            results: data.map((item: any) => ({
              id: item.id,
              text: item.name,
            })),
          }),
          cache: true,
        },
        placeholder: 'Select products',
        minimumInputLength: 1,
        multiple: this.multiple,
        dropdownParent: $(select2Element.parent()),
      });

      // Update FormControl on change
      select2Element.on('change', () => {
        const selectedData = select2Element.select2('data'); // Get selected values
        const selectedIds = selectedData.map((item: any) => item.id);
        // Set value in FormControl
        if (this.multiple) {
          this.formControl.setValue(selectedIds || []); // Set array for multiple
        } else {
          this.formControl.setValue(selectedIds ? selectedIds[0] : '');
        }

      });
      // // Patch initial values if ids are provided
      // if (this.ids && this.ids.length > 0) {
      //   console.log(`Setting initial values for ${select2Id}:`, this.ids);
      //   select2Element.val(this.ids).trigger('change'); // Set initial selected values
      // }
      // Set initial value for Select2
      if (this.formControl.value) {
        console.log(`Setting initial value for ${select2Id}:`, this.formControl.value);
        select2Element.val(this.formControl.value).trigger('change');
      }
    }, 100);
  }
  ngOnInit(): void {
    this.initializeSelect2();
  }
}
