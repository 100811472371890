import { Injectable } from '@angular/core';
import {environment} from '../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class VersionManagerService {
  appVersion = environment.appVersion;

  // Compare versions and handle the result
  handleVersionCheck(newVersion: string): void {
    if (newVersion) {
      if (newVersion === this.appVersion) {
        return;
      } else {
        this.clearCacheAndReload();
      }
    }
  }

  // Clear cache and perform a hard reload
  clearCacheAndReload(): void {
    if ('caches' in window) {
      caches.keys().then((cacheNames) => {
        return Promise.all(cacheNames.map((cache) => {
          return caches.delete(cache);
        }));
      })
        .then(() => {
          setTimeout(() => {
            window.location.reload();
          }, 200);
        });
    } else {
      console.warn('Caches not supported in this browser.');
    }
  }

}
