import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  OnInit,
  ViewChild,
} from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { AuthService } from '../../services/auth.service';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { filter } from 'rxjs/operators';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { HospitalService } from '../../services/hospital.service';
import { MustMatch } from '../../custom-pipes/match-password.validator';
import { ProfileService } from '../../services/profile.service';
import {VersionManagerService} from '../../version-manager';

declare var toastr: any;
declare var $: any;
@Component({
  selector: 'app-login-v2',
  templateUrl: './login-v2.component.html',
  styleUrls: ['./login-v2.component.css'],
})
export class LoginV2Component implements OnInit {
  // @ViewChild('resetUsername') resetUsername: ElementRef;
  // @ViewChild('resetPassword') resetPassword: ElementRef;

  resetPasswordForm: UntypedFormGroup;
  loginForm: UntypedFormGroup;
  isSubmit = false;
  isForgot = false;
  passwordResetForm: UntypedFormGroup;
  isSubmitPass = false;
  isShowLogin = false;
  newPassIcon = false;
  newConfirmPassIcon = false;
  hospitalName: any;
  userObj = {
    userId: '',
    authToken: '',
  };

  previousUrl: any;
  homeImage = '';
  organizationName: any;
  backgroundImage: '';
  subDomain: any;
  medicalUnitId: any;
  constructor(
    private fb: UntypedFormBuilder,
    private authService: AuthService,
    private route: ActivatedRoute,
    private router: Router,
    private ngxLoader: NgxUiLoaderService,
    private chRef: ChangeDetectorRef,
    private modalService: NgbModal,
    private hospitalService: HospitalService,
    private profileService: ProfileService,
    private versionManager: VersionManagerService,
  ) {
    const currentUrl = window.location.href;
    this.subDomain = this.getSubdomainFromUrl(currentUrl);
    if (this.subDomain === '') {
      this.subDomain = 'demo';
    }
    // if ( this.subDomain === 'hims' ) {
    //   this.subDomain = '';
    // }
    this.ngxLoader.stop();
    if (this.authService.isAuthenticated()) {
      let userData = JSON.parse(localStorage.getItem('currentUser'));
      if (userData.is_super_admin === true) {
        this.router.navigate(['superadmin']);
      } else {
        this.router.navigate(['dashboard']);
      }
    }
  }

  ngOnInit(): void {
    this.getSettingsData();
    this.loginForm = this.fb.group({
      email: ['', Validators.compose([Validators.required])],
      password: [
        '',
        Validators.compose([Validators.required, Validators.minLength(3)]),
      ],
    });
    this.resetPasswordForm = this.fb.group({
      email: [
        '',
        Validators.compose([
          Validators.required,
          Validators.email,
          Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,8}$'),
        ]),
      ],
    });

    this.passwordResetForm = this.fb.group(
      {
        newPassword: ['', [Validators.required, Validators.minLength(8)]],
        newPasswordConfirm: ['', [Validators.required]],
      },
      {
        validator: MustMatch('newPassword', 'newPasswordConfirm'),
      },
    );
  }
  getSubdomainFromUrl(url: string): string {
    const hostname = new URL(url).hostname;
    const ipAddressPattern = /^(?:[0-9]{1,3}\.){3}[0-9]{1,3}$/;

    if (ipAddressPattern.test(hostname)) {
      return '';
    } else {
      const parts = hostname.split('.');
      if (parts.length > 2) {
        return parts[0];
      } else {
        return '';
      }
    }
  }
  getSettingsData(): any {
    // console.log('in1');
    this.hospitalService.getMedicalUnitHomeImageV2(this.subDomain).subscribe(
      (data) => {
        this.homeImage = data.homepage_image;
        this.organizationName = data.organization_name_for_login_page;
        this.backgroundImage = data.organization_image_for_login_page;
        this.medicalUnitId = data.medical_unit_id;
      },
      (err) => {},
    );
  }

  submit(): any {
    this.isSubmit = true;
    if (this.loginForm.invalid) {
      return;
    }
    this.ngxLoader.start();
    this.authService
      .loginv2(
        this.loginForm.value.email,
        this.loginForm.value.password,
        this.medicalUnitId,
      )
      .subscribe(
        (data) => {
          console.log('closure', data.user.closure);
          if (data.user.closure === true) {
            $('#promissionsAlertModal').modal('show');
            return;
          }
          if (data.user.is_password_reset === true) {
            this.userObj.userId = data.user.id;
            this.userObj.authToken = data.user.password_token;
            toastr.success('Update your password first');
            this.isShowLogin = true;
            this.ngxLoader.stop();
            return;
          } else {
            localStorage.setItem('currentUser', JSON.stringify(data.user));
            localStorage.setItem('auth_token', data.user.authentication_token);
            localStorage.setItem(
              'current_medical_unit_id',
              data.user.medical_units[0].id,
            );
            localStorage.setItem('is_session_expired', 'false');
            const obj = {
              date_format:
                data.user?.medical_units[0]?.date_format || 'MM-DD-YYYY',
              time_format: data.user?.medical_units[0]?.time_format || 'h:mm a',
              date_time_format:
                data.user?.medical_units[0]?.date_time_format ||
                'DD-MM-YYYY h:mm a',
            };
            localStorage.setItem('dateTimeFormats', JSON.stringify(obj));
            localStorage.setItem(
              'currency_format',
              data.user?.medical_units[0]?.currency_format,
            );
            // localStorage.setItem('currentScreen', JSON.stringify(data.user.role.role_menus[0]));
            if (data.user.is_super_admin === true) {
              this.router.navigate(['superadmin']);
            } else {
              this.router.navigate(['dashboard']);
            }
          }
          this.versionManager.handleVersionCheck(data.user?.app_version);
          this.ngxLoader.stop();
        },
        (error) => {
          this.ngxLoader.stop();
          toastr.error(error.error.message);
        },
      );
  }

  changeEyeIconPassword(): any {
    this.newPassIcon = !this.newPassIcon;
  }
  changeEyeIconConfirmPassword(): any {
    this.newConfirmPassIcon = !this.newConfirmPassIcon;
  }
  onUpdate(): any {
    this.isSubmitPass = true;
    if (this.passwordResetForm.invalid) {
      return;
    }
    if (!this.userObj.userId || !this.userObj.authToken) {
      return;
    }
    this.ngxLoader.start();
    this.profileService
      .updateUserPassword(
        this.userObj,
        this.passwordResetForm.value.newPassword,
      )
      .subscribe(
        (data) => {
          this.changeEyeIconPassword();
          this.changeEyeIconConfirmPassword();
          toastr.success('Successfully Updated Password');
          localStorage.setItem('currentUser', JSON.stringify(data.user));
          localStorage.setItem('auth_token', data.user.authentication_token);
          localStorage.setItem(
            'current_medical_unit_id',
            data.user.medical_units[0].id,
          );
          localStorage.setItem('is_session_expired', 'false');
          if (data.user.is_super_admin === true) {
            this.router.navigate(['superadmin']);
          } else {
            this.router.navigate(['dashboard']);
          }
          this.ngxLoader.stop();
        },
        (err) => {
          this.ngxLoader.stop();
          toastr.error(err.error.message);
        },
      );
  }

  submitForgotPass(): any {
    this.isForgot = true;
    if (this.resetPasswordForm.invalid) {
      return;
    }
    this.ngxLoader.start();
    this.authService
      .forgotPasswordV2(this.resetPasswordForm.value.email)
      .subscribe(
        (data) => {
          toastr.success(data.message);
          $('#resetpassword').modal('hide');
          this.ngxLoader.stop();
        },
        (error) => {
          this.ngxLoader.stop();
          toastr.error(error.error.message);
        },
      );
  }

  get f(): any {
    return this.loginForm.controls;
  }
  get fUpdate(): any {
    return this.passwordResetForm.controls;
  }
  get cont(): any {
    return this.resetPasswordForm.controls;
  }

  // closeUsernameModal(): any {
  //   this.modalService.dismissAll(this.resetUsername);
  // }
  //
  // openUsernameModal(): any {
  //   this.modalService.open(this.resetUsername);
  // }

  // closePasswordModal(): any {
  //   this.modalService.dismissAll(this.resetPassword);
  // }

  openPasswordModal(): any {
    $('#resetpassword').modal('show');
    // $('#resetpassword').modal({
    //   show: true,
    //   backdrop: 'static',
    //   keyboard: false
    // });
  }

  forgotUser(): any {
    $('#resetusername').modal('hide');
    $('#resetpassword').modal('hide');
  }

  resetForgotPassForm(): any {
    this.isForgot = false;
    this.resetPasswordForm.reset();
  }
}
