import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { map } from 'rxjs/operators';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable()
export class SystemControlsService {
  constructor(private http: HttpClient) {}

  // getUpdatedChecks(): Observable<any> {
  //   const results = this.http.get(`${environment.rorUrl}/v4/users/sidebar_data?auth_token=` + localStorage.getItem('auth_token') + '&medical_unit_id=' + localStorage.getItem('current_medical_unit_id')
  //     , {headers: new HttpHeaders({
  //         AuthenticationToken: localStorage.getItem('auth_token'),
  //         Accept: 'application/json'
  //       })}).pipe(map(data => data));
  //   return results;
  // }
  getUpdatedChecks(): Observable<any> {
    const results = this.http
      .get(
        `${environment.pythonUrl}/api/sidebar_data?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }

  getServicesTypeConfig(): Observable<any> {
    const results = this.http
      .get(
        `${environment.rorUrl}/v4/service_type_configurators?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  // Python start
  getServicesTypeConfigV2(): Observable<any> {
    const results = this.http
      .get(
        `${environment.pythonUrl}/api/service_type_configurator/?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  getPartialServices(patientId): Observable<any> {
    const results = this.http
      .get(
        `${environment.pythonUrl}/api/patient_services/partial_payment_details_against_patient/?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') + '&patient_id=' + patientId,
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }

  getFrontDeskControlData(requiredFields): Observable<any> {
    const results = this.http
      .get(
        `${environment.pythonUrl}/api/system_front_desk_control/dynamic_front_desk_control_data/?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&fields=' + requiredFields,
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  // Python End
  updateServicesTypeConfig(data): Observable<any> {
    let results = this.http
      .put(
        `${environment.rorUrl}/v4/service_type_configurators/update_service_type_configurators`,
        {
          auth_token: localStorage.getItem('auth_token'),
          medical_unit_id: localStorage.getItem('current_medical_unit_id'),
          service_type_configurators: data,
        },
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  // Submit Send To claim Control on FS
  updateSendToClaimOnFs(data, id): Observable<any> {
    let results = this.http
      .put(
        `${environment.pythonUrl}/api/system_finance_control/` + id + '/?auth_token=' +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          auth_token: localStorage.getItem('auth_token'),
          medical_unit_id: localStorage.getItem('current_medical_unit_id'),
          system_finance_controls: data,
        },
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
// Get Send To claim Control on FS
  getSendToClaimOnFsControl(): Observable<any> {
    const results = this.http
      .get(
        `${environment.pythonUrl}/api/system_finance_control/?auth_token=` +
        localStorage.getItem('auth_token') +
        '&medical_unit_id=' +
        localStorage.getItem('current_medical_unit_id'),
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  updateServicesTypeConfigv2(data): Observable<any> {
    let results = this.http
      .put(
        `${environment.pythonUrl}/api/update_service_type_configurators/136/?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          auth_token: localStorage.getItem('auth_token'),
          medical_unit_id: localStorage.getItem('current_medical_unit_id'),
          service_type_configurators: data,
        },
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  saveFrontDeskControlTemplate(systemFrontDeskControl, id): Observable<any> {
    let results = this.http
      .put(
        `${environment.pythonUrl}/api/system_front_desk_control/` +
          id +
          `/?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          auth_token: localStorage.getItem('auth_token'),
          medical_unit_id: localStorage.getItem('current_medical_unit_id'),
          system_front_desk_control: systemFrontDeskControl,
        },
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  saveLateInControlData(lateIn, leaveType, remainingDeductionFrom, id): Observable<any> {
    let results = this.http
      .post(
        `${environment.pythonUrl}/api/hr_control/?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          auth_token: localStorage.getItem('auth_token'),
          medical_unit_id: localStorage.getItem('current_medical_unit_id'),
          number_of_late_in: lateIn,
          leave_type: leaveType,
          remaining_deduction_from: remainingDeductionFrom,
          id
        },
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  updatePmtStore(store): Observable<any> {
    let results = this.http
      .post(
        `${environment.pythonUrl}/api/any/?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          auth_token: localStorage.getItem('auth_token'),
          medical_unit_id: localStorage.getItem('current_medical_unit_id'),
          store
        },
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  getFrontDeskControlTemplateData(): Observable<any> {
    const results = this.http
      .get(
        `${environment.pythonUrl}/api/system_front_desk_control/?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  getHrLateInControlsData(): Observable<any> {
    const results = this.http
      .get(
        `${environment.pythonUrl}/api/hr_control/?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  getPmtStore(): Observable<any> {
    const results = this.http
      .get(
        `${environment.pythonUrl}/api/any/?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  getRadiologyControl(): Observable<any> {
    const results = this.http
      .get(
        `${environment.rorUrl}/v4/rad_controls?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  getRadiologyControlV2(): Observable<any> {
    const results = this.http
      .get(
        `${environment.pythonUrl}/api/rad_controls/?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  updateRadiologyControl(data): Observable<any> {
    let results = this.http
      .put(
        `${environment.rorUrl}/v4/rad_controls/update_rad_controls`,
        {
          auth_token: localStorage.getItem('auth_token'),
          medical_unit_id: localStorage.getItem('current_medical_unit_id'),
          rad_controls: data,
        },
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  updateRadiologyControlv2(data): Observable<any> {
    let results = this.http
      .put(
        `${environment.pythonUrl}/api/rad_controls/` +
          localStorage.getItem('current_medical_unit_id') +
          '/?auth_token=' +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          auth_token: localStorage.getItem('auth_token'),
          medical_unit_id: localStorage.getItem('current_medical_unit_id'),
          rad_controls: data,
        },
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }

  getPrescriptionControlByPdf(visitId): Observable<any> {
    const results = this.http
      .get(
        `${environment.rorUrl}/v4/visits/fetch_controls?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&visit_id=' +
          visitId,
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  getPrescriptionControlByPdfV2(visitId): Observable<any> {
    const results = this.http
      .get(
        `${environment.pythonUrl}/opd/fetch_controls/?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&visit_id=' +
          visitId,
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  getPrescriptionControl(): Observable<any> {
    const results = this.http
      .get(
        `${environment.rorUrl}/v4/visits/fetch_controls?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&visit_type=opd',
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  getPrescriptionControlV2(type): Observable<any> {
    const results = this.http
      .get(
        `${environment.pythonUrl}/opd/fetch_controls/?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&visit_type='+type,
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }

  getPressControl(): Observable<any> {
    const results = this.http
      .get(
        `${environment.rorUrl}/v4/fet_conts?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&visit_type=surgery',
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  getPrescriptionControlTempleteWise(templete): Observable<any> {
    const results = this.http
      .get(
        `${environment.rorUrl}/v4/prescription_configurators?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&prescription_template_id=' +
          templete,
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  getPrescriptionControlTempleteWiseV2(templete): Observable<any> {
    const results = this.http
      .get(
        `${environment.pythonUrl}/api/prescription_configurators/?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&prescription_template_id=' +
          templete,
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  getPatientHeaderControlTemplate(templete): Observable<any> {
    const results = this.http
      .get(
        `${environment.pythonUrl}/report/add_report_header_config/?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&report_header_template_id=' +
          templete,
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  getPatientHeaderControlTemplateByUrl(): Observable<any> {
    const url = window.location.pathname;
    const results = this.http
      .get(
        `${environment.pythonUrl}/report/get_report_header_by_screen/?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&screen_url=' +
          url,
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  updatePrescriptionControl(
    data,
    template,
    footer,
    header,
    header_default_height,
    footer_default_height,
    barCode,
    QrCode,
    showMrn,
    medicineAsMedicine,
  ): Observable<any> {
    let results = this.http
      .post(
        `${environment.rorUrl}/v4/prescription_configurators`,
        {
          auth_token: localStorage.getItem('auth_token'),
          medical_unit_id: localStorage.getItem('current_medical_unit_id'),
          prescription_configurators: data,
          prescription_template_id: template,
          show_footer: footer,
          show_header: header,
          header_height: header_default_height,
          footer_height: footer_default_height,
          show_barcode: barCode,
          show_qrcode: QrCode,
          show_mrn: showMrn,
          medicine_as_tabular: medicineAsMedicine,
        },
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }

  updatePrescriptionControlV2(
    data,
    template,
    footer,
    header,
    header_default_height,
    footer_default_height,
    barCode,
    QrCode,
    showMrn,
    medicineAsMedicine,
  ): Observable<any> {
    let results = this.http
      .post(
        `${environment.pythonUrl}/api/prescription_configurators/?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          prescription_configurators: data,
          prescription_template_id: template,
          show_footer: footer,
          show_header: header,
          header_height: header_default_height,
          footer_height: footer_default_height,
          show_barcode: barCode,
          show_qrcode: QrCode,
          show_mrn: showMrn,
          medicine_as_tabular: medicineAsMedicine,
        },
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  updatePrescriptionControlHeaderControl(
    data,
    template,
    footer,
    header,
    header_default_height,
    footer_default_height,
    barCode,
    QrCode,
    showMrn,
    showPatientPicture,
    showPatientThumbScan,
    showSummary,
    selectedTemplateStatus,
  ): Observable<any> {
    let results = this.http
      .post(
        `${environment.pythonUrl}/report/add_report_header_config/?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          auth_token: localStorage.getItem('auth_token'),
          medical_unit_id: localStorage.getItem('current_medical_unit_id'),
          report_header_configurators: data,
          report_header_template: template,
          show_footer: footer,
          show_header: header,
          header_height: header_default_height,
          footer_height: footer_default_height,
          show_barcode: barCode,
          show_qrcode: QrCode,
          show_mrn: showMrn,
          show_patient_picture: showPatientPicture,
          show_patient_thumb_scan: showPatientThumbScan,
          show_summary: showSummary,
          status: selectedTemplateStatus,
        },
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  addPrescriptionTemplete(data): Observable<any> {
    let results = this.http
      .post(
        `${environment.rorUrl}/v4/prescription_templates`,
        {
          auth_token: localStorage.getItem('auth_token'),
          medical_unit_id: localStorage.getItem('current_medical_unit_id'),
          prescription_template: data,
        },
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  addPrescriptionControlsTemplateV2(data): Observable<any> {
    let results = this.http
      .post(
        `${environment.pythonUrl}/api/prescription_control_templates/?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          prescription_template: data,
        },
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  addPrescriptionTempleteV2(data): Observable<any> {
    let results = this.http
      .post(
        `${environment.pythonUrl}/report/add_report_header_template/?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          auth_token: localStorage.getItem('auth_token'),
          medical_unit_id: localStorage.getItem('current_medical_unit_id'),
          report_template: data,
        },
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  getPrescriptionTemplete(): Observable<any> {
    const results = this.http
      .get(
        `${environment.rorUrl}/v4/prescription_templates?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&status=' +
          true,
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  getPrescriptionControlsTemplateV2(): Observable<any> {
    const results = this.http
      .get(
        `${environment.pythonUrl}/api/prescription_control_templates?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&status=' +
          true,
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  getPrescriptionTempletev1(): Observable<any> {
    const results = this.http
      .get(
        `${environment.pythonUrl}/api/prescription_templates?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&status=' +
          true,
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  getReportHeaderTemplateV2(status): Observable<any> {
    const results = this.http
      .get(
        `${environment.pythonUrl}/report/add_report_header_template/?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&status=' +
          status,
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
}
