import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {NgxUiLoaderService} from 'ngx-ui-loader';
import {BloodBankService} from '../../../services/blood-bank.service';
import {DialysisService} from '../../../services/dialysis.service';
import {Router} from '@angular/router';
import {UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {HospitaldoctorService} from '../../../services/hospitaldoctor.service';
import {DepartmentsbyhospitalService} from '../../../services/departmentsbyhospital.service';
import * as moment from "moment";
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {el} from "date-fns/locale";

declare var $: any;
declare var toastr: any;

@Component({
  selector: 'app-dialysis-doctor-notes',
  templateUrl: './dialysis-doctor-notes.component.html',
  styleUrls: ['./dialysis-doctor-notes.component.css']
})
export class DialysisDoctorNotesComponent implements OnInit  {
  DoctorNotesForm: UntypedFormGroup;

  @ViewChild('treatmentCompleteAndDischargeAlert') treatmentCompleteAndDischargeAlert: ElementRef;

  questionnaires: any = [];
  query = '';
  queryType = 'default';
  dialysisRequests = [];
  currentPatientData: any;
  currentPatientIndex: any;
  patient: any;
  screenTestCheck = false;
  doctor = [];
  department: any;
  departmentId: any;
  finalQuestionareList = [];
  requisitionId: number;
  dischargeStatus = 'normal';
  dischargeTypeComments = '';
  doctorSpecialty: number;
  patientData: any;
  DialysisDocNotesId: any;
  selectedPatient: any;
  isSubmitted = false;
  isShowFistula = false;
  isShowCvLine = false;
  isEmergency = false;

  constructor(
    private ngxLoader: NgxUiLoaderService,
    private bloodBankService: BloodBankService,
    private dialysisService: DialysisService,
    private router: Router,
    private fb: UntypedFormBuilder,
    private hospitalDoctorsService: HospitaldoctorService,
    private departmentService: DepartmentsbyhospitalService,
    private modalService: NgbModal,
  ) {
  }
  ngOnInit(): void {
    this.DoctorNotesForm = this.fb.group({
      hcv: [''],
      // hcv_donor_value: [''],
      // hcv_cutoff_value: ['1.0'],
      hiv: [''],
      // hiv_donor_value: [''],
      // hiv_cutoff_value: ['1.0'],
      hbs_ag: [''],
      pcr: [''],
      // hbs_ag_donor_value: [''],
      // hbs_ag_cutoff_value: ['0.5'],
      // vdrc_cutoff_value: ['1.0'],
      // vdrc: [''],
      // vdrc_donor_value: [''],
      // mp: [''],
      // hb: [''],
      platelets: [''],
      is_fistula_complicated: [false],
      is_cv_line_complicated: [false],
      department_id: [''],
      doctor_id: [''],
      consultant_visit: [''],
      diagnosis: [''],
      fistula_complications: [''],
      cv_line_complications: [''],
    });
    setTimeout(() => {
      $('#consultingDetails').summernote({
        height: 100,
        toolbar: [
          ['style', ['bold', 'italic', 'underline']],
          ['font', ['strikethrough', 'superscript', 'subscript']],
          ['fontsize', ['fontsize']],
          ['color', ['color']],
          ['para', ['ul', 'ol', 'paragraph']],
        ],
      });
      $('#DiagnosisDetails').summernote({
        height: 100,
        toolbar: [
          ['style', ['bold', 'italic', 'underline']],
          ['font', ['strikethrough', 'superscript', 'subscript']],
          ['fontsize', ['fontsize']],
          ['color', ['color']],
          ['para', ['ul', 'ol', 'paragraph']],
        ],
      });
      $('#fistulaComplications').summernote({
        height: 100,
        toolbar: [
          ['style', ['bold', 'italic', 'underline']],
          ['font', ['strikethrough', 'superscript', 'subscript']],
          ['fontsize', ['fontsize']],
          ['color', ['color']],
          ['para', ['ul', 'ol', 'paragraph']],
        ],
      });
      $('#cvLineComplications').summernote({
        height: 100,
        toolbar: [
          ['style', ['bold', 'italic', 'underline']],
          ['font', ['strikethrough', 'superscript', 'subscript']],
          ['fontsize', ['fontsize']],
          ['color', ['color']],
          ['para', ['ul', 'ol', 'paragraph']],
        ],
      });
    }, 500);
    this.fetchQuestionnaires();
    this.fetchDialysisRequests();
    this.fetchDoctor();
    this.fetchDepartments();
  }
  fetchDoctorDepartments(id): any {
    if (id) {
      const dep_id = this.doctor.filter((t) => t.id === Number(id))[0]
        .doctor_department_id;
      this.departmentId = dep_id;
      if (dep_id) {
        this.DoctorNotesForm.get('department_id').patchValue(dep_id);
      } else {
        this.DoctorNotesForm.get('department_id').patchValue('');
      }
      setTimeout(() => {
        $('#ipdConsultingDepartment').selectpicker('refresh');
        $('#ipdConsultingDepartments').selectpicker('refresh');
      }, 500);
      setTimeout(() => {
        $('#ipdConsultingDepartment').selectpicker();
        $('#ipdConsultingDepartments').selectpicker();
      }, 500);
    }
  }
  updateCheck(check): any{
    this.isShowFistula = check;
    setTimeout(() => {
      $('#fistulaComplications').summernote({
        height: 100,
        toolbar: [
          ['style', ['bold', 'italic', 'underline']],
          ['font', ['strikethrough', 'superscript', 'subscript']],
          ['fontsize', ['fontsize']],
          ['color', ['color']],
          ['para', ['ul', 'ol', 'paragraph']],
        ],
      });
    }, 100);
  }
  updateLineCheck(check): any{
    this.isShowCvLine = check;
    setTimeout(() => {
      $('#cvLineComplications').summernote({
        height: 100,
        toolbar: [
          ['style', ['bold', 'italic', 'underline']],
          ['font', ['strikethrough', 'superscript', 'subscript']],
          ['fontsize', ['fontsize']],
          ['color', ['color']],
          ['para', ['ul', 'ol', 'paragraph']],
        ],
      });
    }, 100);
  }
  fetchDeptDoctors(id): any {
    this.doctor = [];
    const objIds = '#ipdConsultingdoctors';
    setTimeout(() => {
      $(objIds).selectpicker('refresh');
    }, 500);
    this.ngxLoader.start();
    this.hospitalDoctorsService.fetchDepartmentDoctorListV2(id).subscribe(
      (data) => {
        this.doctor = data.users;
        this.ngxLoader.stop();
        setTimeout(() => {
          $('.doctorSelectpicker').selectpicker('refresh');
          $('.dropdown-menu').css({
            'max-height': '220px',
            'overflow-y': 'auto',
          });
        }, 500);
        this.DoctorNotesForm.get('doctor_id').patchValue('');
      },
      (err) => {
        this.ngxLoader.stop();
        toastr.error(err.error.message);
      },
    );
  }
  fetchDoctor(): any {
    this.ngxLoader.start();
    this.hospitalDoctorsService.fetchOpdConsultationDoctorV2().subscribe(
      (data) => {
        this.doctor = data.users;
        this.ngxLoader.stop();
        setTimeout(() => {
          $('.doctorSelectpicker').selectpicker('refresh');
          $('.dropdown-menu').css({
            'max-height': '220px',
            'overflow-y': 'auto',
          });
        }, 500);
      },
      (err) => {
        this.ngxLoader.stop();
      },
    );
  }
  fetchDepartments(): any {
    this.ngxLoader.start();
    this.departmentService.indexDepartmentsv2().subscribe(
      (data) => {
        this.department = data.departments;
        this.ngxLoader.stop();
        setTimeout(() => {
          $('.departSelectpicker').selectpicker('refresh');
          $('.dropdown-menu').css({
            'max-height': '220px',
            'overflow-y': 'auto',
          });
        }, 500);
      },
      (err) => {
        this.ngxLoader.stop();
      },
    );
  }
  fetchDialysisRequests(): any{
    this.ngxLoader.start();
    this.dialysisService
      .fetchDialysisRequestsV2(this.queryType, this.query)
      .subscribe(
        (data) => {
          this.dialysisRequests = data.dialysis_requests;
          this.ngxLoader.stop();
        },
        (err) => {
          this.ngxLoader.stop();
          this.dialysisRequests = [];
          toastr.error(err.error.message);
        },
      );
  }
  fetchQuestionnaires(): any {
    this.questionnaires = [];
    this.ngxLoader.start();
    this.bloodBankService
      .getQuestionnaires('', '')
      .subscribe(
        (data) => {
          this.questionnaires = data.questionnaires;
          this.questionnaires.forEach(questionnaire => {
            questionnaire.is_active = false;
          });
          this.ngxLoader.stop();
        },
        (err) => {
          this.ngxLoader.stop();
        },
      );
  }

  setQuery(e): any {
    this.query = e.target.value;
  }

  setQueryType(e): any {
    this.queryType = e.target.value;
  }

  fetchPatientValues(dialysisRequest, ind): any {
    this.currentPatientData = dialysisRequest;
    this.currentPatientIndex = ind;
    this.ngxLoader.start();
    this.dialysisService
      .fetchPatientData(this.currentPatientData)
      .subscribe(
        (data) => {
          this.patientData = data.dialysis_doc_notes;
          this.patchData(this.patientData);
          this.ngxLoader.stop();
        },
        (err) => {
          this.ngxLoader.stop();
          this.dialysisRequests = [];
          toastr.error(err.error.message);
        },
      );
  }

  patchData(value): any{
    this.selectedPatient = value;
    this.DialysisDocNotesId = value.id || '';
    this.DoctorNotesForm.patchValue({
      hcv: value.hcv || '',
      // hcv_donor_value: value.hcv_donor_value || '',
      // hcv_cutoff_value: value.hcv_cutoff_value || '',
      hiv: value.hiv || '',
      // hiv_donor_value: value.hiv_donor_value || '',
      // hiv_cutoff_value: value.hiv_cutoff_value || '',
      hbs_ag: value.hbs_ag || '',
      pcr: value.pcr || '',
      is_fistula_complicated: value.is_fistula_complicated,
      is_cv_line_complicated: value.is_cv_line_complicated,
      // hbs_ag_donor_value: value.hbs_ag_donor_value || '',
      // hbs_ag_cutoff_value: value.hbs_ag_cutoff_value || '',
      // vdrc_cutoff_value: value.vdrc_cutoff_value || '',
      // vdrc: value.vdrc || '',
      // vdrc_donor_value: value.vdrc_donor_value || '',
      // mp: value.mp || '',
      // hb: value.hb || '',
      platelets: value.platelets || '',
      department_id: value.department_id || '',
      doctor_id: value.doctor_id || '',
    });
    setTimeout(() => {
      if (value.question) {
        for (let i = 0; i < this.questionnaires.length; i++) {
          for (let j = 0; j < value.question.length; j++) {
            if (
              this.questionnaires[i].id === value.question[j]
            ) {
              $('#singleCheck' + i).prop('checked', true);
            }
          }
        }
      }
    }, 500);
    this.updateCheck(value.is_fistula_complicated);
    this.updateLineCheck(value.is_cv_line_complicated);
    setTimeout(() => {
      $('#consultingDetails').summernote('code', '');
      $('#DiagnosisDetails').summernote('code', '');
      $('#fistulaComplications').summernote('code', '');
      $('#cvLineComplications').summernote('code', '');
      $('#consultingDetails').summernote('code', value?.consultant_visit);
      $('#DiagnosisDetails').summernote('code', value?.diagnosis);
      $('#fistulaComplications').summernote('code', value?.fistula_complications);
      $('#cvLineComplications').summernote('code', value?.cv_line_complications);
      $('#ipdConsultingDepartment').selectpicker('refresh');
      $('#ipdConsultingDoctors').selectpicker('refresh');
    }, 500);
  }

  routePatientDialysis(): any{
    this.router.navigate(['dialysis/patient-dialysis-v2']);
  }

  sendRequest(type): any {
  }
  _onlyNumericWithSingleDot(e: any): any {
    var charCode = e.which ? e.which : e.keyCode;

    if (e.target.value.includes('.')) {
      if (e.key === '.') {
        return false;
      }
    }

    if (
      charCode !== 190 &&
      charCode !== 96 &&
      charCode !== 97 &&
      charCode !== 98 &&
      charCode !== 99 &&
      charCode !== 100 &&
      charCode !== 101 &&
      charCode !== 102 &&
      charCode !== 103 &&
      charCode !== 104 &&
      charCode !== 105 &&
      charCode !== 46 &&
      charCode !== 110 &&
      charCode > 31 &&
      (charCode < 48 || charCode > 57)
    ) {
      return false;
    }
    return true;
  }
  _onlyNumeric(event: any): any {
    const pattern = /[0-9 .]/;
    const inputChar = String.fromCharCode(event.charCode);

    if (!pattern.test(inputChar)) {
      // invalid character, prevent input
      event.preventDefault();
    }
  }
  saveData(): any{
    $('#treatmentCompleteAndDischargeAlert').modal('show');
    // this.modalService.open(this.treatmentCompleteAndDischargeAlert, { size: 'lg' });
    this.dischargeTypeComments = this.selectedPatient.discharge_type_comments;
    this.dischargeStatus = this.selectedPatient.discharge_type;
    this.isEmergency = this.selectedPatient.is_emr;
  }
  submit(): any {
    this.finalQuestionareList = [];
    for (let i = 0; i < this.questionnaires?.length; i++) {
      if ($('#singleCheck' + i).is(':checked')) {
        this.finalQuestionareList.push(this.questionnaires[i]);
      }
    }
    this.DoctorNotesForm.get('consultant_visit').setValue($('#consultingDetails').summernote('code'));
    this.DoctorNotesForm.get('diagnosis').setValue($('#DiagnosisDetails').summernote('code'));
    if (this.DoctorNotesForm.get('is_fistula_complicated').value === true) {
      this.DoctorNotesForm.get('fistula_complications').setValue($('#fistulaComplications').summernote('code'));
    } else {
      this.DoctorNotesForm.get('fistula_complications').setValue('');
    }
    if (this.DoctorNotesForm.get('is_cv_line_complicated').value === true){
      this.DoctorNotesForm.get('cv_line_complications').setValue($('#cvLineComplications').summernote('code'));
    } else {
      this.DoctorNotesForm.get('cv_line_complications').setValue('');
    }
    this.isSubmitted = true;
    this.ngxLoader.start();
    this.dialysisService
      .createDoctorNotes(
        this.DoctorNotesForm.value,
        this.currentPatientData.patient.id,
        this.finalQuestionareList,
        this.currentPatientData.id,
        this.currentPatientData.visit,
        this.dischargeStatus,
        this.dischargeTypeComments,
        this.isEmergency,
        this.DialysisDocNotesId)
      .subscribe(
        (data) => {
          toastr.success('Saved Successfully');
          // this.modalService.dismissAll(this.treatmentCompleteAndDischargeAlert);
          this.closeModal();
          this.isSubmitted = false;
          this.ngxLoader.stop();
        },
        (err) => {
          this.ngxLoader.stop();
          this.isSubmitted = false;
          toastr.error(err.error.message);
        },
      );
  }
  closeModal(): any{
    $('#treatmentCompleteAndDischargeAlert').modal('hide');
  }

  clearForm(): any{
    this.DoctorNotesForm = this.fb.group({
      hcv: [''],
      hcv_donor_value: [''],
      hcv_cutoff_value: ['1.0'],
      hiv: [''],
      hiv_donor_value: [''],
      hiv_cutoff_value: ['1.0'],
      hbs_ag: [''],
      hbs_ag_donor_value: [''],
      hbs_ag_cutoff_value: ['0.5'],
      vdrc_cutoff_value: ['1.0'],
      vdrc: [''],
      vdrc_donor_value: [''],
      mp: [''],
      hb: [''],
      platelets: [''],
      department_id: [''],
      doctor_id: [''],
      consultant_visit: [''],
      diagnosis: [''],
      fistula_complications: [''],
      cv_line_complications: [''],
    });
    $('#consultingDetails').summernote('code', '');
    $('#DiagnosisDetails').summernote('code', '');
    $('#fistulaComplications').summernote('code', '');
    $('#cvLineComplications').summernote('code', '');
  }
}
