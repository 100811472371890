import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class CheckListConfiguratorSettingService {
  constructor(private http: HttpClient) {}

  // Check_configurator_API_Start
  addCheck(value): Observable<any> {
    let results = this.http
      .post(
        `${environment.rorUrl}/v4/check_list_templates?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          name: value.name,
          // status: value.status,
        },
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    // console.log(results);
    return results;
  }
  // Check_configurator_API_Start V2
  addCheckV2(value): Observable<any> {
    let results = this.http
      .post(
        `${environment.pythonUrl}/check_list/check_list_templates/?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          name: value.name,
          type: value.type,
          show_created_by: value.show_created_by,
          show_printed_by: value.show_printed_by,
          show_signature: value.show_signature,
          // status: value.status,
        },
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    // console.log(results);
    return results;
  }

  // Update Check
  updateCheck(value, id): Observable<any> {
    let results = this.http
      .put(
        `${environment.rorUrl}/v4/check_list_templates/` +
          id +
          `?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          name: value.name,
          // status: value.status,
        },
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    // console.log(results);
    return results;
  }
  // Update Check V2
  updateCheckV2(value, id): Observable<any> {
    let results = this.http
      .put(
        `${environment.pythonUrl}/check_list/check_list_templates/` +
          id +
          `/?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          name: value.name,
          type: value.type,
          show_created_by: value.show_created_by,
          show_printed_by: value.show_printed_by,
          show_signature: value.show_signature,
          // status: value.status,
        },
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    // console.log(results);
    return results;
  }

  // Listing
  getCheckNameList(page, per_page): Observable<any> {
    let results = this.http
      .get(
        `${environment.rorUrl}/v4/check_list_templates?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&page=' +
          page +
          '&per_page=' +
          per_page,
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    // console.log(results);
    return results;
  }
  // Listing V2
  getCheckNameListV2(type): Observable<any> {
    let results = this.http
      .get(
        `${environment.pythonUrl}/check_list/check_list_templates/?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') + '&type=' + type,
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    // console.log(results);
    return results;
  }
  // consent Report Data
  getConsentReportData(id): Observable<any> {
    let results = this.http
      .get(
        `${environment.pythonUrl}/api/check_list_data/template_print/?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') + '&id=' + id,
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    // console.log(results);
    return results;
  }
  // patient registration Consent Listing V2
  getConsentListing(patient): Observable<any> {
    let results = this.http
      .get(
        `${environment.pythonUrl}/api/check_list_data/?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') + '&patient_id=' + patient?.id + '&visit_id=' + patient?.visit_id,
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    // console.log(results);
    return results;
  }

  // Check_configurator_API_End
  // Check_List_Setting_Start
  // Headings API
  addHeading(check_list_heading): Observable<any> {
    let results = this.http
      .post(
        `${environment.rorUrl}/v4/check_list_headings?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          check_list_heading,
        },
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    // console.log(results);
    return results;
  }
  // Headings API V2
  addHeadingV2(check_list_heading): Observable<any> {
    let results = this.http
      .post(
        `${environment.pythonUrl}/check_list/check_list_headings/?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          check_list_heading,
        },
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    // console.log(results);
    return results;
  }

  deleteHeading(headingId, type): Observable<any> {
    let results = this.http
      .put(
        `${environment.pythonUrl}/check_list/check_list_templates_configurator/remove_check_list/?auth_token=` +
        localStorage.getItem('auth_token') +
        '&medical_unit_id=' +
        localStorage.getItem('current_medical_unit_id'),
        {
          id: headingId,
          type: type,
        },
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    // console.log(results);
    return results;
  }

  // Check-List-Setting-Configurator
  // Post Api
  addCheckToTheList(
    name,
    check_list_type,
    check_list_values_attributes,
    check_list_heading_id
  ): Observable<any> {
    let results = this.http
      .post(
        `${environment.rorUrl}/v4/check_list_templates_configurator?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          name: name,
          check_list_type: check_list_type,
          check_list_values_attributes: check_list_values_attributes,
          check_list_heading_id: check_list_heading_id,
        },
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    // console.log(results);
    return results;
  }
  // Check-List-Setting-Configurator V2
  // Post Api V2
  addCheckToTheListV2(
    name,
    check_list_type,
    isMandotory,
    defaultValue,
    isReadonly,
    check_list_values_attributes,
    check_list_heading_id
  ): Observable<any> {
    let results = this.http
      .post(
        `${environment.pythonUrl}/check_list/check_list_templates_configurator/?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          name: name,
          check_list_type: check_list_type,
          default_value: defaultValue,
          is_mandatory: isMandotory,
          is_readonly: isReadonly,
          check_list_values_attributes: check_list_values_attributes,
          check_list_heading_id: check_list_heading_id,
        },
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    // console.log(results);
    return results;
  }

  // Put Api check setting
  updateCheckToTheList(
    name,
    check_list_type,
    check_list_values_attributes,
    id
  ): Observable<any> {
    let results = this.http
      .put(
        `${environment.rorUrl}/v4/check_list_templates_configurator/` +
          id +
          `?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          name: name,
          check_list_type: check_list_type,
          check_list_values_attributes: check_list_values_attributes,
        },
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    // console.log(results);
    return results;
  }
  // Put Api check setting V2
  updateCheckToTheListV2(
    name,
    check_list_type,
    isMandotory,
    defaultValue,
    isReadonly,
    check_list_values_attributes,
    id
  ): Observable<any> {
    let results = this.http
      .put(
        `${environment.pythonUrl}/check_list/check_list_templates_configurator/` +
          id +
          `/?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          name: name,
          check_list_type: check_list_type,
          is_mandatory: isMandotory,
          default_value: defaultValue,
          is_readonly: isReadonly,
          check_list_values_attributes: check_list_values_attributes,
        },
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    // console.log(results);
    return results;
  }

  // Listing Api
  checkListing(check_list_template_id): Observable<any> {
    let results = this.http
      .get(
        `${environment.rorUrl}/v4/check_list_headings?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&check_list_template_id=' +
          check_list_template_id,
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    // console.log(results);
    return results;
  }
  // Listing Api V2
  checkListingV2(check_list_template_id): Observable<any> {
    let results = this.http
      .get(
        `${environment.pythonUrl}/check_list/check_list_headings/?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&check_list_template_id=' +
          check_list_template_id,
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    // console.log(results);
    return results;
  }

  // Save Order Api
  saveOrderNo(check_list_headings): Observable<any> {
    let results = this.http
      .put(
        `${environment.rorUrl}/v4/check_list_templates_configurator/update_order_suborder?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          check_list_headings,
        },
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    // console.log(results);
    return results;
  }
  // Save Order Api V2
  saveOrderNoV2(check_list_headings): Observable<any> {
    let results = this.http
      .put(
        `${environment.pythonUrl}/check_list/check_list_templates_configurator/update_order_suborder/?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          check_list_headings,
        },
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    // console.log(results);
    return results;
  }

  // Update Headings
  updateHeading(check_list_heading, headId): Observable<any> {
    let results = this.http
      .put(
        `${environment.rorUrl}/v4/check_list_headings/` +
          headId +
          `?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          check_list_heading,
        },
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    // console.log(results);
    return results;
  }
  // Update Headings V2
  updateHeadingV2(check_list_heading, headId): Observable<any> {
    let results = this.http
      .put(
        `${environment.pythonUrl}/check_list/check_list_headings/` +
          headId +
          `/?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          check_list_heading,
        },
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    // console.log(results);
    return results;
  }

  checkListGroupById(check_list_template_id): Observable<any> {
    let results = this.http
      .get(
        `${environment.rorUrl}/v4/check_list_headings/temp_config_data_order_wise?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&check_list_template_id=' +
          check_list_template_id,
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    // console.log(results);
    return results;
  }

  // Check_List_Setting_API_End
}
