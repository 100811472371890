import { NgModule } from '@angular/core';
import {CommonModule, DatePipe} from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbModal, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgbPaginationModule } from '@ng-bootstrap/ng-bootstrap';

import { DialysisRoutingModule } from './dialysis-routing.module';
import { DialysisRequestComponent } from './dialysis-request/dialysis-request.component';
import { EmployeeRegistrationService } from '../../services/employee-registration.service';
import { HospitaldoctorService } from '../../services/hospitaldoctor.service';
import { DepartmentsbyhospitalService } from '../../services/departmentsbyhospital.service';
import { PatientService } from '../../services/patient.service';
import { DialysisService } from '../../services/dialysis.service';
import { StockService } from '../../services/stock.service';
import { DefaultDialysisConfigComponent } from './default-dialysis-config/default-dialysis-config.component';
import { ReportsReprintComponent } from './reports-reprint/reports-reprint.component';
import { PatientDialysisV2Component } from './patient-dialysis-v2/patient-dialysis-v2.component';
import { DialysisServicesV2Component } from './dialysis-services-v2/dialysis-services-v2.component';
import { DialysisServicesModalComponent } from './dialysis-services-modal/dialysis-services-modal.component';
import { DialysisReportComponent } from './dialysis-report/dialysis-report.component';
import { ReportsReprintV2Component } from './reports-reprint-v2/reports-reprint-v2.component';
import {SharedModule} from '../shared-modules/shared.module';
import { DialysisDoctorNotesComponent } from './dialysis-doctor-notes/dialysis-doctor-notes.component';

@NgModule({
  declarations: [
    DialysisRequestComponent,
    DefaultDialysisConfigComponent,
    ReportsReprintComponent,
    PatientDialysisV2Component,
    DialysisServicesV2Component,
    DialysisServicesModalComponent,
    DialysisReportComponent,
    ReportsReprintV2Component,
    DialysisDoctorNotesComponent,
  ],

  imports: [
    CommonModule,
    DialysisRoutingModule,
    ReactiveFormsModule,
    FormsModule,
    NgbPaginationModule,
    NgbModule,
    SharedModule,
  ],
  exports: [DialysisServicesModalComponent],
  providers: [
    HospitaldoctorService,
    DepartmentsbyhospitalService,
    PatientService,
    DialysisService,
    EmployeeRegistrationService,
    StockService,
    DatePipe
  ],
})
export class DialysisModule {}
