<div class="d-flex align-items-baseline flex-wrap mr-2">
  <!--begin::Page Title-->
  <!--          <h5 class="text-dark font-weight-bold my-1 mr-5">IPD</h5>-->
  <!--end::Page Title-->
  <div class="d-flex">
    <ng-container>
      <div class="symbol symbol-43 mr-2 align-self-start align-self-xxl-center">
        <div
          *ngIf="!patient"
          class="symbol-label"
          style="
            background-image: url(&quot;assets/media/users/blank.png&quot;);
          "
        ></div>
        <div
          *ngIf="patient?.gender === 'unidentified'"
          class="symbol-label"
          style="
            background-image: url(&quot;assets/media/users/blank.png&quot;);
          "
        ></div>
        <div
          *ngIf="patient?.gender === 'transgender'"
          class="symbol-label"
          style="
            background-image: url(&quot;assets/media/users/blank.png&quot;);
          "
        ></div>
        <div
          *ngIf="patient?.gender === 'male'"
          class="symbol-label"
          style="
            background-image: url(&quot;assets/media/svg/avatars/009-boy-4.svg&quot;);
          "
        ></div>
        <div
          *ngIf="patient?.gender === 'female'"
          class="symbol-label"
          style="
            background-image: url(&quot;assets/media/svg/avatars/002-girl.svg&quot;);
          "
        ></div>
      </div>
      <div>
        <table style="color: white" class="table mb-0">
          <tr class="row ml-2" *ngIf="patient">
            <td style="font-size: 14px">
              <p class="m-0">
                <span class="font-weight-bolder">{{
                  patient?.first_name + " " + patient?.last_name
                }}</span>
              </p>
              <p class="m-0">
                <span class="font-weight-bolder">{{ patient?.mrn }}</span>
                <span class="ml-1" style="font-size: 12px"
                  >{{ patient?.age }}, {{ patient?.gender }}</span
                >
              </p>
            </td>
            <td *ngIf="patient" style="font-size: 12px">
              <p class="ml-1 m-0 dotsProperty" *ngIf="patient?.consultant_name" style="max-width: 350px">
                <span class="font-weight-bolder">Consultant:</span>
                <span class="ml-1">{{ patient?.consultant_name }}</span>
              </p>
              <ng-container *ngIf="patient?.admissions?.length">
                <p class="ml-1 m-0 mt-1 dotsProperty" style="max-width: 350px" *ngIf="patient?.admissions[0]?.referred_to">
                  <span class="font-weight-bolder">Department:</span>
                  <span class="ml-1">{{
                      patient?.admissions[0]?.referred_to
                    }}</span>
                </p>
              </ng-container>
            </td>
            <td *ngIf="patient?.admissions?.length" style="font-size: 12px">
              <p class="ml-1 m-0 dotsProperty" *ngIf="patient?.admissions[0]?.ward" style="max-width: 350px">
                <span class="font-weight-bolder">Ward/Room:</span>
                <span
                  class="ml-1 cursor-pointer text-hover-primary"
                  (click)="changeBedIpd()"
                  >{{ patient?.admissions[0]?.ward }}</span
                >
              </p>
              <p class="ml-1 m-0 mt-1 dotsProperty" *ngIf="patient?.admissions?.length">
                <span class="font-weight-bolder" *ngIf="patient?.bed_name"
                  >Bed
                </span>
                <span
                  class="ml-1 cursor-pointer text-hover-primary"
                  (click)="changeBedIpd()"
                >
                  {{ patient?.bed_name }},</span
                >
                <span class="font-weight-bolder"> DOA </span>
                <span>
                  {{
                    patient?.admissions[0]?.created_at | date: "d MMM , y"
                  }},</span
                >
              </p>
            </td>
            <td *ngIf="patient && type === 'request-v3'">
              <p class="ml-1 m-0 dotsProperty" *ngIf="patient?.pat_type_name">
                <span class="font-weight-bolder">Patient Type:</span>
                <span class="ml-1">{{ patient?.pat_type_name }}</span>
              </p>
            </td>
            <td *ngIf="patient?.old_mrn" style="font-size: 12px">
              <p class="ml-1 m-0 dotsProperty" style="max-width: 350px">
                <span class="font-weight-bolder">Old MRN:</span>
                <span
                  class="ml-1 cursor-pointer text-hover-primary"
                >{{ patient?.old_mrn }}</span
                >
              </p>
            </td>
            <td *ngIf="patient && type !== 'request-v3'" style="font-size: 12px">
              <p class="ml-1 m-0 dotsProperty">
                <span class="font-weight-bolder">Weight:</span>
                <span class="ml-1">{{ vitalWeight?.vital_weight }}</span>
                <span class="font-weight-bolder ml-5">Allergies:</span>
                <span class="ml-1">{{ patient?.allergy }}</span>
              </p>
              <p class="ml-1 m-0 mt-1 dotsProperty">
                <span class="font-weight-bolder">Advance Directives:</span>
                <span class="ml-1">{{ patient?.directive }}</span>
              </p>
            </td>
          </tr>
          <!--        <tr class="row ml-2">-->
          <!--          <td *ngIf="patient" style="min-width: 65px; font-size: 12px">-->
          <!--            <span class="font-weight-bolder">{{patient?.mrn}} </span>-->
          <!--          </td>-->
          <!--          <td *ngIf="patient" style="min-width: 105px; font-size: 12px">-->
          <!--            <span class="font-weight-bold"> </span>-->
          <!--            <span>{{patient?.age}}, {{patient?.gender}}</span>-->
          <!--          </td>-->
          <!--          <td *ngIf="patient" class="" style="min-width: 215px; font-size: 12px; display: flex">-->
          <!--            <p class="font-weight-bolder ml-1 mb-0">Department:</p>-->
          <!--            <p class="dotsProperty ml-1 mb-0" style="max-width: 130px">{{patient?.admissions[0]?.referred_to}}</p>-->
          <!--          </td>-->
          <!--          <td *ngIf="patient?.admissions.length > 0" style="min-width: 322px; font-size: 12px">-->
          <!--            <span class="font-weight-bolder ml-1 ">Date of Admission:</span>-->
          <!--            <span class="ml-1">{{patient?.admissions[0]?.created_at | date: 'd MMM , y'}}</span>-->
          <!--          </td>-->
          <!--          <td *ngIf="patient" style="min-width: 240px; font-size: 12px">-->
          <!--            <span class="ml-1 font-weight-bolder ml-1">Advance Directives:</span>-->
          <!--            <span class="ml-1">{{patient?.directive}}</span>-->
          <!--          </td>-->
          <!--        </tr>-->
        </table>
      </div>
    </ng-container>
  </div>
</div>
