<!--begin::Content-->
<div class="content d-flex flex-column flex-column-fluid" id="kt_content">
  <!--begin::Subheader-->
  <div class="subheader py-2 py-lg-6 subheader-solid" id="kt_subheader">
    <div
      class="container-fluid d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap"
    >
      <!--begin::Info-->
      <div class="d-flex align-items-center flex-wrap mr-1">
        <!--begin::Page Heading-->
        <div class="d-flex align-items-baseline flex-wrap mr-5">
          <!--begin::Page Title-->
          <h5 class="text-dark font-weight-bold my-1 mr-5">Invoice</h5>
          <!--          xx  tab headings-->

          <!--end::Page Title-->
          <!--begin::Info-->

          <!--end::Info-->
        </div>
        <!--end::Page Heading-->
      </div>
      <!--end::Info-->

      <!--begin::Toolbar-->
      <div class="d-flex align-items-center">
        <button class="btn btn-light-primary btn-sm" (click)="printReport()">
          Print
        </button>
        <!-- <button class="btn btn-primary btn-sm ml-2" (click)="goBack()">Back</button> -->
      </div>
      <!--end::Toolbar-->
    </div>
  </div>
  <!--end::Subheader-->

  <!--begin::Entry-->
  <div class="d-flex flex-column-fluid main-content">
    <!--begin::Container-->
    <!--    container-xl container-xxl-->
    <div class="container-fluid">
      <!--begin:: Content-->

      <div class="card pdf-card" id="reports">
        <div class="card-body">
          <table class="w-100">
            <thead>
              <tr>
                <!--              AnY Text Here-->
                <td class="d-flex" style="padding-bottom: 10px">
                  <div style="width: 15%">
                    <img
                      [src]="settingData?.logo?.url"
                      style="width: auto; height: 106px"
                    />
                  </div>
                  <div class="text-center" style="width: 70%">
                    <h3 class="hosp-name">{{ settingData?.title }}</h3>
                    <h5 class="hosp-address">{{ settingData?.address }}</h5>
                    <h3>DIALYSIS REPORT</h3>
                  </div>
                  <div style="width: 15%"></div>
                </td>
                <!--                            <div>-->
                <!--                            </div>-->
                <!--            </tr>-->
                <!--            <tr>-->
              </tr>
              <tr>
                <td class="w-100">
                  <app-patient-info></app-patient-info>
                </td>
              </tr>
            </thead>
            <div class="my-4" style="border: 1px solid #313233"></div>
            <tbody>
              <tr>
                <td>
                  <div class="row pl-4 pr-4">
                    <div class="col-4 mb-2">
                      <div class="HeadingData">
                        <span
                          ><strong><u>Vascular Access:</u></strong></span
                        >
                      </div>
                      <div class="detailsData">
                        <p>{{ dialysisReportData?.vascular_access }}</p>
                      </div>
                    </div>
                    <div class="col-4 mb-2">
                      <div class="HeadingData">
                        <span
                          ><strong><u>Dialysis Number</u></strong></span
                        >
                      </div>
                      <div class="detailsData">
                        <p>{{ dialysisReportData?.dialysis_number }}</p>
                      </div>
                    </div>
                    <div class="col-4 mb-2">
                      <div class="HeadingData">
                        <span
                          ><strong><u>Machine Code</u></strong></span
                        >
                      </div>
                      <div class="detailsData">
                        <p>{{ dialysisReportData?.machine_code }}</p>
                      </div>
                    </div>
                    <div class="col-4 mb-2">
                      <div class="HeadingData">
                        <span
                          ><strong><u>Dialyzer</u></strong></span
                        >
                      </div>
                      <div class="detailsData">
                        <p>{{ dialysisReportData?.dialyzer_name }}</p>
                      </div>
                    </div>
                    <div class="col-4 mb-2">
                      <div class="HeadingData">
                        <span
                          ><strong><u>Priming By</u></strong></span
                        >
                      </div>
                      <div class="detailsData">
                        <p>{{ dialysisReportData?.priming_by_name }}</p>
                      </div>
                    </div>
                    <div class="col-4 mb-2">
                      <div class="HeadingData">
                        <span
                          ><strong><u>Priming Solution</u></strong></span
                        >
                      </div>
                      <div class="detailsData">
                        <p>{{ dialysisReportData?.priming_solution }}</p>
                      </div>
                    </div>
                    <div class="col-4 mb-2">
                      <div class="HeadingData">
                        <span
                          ><strong><u>Heparin</u></strong></span
                        >
                      </div>
                      <div class="detailsData">
                        <p>{{ dialysisReportData?.heparin }}</p>
                      </div>
                    </div>
                    <div class="col-4 mb-2">
                      <div class="HeadingData">
                        <span
                          ><strong><u>Advised By</u></strong></span
                        >
                      </div>
                      <div class="detailsData">
                        <p>{{ dialysisReportData?.advised_by }}</p>
                      </div>
                    </div>
                    <div class="col-4 mb-2">
                      <div class="HeadingData">
                        <span
                          ><strong><u>Dialysis Date</u></strong></span
                        >
                      </div>
                      <div class="detailsData">
                        <p>
                          {{
                            dialysisReportData?.dialysis_date
                              | date: "dd-MM-yyyy h:mm a"
                          }}
                        </p>
                      </div>
                    </div>
                    <div class="col-4 mb-2">
                      <div class="HeadingData">
                        <span
                          ><strong><u>Start Time</u></strong></span
                        >
                      </div>
                      <div class="detailsData">
                        <p>
                          {{ dialysisReportData?.start_time | date: "h:mm a" }}
                        </p>
                      </div>
                    </div>
                    <div class="col-4 mb-2">
                      <div class="HeadingData">
                        <span
                          ><strong><u>Finish Time</u></strong></span
                        >
                      </div>
                      <div class="detailsData">
                        <p>
                          {{ dialysisReportData?.finish_time | date: "h:mm a" }}
                        </p>
                      </div>
                    </div>
                    <div class="col-4 mb-2">
                      <div class="HeadingData">
                        <span
                          ><strong><u>Duration</u></strong></span
                        >
                      </div>
                      <div class="detailsData">
                        <p>{{ dialysisReportData?.duration }}</p>
                      </div>
                    </div>
                  </div>
                </td>
              </tr>
              <div class="pl-4 pr-4">
                <h4>
                  <strong><u>Pre Dialysis Assessment</u></strong>
                </h4>
              </div>
              <tr>
                <td>
                  <div class="row pl-4 pr-4">
                    <div class="col-2 mb-2">
                      <div class="HeadingData">
                        <span
                          ><strong><u>Complaints:</u></strong></span
                        >
                      </div>
                      <div class="detailsData">
                        <p>
                          {{
                            dialysisReportData?.pre_dialysis_assessment
                              ?.complaints
                          }}
                        </p>
                      </div>
                    </div>
                    <div class="col-3 mb-2">
                      <div class="HeadingData">
                        <span
                          ><strong><u>Conscious Level</u></strong></span
                        >
                      </div>
                      <div class="detailsData">
                        <p>
                          {{
                            dialysisReportData?.pre_dialysis_assessment
                              ?.conscious_level
                          }}
                        </p>
                      </div>
                    </div>
                    <div class="col-2 mb-2">
                      <div class="HeadingData">
                        <span
                          ><strong><u>Edema</u></strong></span
                        >
                      </div>
                      <div class="detailsData">
                        <p>
                          {{
                            dialysisReportData?.pre_dialysis_assessment?.edema
                          }}
                        </p>
                      </div>
                    </div>
                    <div class="col-3 mb-2">
                      <div class="HeadingData">
                        <span
                          ><strong><u>Card Rhythm</u></strong></span
                        >
                      </div>
                      <div class="detailsData">
                        <p>
                          {{
                            dialysisReportData?.pre_dialysis_assessment
                              ?.card_rhythm
                          }}
                        </p>
                      </div>
                    </div>
                    <div class="col-2 mb-2">
                      <div class="HeadingData">
                        <span
                          ><strong><u>Lungs</u></strong></span
                        >
                      </div>
                      <div class="detailsData">
                        <p>
                          {{
                            dialysisReportData?.pre_dialysis_assessment?.lungs
                          }}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="pl-4 pr-4">
                    <div class="HeadingData">
                      <span
                        ><strong><u>Remarks</u></strong></span
                      >
                    </div>
                    <div class="detailsData">
                      <p>
                        {{
                          dialysisReportData?.pre_dialysis_assessment?.remarks
                        }}
                      </p>
                    </div>
                  </div>
                  <div class="pl-4 pr-4">
                    <div class="HeadingData">
                      <span
                        ><strong><u>Other</u></strong></span
                      >
                    </div>
                    <div class="detailsData">
                      <p>
                        {{ dialysisReportData?.pre_dialysis_assessment?.other }}
                      </p>
                    </div>
                  </div>
                </td>
              </tr>
              <div class="pl-4 pr-4">
                <div>
                  <h4>
                    <strong><u>Special Instructions</u></strong>
                  </h4>
                </div>
                <div class="detailsData">
                  <p>{{ dialysisReportData?.instruction }}</p>
                </div>
              </div>
              <div class="mb-2 pl-4">
                <h4>
                  <strong><u>Pre Dialysis Vitals</u></strong>
                </h4>
              </div>
              <div class="pl-4 pr-4">
                <table class="w-100 table table-bordered">
                  <tr>
                    <th class="vitalsFont" style="width: 11%">Time</th>
                    <th class="vitalsFont" style="width: 11%">Weight (Kg)</th>
                    <th class="vitalsFont" style="width: 10%">Temp (F)</th>
                    <th class="vitalsFont" style="width: 12%">
                      BP-Systolic (mm/hg)
                    </th>
                    <th class="vitalsFont" style="width: 12%">
                      BP-Diastolic (mm/hg)
                    </th>
                    <th class="vitalsFont" style="width: 11%">
                      Pulse (per min)
                    </th>
                    <th class="vitalsFont" style="width: 10%">Blood Flow</th>
                    <th class="vitalsFont" style="width: 11%">UF (per ml)</th>
                    <th class="vitalsFont" style="width: 11%">
                      UF Rate (per h)
                    </th>
                  </tr>
                  <tbody>
                    <ng-container>
                      <tr>
                        <td class="vitalsFont">
                          {{
                            dialysisReportData?.pre_dialysis_vitals[0]
                              .taken_time | date: "h:mm a"
                          }}
                        </td>
                        <td class="vitalsFont">
                          {{
                            dialysisReportData?.pre_dialysis_vitals[0]?.weight
                          }}
                        </td>
                        <td class="vitalsFont">
                          {{
                            dialysisReportData?.pre_dialysis_vitals[0]
                              ?.temperature
                          }}
                        </td>
                        <td class="vitalsFont">
                          {{
                            dialysisReportData?.pre_dialysis_vitals[0]
                              ?.bp_systolic
                          }}
                        </td>
                        <td class="vitalsFont">
                          {{
                            dialysisReportData?.pre_dialysis_vitals[0]
                              ?.bp_diastolic
                          }}
                        </td>
                        <td class="vitalsFont">
                          {{
                            dialysisReportData?.pre_dialysis_vitals[0]?.pulse
                          }}
                        </td>
                        <td class="vitalsFont">
                          {{
                            dialysisReportData?.pre_dialysis_vitals[0]
                              ?.blood_flow
                          }}
                        </td>
                        <td class="vitalsFont">
                          {{ dialysisReportData?.pre_dialysis_vitals[0]?.uf }}
                        </td>
                        <td class="vitalsFont">
                          {{
                            dialysisReportData?.pre_dialysis_vitals[0]?.uf_rate
                          }}
                        </td>
                      </tr>
                    </ng-container>
                  </tbody>
                </table>
              </div>

              <div class="mb-2 pl-4">
                <h4>
                  <strong><u>During Dialysis Vitals</u></strong>
                </h4>
              </div>
              <div class="pl-4 pr-4">
                <table class="w-100 table table-bordered">
                  <tr>
                    <th class="vitalsFont" style="width: 11%">Time</th>
                    <th class="vitalsFont" style="width: 11%">Temp (F)</th>
                    <th class="vitalsFont" style="width: 11%">
                      BP-Systolic (mm/hg)
                    </th>
                    <th class="vitalsFont" style="width: 11%">
                      BP-Diastolic (mm/hg)
                    </th>
                    <th class="vitalsFont" style="width: 11%">
                      Pulse (per min)
                    </th>
                    <th class="vitalsFont" style="width: 11%">Blood Flow</th>
                  </tr>
                  <tbody>
                    <ng-container
                      *ngFor="
                        let lists of dialysisReportData?.during_dialysis_vitals;
                        let z = index
                      "
                    >
                      <tr>
                        <td class="vitalsFont">
                          {{ lists?.taken_time | date: "h:mm a" }}
                        </td>
                        <td class="vitalsFont">{{ lists?.temperature }}</td>
                        <td class="vitalsFont">{{ lists?.bp_systolic }}</td>
                        <td class="vitalsFont">{{ lists?.bp_diastolic }}</td>
                        <td class="vitalsFont">{{ lists?.pulse }}</td>
                        <td class="vitalsFont">{{ lists?.blood_flow }}</td>
                      </tr>
                    </ng-container>
                  </tbody>
                </table>
              </div>

              <div class="mb-2 pl-4 pr-4">
                <h4>
                  <strong><u>Post Dialysis Vitals</u></strong>
                </h4>
              </div>
              <div class="pl-4 pr-4">
                <table class="w-100 table table-bordered">
                  <tr>
                    <th class="vitalsFont" style="width: 11%">Time</th>
                    <th class="vitalsFont" style="width: 11%">Weight (Kg)</th>
                    <th class="vitalsFont" style="width: 11%">Temp (F)</th>
                    <th class="vitalsFont" style="width: 11%">
                      BP-Systolic (mm/hg)
                    </th>
                    <th class="vitalsFont" style="width: 11%">
                      BP-Diastolic (mm/hg)
                    </th>
                    <th class="vitalsFont" style="width: 11%">
                      Pulse (per min)
                    </th>
                  </tr>
                  <tbody>
                    <ng-container>
                      <tr>
                        <td class="vitalsFont">
                          {{
                            dialysisReportData?.post_dialysis_vitals[0]
                              ?.taken_time | date: "h:mm a"
                          }}
                        </td>
                        <td class="vitalsFont">
                          {{
                            dialysisReportData?.post_dialysis_vitals[0]?.weight
                          }}
                        </td>
                        <td class="vitalsFont">
                          {{
                            dialysisReportData?.post_dialysis_vitals[0]
                              ?.temperature
                          }}
                        </td>
                        <td class="vitalsFont">
                          {{
                            dialysisReportData?.post_dialysis_vitals[0]
                              ?.bp_systolic
                          }}
                        </td>
                        <td class="vitalsFont">
                          {{
                            dialysisReportData?.post_dialysis_vitals[0]
                              ?.bp_diastolic
                          }}
                        </td>
                        <td class="vitalsFont">
                          {{
                            dialysisReportData?.post_dialysis_vitals[0]?.pulse
                          }}
                        </td>
                      </tr>
                    </ng-container>
                  </tbody>
                </table>
              </div>
              <div *ngIf="dialysisDoctorNotesDetails?.question_names?.length > 0" class="mb-2 pl-4 pr-4">
                <h4>
                  <strong><u>Questionnaire</u></strong>
                </h4>
              </div>
              <div class="mb-2 pl-4 pr-4">
                <ng-container *ngFor="let questions of dialysisDoctorNotesDetails?.question_names; let i = index">
                  <p>{{i+1}}-{{questions}}</p>
                </ng-container>
              </div>
              <div class="mb-2 pl-4 pr-4">
                <h4>
                  <strong><u>Screening Test Result</u></strong>
                </h4>
              </div>
              <div class="pl-4 pr-4">
                <table class="w-100 table table-bordered">
                  <thead>
                  <tr>
                    <th style="width: 25%">
                      Donor's Test
                    </th>
<!--                    <th style="width: 25%">Donor Value</th>-->
<!--                    <th style="width: 25%">CutOff Value</th>-->
                    <th style="width: 25%">Result</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr>
                    <td style="padding: 10px">HBsAg</td>
<!--                    <td style="padding: 10px">-->
<!--                      {{ dialysisDoctorNotesDetails?.hbs_ag_donor_value || "&#45;&#45;" }}-->
<!--                    </td>-->
<!--                    <td style="padding: 10px">-->
<!--                      {{ dialysisDoctorNotesDetails?.hbs_ag_cutoff_value || "-" }}-->
<!--                    </td>-->
                    <td style="padding: 10px">
                      {{ dialysisDoctorNotesDetails?.hbs_ag || "-" }}
                    </td>
                  </tr>
                  <tr>
                    <td style="padding: 10px">HCV</td>
<!--                    <td style="padding: 10px">-->
<!--                      {{ dialysisDoctorNotesDetails?.hcv_donor_value || "-" }}-->
<!--                    </td>-->
<!--                    <td style="padding: 10px">-->
<!--                      {{ dialysisDoctorNotesDetails?.hcv_cutoff_value || "-" }}-->
<!--                    </td>-->
                    <td style="padding: 10px">
                      {{ dialysisDoctorNotesDetails?.hcv || "-" }}
                    </td>
                  </tr>
                  <tr>
                    <td style="padding: 10px">HIV</td>
<!--                    <td style="padding: 10px">-->
<!--                      {{ dialysisDoctorNotesDetails?.hiv_donor_value || "-" }}-->
<!--                    </td>-->
<!--                    <td style="padding: 10px">-->
<!--                      {{ dialysisDoctorNotesDetails?.hiv_cutoff_value || "-" }}-->
<!--                    </td>-->
                    <td style="padding: 10px">
                      {{ dialysisDoctorNotesDetails?.hiv || "-" }}
                    </td>
                  </tr>
                  <tr>
                    <td style="padding: 10px">PCR</td>
                    <!--                    <td style="padding: 10px">-->
                    <!--                      {{ dialysisDoctorNotesDetails?.hiv_donor_value || "-" }}-->
                    <!--                    </td>-->
                    <!--                    <td style="padding: 10px">-->
                    <!--                      {{ dialysisDoctorNotesDetails?.hiv_cutoff_value || "-" }}-->
                    <!--                    </td>-->
                    <td style="padding: 10px">
                      <span *ngIf="dialysisDoctorNotesDetails?.pcr === 'Yes'">
                        Positive
                      </span>
                      <span *ngIf="dialysisDoctorNotesDetails?.pcr === 'No'">
                        Negative
                      </span>
                    </td>
                  </tr>
<!--                  <tr>-->
<!--                    <td style="padding: 10px">VDRL</td>-->
<!--                    <td style="padding: 10px">-->
<!--                      {{ dialysisDoctorNotesDetails?.vdrc_donor_value || "-" }}-->
<!--                    </td>-->
<!--                    <td style="padding: 10px">-->
<!--                      {{ dialysisDoctorNotesDetails?.vdrc_cutoff_value || "-" }}-->
<!--                    </td>-->
<!--                    <td style="padding: 10px">-->
<!--                      {{ dialysisDoctorNotesDetails?.vdrc || "-" }}-->
<!--                    </td>-->
<!--                  </tr>-->
<!--                  <tr>-->
<!--                    <td style="padding: 10px">MP</td>-->
<!--                    <td style="padding: 10px">-</td>-->
<!--                    <td style="padding: 10px">-</td>-->
<!--                    <td style="padding: 10px">-->
<!--                      <span *ngIf="dialysisDoctorNotesDetails?.mp === 'Yes'">Positive</span>-->
<!--                      <span *ngIf="dialysisDoctorNotesDetails?.mp === 'No'">Negative</span>-->
<!--                      <span *ngIf="dialysisDoctorNotesDetails?.mp === ''">-</span>-->
<!--                    </td>-->
<!--                  </tr>-->
<!--                  <tr>-->
<!--                    <td style="padding: 10px">HB(g/dl)</td>-->
<!--                    <td style="padding: 10px">-</td>-->
<!--                    <td style="padding: 10px">-</td>-->
<!--                    <td style="padding: 10px">-->
<!--                      {{ dialysisDoctorNotesDetails?.hb || "-" }}-->
<!--                    </td>-->
<!--                  </tr>-->
<!--                  <tr>-->
<!--                    <td style="padding: 10px">-->
<!--                      Platelets(10^9/L)-->
<!--                    </td>-->
<!--                    <td style="padding: 10px">-</td>-->
<!--                    <td style="padding: 10px">-</td>-->
<!--                    <td style="padding: 10px">-->
<!--                      {{ dialysisDoctorNotesDetails?.platelets || "-" }}-->
<!--                    </td>-->
<!--                  </tr>-->
                  </tbody>
                </table>
              </div>
              <ng-container *ngIf="dialysisDoctorNotesDetails?.consultant_visit || dialysisDoctorNotesDetails?.doctor_name || dialysisDoctorNotesDetails?.department_name">
                <div class="mb-2 pl-4 pr-4">
                  <h4>
                    <strong><u>Consultant Visit</u></strong>
                  </h4>
                </div>
                <div class="pl-4 pr-4 mb-7">
                  <div class="d-flex">
                    <div class="col-4 pl-0">
                      <span><strong>Doctor Name:</strong> {{dialysisDoctorNotesDetails?.doctor_name}}</span>
                    </div>
                    <div>
                      <span><strong>Department Name:</strong> {{dialysisDoctorNotesDetails?.department_name}}</span>
                    </div>
                  </div>
                  <div>
                    <app-summernotes
                      [id]="'consultant_visit'"
                      [htmlData]="dialysisDoctorNotesDetails?.consultant_visit"
                    >
                    </app-summernotes>
                  </div>
                </div>
              </ng-container>
              <ng-container *ngIf="dialysisDoctorNotesDetails?.diagnosis">
                <div class="mb-2 pl-4 pr-4">
                  <h4>
                    <strong><u>Diagnosis</u></strong>
                  </h4>
                </div>
                <div class="pl-4 pr-4 mb-7">
                  <div>
                    <app-summernotes
                      [id]="'diagnosis'"
                      [htmlData]="dialysisDoctorNotesDetails?.diagnosis"
                    >
                    </app-summernotes>
                  </div>
                </div>
              </ng-container>
              <ng-container *ngIf="dialysisDoctorNotesDetails?.fistula_complications">
                <div class="mb-2 pl-4 pr-4">
                  <h4>
                    <strong><u>Fistula Complications</u></strong>
                  </h4>
                </div>
                <div class="pl-4 pr-4 mb-7">
                  <div>
                    <app-summernotes
                      [id]="'fistula_complications'"
                      [htmlData]="dialysisDoctorNotesDetails?.fistula_complications"
                    >
                    </app-summernotes>
                  </div>
                </div>
              </ng-container>
              <ng-container *ngIf="dialysisDoctorNotesDetails?.cv_line_complications">
                <div class="mb-2 pl-4 pr-4">
                  <h4>
                    <strong><u>CV Line Complications</u></strong>
                  </h4>
                </div>
                <div class="pl-4 pr-4 mb-7">
                  <div>
                    <app-summernotes
                      [id]="'cv_line_complications'"
                      [htmlData]="dialysisDoctorNotesDetails?.cv_line_complications"
                    >
                    </app-summernotes>
                  </div>
                </div>
              </ng-container>
            </tbody>
            <tfoot>
              <tr>
                <td class="pl-4 pr-4">
                  <div>
                    <p>
                      <span class="font-weight-bold">Printed By: </span
                      >{{ userName.full_name }}
                    </p>
                    <p>
                      <span class="font-weight-bold">Created at: </span
                      >{{ today | date: "dd-MM-yyyy h:mm a" }}
                    </p>
                    <p>
                      <span class="font-weight-bold">Designation: </span
                      >{{ userName.employee_designation }}
                    </p>
                  </div>
                </td>
              </tr>
            </tfoot>
          </table>
        </div>
      </div>

      <!--end:: Content-->
    </div>
    <!--end::Container-->
  </div>
  <!--end::Entry-->
</div>
<!--end::Content-->
