import {ChangeDetectorRef, Component, Input, OnInit} from '@angular/core';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { HrReportsService } from '../../../../../services/hr-reports.service';
import { HospitalService } from '../../../../../services/hospital.service';
import { ConfiguratorsService } from '../../../../../services/configurators.service';
import { DepartmentsbyhospitalService } from '../../../../../services/departmentsbyhospital.service';
import { ConfigurationService } from '../../../../../services/configuration.service';
import { RosterServiceService } from '../../../../../services/roster-service.service';
import { RouteShareService } from '../../../../../services/route-share.service';
import * as moment from 'moment/moment';
import { InventoryGeneralSettingsService } from '../../../../../services/inventory-general-settings.service';

declare var $: any;
declare var toastr: any;
@Component({
  selector: 'app-late-in-early-out-report',
  templateUrl: './late-in-early-out-report.component.html',
  styleUrls: ['./late-in-early-out-report.component.css'],
})
export class LateInEarlyOutReportComponent implements OnInit {
  @Input() type: any;
  depart: any;
  designation: any;
  endDate: any;
  startDate: any;
  department = '';
  employee = '';
  status = 'all';
  reportType = 'summarized';
  reportedType = '';
  designations = '';
  employees = [];
  reportData: any;
  reportDetailedData = [];
  screenName = '';
  page = 1;
  totalPages: any;
  perPage = 25;
  perPageValue: any;
  reportDataCSV = [];
  reportDetailedDataCSV = [];
  objOfManufactrureCSV: {};
  settingData: any;
  stateOfHeader: any;
  constructor(
    private ngxLoader: NgxUiLoaderService,
    private configuratorsService: ConfiguratorsService,
    private departmentsByHospital: DepartmentsbyhospitalService,
    private configurationService: ConfigurationService,
    private hospitalService: HospitalService,
    private inventoryControlService: InventoryGeneralSettingsService,
    private RosterService: RosterServiceService,
    private routeSevice: RouteShareService,
    private ref: ChangeDetectorRef,
  ) {
    this.screenName = this.routeSevice.getValue();
  }
  getSettingsData(): any {
    this.hospitalService.getMedicalUnitDetails().subscribe(
      (data) => {
        this.settingData = data.medical_unit;
      },
      (err) => {
        toastr.error(err.error.error);
      },
    );
  }
  getInventorySysControl(): any {
    this.ngxLoader.start();
    this.inventoryControlService.getInventorySystemControls().subscribe(
      (data) => {
        this.stateOfHeader = data.report_header_alignment;
        this.ngxLoader.stop();
      },
      (err) => {
        this.ngxLoader.stop();
        toastr.error(err.error.error);
      },
    );
  }
  getDepartments(): any {
    setTimeout((e) => {
      $('.selectpicker').selectpicker('refresh');
    }, 500);
    this.departmentsByHospital.index().subscribe((data) => {
      this.depart = data.departments;
      setTimeout((e) => {
        $('.selectpicker').selectpicker('refresh');
      }, 500);
      setTimeout((e) => {
        $('.selectpicker').selectpicker();
      }, 500);
    });
  }
  getdesignation(): any {
    setTimeout((e) => {
      $('.selectpicker').selectpicker('refresh');
    }, 500);
    this.configuratorsService
      .fetchConfiguration('designation')
      .subscribe((data) => {
        this.designation = data.configurator_types;
        setTimeout((e) => {
          $('.selectpicker').selectpicker('refresh');
        }, 500);
        setTimeout((e) => {
          $('.selectpicker').selectpicker();
        }, 500);
      });
  }
  getEmployeesOfDepartment(): any {
    this.employees = [];
    setTimeout((e) => {
      $('#employee').selectpicker('refresh');
    }, 200);
    this.configurationService
      .getEmployeesOfSpecificDepartment(this.department)
      .subscribe(
        (data) => {
          this.employees = data;
          setTimeout((e) => {
            $('#employee').selectpicker('refresh');
            $('#employee').selectpicker();
          }, 500);
        },
        (err) => {
          setTimeout((e) => {
            $('#employee').selectpicker('refresh');
          }, 500);
          this.ngxLoader.stop();
          toastr.error(err.error.message);
        },
      );
  }
  changeReportType(): any{
    this.reportType = 'summarized';
  }
  searchReport(): any {
    const startDate = moment($('.start_date').val(), 'DD-MM-YYYY');
    const endDate = moment($('.end_date').val(), 'DD-MM-YYYY');
    const dateCheck = endDate.diff(startDate, 'days') + 1;
    if (dateCheck > 31 && this.status !== 'late_in') {
      toastr.error('Date range must be between 31 days');
      return;
    }
    if ((this.status === 'late_in' && this.reportType === 'summarized' && dateCheck > 62) || (this.status === 'late_in' && this.reportType === 'detailed' && this.employee && dateCheck > 62)) {
      toastr.error('Date range must be between 62 days');
      return;
    }
    if (this.status === 'late_in' && !this.employee && this.reportType === 'detailed' && dateCheck > 2) {
      toastr.error('Date range must be between 2 days');
      return;
    }
    this.reportedType = this.reportType;
    this.startDate = $('.start_date').val();
    this.endDate = $('.end_date').val();
    this.ngxLoader.start();
    if (this.reportType !== 'detailed') {
      this.RosterService.searchEarlyInLateOutReport(
        this.startDate,
        this.endDate,
        this.department,
        this.designations,
        this.employee,
        this.status,
      ).subscribe(
        (data) => {
          this.reportData = data;
          if (this.reportData.length) {
            // this.totalPages = data.meta.total_count;
            this.ref.markForCheck();
            this.getListDataCSV();
            this.getCSV();
          } else {
            this.reportData = [];
            this.reportDetailedData = [];
            toastr.error('Record not found');
          }
          this.ngxLoader.stop();
        },
        (err) => {
          this.ngxLoader.stop();
          toastr.error(err.error.message);
        },
      );
    } else {
      this.RosterService.searchEarlyInLateOutReportDetailed(
        this.startDate,
        this.endDate,
        this.department,
        this.designations,
        this.employee,
        this.status,
      ).subscribe(
        (data) => {
          this.reportDetailedData = data;
          this.ngxLoader.stop();
          if (this.reportDetailedData.length) {
            // this.totalPages = data.meta.total_count;
            this.ref.markForCheck();
            this.getDetailedListDataCSV();
            this.getDetailedCSV();
          } else {
            this.reportData = [];
            this.reportDetailedData = [];
            toastr.error('Record not found');
          }
        },
        (err) => {
          this.ngxLoader.stop();
          toastr.error(err.error.message);
        },
      );
    }
  }
  getListDataCSV(): any {
    this.reportDataCSV = [];
    for (var i = 0; i < this.reportData.length; i++) {
      for (var j = 0; j < this.reportData[i].employees.length; j++) {
        this.objOfManufactrureCSV = {
          Department: this.reportData[i].department,
          'Employee ID': this.reportData[i].employees[j].employee_code,
          'Employee Name': this.reportData[i].employees[j].name,
          Designation: this.reportData[i].employees[j].designation,
          'Late In': this.reportData[i].employees[j].late_in,
          'Early Out': this.reportData[i].employees[j].early_out,
          'Short Hours': this.reportData[i].employees[j].short_hours,
          'Leave Balance(EL)': this.reportData[i].employees[j].leave_balance,
        };
        this.ref.markForCheck();
        this.reportDataCSV.push(this.objOfManufactrureCSV);
      }
    }
  }
  getDetailedListDataCSV(): any {
    this.reportDetailedDataCSV = [];
    for (var i = 0; i < this.reportDetailedData.length; i++) {
      for (var j = 0; j < this.reportDetailedData[i].employees.length; j++) {
      for (var z = 0; z < this.reportDetailedData[i].employees[j].attendance.length; z++) {
        this.objOfManufactrureCSV = {
          'Employee ID': this.reportDetailedData[i].employees[j].employee_code,
          Department: this.reportDetailedData[i].department,
          'Employee Name': this.reportDetailedData[i].employees[j].name,
          Designation: this.reportDetailedData[i].employees[j].designation,
          'Shift Time': this.reportDetailedData[i].employees[j].attendance[z]?.shift?.time_to + ' ' + this.reportDetailedData[i].employees[j].attendance[z]?.shift?.time_from,
          'Check In Time': this.reportDetailedData[i].employees[j].attendance[z]?.check_in_time,
          'Check In Date': this.reportDetailedData[i].employees[j].attendance[z]?.check_in_date,
          'Late In': this.reportDetailedData[i].employees[j].attendance[z]?.short_hours,
        };
        this.ref.markForCheck();
        this.reportDetailedDataCSV.push(this.objOfManufactrureCSV);
      }
      }
    }
  }
  getCSV(): any {
    const array = [Object.keys(this.reportDataCSV[0] ? this.reportDataCSV[0] : '')].concat(
      this.reportDataCSV,
    );
    console.log('array', array);
    return array
      .map((it) => {
        return Object.values(it).toString();
      })
      .join('\n');
  }
  getDetailedCSV(): any {
    const array = [Object.keys(this.reportDetailedDataCSV[0] ? this.reportDetailedDataCSV[0] : '')].concat(
      this.reportDetailedDataCSV,
    );
    console.log('array', array);
    return array
      .map((it) => {
        return Object.values(it).toString();
      })
      .join('\n');
  }

  downloadCSV(): any {
    const hiddenElement = document.createElement('a');
    hiddenElement.href =
      'data:text/csv;charset=utf-8,' + encodeURI(this.reportType === 'detailed' ? this.getDetailedCSV() : this.getCSV());
    hiddenElement.target = '_blank';
    hiddenElement.download = 'LateIn&EarlyOutReport.csv';
    hiddenElement.click();
  }
  getDate(): any {
    setTimeout(() => {
      $('.start_date').datepicker({
        format: 'dd-mm-yyyy',
        orientation: 'bottom-left',
      });
      $('.start_date').datepicker('setDate', new Date());
      $('.end_date').datepicker({
        format: 'dd-mm-yyyy',
        orientation: 'bottom-left',
      });
      $('.end_date').datepicker('setDate', new Date());
      this.searchReport();
    }, 500);
  }
  loadPage(event): any {
    this.searchReport();
  }
  selectPaginationLimit(event): any {
    this.perPage = event;
    if (event === '') {
      this.perPage = this.totalPages;
      this.page = 1;
      this.perPageValue = 'All';
    } else {
      this.perPageValue = event;
    }
    this.searchReport();
  }
  printReport(): any {
    window.print();
  }
  ngOnInit(): void {
    this.perPageValue = 25;
    this.getEmployeesOfDepartment();
    this.getdesignation();
    this.getDepartments();
    this.getDate();
    this.getInventorySysControl();
    this.getSettingsData();
  }
}
