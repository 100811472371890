import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {NgxUiLoaderService} from 'ngx-ui-loader';
import {CheckListConfiguratorSettingService} from '../../../../services/check-list-configurator-setting.service';
import {HospitalService} from '../../../../services/hospital.service';
import {DomSanitizer, SafeHtml} from '@angular/platform-browser';

declare var toastr: any;
@Component({
  selector: 'app-consent-report',
  templateUrl: './consent-report.component.html',
  styleUrls: ['./consent-report.component.css']
})
export class ConsentReportComponent implements OnInit {
  checkListDetail: any;
  signature: any;
  id: any;
  settingData: any;
  sanitizedValue: SafeHtml;
  constructor( private route: ActivatedRoute,
               private ngxLoader: NgxUiLoaderService,
               private checkService: CheckListConfiguratorSettingService,
               private hospitalService: HospitalService,
               private sanitizer: DomSanitizer) { }

  getConsentReport(): any {
    this.checkListDetail = [];
    this.ngxLoader.start();
    this.checkService
      .getConsentReportData(this.id)
      .subscribe((data) => {
        this.checkListDetail = data.templates;
        this.checkListDetail = this.checkListDetail.filter(dataItem =>
          dataItem.check_list_temp_config.some(checkData =>
            checkData.check_list_template_configurators.some(item =>
              item.value || item.employee_name || item.value_name
            )
          )
        );
        this.signature = data.signature_url;
        this.ngxLoader.stop();
        // this.urlData = data.signature_url;
      }, err => {
        this.ngxLoader.stop();
        toastr.error(err.error.message);
      });
  }
  sanitizeHtml(value: string): SafeHtml {
    return this.sanitizer.bypassSecurityTrustHtml(value);
  }
  getSettingsData(): any {
    this.hospitalService.getMedicalUnitDetails().subscribe(
      (data) => {
        this.settingData = data.medical_unit;
      },
      (err) => {
        toastr.error(err.error.error);
      },
    );
  }
  printReport(): any{
    window.print();
  }
  ngOnInit(): void {
    this.route.queryParams.subscribe((params) => {
      this.id = params['id'];
    });
    this.getConsentReport();
    this.getSettingsData();
  }

}
