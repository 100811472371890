import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { map } from 'rxjs/operators';

@Injectable()
export class PdfServicesService {
  constructor(private http: HttpClient) {}

  getMedicalUnitDetails(): Observable<any> {
    const results = this.http
      .get(
        `${environment.pythonUrl}/api/get_medical_unit?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  getMedicalUnitDetailsV2(invoiceId): Observable<any> {
    const results = this.http
      .get(
        `${environment.pythonUrl}/api/get_medical_unit?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&invoice_id=' +
          invoiceId,
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }

  searchPatientByType(patient): Observable<any> {
    const results = this.http
      .get(
        `${environment.rorUrl}/v4/patients/get_patients_by_type?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&type=' +
          patient.type +
          '&query=' +
          patient.search_query,
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  searchDepartmentWiseDiagnoseData(fromDate, toDate, departmentId): Observable<any> {
    const results = this.http
      .get(
        `${environment.pythonUrl}/report/dept_wise_diagnose_report/?auth_token=` +
        localStorage.getItem('auth_token') +
        '&medical_unit_id=' +
        localStorage.getItem('current_medical_unit_id') +
        '&from_date=' + fromDate +
        '&to_date=' + toDate +
        '&department_id=' + departmentId,
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  searchPatientByTypeAdmissionV2(patient): Observable<any> {
    const results = this.http
      .get(
        `${environment.pythonUrl}/api/get_patients_by_type?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&type=' +
          patient.type +
          '&query=' +
          patient.search_query,
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }

  patientFetchByMRN(patientId): Observable<any> {
    const results = this.http
      .get(
        `${environment.rorUrl}/v4/patients/fetch_by_mrn?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&patient_id=' +
          patientId,
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }

  patientInvoice(
    mrn,
    status: boolean,
    admissionId,
    hswBill,
    paymentStatus,
  ): Observable<any> {
    // tslint:disable-next-line:max-line-length
    const results = this.http
      .get(
        `${environment.rorUrl}/v4/patients/` +
          mrn +
          `/final_settlements/ipd_bill_data?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&previous_status=' +
          status +
          '&admission_id=' +
          admissionId +
          '&hsw_bill=' +
          hswBill +
          '&payment_status=' +
          paymentStatus,
        {
          headers: new HttpHeaders({
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  patientInvoiceV2(
    mrn,
    status: boolean,
    admissionId,
    hswBill,
    paymentStatus,
    visitId,
    mergeOTMedicines,
  ): Observable<any> {
    // tslint:disable-next-line:max-line-length
    const results = this.http
      .get(
        `${environment.pythonUrl}/api/ipd_bill_data/` +
          mrn +
          `/?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&previous_status=' +
          status +
          '&admission_id=' +
          admissionId +
          '&hsw_bill=' +
          hswBill +
          '&payment_status=' +
          paymentStatus +
          '&visit_id=' +
          visitId +
          '&merge_ot_medicine=' +
          mergeOTMedicines,
        {
          headers: new HttpHeaders({
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  patientInvoiceV3(
    mrn,
    status: boolean,
    admissionId,
    hswBill,
    paymentStatus,
    visitId,
    mergeOTMedicines,
  ): Observable<any> {
    // tslint:disable-next-line:max-line-length
    const results = this.http
      .get(
        `${environment.pythonUrl}/api/new_summarized_bill/` +
          mrn +
          `/?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&previous_status=' +
          status +
          '&admission_id=' +
          admissionId +
          '&hsw_bill=' +
          hswBill +
          '&payment_status=' +
          paymentStatus +
          '&visit_id=' +
          visitId +
          '&merge_ot_medicine=' +
          mergeOTMedicines,
        {
          headers: new HttpHeaders({
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  summerizeBill(mrn, visitId): Observable<any> {
    const results = this.http
      .get(
        `${environment.pythonUrl}/api/in_patient_summarize_bill/?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&mrn=' +
          mrn +
          '&visit_id=' +
          visitId,
        {
          headers: new HttpHeaders({
            // AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  getEmployeeLoanDetail(id): Observable<any> {
    const results = this.http
      .get(
        `${environment.pythonUrl}/hr/get_employee_loan_report?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&id=' +
          id,
        {
          headers: new HttpHeaders({
            // AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  // getConsultations(patientId, status, history, ids: any): Observable<any> {
  //   const results = this.http.get(`${environment.rorUrl}/v4/ipd_doctors?auth_token=` + localStorage.getItem('auth_token') + '&medical_unit_id=' + localStorage.getItem('current_medical_unit_id') + '&patient_id=' + patientId + '&status=' + status + '&history=' + history + '&ids=' + ids
  //     , {headers: new HttpHeaders({
  //         AuthenticationToken: localStorage.getItem('auth_token'),
  //         Accept: 'application/json'
  //       })}).pipe(map(data => data));
  //   return results;
  // }
  getConsultations(
    patientId,
    status,
    history,
    ids: any,
    visitId,
  ): Observable<any> {
    const results = this.http
      .get(
        `${environment.pythonUrl}/opd/ipd_doctors/?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&patient_id=' +
          patientId +
          '&status=' +
          status +
          '&history=' +
          history +
          '&ids=' +
          ids +
          '&visit_id=' +
          visitId,
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  getPatientServices(serviceId): Observable<any> {
    const results = this.http
      .get(
        `${environment.rorUrl}/v4/patient_service_invoices/` +
          serviceId +
          `?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  getPatientServicesV2(serviceId): Observable<any> {
    const results = this.http
      .get(
        `${environment.pythonUrl}/api/patient_service_invoices/` +
          serviceId +
          `?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  getPatientAllVisitServicesV2(serviceId, visitId): Observable<any> {
    const results = this.http
      .get(
        `${environment.pythonUrl}/api/patient_service_invoices/` +
        serviceId +
        `?auth_token=` +
        localStorage.getItem('auth_token') +
        '&medical_unit_id=' +
        localStorage.getItem('current_medical_unit_id') + '&visit_id=' + visitId,
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  getPatientData(
    patientRadID,
    invoiceID,
    visitId,
    patientMRN,
    patientLabIds,
    patientOtReuqestId,
  ): Observable<any> {
    const results = this.http
      .get(
        `${environment.pythonUrl}/api/patient_optimize/` +
          patientMRN +
          `?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&invoice_id=' +
          invoiceID +
          '&visit_id=' +
          visitId +
          '&rad_report_id=' +
          patientRadID +
          '&lab_order_detail_id=' +
          patientLabIds +
          '&op_request_id=' +
          patientOtReuqestId,
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  getPatientAdmission(serviceId, visit_id): Observable<any> {
    const results = this.http
      .get(
        `${environment.pythonUrl}/report/admission_slip/?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&id=' +
          serviceId +
          '&visit_id=' +
          visit_id,
        {
          headers: new HttpHeaders({
            // AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  getEmployeeLeaveDetail(id): Observable<any> {
    const results = this.http
      .get(
        `${environment.pythonUrl}/hr/get_employee_leave_report?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&id=' +
          id,
        {
          headers: new HttpHeaders({
            // AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  getPatientTemplate(patientMRN, id): Observable<any> {
    const results = this.http
      .get(
        `${environment.rorUrl}/v4/patients/get_template?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&patient_id=' +
          patientMRN +
          '&visit_id=' +
          id,
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  getPatientTemplateV2(patientMRN, id): Observable<any> {
    const results = this.http
      .get(
        `${environment.pythonUrl}/opd/get_template?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&patient_id=' +
          patientMRN +
          '&visit_id=' +
          id +
        '&medications=all',
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  //  Blood bank PDF reports
  bloodInvoice(blood_requisition_id): Observable<any> {
    // tslint:disable-next-line:max-line-length
    const results = this.http
      .get(
        `${environment.pythonUrl}/blood_bank/blood_requisition_print?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&id=' +
          blood_requisition_id,
        {
          headers: new HttpHeaders({
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  //  Blood bank Donor Registration slip
  donorInvoice(donorId): Observable<any> {
    // tslint:disable-next-line:max-line-length
    const results = this.http
      .get(
        `${environment.pythonUrl}/blood_bank/blood_donor_register_print?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&donor_id=' +
          donorId,
        {
          headers: new HttpHeaders({
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }

  patientDeathData(id): Observable<any> {
    // tslint:disable-next-line:max-line-length
    const results = this.http
      .get(
        `${environment.rorUrl}/v4/death_certificates/death_certificate?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&patient_id=' +
          id +
          '&type=print',
        {
          headers: new HttpHeaders({
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }

  patientDeathDataV2(id): Observable<any> {
    // tslint:disable-next-line:max-line-length
    const results = this.http
      .get(
        `${environment.pythonUrl}/employee/death_certificate/?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&patient_id=' +
          id +
          '&type=print',
        {
          headers: new HttpHeaders({
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }

  fetchGeneralHistory(patientMRN, visitId): Observable<any> {
    return this.http
      .get(
        `${environment.rorUrl}/v4/patients/` +
          patientMRN +
          `/patient_general_histories?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&visit_id=' +
          visitId,
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
  }
  fetchGeneralHistoryV2(patientMRN, visitId): Observable<any> {
    return this.http
      .get(
        `${environment.pythonUrl}/ipd/patient_general_histories/` +
          patientMRN +
          `/?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&visit_id=' +
          visitId,
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
  }

  getBirthInvoice(id): Observable<any> {
    return this.http
      .get(
        `${environment.rorUrl}/v4/birth_certificates/` +
          id +
          `?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&type=print',
        {
          headers: new HttpHeaders({
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
  }

  getBirthInvoiceV2(id): Observable<any> {
    return this.http
      .get(
        `${environment.pythonUrl}/api/birth_certificates/show_report/?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&type=print' +
          '&id=' +
          id,
        {
          headers: new HttpHeaders({
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
  }
  getRadiologyReport(id): Observable<any> {
    return this.http
      .get(
        `${environment.rorUrl}/v4/reports/radiology_report/?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&id=' +
          id,
        {
          headers: new HttpHeaders({
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
  }
  getRadiologyReportV2(id): Observable<any> {
    return this.http
      .get(
        `${environment.pythonUrl}/report/radiology_report/?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&id=' +
          id,
        {
          headers: new HttpHeaders({
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
  }
  getPatientVisitData(id): Observable<any> {
    return this.http
      .get(
        `${environment.rorUrl}/v4/visits/` +
          id +
          '?auth_token=' +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          headers: new HttpHeaders({
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
  }
  getPatientVisitDataV2(id): Observable<any> {
    return this.http
      .get(
        `${environment.pythonUrl}/opd/visits?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') + '&visit_id=' + id,
        {
          headers: new HttpHeaders({
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
  }
  getPatientOTData(patientReqId): Observable<any> {
    return this.http
      .get(
        `${environment.rorUrl}/v4/operation_requests/` +
          patientReqId +
          `/get_data?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
  }
  getPhysicalSystemicExaminations(id, mrn): Observable<any> {
    return this.http
      .get(
        `${environment.rorUrl}/v4/patients/` +
          mrn +
          '/physical_systemic_examinations?auth_token=' +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&visit_id=' +
          id,
        {
          headers: new HttpHeaders({
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
  }
  getPhysicalSystemicExaminationsV2(id, mrn): Observable<any> {
    return this.http
      .get(
        `${environment.pythonUrl}/opd/physical_systemic_examinations/?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&visit_id=' +
          id +
          '&patient_id=' +
          mrn,
        {
          headers: new HttpHeaders({
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
  }
  getMedicineAdministrationPDF(patientId, visitId): Observable<any> {
    const results = this.http
      .get(
        `${environment.pythonUrl}/medication/patient_medication_report/?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&patient=' +
          patientId +
          '&visit=' +
          visitId,
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }

  dayBookReport(item: any): Observable<any> {
    const results = this.http
      .get(
        `${environment.pythonUrl}/api/day_book/?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&from_date=' +
          item.from_date +
          '&to_date=' +
          item.to_date +
          '&user_id=' +
          item.user_id +
          '&department_id=' +
          item.department_id +
          '&service_id=' +
          item.service_id +
          '&patient_mrn=' +
          item.patient_mrn +
          '&user_type=' +
          item.user_type +
          '&payment_status=' +
          item.payment_status +
          '&opd_ipd_emr=' +
          item.opd_ipd_emr,
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }

  patientTypeWiseSummarizedReport(
    fromDate,
    toDate,
    patientTypeId,
    groupId,
    companyId,
  ): Observable<any> {
    const results = this.http
      .get(
        `${environment.pythonUrl}/report/v2/patient_type_wise_report/summarized_report/?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&from_date=' +
          fromDate +
          '&to_date=' +
          toDate +
          '&patient_type_id=' +
          patientTypeId +
          '&group_id=' +
          groupId +
          '&company_id=' +
          companyId,
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
}
