import { Component, OnInit } from '@angular/core';
import { PdfServicesService } from '../../../../services/pdf-services.service';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { environment } from '../../../../../environments/environment';
import { UserService } from '../../../../services/user.service';
import { Validators } from '@angular/forms';
import { ShareDataService } from '../../../../services/share-data.service';
import { ConfigurationService } from '../../../../services/configuration.service';
import { SystemControlsService } from '../../../../services/system-controls.service';

declare var $: any;
declare var toastr: any;
@Component({
  selector: 'app-pdf-report-new-v2',
  templateUrl: './pdf-report-new-v2.component.html',
  styleUrls: ['./pdf-report-new-v2.component.css'],
})
export class PdfReportNewV2Component implements OnInit {
  today = new Date();
  Id: number;
  patientRadiologyData: any;
  userName: any;
  settingData: any;
  hospitalName = environment.hospital;
  currentControls: any;
  showHeaderInUrl: any;
  showFooterInUrl: any;
  indication_for_exam = true;
  showHeader: any;
  showFooter: any;
  systemControlCount = 0;
  userData: any;
  uName: any;
  todayDate = new Date();
  masterUser: any;
  headerHeight = 150;
  footerHeight = 150;
  checkApiCall = {
    fetchRadiology: false,
    settingsData: false,
    controlsData: false,
    PaddingData: false,
    setControl: false,
    patientData: false,
    SummaryControl: false,
  };
  extractedPath: any;
  showServiceNameOnRadiology = false;
  constructor(
    private ngxLoader: NgxUiLoaderService,
    private pdfService: PdfServicesService,
    private router: Router,
    private route: ActivatedRoute,
    private shareDataService: ShareDataService,
    private configService: ConfigurationService,
    private systemControlService: SystemControlsService,
  ) {
    this.userData = JSON.parse(localStorage.getItem('currentUser'));
    if (this.userData) {
      this.uName = this.userData.first_name + ' ' + this.userData.last_name;
      this.masterUser = this.userData?.role?.title;
      console.log('afddsgdg', this.userData);
      console.log('hospi', this.hospitalName);
    }
    const currentUrl = this.router.url;
    this.extractedPath = this.extractPath(currentUrl);
  }
  extractPath(url: string): any {
    const urlWithoutQueryParams = url.split('?')[0];
    const cleanedUrl = urlWithoutQueryParams.startsWith('/')
      ? urlWithoutQueryParams.substring(1)
      : urlWithoutQueryParams;
    return cleanedUrl;
  }
  ngOnInit(): void {
    this.route.queryParams.subscribe((params) => {
      this.Id = +params['rad_id'];
      this.showHeaderInUrl = params['show_header'];
      this.showFooterInUrl = params['show_footer'];
    });
    if (this.Id) {
      this.fetchRadiologyData();
    }
    this.userName = JSON.parse(localStorage.getItem('currentUser'));
    console.log('adsdfg', this.userName);
    this.getSettingsData();
    this.getControlsData();
    this.getPaddingData();
    this.getRadiologyPACSUrls();
  }
  getPatient(event): any {
    this.checkApiCall.patientData = true;
    this.allApiSuccess(this.checkApiCall);
  }
  getSummaryControl(event): any {
    this.checkApiCall.SummaryControl = true;
    this.allApiSuccess(this.checkApiCall);
  }
  getControlsData(): any {
    this.systemControlCount++;
    // this.shareDataService.controlCheck.subscribe(data => {
    this.systemControlService.getUpdatedChecks().subscribe((data) => {
      this.currentControls = data.report_controls;
      this.indication_for_exam =
        this.currentControls?.radiology_services?.indication_for_exam;
      if (this.currentControls) {
        this.checkApiCall.controlsData = true;
        this.allApiSuccess(this.checkApiCall);
        this.setControlData();
      } else {
        setTimeout(() => {
          if (this.systemControlCount < 1) {
            this.getControlsData();
          }
        }, 2000);
      }
    });
  }
  setControlData(): any {
    if (this.showHeaderInUrl === 'true' || this.showHeaderInUrl === 'false') {
      this.showHeader = this.showHeaderInUrl;
      console.log('in 1', this.showHeader);
    } else {
      this.showHeader =
        this.currentControls.radiology_services.is_radiology_invoice_header;
      console.log('in 11', this.showHeader);
    }
    if (this.showFooterInUrl === 'true' || this.showFooterInUrl === 'false') {
      this.showFooter = this.showFooterInUrl;
      console.log('in 2', this.showFooter);
    } else {
      this.showFooter =
        this.currentControls.radiology_services.is_radiology_invoice_footer;
      console.log('in 22', this.showFooter);
    }
    this.checkApiCall.setControl = true;
    this.allApiSuccess(this.checkApiCall);
  }
  fetchRadiologyData(): any {
    this.ngxLoader.start();
    this.pdfService.getRadiologyReportV2(this.Id).subscribe(
      (data) => {
        this.patientRadiologyData = data.report;
        if (this.patientRadiologyData) {
          this.checkApiCall.fetchRadiology = true;
          this.allApiSuccess(this.checkApiCall);
        }
        setTimeout(() => {
          if (this.patientRadiologyData) {
            if (this.patientRadiologyData.report) {
              document.getElementById('resultHTML').innerHTML = this
                .patientRadiologyData.report
                ? this.patientRadiologyData.report
                : '';
            }
            if (this.patientRadiologyData.impression) {
              document.getElementById('impressionHTML').innerHTML = this
                .patientRadiologyData.impression
                ? this.patientRadiologyData.impression
                : '';
            }
            if (this.patientRadiologyData.addendum) {
              document.getElementById('addendumHTML').innerHTML = this
                .patientRadiologyData.addendum
                ? this.patientRadiologyData.addendum
                : '';
            }
            $('.data table').css({
              'max-width': '100%',
              'margin-bottom': '10px',
              'margin-top': '10px',
            });
          }
        }, 500);
        console.log('patientRadiologyData', this.patientRadiologyData);
        this.ngxLoader.stop();
      },
      (err) => {
        toastr.error(err.error.message);
        this.ngxLoader.stop();
      },
    );
  }
  printReport(): any {
    window.print();
  }
  downloadDocReport(): any {
    $('.barcodeSection').css('display', 'none');
    setTimeout(() => {
      this.export2Word('reports', 'testing');
    }, 500);
    // var elHtml = document.getElementById('reports').innerHTML;
    // var link = document.createElement('a');
    // link.setAttribute('download', 'myfile');
    // link.setAttribute('href', 'data:' + 'text/doc' + ';charset=utf-8,' + encodeURIComponent(elHtml));
    // link.click();
  }
  export2Word(element, filename = '') {
    var preHtml =
      "<html xmlns:o='urn:schemas-microsoft-com:office:office' xmlns:w='urn:schemas-microsoft-com:office:word' xmlns='http://www.w3.org/TR/REC-html40'><head><meta charset='utf-8'><title>Export HTML To Doc</title></head><body>";
    var postHtml = '</body></html>';
    var html = preHtml + document.getElementById(element).innerHTML + postHtml;
    var blob = new Blob(['\ufeff', html], {
      type: 'application/msword',
    });

    // Specify link url
    var url =
      'data:application/vnd.ms-word;charset=utf-8,' + encodeURIComponent(html);

    // Specify file name
    filename = filename ? filename + '.doc' : 'document.doc';

    // Create download link element
    var downloadLink = document.createElement('a');

    document.body.appendChild(downloadLink);

    if ((navigator as any).msSaveOrOpenBlob) {
      (navigator as any).msSaveOrOpenBlob(blob, filename);
    } else {
      // Create a link to the file
      downloadLink.href = url;

      // Setting the file name
      downloadLink.download = filename;

      //triggering the function
      downloadLink.click();
    }

    document.body.removeChild(downloadLink);
    $('.barcodeSection').css('display', 'initial');
  }
  getSettingsData(): any {
    this.pdfService.getMedicalUnitDetailsV2('').subscribe(
      (data) => {
        this.settingData = data.medical_unit;
        if (this.settingData) {
          this.checkApiCall.settingsData = true;
          this.allApiSuccess(this.checkApiCall);
        }
      },
      (err) => {
        toastr.error(err.error.error);
      },
    );
  }

  getPaddingData(): any {
    this.configService.getPACSUrlPublicV2().subscribe(
      (data) => {
        this.headerHeight = data.radiology_configurator.header_height;
        this.footerHeight = data.radiology_configurator.footer_height;
        if (data.radiology_configurator) {
          this.checkApiCall.PaddingData = true;
          this.allApiSuccess(this.checkApiCall);
        }
      },
      (err) => {
        toastr.error(err.error.error);
      },
    );
  }

  allApiSuccess(obj): any {
    if (
      obj.fetchRadiology &&
      obj.settingsData &&
      obj.controlsData &&
      obj.PaddingData &&
      obj.setControl &&
      obj.patientData &&
      obj.SummaryControl
    ) {
      // Check if the device is not a tablet or larger
      setTimeout(() => {
        if (window.innerWidth < 768) {
          window.print();
        }
      }, 500);
    }
  }
  getRadiologyPACSUrls(): any {
    this.configService.getPACSUrlV2().subscribe(
      (data) => {
        if (data.radiology_configurator) {
            this.showServiceNameOnRadiology = data?.radiology_configurator?.show_service_name_on_radiology ?? false;
        } else {
          toastr.error(data.message);
        }
        this.ngxLoader.stop();
      },
      (err) => {
        this.ngxLoader.stop();
        toastr.error(err.error.error);
      },
    );
  }
}
