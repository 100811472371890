import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { map } from 'rxjs/operators';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable()
export class DialysisService {
  constructor(private http: HttpClient) {}

  fetchDialysisRequests(queryType, query): Observable<any> {
    const results = this.http
      .get(
        `${environment.rorUrl}/v4/dialysis_requests?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&query=' +
          query +
          '&type=' +
          queryType,
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  fetchDialysisRequestsV2(queryType, query): Observable<any> {
    const results = this.http
      .get(
        `${environment.pythonUrl}/api/dialysis_patients?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&query=' +
          query +
          '&type=' +
          queryType,
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  fetchPatientData(value): Observable<any> {
    const results = this.http
      .get(
        `${environment.pythonUrl}/api/doc_dialysis_notes/send_doc_notes/?auth_token=` +
        localStorage.getItem('auth_token') +
        '&medical_unit_id=' +
        localStorage.getItem('current_medical_unit_id') +
        '&patient_id=' +
        value.patient.id +
        '&dialysis_request_id=' +
        value.id,
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }

  getDialysisDoctorNotes(dialysisId, patientId): Observable<any> {
    const results = this.http
      .get(
        `${environment.pythonUrl}/api/doc_dialysis_notes/send_doc_notes/?auth_token=` +
        localStorage.getItem('auth_token') +
        '&medical_unit_id=' +
        localStorage.getItem('current_medical_unit_id') +
        '&patient_id=' +
        patientId +
        '&dialysis_request_id=' +
        dialysisId,
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }

  create(dialysis_request, patient_id): Observable<any> {
    const results = this.http
      .post(
        `${environment.rorUrl}/v4/dialysis_requests?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&patient_id=' +
          patient_id,
        {
          dialysis_request,
        },
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }

  fetchAvailabaleSlots(date): Observable<any> {
    const results = this.http
      .get(
        `${environment.rorUrl}/v4/available_slots/fetch_by_date?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&date=' +
          date,
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }

  createPatientDislysis(dialysis_patient, patient_id): Observable<any> {
    const results = this.http
      .post(
        `${environment.rorUrl}/v4/dialysis_patients?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&patient_id=' +
          patient_id,
        {
          dialysis_patient,
        },
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  createPatientDislysisV2(dialysis_patient, patient_id): Observable<any> {
    const results = this.http
      .post(
        `${environment.pythonUrl}/api/dialysis_patients/?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&patient_id=' +
          patient_id,
        {
          dialysis_patient,
        },
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  createDoctorNotes(dialysis_patient, patient, question, dialysis_request, visit, discharge_type, discharge_type_comments, is_emr, id): Observable<any> {
    const results = this.http
      .post(
        `${environment.pythonUrl}/api/doc_dialysis_notes/?auth_token=` +
        localStorage.getItem('auth_token') +
        '&medical_unit_id=' +
        localStorage.getItem('current_medical_unit_id'),
        {
          dialysis_patient,
          patient,
          question,
          dialysis_request,
          visit,
          id,
          discharge_type,
          discharge_type_comments,
          is_emr
        },
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  updatePatientDislysis(dialysis_patient, patient_id): Observable<any> {
    const results = this.http
      .put(
        `${environment.rorUrl}/v4/dialysis_patients/` +
          dialysis_patient.id +
          `?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&patient_id=' +
          patient_id,
        {
          dialysis_patient,
        },
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  updatePatientDislysisV2(dialysis_patient, patient_id): Observable<any> {
    const results = this.http
      .put(
        `${environment.pythonUrl}/api/dialysis_patients/` +
          dialysis_patient.id +
          `/?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&patient_id=' +
          patient_id,
        {
          dialysis_patient,
        },
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  fetchEquipments(category): Observable<any> {
    const results = this.http
      .get(
        `${environment.rorUrl}/v4/equipments?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&category=' +
          category,
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  fetchEquipmentsV2(category): Observable<any> {
    const results = this.http
      .get(
        `${environment.pythonUrl}/api/equipments?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&category=' +
          category,
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }

  createDialysisService(dialysisService: any): Observable<any> {
    const results = this.http
      .post(
        `${environment.rorUrl}/v4/dialysis_services?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&department_id=' +
          dialysisService.department_id +
          '&department_service_id=' +
          dialysisService.department_service_id,
        {
          dialysis_service: dialysisService,
        },
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  createDialysisServiceV2(dialysisService: any): Observable<any> {
    const results = this.http
      .post(
        `${environment.pythonUrl}/api/dialysis_services/?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&department_id=' +
          dialysisService.department_id +
          '&department_service_id=' +
          dialysisService.department_service_id,
        {
          dialysis_service: dialysisService,
        },
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  indexDialysisService(
    current_page: any,
    per_page: any,
    search_query: any,
  ): Observable<any> {
    const results = this.http
      .get(
        `${environment.rorUrl}/v4/dialysis_services?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&page=' +
          current_page +
          '&per_page=' +
          per_page +
          '&search_string=' +
          search_query,
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  indexDialysisServiceV2(
    current_page: any,
    per_page: any,
    search_query: any,
  ): Observable<any> {
    const results = this.http
      .get(
        `${environment.pythonUrl}/api/dialysis_services/?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&page=' +
          current_page +
          '&per_page=' +
          per_page +
          '&search_string=' +
          search_query,
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }

  showDialysisService(id: number): Observable<any> {
    const results = this.http
      .get(
        `${environment.rorUrl}/v4/dialysis_services/` +
          id +
          `?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  showDialysisServiceV2(id: number): Observable<any> {
    const results = this.http
      .get(
        `${environment.pythonUrl}/api/dialysis_services/` +
          id +
          `/?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }

  updateDialysisService(
    dialysisService: any,
    id: number,
    serviceId: number,
  ): Observable<any> {
    const results = this.http
      .put(
        `${environment.rorUrl}/v4/dialysis_services/` +
          id +
          `?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&service_id=' +
          serviceId,
        {
          dialysis_service: dialysisService,
        },
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  updateDialysisServiceV2(
    dialysisService: any,
    id: number,
    serviceId: number,
  ): Observable<any> {
    const results = this.http
      .put(
        `${environment.pythonUrl}/api/dialysis_services/` +
          id +
          '/?auth_token=' +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&service_id=' +
          serviceId,
        {
          dialysis_service: dialysisService,
        },
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }

  fetchHeparin(): Observable<any> {
    const results = this.http
      .get(
        `${environment.rorUrl}/v4/dialysis_configurations?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  fetchHeparinV2(): Observable<any> {
    const results = this.http
      .get(
        `${environment.pythonUrl}/api/dialysis_configurations?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }

  // for dialysis default config
  createDialysisPrimingSol(value: any): Observable<any> {
    const results = this.http
      .post(
        `${environment.rorUrl}/v4/default_configurators?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          default_configurator: value,
        },
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  getDialysisPrimingSol(): Observable<any> {
    const results = this.http
      .get(
        `${environment.rorUrl}/v4/default_configurators?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  getDeliveredReports(
    currentPage: any,
    perPage: any,
    lab_filters,
  ): Observable<any> {
    const results = this.http
      .get(
        `${environment.rorUrl}/v4/dialysis_patients/completed_dialysis?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&from_date=' +
          lab_filters.from_date +
          '&to_date=' +
          lab_filters.to_date +
          '&patient_id=' +
          lab_filters.patient_id +
          '&page=' +
          currentPage +
          '&per_page=' +
          perPage,
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }

  getDeliveredReportsV2(
    currentPage: any,
    perPage: any,
    lab_filters,
  ): Observable<any> {
    const results = this.http
      .get(
        `${environment.pythonUrl}/api/dialysis_patients/completed_dialysis/?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&from_date=' +
          lab_filters.from_date +
          '&to_date=' +
          lab_filters.to_date +
          '&patient_id=' +
          lab_filters.patient_id +
          '&hcv=' +
          lab_filters.hcv +
          '&hiv=' +
          lab_filters.hiv +
          '&hbs_ag=' +
          lab_filters.hbs_ag +
          '&doctor_id=' +
          lab_filters.doctor_id +
          '&pcr=' +
          lab_filters.pcr +
          '&is_fistula_complicated=' +
          lab_filters.is_fistula_complicated +
          '&is_cv_line_complicated=' +
          lab_filters.is_cv_line_complicated +
          '&is_emr=' +
          lab_filters.is_emr +
          '&discharge_type=' +
          lab_filters.discharge_type +
          '&page=' +
          currentPage +
          '&per_page=' +
          perPage,
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }

  // for dialysis reprint
  createDialysisReport(id: any): Observable<any> {
    const results = this.http
      .put(
        `${environment.pythonUrl}/api/update_dialysis_request/` +
          id +
          `/?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {},
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  addImageInPo(file, id): Observable<any> {
    const body = new FormData();
    if (file) {
      file.forEach((item: string | Blob) => body.append('file', item));
    }
    const results = this.http.post(
      `${environment.pythonUrl}/api/upload_dialysis_patient_images/` +
        `?auth_token=` +
        localStorage.getItem('auth_token') +
        '&medical_unit_id=' +
        localStorage.getItem('current_medical_unit_id') +
        '&id=' +
        id,
      body,
      {
        headers: new HttpHeaders({
          AuthenticationToken: localStorage.getItem('auth_token'),
        }),
      },
    );
    return results;
  }
  getImagesFiles(id): Observable<any> {
    const results = this.http
      .get(
        `${environment.pythonUrl}/api/upload_dialysis_patient_images` +
          `?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&id=' +
          id,
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }

  getDialysisReportData(id): Observable<any> {
    const results = this.http
      .get(
        `${environment.pythonUrl}/api/dialysis_patients/dialysis_report_print/` +
          `?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&id=' +
          id,
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
}
