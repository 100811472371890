import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { AuthService } from '../../services/auth.service';
import { Router } from '@angular/router';
import { ShareDataService } from '../../services/share-data.service';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { environment } from '../../../environments/environment';
import { HrService } from '../../services/hr.service';
import { Configuration, OpenAIApi } from 'openai';
import { gptModels } from '../models/constants';
import { ChatWithBot, ResponseModel } from '../models/gpt-response';
import { Location } from '@angular/common';
import {FirebaseMessagingService} from "../../services/firebase-messaging.service";
import {ConfigurationService} from "../../services/configuration.service";

declare var $: any;
declare var google: any;
declare var toastr: any;
@Component({
  selector: 'app-topbar',
  templateUrl: './topbar.component.html',
  styleUrls: ['./topbar.component.css'],
})
export class TopbarComponent implements OnInit {
  currentScreen: any;
  userName: any;
  branchName: any;
  setLanguage = localStorage.getItem('language');
  firstLetter: any;
  secondLetter: any;
  employeeDetail: any;
  scrollTop = 200;
  imagePath: string;
  employeeID: any;
  userID: any;
  hospitalName = environment.hospital;

  chatConversation: ChatWithBot[] = [];
  title = 'chat-gpt';
  // @ViewChild('scrollMe') scrollMe: ElementRef;

  response!: ResponseModel | undefined;
  gptModels = gptModels;
  promptText = '';
  showSpinner = false;
  mainMenus = [];
  medicalUnitsData = [];
  medicalUnit = '';
  changeBranch: any;
  currentUser: any;
  branchList = [];

  constructor(
    private authService: AuthService,
    private router: Router,
    private shareDataService: ShareDataService,
    private ngxLoader: NgxUiLoaderService,
    private hrService: HrService,
    private location: Location,
    private messagingService: FirebaseMessagingService,
    private configurationService: ConfigurationService,
  ) {
    this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
    if (this.currentUser) {
      this.medicalUnit = this.currentUser?.medical_units[0].id;
    }
    const currentUser = JSON.parse(localStorage.getItem('currentUser'));
    this.userID = currentUser.id;
    this.employeeID = currentUser.employee_id;
    // if (this.employeeID){
    //   this.getEmployeeData();
    // }
  }

  ngOnInit(): void {
    this.medicalUnits();
    this.fetchMenu();
    $.getScript('../../assets/js/scripts.bundle.js');
    this.shareDataService.currentMessage.subscribe((message) => {
      this.currentScreen = message;
      // console.log('currentScreen', this.currentScreen);
    });

    this.userName = JSON.parse(localStorage.getItem('currentUser'));
    // this.branchName = localStorage.getItem('active_branch');
    this.firstLetter = this.userName.first_name.charAt(0);
    this.secondLetter = this.userName.last_name.charAt(0);
    if (!this.setLanguage) {
      this.setLanguage = 'english';
    } else if (this.setLanguage === 'spanish') {
      this.setLanguage = localStorage.getItem('language');
    } else {
      this.setLanguage = 'english';
    }
    this.googleTranslateElementInit();
    setTimeout(() => {
      $('.chat_on').click(function () {
        $('.Layout').toggle();
        $('.chat_on').hide(300);
      });

      $('.chat_close_icon').click(function () {
        $('.Layout').hide();
        $('.chat_on').show(300);
      });
    }, 500);
    this.mainMenus = this.userName?.role?.role_menus;
    setTimeout(() => {
      $('#screenSearch').selectpicker();
      $('#screenSearch').selectpicker('refresh');
    }, 500);
    // Register the Service Worker
    this.messagingService.registerServiceWorker();

    // Request notification permission
    this.messagingService.requestPermission();

    // Listen for incoming messages
    this.messagingService.receiveMessage();
  }
  fetchMenu(): any {
    this.ngxLoader.start();
    this.branchList = [];
    this.configurationService.getAllocatedBranches().subscribe(
      (data) => {
        this.branchList = data.branches;
        const activeBranchIdFromData = data.active_branch_id;

        const activeBranch = this.branchList.find(
          branch => branch.id === activeBranchIdFromData
        );
        if (activeBranch) {
          this.changeBranch = activeBranch.id;
        } else {
          this.changeBranch = '';
        }
        this.ngxLoader.stop();
      },
      (err) => {
        toastr.error(err.error.message);
        this.ngxLoader.stop();
      },
    );
  }
  checkResponse() {
    this.pushChatContent(this.promptText, 'You', 'person');
    this.invokeGPT();
    this.promptText = '';
  }
  pushChatContent(content: string, person: string, cssClass: string) {
    const chatToPush: ChatWithBot = {
      person: person,
      response: content,
      cssClass: cssClass,
    };
    this.chatConversation.push(chatToPush);
    setTimeout(() => {
      var messageBody = document.querySelector('#gptBody');
      messageBody.scrollTop =
        messageBody.scrollHeight - messageBody.clientHeight;
    }, 200);
  }
  getText(data: string) {
    return data.split('\n').filter((f) => f.length > 0);
  }

  async invokeGPT() {
    if (this.promptText.length < 2) return;

    try {
      this.response = undefined;
      let configuration = new Configuration({
        apiKey: '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',
      });
      let openai = new OpenAIApi(configuration);

      let requestData = {
        model: 'text-davinci-003', //'text-davinci-003',//"text-curie-001",
        prompt: this.promptText, //this.generatePrompt(animal),
        temperature: 0.95,
        max_tokens: 150,
        top_p: 1.0,
        frequency_penalty: 0.0,
        presence_penalty: 0.0,
      };
      this.showSpinner = true;
      let apiResponse = await openai.createCompletion(requestData);
      this.response = apiResponse.data as ResponseModel;
      this.pushChatContent(this.response.choices[0].text.trim(), 'AI', 'bot');
      this.showSpinner = false;
    } catch (error) {
      this.showSpinner = false;
      // Consider adjusting the error handling logic for your use case
      if (error.response) {
        console.error(error.response.status, error.response.data);
      } else {
        console.error(`Error with OpenAI API request: ${error.message}`);
        $().addClass('active');
      }
    }
  }
  // getEmployeeData(): any {
  //   this.hrService.getStaffList(this.employeeID).subscribe(data => {
  //     this.employeeDetail = data;
  //     // this.imagePath = environment.pythonUrl;
  //   });
  // }
  googleTranslateElementInit(): any {
    // google.translate.TranslateElement(
    //   {
    //     pageLanguage: 'en',
    //     layout: google.translate.TranslateElement.InlineLayout.SIMPLE,
    //   },
    //   'google_translate_element',
    // );
  }
  getLanguage(): any {
    localStorage.setItem('language', this.setLanguage);
    window.location.reload();
  }

  setBranch(branchId): any{
    this.ngxLoader.start();
    this.authService.setBranch(this.employeeID, branchId).subscribe(
      (data) => {
        // localStorage.setItem(
        //   'active_branch',
        //   data.active_branch_name,
        // );
        // window.location.reload();
        this.ngxLoader.stop();
      },
      (err) => {
        this.ngxLoader.stop();
        toastr.error(err.error.message);
      },
    );
  }
  currentNavBar(screen, menu): any {
    if (menu.closure === true) {
      $('#promissionsAlertModal').modal('show');
      return;
    } else {
      if (screen.url_type === 'new_url' || screen.url_type === 'both') {
        this.router.navigate([screen.url]);
      } else {
        let url =
          environment.oldAdminUrl +
          '/user-verification?user_id=' +
          window.localStorage.getItem('auth_token') +
          '&redirecturl=' +
          screen.url;
        window.open(url, '_blank');
      }
    }
  }
  changeMenuScreenDropdown(url): any {
    this.router.navigate([url]);
  }
  openMenuScreenDropdown(): any {
    $('#screenSearch').selectpicker('toggle');
  }

  logout(): any {
    this.ngxLoader.start();
    this.authService
      .logOutv2(localStorage.getItem('auth_token'))
      .subscribe((data) => {
        localStorage.clear();
        this.shareDataService.changeMessage([]);
        this.router.navigate(['login-v2']);
        this.ngxLoader.stop();
        localStorage.setItem('is_session_expired', 'true');
      });
  }
  medicalUnits(): any {
    this.authService.getMedicalUnits().subscribe(
      (data) => {
        this.medicalUnitsData = data.medical_units;
        setTimeout(() => {
          $('#medical_unit').selectpicker();
          $('#medical_unit').selectpicker('refresh');
        }, 500);
      },
      (err) => {
        toastr.error(err.error.message);
      },
    );
  }
  switchMedicalUnit(): any {
    if (!this.medicalUnit) {
      toastr.error('Select Medical Unit');
      return;
    }
    this.ngxLoader.start();
    this.authService.switchMedicalUnits(this.medicalUnit).subscribe(
      (data) => {
        localStorage.setItem(
          'current_medical_unit_id',
          data.user_new_medical_unit_id,
        );
        this.currentUser.medical_units[0].id = data.user_new_medical_unit_id;
        localStorage.setItem('currentUser', JSON.stringify(this.currentUser));
        window.location.reload();
        this.ngxLoader.stop();
        toastr.success(data.message);
      },
      (err) => {
        this.ngxLoader.stop();
        toastr.error(err.error.message);
      },
    );
  }
  moveToReset(): any {
    // window.location();
    $('#kt_quick_user_close').click();
    // $('#kt_quick_user').removeClass('offcanvas-on');
    // $('.offcanvas.offcanvas-right').css('left', 'auto');
    // $('.offcanvas.offcanvas-right').css('right', '-395px');
    this.router.navigate(['profile']);
  }
}
