import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ComplaintListingComponent } from '../complaint-management/Worklist/complaint-listing/complaint-listing.component';
import { AddTaskComponent } from './add-task/add-task.component';
import { TaskStatusComponent } from './task-status/task-status.component';
import { TaskTabComponent } from './task-tab/task-tab.component';
import { TaskPriorityComponent } from './task-priority/task-priority.component';
import { UserStatusReportComponent } from './user-status-report/user-status-report.component';
import { ProjectComponent } from './project/project.component';
import { EmployeesTimesheetComponent } from './employees-timesheet/employees-timesheet.component';
import {TasksListComponent} from './tasks-list/tasks-list.component';
import {PmToolDashboardComponent} from './pm-tool-dashboard/pm-tool-dashboard.component';
import {AuthorizationGuard} from '../../services/authorization.guard';

const routes: Routes = [
  { path: 'add-task', component: AddTaskComponent, canActivate: [AuthorizationGuard] },
  { path: 'add-status', component: TaskStatusComponent, canActivate: [AuthorizationGuard] },
  { path: 'add-tab', component: TaskTabComponent, canActivate: [AuthorizationGuard] },
  { path: 'task-priority', component: TaskPriorityComponent, canActivate: [AuthorizationGuard] },
  { path: 'user-status-report', component: UserStatusReportComponent, canActivate: [AuthorizationGuard] },
  { path: 'project', component: ProjectComponent, canActivate: [AuthorizationGuard] },
  { path: 'task-list', component: TasksListComponent, canActivate: [AuthorizationGuard] },
  { path: 'order-list', component: TasksListComponent },
  { path: 'employees-timesheet', component: EmployeesTimesheetComponent, canActivate: [AuthorizationGuard] },
  { path: 'pm-tool-dashboard', component: PmToolDashboardComponent, canActivate: [AuthorizationGuard] },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class TaskManagementRoutingModule {}
