import {ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {RouteShareService} from '../../../../../services/route-share.service';
import {NgxUiLoaderService} from 'ngx-ui-loader';
import {HospitalService} from '../../../../../services/hospital.service';
import {InventoryGeneralSettingsService} from '../../../../../services/inventory-general-settings.service';
import {RosterServiceService} from '../../../../../services/roster-service.service';
import moment from 'moment';
import {DepartmentsbyhospitalService} from "../../../../../services/departmentsbyhospital.service";
import {ConfiguratorsService} from "../../../../../services/configurators.service";
import {ConfigurationService} from "../../../../../services/configuration.service";

declare var $: any;
declare var toastr: any;
@Component({
  selector: 'app-auto-late-in-deduction',
  templateUrl: './auto-late-in-deduction.component.html',
  styleUrls: ['./auto-late-in-deduction.component.css']
})
export class AutoLateInDeductionComponent implements OnInit {
  screenName = '';
  fromDate = '';
  toDate = '';
  department = '';
  designation = '';
  employee = '';
  status = '';
  settingData: any;
  stateOfHeader: any;
  depart = [];
  designationArray = [];
  employees = [];
  reportData: any;
  constructor(    private routeService: RouteShareService,
                  private ngxLoader: NgxUiLoaderService,
                  private hospitalService: HospitalService,
                  private inventoryControlService: InventoryGeneralSettingsService,
                  private RosterService: RosterServiceService,
                  private ref: ChangeDetectorRef,
                  private departmentsByHospital: DepartmentsbyhospitalService,
                  private configuratorsService: ConfiguratorsService,
                  private configurationService: ConfigurationService
  ) {
    this.screenName = this.routeService.getValue();
  }
  getSettingsData(): any {
    this.hospitalService.getMedicalUnitDetails().subscribe(
      (data) => {
        this.settingData = data.medical_unit;
      },
      (err) => {
        toastr.error(err.error.error);
      },
    );
  }
  getInventorySysControl(): any {
    this.ngxLoader.start();
    this.inventoryControlService.getInventorySystemControls().subscribe(
      (data) => {
        this.stateOfHeader = data.report_header_alignment;
        this.ngxLoader.stop();
      },
      (err) => {
        this.ngxLoader.stop();
        toastr.error(err.error.error);
      },
    );
  }
  searchReport(): any {
    const startDate = moment($('.start_date').val(), 'DD-MM-YYYY');
    const endDate = moment($('.end_date').val(), 'DD-MM-YYYY');
    const dateCheck = endDate.diff(startDate, 'days') + 1;
    if (dateCheck > 31) {
      toastr.error('Date range must be between 31 days');
      return;
    }
    const fromDate = moment($('.start_date').val(), 'DD-MM-YYYY').format('YYYY-MM-DD');
    const toDate = moment($('.end_date').val(), 'DD-MM-YYYY').format('YYYY-MM-DD');
    this.reportData = [];
    this.ngxLoader.start();
    this.RosterService.searchAutoLateInDeduction(fromDate, toDate, this.department, this.designation, this.employee, this.status).subscribe(
      (data) => {
        this.reportData = data?.employees;
        if (this.reportData.length) {
          this.reportData.forEach((employee) => {
            employee.late_in = false;
          });
          // this.totalPages = data.meta.total_count;
          this.ref.markForCheck();
          // this.getListDataCSV();
          // this.getCSV();
        }
        this.ngxLoader.stop();
      },
      (err) => {
        this.ngxLoader.stop();
        toastr.error(err.error.message);
      },
    );
  }
  submit(): any{
    const arrayForUpdate = [];
    for (let i = 0; i < this.reportData.length; i++) {
      if ($('#late_in' + i).prop('checked') === true) {
        arrayForUpdate.push(this.reportData[i]);
      }
    }
    if (!arrayForUpdate.length){
      toastr.error('Select Record');
      return;
    }
    this.ngxLoader.start();
    this.RosterService.submitAutoLateInDeduction(arrayForUpdate).subscribe(
      (data) => {
        toastr.success('Submit Successfully');
        this.ngxLoader.stop();
      },
      (err) => {
        this.ngxLoader.stop();
        toastr.error(err.error.message);
      },
    );
  }
  getDate(): any {
    setTimeout(() => {
      $('.start_date').datepicker({
        format: 'dd-mm-yyyy',
        orientation: 'bottom-left',
      });
      $('.start_date').datepicker('setDate', new Date());
      $('.end_date').datepicker({
        format: 'dd-mm-yyyy',
        orientation: 'bottom-left',
      });
      $('.end_date').datepicker('setDate', new Date());
    }, 500);
  }
  getDepartments(): any {
    setTimeout((e) => {
      $('.selectpicker').selectpicker('refresh');
    }, 500);
    this.departmentsByHospital.getOpdDepartmentsListV2().subscribe((data) => {
      this.depart = data.departments;
      setTimeout((e) => {
        $('.selectpicker').selectpicker('refresh');
      }, 500);
      setTimeout((e) => {
        $('.selectpicker').selectpicker();
      }, 500);
    });
  }
  getDesignation(): any {
    setTimeout((e) => {
      $('.selectpicker').selectpicker('refresh');
    }, 500);
    this.configuratorsService
      .fetchConfigurationV2('designation')
      .subscribe((data) => {
        this.designationArray = data.configurator_types;
        setTimeout((e) => {
          $('.selectpicker').selectpicker('refresh');
        }, 500);
        setTimeout((e) => {
          $('.selectpicker').selectpicker();
        }, 500);
      });
  }
  getEmployeesOfDepartment(): any {
    this.employees = [];
    setTimeout((e) => {
      $('#employee').selectpicker('refresh');
    }, 200);
    this.configurationService
      .getEmployeesOfSpecificDepartment('')
      .subscribe(
        (data) => {
          this.employees = data;
          setTimeout((e) => {
            $('#employee').selectpicker('refresh');
            $('#employee').selectpicker();
          }, 500);
        },
        (err) => {
          setTimeout((e) => {
            $('#employee').selectpicker('refresh');
          }, 500);
          this.ngxLoader.stop();
          toastr.error(err.error.message);
        },
      );
  }

  ngOnInit(): void {
    this.getDate();
    this.getEmployeesOfDepartment();
    this.getDepartments();
    this.getDesignation();
    this.getSettingsData();
    this.getInventorySysControl();
  }

}
