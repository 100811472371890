<!--begin::Content-->
<div class="content d-flex flex-column flex-column-fluid" id="kt_content">
  <!--begin::Subheader-->
  <div
    class="subheader py-2 py-lg-6 subheader-solid"
    id="kt_subheader"
    *ngIf="extractedPath !== 'rad-report-pdf'"
  >
    <div
      class="container-fluid d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap"
    >
      <!--begin::Info-->
      <div class="d-flex align-items-center flex-wrap mr-1">
        <!--begin::Page Heading-->
        <div class="d-flex align-items-baseline flexbrCode-wrap mr-5">
          <!--begin::Page Title-->
          <h5 class="text-dark font-weight-bold my-1 mr-5">Radiology Report</h5>
          <!--          tab headings-->

          <!--end::Page Title-->
          <!--begin::Info-->

          <!--end::Info-->
        </div>
        <!--end::Page Heading-->
      </div>
      <!--end::Info-->

      <!--begin::Toolbar-->
      <div class="d-flex align-items-center">
        <button class="btn btn-light-primary btn-sm" (click)="printReport()">
          Print
        </button>
        <button
          class="btn btn-light-primary btn-sm ml-5"
          (click)="downloadDocReport()"
        >
          Download .docx
        </button>
        <!-- <button class="btn btn-primary btn-sm ml-2" (click)="goBack()">Back</button> -->
      </div>
      <!--end::Toolbar-->
    </div>
  </div>
  <!--end::Subheader-->

  <!--begin::Entry-->
  <div class="d-flex flex-column-fluid main-content">
    <!--begin::Container-->
    <!--    container-xl container-xxl-->
    <div class="container-fluid">
      <!--begin:: Content-->

      <div class="card pdf-card googoose-wrapper" id="reports">
        <div class="card-body">
          <div
            *ngIf="extractedPath === 'rad-report-pdf'"
            style="padding-bottom: 5px"
            class="d-print-none"
          >
            <button
              class="btn btn-light-primary btn-sm"
              (click)="printReport()"
            >
              Print
            </button>
          </div>

          <table style="width: 100%">
            <thead>
              <tr>
                <th>
                  <div
                    *ngIf="showHeader === false || showHeader === 'false'"
                    [ngStyle]="{ height: headerHeight + 'px' }"
                  ></div>
                  <table
                    class="table"
                    *ngIf="showHeader === true || showHeader === 'true'"
                  >
                    <tbody>
                      <tr class="borders">
                        <td style="width: 15%; padding: 0">
                          <img
                            [src]="settingData?.logo?.url"
                            style="width: auto; height: 106px"
                          />
                        </td>
                        <td style="width: 70%; vertical-align: middle">
                          <div class="font-size-h2 text-center">
                            <h3 class="hosp-name">
                              {{ settingData?.title }}
                            </h3>
                          </div>
                          <div class="font-size-h3 text-center">
                            <h5 class="hosp-address">
                              {{ settingData?.address }}
                            </h5>
                          </div>
                          <!--                          <div class="text-center"><h3>INVOICE </h3></div>-->
                        </td>
                        <td style="width: 15%"></td>
                      </tr>
                    </tbody>
                  </table>
                </th>
              </tr>
            </thead>

            <tbody>
              <tr>
                <td>
                  <div style="border-bottom: 3px solid #000000">
                    <app-patient-info
                      (patient)="getPatient($event)"
                      (summaryControl)="getSummaryControl($event)"
                    ></app-patient-info>
                  </div>
                  <div>
                    <h2 class="mt-8 font-size-service-heading" *ngIf="showServiceNameOnRadiology">
                      {{ patientRadiologyData?.patient_service?.service?.name }}
                    </h2>
                    <ng-container
                      *ngIf="
                        patientRadiologyData?.patient_service?.history &&
                        indication_for_exam
                      "
                    >
                      <h2 class="mt-8 font-size-service-name">
                        Indication For exam
                      </h2>
                      <div class="radiology-report-area">
                        {{ patientRadiologyData?.patient_service?.history }}
                      </div>
                    </ng-container>
                    <ng-container *ngIf="patientRadiologyData?.report">
                      <h2 class="mt-8 font-size-service-name">Result</h2>
                      <div class="radiology-report-area" id="resultHTML"></div>
                    </ng-container>
                    <ng-container *ngIf="patientRadiologyData?.impression">
                      <h2 class="mt-8 font-size-service-name">Impression</h2>
                      <div
                        class="radiology-report-area"
                        id="impressionHTML"
                      ></div>
                    </ng-container>
                    <ng-container *ngIf="patientRadiologyData?.addendum">
                      <h2 class="mt-8 font-size-service-name">Addendum</h2>
                      <div
                        class="radiology-report-area"
                        id="addendumHTML"
                      ></div>
                    </ng-container>
                  </div>
                </td>
              </tr>
            </tbody>
            <div class="row pl-5">
              <div class="col-12 pl-0">
                <div *ngFor="let template of patientRadiologyData?.radiology_report_remarks" class="mt-3">
                <span
                  *ngIf="template?.heading"
                  class="font-weight-bold footerClass"
                >{{ template?.heading }}:</span
                >
                  <span
                    *ngIf="template?.detail"
                    class="footerClass"
                    [innerHTML]="template?.detail"
                  ></span>
                </div>
              </div>
            </div>
            <tfoot>
              <tr>
                <td>
                  <div class="">
                    <div
                      [ngStyle]="{ height: footerHeight + 'px' }"
                      *ngIf="showFooter === false || showFooter === 'false'"
                    ></div>
                    <div *ngIf="showFooter === true || showFooter === 'true'">
                      <div class="">
                        <div class="">
                          <div>
                            <img
                              *ngIf="
                                hospitalName === 'cloudSetting' &&
                                  patientRadiologyData?.verified_by_id === 935;
                                else showAuthenticated
                              "
                              src="../../../../../assets/images/amjad-iqbal.png"
                              style="width: auto; height: 60px"
                            />
                            <ng-template #showAuthenticated>
                              <span
                                ><strong
                                  >Dictated & Authenticated By:
                                </strong></span
                              >
                            </ng-template>
                          </div>
                          <div>
                            <span>{{
                              patientRadiologyData?.verified_by_name
                            }}</span>
                          </div>
                          <div>
                            <span>{{
                              patientRadiologyData?.verified_by_qualification
                            }}</span>
                          </div>
                          <div>
                            <span>{{
                              patientRadiologyData?.verified_by_designation
                            }}</span>
                          </div>
                          <div>
                            <span
                              ><strong>Date & Time: </strong
                              >{{
                                patientRadiologyData?.verification_date
                                  | date: "EE, MMM dd, yy, h:mm:ss a"
                              }}</span
                            >
                          </div>

                          <div
                            *ngIf="
                              hospitalName !== 'cloudSetting' &&
                              patientRadiologyData?.verified_by_id !== 935
                            "
                          >
                            <span
                              ><strong
                                >No Signature(s) required, Electronically
                                Verified</strong
                              ></span
                            >
                          </div>
                        </div>
                        <div
                          class=""
                          *ngIf="hospitalName === 'nationalHospital'"
                        >
                          <p class="mb-0"><strong>Printed by:</strong></p>
                          <p class="mb-0">{{ uName }}</p>
                          <p class="mb-0">{{ masterUser }}</p>
                          <p>
                            {{ todayDate | date: "EE, MMM dd, yy, h:mm:ss a" }}
                          </p>
                        </div>
                        <!--          </div>-->
                      </div>
                      <!--            <p class="text-center"><b>*This is electronically generated report, no signature required.</b></p>-->
                    </div>
                  </div>
                </td>
              </tr>
            </tfoot>
          </table>
        </div>
      </div>
    </div>

    <!--end:: Content-->
  </div>
  <!--end::Container-->
</div>
<!--end::Entry-->
<!--end::Content-->
